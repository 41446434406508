<template>
    <div v-if="langCode && recordStore && (hotelsByDay[day.DayNr] || day.PaxHotel==1)" >
        <div class="entry-meta icon-box not-break-inside">
            <i class="ri-hotel-line"></i>
            <div v-if="hotelsByDay[day.DayNr]">
              <h4>{{tr('Accommodation')}}</h4>
              <p>
                {{getHotelName()}}
              </p>
            </div>
            <div v-else-if="day.PaxHotel==1">
              <h4>{{tr('Accommodation')}}</h4>
              <p>
                {{tr('Booked by yourself')}}
              </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'itinerary-body-hotel',
    props: ['day', 'crop'],
    components: {
    },
    data () {
        return {
            cityId: null,
            expanded: false,
            hotelText: true,
            hotelSelected: {},
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getHotelText (descriptions) {
            let t = _.find(descriptions, (c) => c.LanguageId==this.recordStore.LanguageId);
            if (t) return t.Description;
            return '';
        },
        setSelectedHotel (dayNr, i) {
            this.hotelSelected[dayNr] = i;
            this.hotelSelected = Object.assign({}, this.hotelSelected);
        },
        getRoomCategoryName (hotelDay) {
            let hotelList = [];
            let hotels = {};
            for (let room of hotelDay.BookingDayRooms) {
                if (!room.Hotel) continue;
                if (!room.Hotel.id) continue;
                if (!room.Hotel.RoomCategories) continue;
                if (!room.roomQuoteSelected && this.recordStore.anyQuoteSelected) continue;
                if (!hotels[room.Hotel.id]) {
                    hotelList.push(room.Hotel.id);
                    hotels[room.Hotel.id] = {rooms: [], hotelName: room.Hotel.Name};
                    if (room.HotelCategory && room.HotelCategory.Name) {
                        hotels[room.Hotel.id].HotelCategory = room.HotelCategory.Name;
                    }
                }
                for (let category of room.Hotel.RoomCategories) {
                    if (category.id == room.RoomCategory && hotels[room.Hotel.id].rooms.indexOf(category.Name)==-1) {
                        hotels[room.Hotel.id].rooms.push(category.Name);
                    }
                }
            }
            let res = [];
            for (let id of hotelList) {
                let hotel = hotels[id];
                let r = '';
                if (hotel.HotelCategory) {
                    r += hotel.HotelCategory + ' - ';
                }
                r += hotel.hotelName + ' (' + hotel.rooms.join(', ') + ')';
                res.push(r);

            }
            return res.join(' / ');
        },
        getCityImage (city) {
            if (!city) return;
            if (city.Image && city.Image.url) return city.Image.url;
        },
        getHotelName () {
            let res = [];
            if (this.hotelsByDay[this.day.DayNr] && this.hotelsByDay[this.day.DayNr].BookingDayRooms) {
                for (let room of this.hotelsByDay[this.day.DayNr].BookingDayRooms) {
                    if (!room.Hotel) continue;
                    if (res.indexOf(room.Hotel.Name) == -1) {
                        res.push(room.Hotel.Name);
                    }
                }
            }
            return res.join(', ');
        }
   },
}
</script>


