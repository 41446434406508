<template>
  <div class="modal fade" id="report-task" tabindex="-1" role="dialog" aria-labelledby="report-task"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-extra-large" role="document">
      <div class="modal-content modal-large">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Report Task')}}</h5>
        </div>
        <div class="modal-body form-group form-default" v-if="ready">
              <table class="table table-striped  table-sm table-bordered" v-if="!showRecord">
                  <thead>
                      <tr class="table-secondary">
                          <th><span>{{tr('Name')}}</span></th>
                          <th><span>{{tr('Disabled')}}</span></th>
                          <th><span>{{tr('Process')}}</span></th>
                          <th><span>{{tr('Emails')}}</span></th>
                          <th><span>{{tr('User')}}</span></th>
                          <th><span>{{tr('Days')}}</span></th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="c in tasks" class="text-center">
                          <td>{{c.Name}}</td>
                          <td>{{c.Disabled? tr('YES'): tr('NO')}}</td>
                          <td>{{c.CronTask.Name}}</td>
                          <td>{{c.CreatedUser.Name}}</td>
                          <td>{{c.Emails}}</td>
                          <td>{{getDays(c)}}</td>
                          <td>
                              <button type="button" class="btn btn-primary btn-sm" @click="edit(c.id)">
                                  {{tr('Edit')}}
                              </button>
                          </td>
                      </tr>
                  </tbody>
              </table>

              <record-window
                  v-if="showRecord"
                  ref="recordWindow"
                  table="reporttask"
                  :id="id"
                  :newRecord="newRecord"
                  @close="closeRecord"
                  modal="1"
              ></record-window>
              <i class="add-item cursor-pointer" @click="addRow()">
                  <font-awesome-icon icon="plus-circle"/>
              </i>
        </div>
        <div class="modal-footer" v-if="!showRecord">
            <button type="button" class="btn btn-primary" @click="save">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import recordWindow from '@/components/tools/RecordWindow';
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'report-task',
    mixins: [escape],
    props: ['endpoint', 'show', 'fields', 'values'],
    components: {
        'record-window': recordWindow,
    },
    data () {
        return {
            ready: false,
            id: null,
            showRecord: false,
            newRecord: null,
            tasks: [],
            interval: null,
        }
    },
    async mounted () {
        $('#report-task').modal({backdrop: 'static', keyboard: false}, 'show');
        await this.getTasks();
    },
    computed: {
    },
    methods: {
        async getTasks () {
            this.ready = false;
            let tasks = await api.get('/api/reporttask/by_endpoint/', {endpoint: this.endpoint});
            if (tasks) {
                this.tasks = tasks;
                this.ready = true;
            }
        },
        save () {
            this.close();
        },
        close () {
            this.$emit('update:show', false);
            $('#report-task').modal('hide');
        },
        addRow () {
            let newRecord = {};
            newRecord.Endpoint = this.endpoint;
            this.id = 'new';
            let p = this.getValues();
            newRecord.Data = JSON.stringify(p);
            newRecord.current = p;
            newRecord.reportFields = this.fields;
            this.newRecord = newRecord;
            this.showRecord = true;
        },
        getValues () {
            let p = {};
            for (let field of this.fields) {
                if (!this.values[field.name]) continue;
                  p[field.name] = this.values[field.name];
                if (field.editor == 'date') {
                    let days = moment(this.values[field.name]).diff(moment(new Date()), 'days');
                    p[field.name] = {days}
                }
            }
            return p;
        },
        async edit (id) {
            this.id = id;
            this.showRecord = true;
            await this.$nextTick();
            let p = this.getValues();
            this.interval = setInterval(() => {
                let comp = this.$refs.recordWindow;
                let record = comp.record;
                if (record) {
                    record.current = p;
                    record.reportFields = this.fields;
                    let reportLabels = comp.$refs.reportLabels;
                    if (reportLabels && reportLabels[0]) reportLabels[0].onMount();
                    clearInterval(this.interval)
                }
            }, 500);
        },
        closeRecord () {
            this.showRecord = false;
            this.newRecord = null;
            this.getTasks();
        },
        getDays (r) {
            if (!r.ByWeekDay) return tr('All Days');

            let res = [];
            for (let d of [0, 1, 2, 3, 4, 5, 6]) {
                if (r.WeekDays[d]) {
                    res.push(tr(tools.getWeekDayName(d)).substring(0, 2));
                } else {
                    res.push('X');
                }
            }
            return res.join(' ');
        }

    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
}
</script>
