<template>
    <div v-if="images">
      <lin-gallery :items="images" v-if="images.length>1"></lin-gallery>
      <div class="carousel slide" v-else-if="images.length==1">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <async-img :url="images[0].src" class="d-block w-100"></async-img>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import lingallery from 'lingallery';
const linGallery = importVueComp('components/tools', 'LinGallery');
import Vue from 'vue';
import { mapState } from "vuex";

export default {
    name: 'day-images',
    props: ['day', 'crop', 's', 'images', 'imgClass'],
    components: {
      'lin-gallery': linGallery,
    },
    mounted () {
        //console.log(this.day.DayNr, this.day.Image.url)
    },
    data () {
        return {
            active: 0,
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        getExtraServiceImages () {
            let res = [];
            if (!this.day) return res;
            let s = this.day.BookingDayServices[0];
            if (s && s.BookingDayServiceImages) {
              for (let image of s.BookingDayServiceImages) {
                  if (!image.Image) continue;
                  res.push(image.Image);
              }
            }
            return res;
        },
    }
}
</script>

