<template>
      <div class="tooltip-box ">
        <div class="tooltip1 d-flex align-items-center flex-column" :class="c.pos" :id="'in-app-' + _uid" v-if="c">
          <div class="in-app-text"
              :class="positions">
              {{c.text}}
          </div>
          <button type="button" class="btn btn-sm btn-outline-secondary" @click="ok">{{tr('OK')}}</button>
        </div>
      </div>
</template>

<script>
export default {
  name: 'in-app',
  props: [],
  data () {
      return {
          values: [],
          index: 0,
          c: null,
      }
  },
  computed: {
      positions () {
          return {
              'pos-bottom': this.c.pos == 'bottom',
              'pos-top': this.c.pos == 'top',
              'pos-left': this.c.pos == 'left',
              'pos-right': this.c.pos == 'right',
              'pos-bottom-left': this.c.pos == 'bottom-left',
              'pos-bottom-right': this.c.pos == 'bottom-right',
          }
      }
  },
  mounted () {
      this.values.push({divId: 'navbarNotifications', pos: 'bottom-left', text: ' fjdksf d fdskf jdkslfj'});
      this.values.push({divId: 'serviceSelectorTitle', pos: 'bottom', text: 'fjdksf d fdskf jdkslfj dlksfj kldsjfklds fds fdsf dkslfjsd fdsf'});
      setTimeout(() => {
          this.setValues(this.values[0])
      }, 1000)
  },
  methods: {
      setValues (value) {
          return;
          let divId = value.divId;
          let pos = value.pos;
          this.c = value
          setTimeout(() => {
              let div = $('#' + divId);
              let self = $('#in-app-' + this._uid);
              let p = div.offset();
              let top;
              let left;
              let width;
              if (pos == 'bottom') {
                  top = p.top + div.innerHeight();
                  left = p.left + div.innerWidth() / 2 - 120;
              }
              if (pos == 'top') {
                  top = p.top - div.innerHeight();
                  left = p.left + div.innerWidth() / 2 - 120;
              }
              if (pos == 'left') {
                  top = p.top - 30;
                  left = p.left -  self.innerWidth() / 2;
              }
              if (pos == 'bottom-left') {
                  top = p.top;
                  left = p.left - self.innerWidth() / 2;
              }
              if (pos == 'right') {
                  top = p.top - 30;
                  left = p.left + div.innerWidth();
              }
              if (pos == 'bottom-right') {
                  top = p.top;
                  left = p.left + div.innerWidth();
              }
              if (this.c.x) left += this.c.x;
              if (this.c.y) top += this.c.y;
              self.css({top, left})
          }, 100 )
      },
      ok () {
          this.index += 1;
          this.c = null;
          if (this.values[this.index]) {
              setTimeout(() => {
                  this.setValues(this.values[this.index]);
              }, 100);
          }
      },
      push (value) {
          if (value.validTo) {
              if (moment() > moment(value.validTo)) return;
          }
          this.values.push(value);
          setTimeout(() => {
              this.setValues(this.values[this.index]);
          }, 100);
      }
  }
};
</script>

<style scoped>
tooltip-box {
  position: absolute;
  display: block;
}

.tooltip-box .tooltip1 {
  position: absolute;
  opacity: 1;
}

.tooltip1 {
  color: #ffffff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  z-index: 2000;
  background: #a782e8;
}

.top {
    width: 240px;
    max-width: 240px;
    margin-top: -70px;
}

.bottom {
    width: 240px;
    max-width: 240px;
    margin-top: 10px;
}

.left, .bottom-left {
  min-width: 240px;
  max-width: 240px;
  margin-left: -120px;
  height: 90px;
  max-height: 90px;
}

.right, .bottom-right {
  min-width: 240px;
  max-width: 240px;
  height: 90px;
  max-height: 90px;
  margin-left: 0px;
}

.in-app-text {
  max-width: 300px;
  display: inline-block;
  word-break: break-word;
  overflow-wrap: break-word;
}


.in-app-text::after {
  content: " ";
  position: absolute;
  border-style: solid;
  z-index: 2000;
}

.pos-top::after {
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-color: #a782e8 transparent transparent transparent;
}

.pos-bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-color: transparent transparent #a782e8 transparent;
}

.pos-left::after {
  top: 30px;
  right: -30px;
  margin-left: -15px;
  border-width: 15px;
  border-color: transparent transparent transparent #a782e8 ;
}

.pos-bottom-left::after {
  top: 5px;
  right: -30px;
  margin-left: -15px;
  border-width: 15px;
  border-color: transparent transparent transparent #a782e8 ;
}

.pos-right::after {
  top: 30px;
  left: -15px;
  margin-left: -15px;
  border-width: 15px;
  border-color: transparent #a782e8 transparent transparent  ;
}

.pos-bottom-right::after {
  top: 5px;
  left: -15px;
  margin-left: -15px;
  border-width: 15px;
  border-color: transparent #a782e8 transparent transparent  ;
}


.btn {
  color: #fff;
}

</style>