<template>
    <img :src="imagesPath" v-if="imagesPath" :width="width">
</template>

<script>
export default {
    name: 'async-img',
    props: ['path', 'img', 'width', 'url'],
    data () {
        return {
            imagesPath: null
        }
    },
    async mounted () {
       this.imagesPath = await this.getImagePath();
    },
    methods: {
        async getImagePath () {
            if (this.url) return this.url;
            if (!this.path) return this.url;
            let res = null;
            let includes = this.path.includes('extra');
            let path = '';
            if (includes) {
                path = this.path.replace('extra/', '');
                let fileName = this.img;
                await Promise.resolve(import(`@/extra/${path}/${fileName}`)).then((value) => {
                    res = value.default;
                });
            } else {
                let fileName = this.img;
                await Promise.resolve(import(`@/img/${fileName}`)).then((value) => {
                    res = value.default;
                });
            }
            return res;
            return this.path;
        }
    }
}
</script>
