<template>
      <report-window
        v-if="ready"
        endpoint="/custom/reports/operation_status"
        :fields="fields"
        :title="cardTitle || title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :columnsClasses="columnsClasses"
        :notFilterable="options && options.notFilterable"
        :headerColumns="options? options.headerColumns: null"
        :columnClick="columnClick"
        :current.sync="current"
        :slotsFields="slotsFields"
        :columnsFormat="columnsFormat"
        :fieldClasses="fieldClasses"
        ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'oper-status-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            operManager: state => state.main.operManager,
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        if (this.user.Department && this.user.Department.Oper && !this.managerActions && !this.operManager) {
            this.current.OperUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'OperUserId');
            field.disabled = true;
        }
        if (this.user.Department && this.user.Department.Sales && !this.managerActions && !this.operManager) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        let bookingView = api.importMixinModule('booking', 'views');
        return {
            current: {},
            columnsClasses: {},
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "From",
                },
                {
                    name: "ToDate",
                    editor: "date",
                    label: "To",
                },
                {name: 'OperUserId', label: 'Oper User', editor: 'select', addBlank: true,
                    optionLabels: 'UserName', relation: 'user',
                    filters: (row) => {
                        if (row.Closed) return false;
                        let department = _.find(api.tables.department, (r) => r.id == row.DepartmentId);
                        if (department) {
                            if (department.Oper) return true;
                        }
                    }
                },
                {name: 'SalesUserId', label: 'Seller', editor: 'select', columns: 2,
                  optionLabels: 'UserName', relation: 'user', addBlank: true,
                  filters: (row) => {
                      if (row.Closed) return false;
                      if (row.Department && row.Department.Sales) return true;
                  }
                },

            ],
            title: 'Operation Status',
            headers: {
                BookingId: 'File Nr',
                StartDate: 'Date Inn',
                OperUser: 'Oper User',
                PaxName: 'Passenger',
                ConfirmedDate: 'Confirmed Date',
                OperAssignDate: 'Fecha Asignación'

            },
            templates: [
                {
                    name: "StartDate",
                    callback: (row) =>  {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "ConfirmedDate",
                    callback: (row) =>  {
                        if (!row.ConfirmedDate) return "";
                        return moment(row.ConfirmedDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "OperAssignDate",
                    callback: (row) =>  {
                        if (!row.OperAssignDate) return "";
                        return moment(row.OperAssignDate).format("DD/MM/YYYY");
                    }
                },
                {name: 'Status', callback: (row) => {
                        return tr(bookingView.bookingStatus[row.Status]);
                    }
                },
            ],
            slotsFields: [
                {name: 'Color', editor: 'icon', icon: 'circle', classes: 'mb-0'},
            ],
            columnsFormat: {
            },
            fieldClasses: {
                BookingId: (row) => {
                    return 'cursor-pointer-report';
                },
                Color: (row) => {
                    if (row.Color) return `status-${row.Color}`;
                }
            },
            columnClick: {
                BookingId: this.showBooking,
            },

      }
    },
    methods: {
        showBooking (row) {
            this.$router.push({ name: 'my-booking', params: {id: row.BookingId.toString(), TemplateType: 'NONE'} })
        },
    }
}
</script>

<style>
    .status-green {
        color: green;
    }
    .status-orange {
        color: orange;
    }
    .status-blue {
        color: blue;
    }
    .status-red {
        color: red;
    }

</style>