<template>
    <div class="modal fade bd-example-modal-lg" id="booking-changes" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="templateBookingChanges" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
          <div class="modal-body text-center" v-if="ready">
            <h4>{{tr('Changes History')}}</h4>
            <table class="table table-striped  table-sm table-bordered">
                <thead>
                    <tr class="table-secondary">
                        <th>{{tr('Date')}}</th>
                        <th>{{tr('File Status')}}</th>
                        <th colspan="2">{{tr('Type')}}</th>
                        <th>{{tr('Detail')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row of changes">
                        <td>{{row.CreatedUTC | toLocalDateTime}}</td>
                        <td>{{status(row.Status)}}</td>
                        <td>{{actions[row.Type]}}</td>
                        <td>{{tr(row.ServiceType)}}</td>
                        <td>{{row.Text}}</td>
                    </tr>
                </tbody>
            </table>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
let bookingView = api.importMixinModule('booking', 'views');
import { mapState } from 'vuex';
export default {
    name: 'booking-changes',
    props: ['show'],
    mixins: [escape],
    data () {
        return {
            ready: false,
            changes: [],
            actions: {
                MOVE: tr('Day Change'),
                ADD: tr('New'),
                REMOVE: tr('Deleted'),
                CHANGE: tr('Change')
            }
        }
    },
    async mounted () {
        $('#booking-changes').modal({backdrop: 'static', keyboard: false}, 'show');
        this.changes = await api.get('/api/bookingchange/', {filters: JSON.stringify({BookingId: this.recordStore.id})})
        this.ready = true;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    methods: {
        close () {
            this.$emit('update:show', false);
            $('#booking-changes').modal('hide');
        },
        status (s) {
            return tr(bookingView.bookingStatus[s])
        }
    },
}
</script>
