<template>
    <report-window v-if="ready"
        endpoint="/reports/filesoper"
        :perPage="500"
        :fields="fields"
        :title="title"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :current.sync="current"
        :headers="headers"
        :notFilterable="options && options.notFilterable"
        :totalsOn="totalsOn"
        :beforeRender="beforeRender"
        :totalLabel="totalLabel"
    ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";

export default {
    name: "files-oper-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        let bookingView = api.importMixinModule('booking', 'views');
        let bookingStatusOptions = bookingView.bookingStatusOptions;
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "From",
                    //required: true,
                    columns: 6
                },
                {
                    name: "ToDate",
                    editor: "date",
                    label: "To",
                    //required: true,
                    columns: 6
                },
                {name: 'FilterDate', label: 'Filter Date by', editor: 'select', columns: 12, addBlank: true,
                    options: [
                        {value: '0', label: 'Sales Date'},
                        {value: '1', label: 'Date Inn'},
                        {value: '2', label: 'Date Out'},
                    ], required: true,
                },
                { name: 'BookingStatus', label: 'File Status', editor: 'select',
                      options: bookingStatusOptions, addBlank: true, list: true
                },
                {
                    name: 'CustomerTypeId', relation: 'customertype', optionLabels: 'Name',
                    editor: 'select', label: 'Customer Type', addBlank: true
                },
                { name: 'GroupByMonth', label: 'Group by month', editor: 'checkbox'},
            ],
            title: "Oper User Files",
            headers: {
                CNT: "Files",
                OperUserName: "User",
            },
            totalsOn: ['CNT'],
            totalLabel: ['OperUserName']
        };
    },
    methods: {
        beforeRender (report) {
            for (let column of report.columnsNames) {
                if (column != 'OperUserName') {
                    this.totalsOn.push(column)
                }
            }
            this.totalsOn = Object.assign([], this.totalsOn);
        },

    }
};
</script>
