var EventBus = require('@/tools/event-bus').default;

const main = {
    state: {
        modified: false,
        isNew: false,
        user: null,
        loaded: false,
        processing: false,
        toPath: null,
        callConfirmClose: false,
        search: {},
        abmFilters: null,
        filters: false,
        tablePage: null,
        tableOrderBy: {ascending: true, column: null},
        adminActions: null,
        salesActions: null,
        operActions: null,
        operManager: null,
        managerActions: null,
        systemManager: null,
        baseCurrency: null,
        record: null,
        modalRecord: null,
        fullScreen: false,
        modalMinTitle: null,
        salesLeader: null,
        dmcManager: null,
        mobile: false,
    },
    mutations: {
        setUser (state, user) {
            state.user = Object.assign({}, user);
            state.adminActions = tools.canAccess(user, 'api','GET', 'admin_actions', false);
            state.salesActions =  tools.canAccess(user, 'api','GET', 'sales_actions', false);
            state.operActions =  tools.canAccess(user, 'api','GET', 'oper_actions', false);
            state.operManager =  tools.canAccess(user, 'api','GET', 'oper_manager', false);
            state.managerActions =  tools.canAccess(user, 'api','GET', 'manager_actions', false);
            state.systemManager =  tools.canAccess(user, 'api','GET', 'system_manager', false) || user.UserName == 'admin';
            state.salesLeader = tools.canAccess(user, 'api','GET', 'sales_leader', false);
            state.dmcManager =  tools.canAccess(user, 'api','GET', 'dmc_manager', false);
        },
        setActions (state, {action, value}) {
            state[action] = value;
        },
        setProcessing (state, value) {
            state.processing = value;
        },
        setMobile (state, value) {
            state.mobile = value;
        },
        setModified (state, value) {
            state.modified = value;
        },
        setNew (state, value) {
            state.isNew = value;
        },
        setCallConfirmClose (state, value) {
            state.callConfirmClose = value;
        },
        setToPath (state, value) {
            state.toPath = value;
        },
        setSearch (state, {value, key}) {
            state.search[key] = value;
        },
        setFilters (state, value) {
            state.filters = value;
        },
        setAbmFilters (state, value) {
            state.abmFilters = value;
        },
        setTablePage (state, value) {
            state.tablePage = value;
        },
        setTableOrderBy (state, value) {
            state.tableOrderBy = value;
        },
        setBaseCurrency (state, value) {
            state.baseCurrency = value;
        },
        setFullScreen (state, value) {
            state.fullScreen = value;
        },
        initRecord (state, {record, modal}) {
            if (modal) {
                state.modalRecord = record;
                return;
            }
            state.record = record;
        },
        setModalMinTitle (state, value) {
            if (!value) return '...';
            state.modalMinTitle = value.substring(0, 15) + '...';
        }
    },
    getters: {
        modified: state => {
            return state.modified;
        },
        isNew: state => {
            return state.isNew;
        }
    },
    actions: {
        async setBaseCurrency ({commit}) {
            commit('setBaseCurrency', await api.get('/api/get_base_currency/'));
        },
        async setSearchValue ({commit}, {value}) {
            //commit('setSearchValue', value);
            EventBus.$emit('search-value', value);
        },
        async callAction ({commit, state}, {params, action, args, modalRecord}) {
            let obj = modalRecord? state.modalRecord: state.record;
            if (params) {
                for (let i in params) {
                    let p = params[i];
                    if (i<params.length) {
                        obj = obj[p];
                    } else {
                        //console.log('stop')
                    }
                }
            }
            if (obj && action && obj[action]) {
                await obj[action](args);
            }
        },

    },

}

export default main;
