<template>
    <div>
        <iframe :src="url" frameborder="0" width="100%" height="200px"></iframe>
    </div>
</template>

<script>
export default {
    name: 'i-frame',
    props: ['url'],
}
</script>
