<template>
  <div class="modal fade" id="confirmClose" tabindex="-1" role="dialog" aria-labelledby="confirmCloseLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          {{tr('Save Changes?')}}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saveClose">{{tr('YES')}}</button>
          <button type="button" class="btn btn-danger" @click="confirmClose">{{tr('NO')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'confirm-close',
    mixins: [escape],
    mounted () {
        $('#confirmClose').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        saveClose () {
            this.$store.commit('setCallConfirmClose', false);
            this.$emit('saveClose');
            $('#confirmClose').modal('hide');
        },
        confirmClose () {
            this.$store.commit('setCallConfirmClose', false);
            vueTools.setModified(false);
            $('#confirmClose').modal('hide');
            this.$emit('confirmClose');
        },
        close () {
            this.$store.commit('setCallConfirmClose', false);
            $('#confirmClose').modal('hide');
            this.$emit('cancelClose');
        }
    },

}
</script>
