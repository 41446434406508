<template>
    <div>
        <div v-if="hotelInfo.firstDay">
            <div class="form-row">
                <label class='my-0' :class="hotelInfo.serviceClassName">
                    {{tr('Accommodation')}}: {{getHotelNames}}
                </label>
            </div>
            <div class="form-row">
                <i><label v-if="hotelInfo.Days==1">1 {{tr('Night')}}, </label>
                <span v-else-if="hotelInfo.Days>1">{{hotelInfo.Days}} {{tr('Nights')}}, </span>
                <span>{{tr('Room')}}:</span>
                <span>{{hotelInfo.roomTypesNames}}</span></i>
            </div>
        </div>
        <div v-else>
            <label>{{tr('Accommodation')}}: {{getHotelNames}}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'hotel-day',
    props: ['hotelInfo', 'dayNr'],
    data () {
        return {
            roomField: {name: 'RoomTypeId', optionLabels: 'Name', relation: 'roomtype'},
        }
    },
    async mounted () {
    },
    computed: {
        getHotelNames () {
            let res = [];
            if (this.hotelInfo.BookingDayRooms[0] && this.hotelInfo.BookingDayRooms[0].Hotel) {
                return this.hotelInfo.BookingDayRooms[0].Hotel.Name;
            }
            return '';
        },
    },
    methods: {
    }
}

</script>
