<template>
    <div class="">
        <div class="form-check checkbox-group" v-if="canSplitQuoteDate">
            <input v-model="record.SplitQuoteDate" class="form-check-input" type="checkbox"
            @change="setSplitQuoteDate()" :disabled="!salesActions"
            id="SplitQuoteDate">
            <label for="SplitQuoteDate" class="smaller form-check-label">{{tr('Quote in other date')}}</label>
        </div>
        <div class="form-check checkbox-group">
            <input v-model="record.HideTotalPrice" class="form-check-input" type="checkbox"
            @change="change('HideTotalPrice')" id="HideTotalPrice">
            <label for="HideTotalPrice" class="smaller form-check-label">{{tr('Hide Total Price')}}</label>
        </div>
        <div class="form-check checkbox-group">
            <input v-model="record.PriceBreakdown" class="form-check-input" type="checkbox"
            @change="change('PriceBreakdown')"
            id="PriceBreakdown">
            <label for="PriceBreakdown" class="smaller form-check-label">{{tr('Prices Breakdown')}}</label>
        </div>
        <div class="form-check checkbox-group" v-if="record.PriceBreakdown">
            <input v-model="record.HidePriceBreakdown" class="form-check-input" type="checkbox"
            @change="change('HidePriceBreakdown')"
                id="HidePriceBreakdown">
            <label for="HidePriceBreakdown" class="smaller form-check-label">{{tr('Hide Breakdown Prices')}}</label>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote-config-checkbox',
    data () {
        return {
            record: {},
        }
    },
    mounted () {
        this.setRecord();
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
        }),
        canSplitQuoteDate () {
            return true;
        },
    },
    methods: {
        setRecord () {
            this.record = tools.plainObject(this.recordStore, true);
        },
        async change (fieldName) {
            await this.recordStore.setValue({fieldName, value: this.record[fieldName]});
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        async setSplitQuoteDate () {
            this.change('SplitQuoteDate');
            this.setRecord();
        },
    },
}
</script>

