<template>
    <div v-if="recordStore">
        <section id="blog" class="blog">
          <div class="container" id="container-blog">
            <div class="row">
              <div class="col-12 entries">
                <itinerary-body-day
                    v-for="(day, idx) of recordStore.BookingDays"
                    :key="idx"
                    :day="day"
                    :images="images"
                    :crop="crop"
                    :canEdit="canEdit"
                    :expanded="expanded"
                ></itinerary-body-day>
              </div>
            </div>
          </div>
        </section>
    </div>
</template>

<script>
const itineraryBodyDay = importVueComp('components/itinerary', 'ItineraryBodyDay');
import { mapState } from 'vuex';
import ResizeSensor from "resize-sensor";
export default {
    name: 'itinerary-body',
    props: ['crop', 'expanded', 'images', 'canEdit'],
    components: {
        'itinerary-body-day': itineraryBodyDay,
    },
    mounted () {
        if ($('.app-index').length > 0) {
            new ResizeSensor($('#container-blog'), () => {
                let c = $('#container-blog');
                let w = $('#blog').width();
                if (w > 1300) {
                    c.css('padding-left','15px');
                    c.css('padding-right','15px');
                } else if (w > 1100 ) {
                    c.css('padding-left','30px');
                    c.css('padding-right','30px');
                } else if (w > 950 ) {
                    c.css('padding-left','45px');
                    c.css('padding-right','45px');
                } else {
                    c.css('padding-left','60px');
                    c.css('padding-right','60px');
                }
            });
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        getCardClassDays () {
            let res = {};
            let cnt = 0;
            for (let i=0; i<this.recordStore.BookingDays.length; i++) {
                if (this.recordStore.skipDays[i]) continue;
                cnt += 1;
                if (cnt % 2) {
                    res[i] = 'acc-grey';
                } else {
                    res[i] = 'acc-white';
                }
            }
            return res;
        },
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getDayMonth (day) {
            return moment(this.recordStore.StartDate).add(day.DayNr,'days').locale(this.recordStore.Language.Code).format("MMM").substring(0,3);
        },
        getDayNr (day) {
            return moment(this.recordStore.StartDate).add(day.DayNr,'days').format("DD");
        },
        getDatesText (day) {
            if (day.GroupDays && day.GroupDayTo) {
                let r1 = this.getDayNr(day) + ' ' + this.getDayMonth(day);
                let r2 = '';
                let dayTo = _.find(this.recordStore.BookingDays, (p) => p.DayNr == day.GroupDayTo - 1);
                if (dayTo) {
                    r2 = this.getDayNr(dayTo) + ' ' + this.getDayMonth(dayTo);
                }
                return `${this.tr('From')} ${r1} ${this.tr('TO_')} ${r2}`;
            }
            return this.getDayNr(day) + ' ' + this.getDayMonth(day);
        },
        getCitiesNames (day) {
            return day.getCitiesNames(this.recordStore.hotelsByDay[day.DayNr]);
        },
        getCardClass (idx) {
            if (this.expanded) return 'acc-grey';
            return this.getCardClassDays[idx];
        },
        getDayNumber (day) {
            if (day.GroupDays && day.GroupDayTo) {
                let r = parseInt(day.DayNr) + 1;
                return `${this.tr('_From')} ${this.tr('Day')} ${r} ${this.tr('_TO')} ${day.GroupDayTo}`;
            }
            return parseInt(day.DayNr) + 1;
        },
   },
}
</script>


