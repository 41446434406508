<template>
      <report-window
        v-if="ready"
        endpoint="/reports/servicelist"
        :fields="fields"
        :title="cardTitle || title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :columnClick="columnClick"
        :notFilterable="options && options.notFilterable"
        :headerColumns="options? options.headerColumns: null"
        :current.sync="current"
        :slotsFields="[]"
        :fieldClasses="fieldClasses"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'service-list-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
    },
    mounted () {
    frontTools.setReportValues(this);
        /*if (this.user.Department && this.user.Department.Sales && !this.managerActions) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }*/
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        let bookingView = api.importMixinModule('booking', 'views');
        let bookingDayServiceView = api.importMixinModule('bookingdayservice', 'views');
        let bookingStatusOptions = bookingView.bookingStatusOptions;
        bookingStatusOptions.push({value: 'ALL_CONFIRMED', label: 'All Confirmed'});
        return {
            current: {},
            ready: false,
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From', requiredCallback: (r, c) => !c.BookingId},
              {name: 'ToDate', editor: 'date', label: 'To', requiredCallback: (r, c) => !c.BookingId},
              {name: 'BookingId', editor: 'number', decimales: 0, label: 'File'},
              {name: 'ServiceStatus', label: 'Service Status', editor: 'select',
                      options: bookingDayServiceView.serviceStatusOptions, addBlank: true},
              {name: 'BookingStatus', label: 'File Status', editor: 'select',
                      options: bookingStatusOptions, addBlank: true},
              {name: 'OperUserId', relation: 'user', optionLabels: 'UserName'
                  , editor: 'select', label: 'Oper User', addBlank: true,
                  filters: (row) => {
                      if (row.Closed) return false;
                      let department = _.find(api.tables.department, (r) => r.id == row.DepartmentId);
                      if (department) {
                          if (department.Oper) return true;
                      }
                  },
              },
              {name: 'SalesUserId', relation: 'user', optionLabels: 'UserName'
                  , editor: 'select', label: 'Seller', addBlank: true, filters: (row) => {return !row.Closed}
              },
              {name: 'SupplierId', relation: 'supplier', optionLabels: 'Name', label: 'Supplier',
                addBlank: true, editor: 'vue-select', list: true,
                requiredCallback: this.checkDates
              },
              { name: 'IncludeFlights', label: 'Flights', editor: 'select', defValue: '0', 
                options: [
                      { value : '0', label: 'Not Include' },
                      { value : '1', label: 'Include' },
                      { value : '2', label: 'Only Flights' },
                ]
              },
            ],
            title: 'Service/Hotel List',
            headers: {
              UserName: 'Seller',
              OperUserName: 'Oper User',
              PaxName: 'Passenger',
              ServiceName: 'Service',
              BookingStatus: 'File Status',
              Status: 'Service Status',
              SupplierName: 'Supplier',
            },
            templates: [
                {name: 'ServiceDate', callback: (row) => {
                    if (!row.ServiceDate) return '';
                    return moment(row.ServiceDate).format("DD/MM/YYYY")
                  }
                },
                {name: 'PaymentDate', callback: (row) => {
                    if (!row.PaymentDate) return '';
                    return moment(row.PaymentDate).format("DD/MM/YYYY")
                  }
                },
                {name: 'Status', callback: (row) => {
                    return tr(bookingDayServiceView.serviceStatus[row.Status]);
                }},
                {name: 'BookingStatus', callback: (row) => {
                    return tr(bookingView.bookingStatus[row.BookingStatus])
                }},
            ],
            columnClick: {
                File: this.showBooking,
            },
            fieldClasses: {
                File: (row) => {
                    return 'cursor-pointer-report';
                },
            },
        }
    },
    methods: {
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-booking', {id: row.File.toString(), TemplateType: 'NONE'});
        },
        checkDates (r, t) {
            return;
            if (!t.FromDate) return true;
            if (!t.ToDate) return true;
            let dateTo = moment(t.FromDate).add(10, 'days').format("YYYY-MM-DD");
            if (dateTo < t.ToDate) return true;
        }
    }
}
</script>

