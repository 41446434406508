<template>
  <div class="modal fade" id="paidVoucher" tabindex="-1" role="dialog" aria-labelledby="payModalLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-large" role="document">
      <div class="modal-content">
        <div class="modal-body form-material p-5" v-if="ready" id="payModalBody">
            <div class="form-row">
                <span class="col-3">Pagamos a la orden de: </span>
                <span class="col-9"><b>{{supplier.CompanyName || supplier.Name}}</b></span>
                <span class="col-9 offset-3 h6">{{supplier.Address}}</span>
            </div>
            <div class="form-row">
                <span class="col-3 offset-9 h6"><b>Fecha:</b> {{currentDate}}</span>
            </div>
            <div class="form-row mt-2">
                <span class="col-3 offset-9 h6"><b>Turismo</b></span>
            </div>
            <hr class="hr-row mt-0 mb-3">
            <div class="form-row">
                <span class="col-4"><b>Importe $</b></span>
                <span class="col-8"><b>Tipo de Valor - Observaciones</b></span>
            </div>
            <div class="form-row mt-3">
                <span class="col-4">{{total | formatNumber({d: 2})}} Efectivo</span>
            </div>
            <div class="form-row mt-3">
                <span class="col-4 offset-8"><b>Total a pagar: $ {{total | formatNumber({d: 2})}}</b></span>
            </div>
            <hr class="hr-row mt-3 mb-1">
            <div class="form-row">
                <span class="col-4"><b>Se aplica a los siguientes comprobantes</b></span>
            </div>
            <div class="form-row" v-for="row of rows">
                <span class="col-6 offset-4">File: {{row.BookingId}} - Cliente: {{row.PaxName}}: {{resNumber(row.BookingId)}}</span>
                <span class="col-2 text-right">$ {{row.PaymentAmount | formatNumber({d: 2})}}</span>
            </div>
            <div class="form-row mt-3">
                <span class="col-2 offset-8"><b>Total a pagar: </b></span>
                <span class="col-2 text-right"><b>$ {{total | formatNumber({d: 2})}}</b></span>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="printScreen" :disabled="printing">Envir Correo</button>
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'paid-voucher',
    mixins: [escape],
    props: ['show', 'report'],
    data () {
        return {
            ready: false,
            services: [],
            printing: false,
        }
    },
    computed: {
        rows () {
            let res = [];
            for (let row of this.report.dataList) {
                if (row.Selected) {
                    res.push(row);
                }
            }
            return res;
        },
        total () {
            let t = 0;
            for (let row of this.report.dataList) {
                if (row.Selected) {
                    t += row.PaymentAmount;
                }
            }
            return t;
        },
        supplier () {
            for (let row of this.report.dataList) {
                if (row.Selected) {
                    return row.Supplier;
                }
            }
            return {};
        },
        currentDate () {
            return moment(new Date(), "YYYY-MM-DD").format("DD/MM/YYYY");
        },
        bookingIds () {
            return _.map(this.rows, (r) => r.BookingId);
        }
    },
    async mounted () {
        if (this.$parent && this.$parent.$refs && this.$parent.$refs.reportWindow) {
            $('#' + this.$parent.$refs.reportWindow._uid).toggleClass('container-report');
        }
        $('#paidVoucher').modal({backdrop: 'static', keyboard: false}, 'show');
        if (this.supplier) {
            let data = {BookingIds: this.bookingIds, SupplierId: this.supplier.id}
            let res = await api.get('/custom/reports/services_by_supplier', data);
            if (res) {
                this.services = res;
            }
        }
        this.ready = true;
    },
    methods: {
        print () {
            window.print();
        },
        printScreen () {
            this.printing = true;
            frontTools.printScreen('payModalBody', 'pagos.png', (res) => {
                let src;
                if (res.key) src = api.serverEndpoint + '/api/get_attach/?key=' + res.key;
                this.printing = false;
                this.close()
                setTimeout(() => {
                    let mailRecord = {
                        MailFrom: api.currentUser.Email,
                        Subject: 'Detalle de Pagos',
                        UserId: api.currentUser.id,
                        Status: 'CREATED',
                        Datetime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                        MailAttachments: [
                            { Key: res.key, Name: res.file_name }
                        ]
                    }
                    if (this.supplier && this.supplier.Email) {
                        mailRecord.MailTo = this.supplier.Email;
                    }
                    this.$root.$children[0].openModalRecord({modalRecord: mailRecord, modalTable: 'mail', modalId: 'new'});
                }, 1000);
            })
        },
        close () {
            if (this.$parent && this.$parent.$refs && this.$parent.$refs.reportWindow) {
                $('#' + this.$parent.$refs.reportWindow._uid).toggleClass('container-report');
            }
            this.$emit('update:show', false);
            $('#paidVoucher').modal('hide');
        },
        resNumber (bookingId) {
            if (this.services[bookingId]) return this.services[bookingId].join(',');
            return '';
        }
    }
}
</script>

