<template>
          <div class="" v-if="recordStore">
                <div class="card-body form-material px-0">
                    <div class="form-row col-md-12 mx-0 px-0">
                        <div class="col-md-1 section-title">
                            <label class="ml-3">{{tr('Services')}}</label>
                        </div>
                        <div class="form-row col-md-11 d-flex align-items-center mx-0 px-0">
                          <div :class="serviceCols">
                          </div>
                          <div class="d-flex justify-content-center form-row" :class="pricesCols" v-if="recordStore.QuoteBy!='QUANTITY'">
                              <div class="form-row" :class="paxCols"
                                   v-for="pType of getPaxTypesByBase" v-if="quote[pType]">
                                      <p class="col-md-9 offset-3">{{pType}}</p>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div class="form-row col-md-12 mx-0 px-0 border-bottom">
                        <div class="form-row col-md-12 mx-0 px-0">
                          <div class="col-md-1">
                          </div>
                          <div class="form-row col-md-11 d-flex align-items-center mx-0 px-0">
                            <div class="form-row col-md-12 mx-0 px-0">
                                <div :class="serviceCols">
                                </div>
                                <div class="d-flex justify-content-center form-row" :class="pricesCols" v-if="recordStore.QuoteBy!='QUANTITY'">
                                    <div class="form-row text-center" :class="paxCols"
                                        v-for="pType of getPaxTypesByBase" v-if="quote[pType]">
                                        <div class="col service-row col-input">
                                            <label>{{tr('Cost')}}</label>
                                        </div>
                                        <div class="col service-row col-input">
                                            <label>{{tr('Markup')}}</label>
                                        </div>
                                        <div class="col service-row col-input">
                                            <label>{{tr('Gross')}}</label>
                                        </div>
                                        <div class="col service-row col-input">
                                            <label v-if="edit">{{tr('Base')}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center form-row px-0 mx-0 service-quantity" :class="pricesCols" v-else>
                                    <div class="form-row col-md-12 text-center px-0 mx-0 ">
                                        <div class="col service-row col-input">
                                            <label>{{tr('Cost')}}</label>
                                        </div>
                                        <div class="col service-row col-input">
                                            <label>{{tr('Markup')}}</label>
                                        </div>
                                        <div class="col service-row col-input">
                                            <label>{{tr('Gross')}}</label>
                                        </div>
                                        <div class="col service-row col-input-sm">
                                            <label>{{tr('Quant.')}}</label>
                                        </div>
                                        <div class="col service-row col-input-xsm" v-if="recordStore.BaseQuotes[baseId].TourLeader">
                                        </div>
                                        <div class="col service-row col-input">
                                            <label>{{tr('Cost Total')}}</label>
                                        </div>
                                        <div class="col service-row col-input">
                                            <label>{{tr('Gross Total')}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div class="form-row col-md-12 px-0 mx-0" >
                        <div v-for="(day, dayId) of recordStore.BookingDays" class="form-row col-md-12 border-bottom px-0 mx-0"
                            :class="getBGClass(dayId)" >
                            <div class="col-md-1 px-2 d-flex align-items-center" v-if="showServices(day)">
                                <div class="text-center">
                                    <span class="text-nowrap">{{date(day)}}</span>&nbsp
                                    <span class="text-nowrap">{{tr('Day')}} {{day.DayNr+1}}</span>
                                </div>
                            </div>
                            <div class="form-row col-md-11 d-flex align-items-center mx-0 px-0"
                                v-if="recordStore.QuoteBy!='QUANTITY'">
                                <booking-quote-service
                                    class="form-row col-md-12"
                                    :class="{'service-strong': focusDayId == dayId && i == focusService}"
                                    v-for="(s, i) of day.BookingDayServices"
                                    :key="day.DayNr + '-' + i"
                                    :dayId="dayId"
                                    :dayNr="day.DayNr"
                                    :edit="edit"
                                    :baseId="baseId"
                                    :i="i"
                                    @handleClick="handleClick"
                                    @focusOn="focusOnService"
                                ></booking-quote-service>
                            </div>
                            <div class="form-row col-md-11 d-flex align-items-center mx-0 px-0" v-else>
                                <booking-quote-service-quant
                                    class="form-row col-md-12 mx-0 px-0"
                                    :class="{'service-strong': focusDayId == dayId && i == focusService}"
                                    v-for="(s, i) of day.BookingDayServices"
                                    :key="day.DayNr + '-' + i +'Q'"
                                    :dayId="dayId"
                                    :dayNr="day.DayNr"
                                    :edit="edit"
                                    :baseId="baseId"
                                    :i="i"
                                    @handleClick="handleClick"
                                    @focusOn="focusOnService"
                                ></booking-quote-service-quant>
                            </div>
                        </div>
                    </div>
                    <div class="form-row col-md-12 mx-0 px-0">
                      <div class="form-row col-md-12 mx-0 px-0" :class="{'service-quantity': recordStore.QuoteBy=='QUANTITY'}">
                        <div class="col-md-1 service-row">
                        </div>
                        <div class="form-row col-md-11 d-flex align-items-center mx-0 px-0">
                          <div class="service-row" :class="serviceCols">
                              <label>{{tr('Total Services')}}</label>
                          </div>
                          <div class="d-flex justify-content-center form-row mx-0 px-0" :class="pricesCols">
                            <div class="row cost-input-row" :class="paxCols"
                                v-for="pType of getPaxTypesByBase"
                                v-if="totalsServices && totalsServices[baseId] && totalsServices[baseId][pType] && recordStore.BaseQuotes[baseId][pType]">
                                <div class="form-group col service-row col-input" v-if="recordStore.QuoteBy=='QUANTITY'">
                                </div>
                                <div class="form-group col service-row col-input" v-if="recordStore.QuoteBy=='QUANTITY'">
                                </div>
                                <div class="form-group col service-row col-input" v-if="recordStore.QuoteBy=='QUANTITY'">
                                </div>
                                <div class="form-group col service-row col-input-sm" v-if="recordStore.QuoteBy=='QUANTITY'">
                                </div>
                                <div class="col service-row col-input-xsm" v-if="recordStore.BaseQuotes[baseId].TourLeader">
                                </div>
                                <div class="form-group col service-row col-input">
                                    <number-input
                                        :changeStyle="true"
                                        class="my-0 mx-1"
                                        :v="totalsServices[baseId][pType].Cost"
                                        disabled="true" :currency="recordStore.Currency"
                                    ></number-input>
                                </div>
                                <div class="form-group col service-row col-input" v-if="!user.AgencyId && recordStore.QuoteBy!='QUANTITY'">
                                </div>
                                <div class="form-group col service-row col-input">
                                    <number-input
                                        :changeStyle="true"
                                        class="my-0 mx-1"
                                        :v="totalsServices[baseId][pType].Price"
                                        disabled="true"
                                        :currency="recordStore.Currency"
                                    ></number-input>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row col-md-12 mt-3 border-bottom px-0 mx-0">
                        <div class="form-row col-md-12 px-0 mx-0">
                            <div class="form-row col-md-12 px-0 mx-0">
                                <div class="form-group service-row section-title col-md-1">
                                    <label class="ml-3">{{tr('Hotels')}}</label>
                                </div>
                                <div class="form-group service-row" :class="hotelCols">
                                </div>
                                <div class="form-row d-flex justify-content-center text-center col-md-5 mx-0"
                                    v-if="recordStore.QuoteBy!='QUANTITY'"
                                    :class="{'pr-0 pl-5': recordStore.QuoteBy!='QUANTITY', 'px-0': recordStore.QuoteBy=='QUANTITY'}">
                                    <div class="form-row col-md-12">
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Cost/Pax Stay')}}</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Markup')}}</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Gross / Pax Stay')}}</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Cost / Room Night')}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row d-flex justify-content-center text-center col-md-8 pr-0 mx-0 service-pl-5 service-quantity" v-else>
                                    <div class="form-row col-md-12">
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Cost')}}*</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Markup')}}</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Gross')}}</label>
                                        </div>
                                        <div class="form-group col col-input-sm service-row">
                                            <label>{{tr('Quant.')}}</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Cost Total')}}</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>{{tr('Gross Total')}}</label>
                                        </div>
                                        <div class="form-group col col-input service-row">
                                            <label>*{{tr('Cost by')}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-for="(day, dayId) of recordStore.BookingDays" class="form-row col-md-12 border-bottom px-0 mx-0"
                        v-if="day.BookingDayHotels[0]" :class="getBGClass(dayId)">
                            <div v-for="(roomRow, roomIdx) of day.BookingDayHotels[0].BookingDayRooms"
                            class="form-row col-md-12  px-0 mx-0" >
                                <booking-quote-hotel-room
                                    class="px-0 mx-0"
                                    :class="{'service-strong': focusDayId == dayId && roomIdx == focusHotel}"
                                    :key="dayId + '-' + roomIdx"
                                    :_key="dayId + '-' + roomIdx"
                                    v-if="showRoom(roomRow, day.DayNr)"
                                    :ref="dayId + '-' + roomIdx"
                                    :roomName="roomRow.Room.Name"
                                    :rooms="roomRow.Rooms"
                                    :roomRow="roomRow"
                                    :baseId="baseId"
                                    :dayId="dayId"
                                    :dayNr="day.DayNr"
                                    :edit="edit"
                                    :roomIdx="roomIdx"
                                    :room="roomRow"
                                    @editHotel="editHotel(day.DayNr, dayId)"
                                    @handleClick="handleClick"
                                    @focusOn="focusOnHotel"
                                ></booking-quote-hotel-room>
                            </div>
                    </div>
                    <div class="form-row col-md-12 border-bottom px-0 mx-0" >
                        <div class="form-row col-md-12 px-0 mx-0" v-if="totalsHotels[baseId]" :class="{'service-quantity': recordStore.QuoteBy=='QUANTITY'}">
                                <div class="form-row col-md-12 px-0 mx-0" v-for="(cost,pType) of totalsHotels[baseId]">
                                    <div class="form-group service-row col-md-1">
                                    </div>
                                    <div class="form-group service-row mt-0"  :class="hotelCols">
                                        <label>{{tr('Total Hotels')}} {{pType}}</label>
                                    </div>
                                    <div class="form-row d-flex justify-content-center pr-0 mx-0 " :class="hotelPricesCols">
                                        <div class="form-row col-md-12">
                                            <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                                            </div>
                                            <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                                            </div>
                                            <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                                            </div>
                                            <div class="form-group col col-input-sm service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                                            </div>

                                            <div class="form-group col col-input service-row">
                                                <number-input
                                                    :changeStyle="true"
                                                    class="my-0 mx-1"
                                                    :v="totalsHotels[baseId][pType].Cost"
                                                    disabled="true"
                                                    :currency="recordStore.Currency"
                                                ></number-input>
                                            </div>
                                            <div class="form-group col col-input service-row"
                                                v-if="!user.AgencyId && recordStore.QuoteBy!='QUANTITY'">
                                            </div>
                                            <div class="form-group col col-input service-row">
                                                <number-input
                                                    :changeStyle="true"
                                                    class="my-0 mx-1"
                                                    :v="totalsHotels[baseId][pType].Price"
                                                    disabled="true"
                                                    :currency="recordStore.Currency"
                                                ></number-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="form-row col-md-12 mt-3 border-bottom px-0 mx-0" v-if="quote.SingleSupplement">
                        <div class="form-row col-md-12 px-0 mx-0 border-bottom">
                            <div class="form-row col-md-12 px-0 mx-0">
                                <div class="form-group service-row section-title col">
                                    <label class="ml-3">{{tr('SGL Supplement')}}</label>
                                </div>
                            </div>
                        </div>
                        <div v-for="(day, dayId) of recordStore.BookingDays" class="form-row col-md-12 border-bottom px-0 mx-0"
                            v-if="day.BookingDayHotels[0]" :class="getBGClass(dayId)">
                                <div v-for="(roomRow, roomIdx) of day.BookingDayHotels[0].BookingDayRooms" class="form-row col-md-12  px-0 mx-0">
                                    <booking-quote-hotel-room
                                        class="px-0 mx-0"
                                        :class="{'service-strong': focusDayId == dayId && roomIdx == focusHotel}"
                                        :key="dayId + '-' + roomIdx"
                                        :_key="dayId + '-' + roomIdx"
                                        v-if="roomRow.SingleSupplement"
                                        :roomName="roomRow.Room.Name"
                                        :rooms="roomRow.Rooms"
                                        :roomRow="roomRow"
                                        :baseId="baseId"
                                        :dayId="dayId"
                                        :dayNr="day.DayNr"
                                        :edit="edit"
                                        :roomIdx="roomIdx"
                                        :room="roomRow"
                                        @editHotel="editHotel(day.DayNr, dayId)"
                                        @focusOn="focusOnHotel"
                                    ></booking-quote-hotel-room>
                                </div>
                        </div>
                    </div>


                    <booking-quote-flights
                        :baseId="baseId"
                        :edit="edit"
                    ></booking-quote-flights>
                    <div class="form-row col-md-12 mt-3">
                        <div class="col section-title">
                            <label class="ml-3">{{tr('Totals')}}</label>
                        </div>
                    </div>
                    <booking-quote-total-variant
                        v-if="hasVariants"
                        :quote.sync="quote"
                        :baseId="baseId"
                    ></booking-quote-total-variant>
                    <booking-quote-total
                        v-else
                        :quote.sync="quote"
                        :baseId="baseId"
                    ></booking-quote-total>
                </div>
                <div class="row mt-3 d-flex align-items-center">
                    <div class="offset-md-1 col-md-4">
                        <div class="form-check form-check" v-if="canUseTourLeader">
                            <input v-model="quote.TourLeader" name="TourLeader" class="form-check-input"
                            :disabled="!canEdit" @change="setTourLeader"
                            type="checkbox" id="TourLeader">
                            <label class="smaller form-check-label">{{tr('With Tour Leader')}}</label>
                            <i class="smaller ml-2 cursor-pointer" :class="{'font-weight-bold': tlRoom == 'SGL'}" @click="setTLRoom(1)">SGL Room</i>
                            / <i class="smaller ml-1  cursor-pointer" :class="{'font-weight-bold': tlRoom == 'DBL'}" @click="setTLRoom(2)">DBL Room</i>

                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-check form-check" v-if="canUseSingleSupplement">
                            <input v-model="quote.SingleSupplement" name="SingleSupplement" class="form-check-input"
                            :disabled="!canEdit" @change="setSingleSupplement"
                            type="checkbox" id="SingleSupplement">
                            <label class="smaller form-check-label">{{tr('Single Supplement')}}</label>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <i class="quote-actions cursor-pointer" @click="edit = !edit" v-if="canEdit">
                              <font-awesome-icon v-if="edit" icon="edit" size="lg"/>
                              <font-awesome-icon v-else icon="pen" size="lg"/>
                         </i>
                    </div>
                    <div class="col-md-1 cursor-pointer">
                        <div v-if="canEdit">
                            <button @click="updateCostByDate" v-if="!updating" class="quote-actions" :disabled="!canRefreshQuote">
                                <font-awesome-icon icon="sync" size="lg"/>
                            </button>
                            <button v-else class="quote-actions">
                                <font-awesome-icon icon="sync" spin size="lg"/>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <i class="quote-actions cursor-pointer" @click="copyBase" v-if="canCopy">
                            <font-awesome-icon icon="copy" size="lg"/>
                        </i>
                    </div>
                    <div class="col-md-1" v-if="canSetVariants">
                        <i class="quote-actions cursor-pointer" @click="setVariants" >
                            <font-awesome-icon icon="layer-group" size="lg" :class="{'icon-active': hasVariants}" />
                        </i>
                    </div>
                    <div class="col-md-1" v-if="canEdit">
                        <i class="quote-actions cursor-pointer" @click="removeBase">
                            <font-awesome-icon icon="trash-alt" size="lg"/>
                        </i>
                    </div>
                    <div class="col-md-1" v-if="canEditPax">
                        <i class="quote-actions cursor-pointer" @click="showInputQuote = true">
                            <font-awesome-icon icon="user-edit" size="lg"/>
                        </i>
                    </div>  
                </div>
                <hotel-selector-modal
                    v-if="hotelSelector"
                    :dayNr="currentDayNr"
                    :dayId="currentDayId"
                    @close="closeHotelSelector"
                    @delete="deleteHotel"
                    @removeRow="removeRow"
                ></hotel-selector-modal>
                <input-quote
                    v-if="showInputQuote"
                    @save="setBase"
                    @close="showInputQuote = false"
                    :defADT="recordStore.BaseQuotes[baseId].ADT"
                    :defCHD="recordStore.BaseQuotes[baseId].CHD"
                ></input-quote>
              <context-menu
                  v-if="showMenu"
                  :options="menuOptions"
                  :ref="'quoteMenu' + _uid"
                  @clicked="menuClicked"
              ></context-menu>
                <booking-quote-variants
                    :baseId="baseId"
                    :quoteVariants.sync="quoteVariants"
                    v-if="quoteVariants"
                ></booking-quote-variants>
          </div>
</template>

<script>
const bookingQuoteHotelRoom = importVueComp('components/booking', 'BookingQuoteHotelRoom');
const bookingQuoteService = importVueComp('components/booking', 'BookingQuoteService');
const bookingQuoteServiceQuant = importVueComp('components/booking', 'BookingQuoteServiceQuant');
const bookingQuoteFlights = importVueComp('components/booking', 'BookingQuoteFlights');
const hotelSelectorModal = importVueComp('components/hotel', 'HotelSelectorModal');
const bookingQuoteTotal = importVueComp('components/booking', 'BookingQuoteTotal');
const bookingQuoteTotalVariant = importVueComp('components/booking', 'BookingQuoteTotalVariant');
const inputQuote = importVueComp('components/booking', 'InputQuote');
const bookingQuoteVariants = importVueComp('components/booking', 'BookingQuoteVariants');

import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote',
    props: ['quote', 'baseId'],
    components: {
        'booking-quote-hotel-room': bookingQuoteHotelRoom,
        'booking-quote-service': bookingQuoteService,
        'booking-quote-service-quant': bookingQuoteServiceQuant,
        'booking-quote-flights': bookingQuoteFlights,
        'booking-quote-total': bookingQuoteTotal,
        'booking-quote-total-variant': bookingQuoteTotalVariant,
        'hotel-selector-modal': hotelSelectorModal,
        'input-quote': inputQuote,
        'booking-quote-variants': bookingQuoteVariants
    },
    data () {
        return {
            edit: false,
            updating: null,
            hotelSelector: false,
            currentDay: null,
            bookingCityId: null,
            hotelInfo: {},
            bookingCityName: null,
            currentBaseId: null,
            totalsServices: {},
            totalsHotels: {},
            totals: {},
            currentDayId: null,
            currentDayNr: null,
            focusDayId: null,
            focusService: null,
            focusHotel: null,
            showInputQuote: false,
            menuOptions: [],
            defMenuOptions: [
                {label: tr('Deselect All'), name: 'deselectAll'},
                {label: tr('Select All'), name: 'selectAll'},
                {label: tr('Deselect Day'), name: 'deselectDay'},
                {label: tr('Select Day'), name: 'selectDay'},
            ],
            showMenu: false,
            quoteVariants: false,
            hasVariants: false,
        }
    },
    async mounted () {
        this.setTotals();
        EventBus.$on('update-totals', this.setTotals);
        //await this.$nextTick()
        //this.$parent.loading = null;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            managerActions: state => state.main.managerActions,
        }),
        canEdit () {
            return this.salesActions;
        },
        canCopy () {
            return this.canEdit;
        },
        canRefreshQuote () {
            return this.canEdit;
        },
        canEditPax () {
            return tools.canAccess(this.user, "api", "GET", 'edit_quote_pax', false);
        },
        canUseTourLeader () {
            if (this.hasVariants) return false;
            return tools.canAccess(this.user, "api", "GET", 'use_tour_leader', false);
        },
        canSetVariants() {
            return tools.canAccess(this.user, "api", "GET", 'set_variants', false);
        },
        canUseSingleSupplement () {
            if (this.hasVariants) return false;
            return tools.canAccess(this.user, "api", "GET", 'single_supplement', false);
        },
        getPaxTypesByBase () {
            return this.recordStore.getPaxTypesByBase(this.baseId);
        },
        serviceCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'col-md-6';
            if (this.getPaxTypesByBase.length==2) return 'col-md-5';
            return 'col-md-7';
        },
        hotelCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'col-md-3';
            return 'col-md-6';
        },
        hotelPricesCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'service-pl-5 col-md-8';
            return 'pl-5 col-md-5';
        },
        pricesCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'col-md-6';
            if (this.getPaxTypesByBase.length==2) return 'col-md-7';
            return 'col-md-5';
        },
        paxCols () {
            if (this.getPaxTypesByBase.length==2) return 'col-md-6';
            return 'col-md-12';
        },
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        tlRoom () {
            if (!this.quote.TourLeader) return;
            for (let day of this.recordStore.BookingDays) {
                for (let hotel of day.BookingDayHotels) {
                    if (hotel.BookingDayRooms.length == 0) continue;
                    let roomTL = _.find(hotel.BookingDayRooms, (r) => r.TourLeader);
                    if (roomTL && roomTL.Room && roomTL.Room.Pax == 2) {
                        return 'DBL';
                    }
                    if (roomTL && roomTL.Room && roomTL.Room.Pax == 1) {
                        return 'SGL';
                    }
                }
            }
        }
    },
    methods: {
        showServices (day) {
            let found = false;
            for (let s of day.BookingDayServices) {
                if (s.Service && s.Service.NotPrice) continue;
                if (s.Optional) return true;
                if (s.Quote.QuoteList && s.Quote.QuoteList[this.baseId]) {
                    for (let p in s.Quote.QuoteList[this.baseId]) {
                        if (s.Quote.QuoteList[this.baseId][p] && s.Quote.QuoteList[this.baseId][p].Include) found = true;
                    }
                }
            }
            if (found || this.edit) {
                return true;
            }
            return false;
        },
        setTotals () {
            this.totalsServices = Object.assign({}, this.recordStore.totalsServices);
            this.totalsHotels = Object.assign({}, this.recordStore.totalsHotels);
            this.totals = Object.assign({}, this.recordStore.totals);
            this.hasVariants = this.recordStore.isVariant(this.baseId);
            //EventBus.$emit('update-summary');
        },
        getRooms (RoomConfiguration) {
            let res = []
            let roomTypes = this.recordStore.roomTypes;
            for (let base in roomTypes) {
                for (let room of RoomConfiguration) {
                    if (room.RoomTypeId==roomTypes[base].id) {
                        res.push(room)
                    }
                }
            }
            return res;
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        async updateCostByDate () {
            let selectValue = {
                text: 'Update cost edited manually?',
                yes: async () => {
                    api.setProcessing(true);
                    await this.recordStore.setCostByDate({update: true, baseId: this.baseId, updateModified: 1});
                    EventBus.$emit('update-totals');
                },
                not: async () => {
                    api.setProcessing(true);
                    await this.recordStore.setCostByDate({update: true, baseId: this.baseId});
                    EventBus.$emit('update-totals');
                }
            }
            this.$root.$children[0].openSelectYesNot(selectValue);
        },
        setSelected () {
            EventBus.$emit('update-totals');
        },
        getRoomsNames (rooms) {
            let r = [];
            for (let roomId in rooms) {
                if (rooms[roomId].Checkin) continue;
                if (r.indexOf(rooms[roomId].Name)==-1) {
                    r.push(rooms[roomId].Name);
                }
            }
            return r.join(' / ');
        },
        removeBase () {
            this.$emit('remove', this.baseId);
        },
        copyBase () {
            this.$emit('copy', this.baseId);
        },
        getBasePaxTypes (quote, baseId) {
            return this.recordStore.getBasePaxTypes(quote, baseId);
        },
        getBGClass (dayId) {
            if (dayId % 2 == 0) return 'bg-line';
            return '';
        },
        async change (value, baseId, pType, fieldName) {
            await this.recordStore.Totals[baseId][pType].setValue({fieldName, value});
            EventBus.$emit('update-totals');
            EventBus.$emit('update-quotes');
        },
        removeRow () {
            //this.setTotals();
            //console.log('removeRow')
            EventBus.$emit('update-quotes-hotels');
        },
        hasPayments (dayNr) {
            let hotelInfo = this.hotelsByDay[dayNr];
            if (!hotelInfo) return true;
            if (!hotelInfo.BookingDayRooms) return true;
            for (let room of hotelInfo.BookingDayRooms) {
                let supplierId;
                if (room.Hotel && room.Hotel.SupplierId) supplierId = room.Hotel.SupplierId;
                if (!supplierId) continue;
                let payments = _.filter(api.payments, (p) => p.SupplierId == supplierId);
                if (payments.length > 0) {
                    let res = confirm(tr('Attention! This provider has payments in the file. Before make changes, edit or remove payments. Do you want to continue?'));
                    if (!res) return false;
                }
            }
            return true
        },
        editHotel (dayNr, dayId) {
            if (!this.hasPayments(dayNr)) return;
            this.currentDayId = dayId;
            this.currentDaNr = dayNr;
            this.hotelSelector = true;
        },
        async deleteHotel () {
            await this.recordStore.BookingDays[this.currentDayId].deleteHotel()
            EventBus.$emit('update-totals');
            this.hotelSelector = false;
            this.currentDayId = null;
            this.currentDayNr = null;
        },
        closeHotelSelector () {
            this.hotelSelector = false;
            this.currentDayId = null;
            this.currentDayNr = null;
        },
        showRoom (roomRow, dayNr) {
            if (roomRow.SingleSupplement) return false;
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && roomRow.QuoteList[this.baseId] && (roomRow.QuoteList[this.baseId].Include || this.edit)) return true;
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && !roomRow.QuoteList[this.baseId]) return true;
        },
        date (day) {
            let date = day.TransDate;
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        focusOnService (dayId, i) {
            this.focusDayId = dayId;
            this.focusService = i;
            this.focusHotel = null;
        },
        focusOnHotel (dayId, i) {
            this.focusDayId = dayId;
            this.focusService = null;
            this.focusHotel = i;
        },
        async setBase (adt, chd) {
            this.showInputQuote = false;
            this.recordStore.changeBase(this.baseId, adt, chd);
            await this.updateCostByDate();
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-bases');
        },
        async setSingleSupplement () {
            let value = this.quote.SingleSupplement;
            await this.recordStore.BaseQuotes[this.baseId].setValue({fieldName: 'SingleSupplement', value})
            api.setProcessing(true);
            await this.recordStore.addSingleSupplementRoom(this.baseId, value);
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
            api.setProcessing(false);
        },
        async setTourLeader () {
            let value = this.quote.TourLeader;
            await this.recordStore.BaseQuotes[this.baseId].setValue({fieldName: 'TourLeader', value})
            api.setProcessing(true);
            await this.recordStore.addTourLeaderRoom(this.baseId, value, 1);
            await this.recordStore.setCostByDate({baseId: this.baseId});
            EventBus.$emit('update-totals');
            api.setProcessing(false);
        },
        async setTLRoom (value) {
            if (!this.quote.TourLeader) return;
            if (this.recordStore.QuoteBy == 'QUANTITY') return;
            api.setProcessing(true);
            await this.recordStore.addTourLeaderRoom(this.baseId, this.quote.TourLeader, value);
            EventBus.$emit('update-totals');
            api.setProcessing(false);
        },
        async handleClick (event, dayId, type) {
            if (!this.edit) return;
            event.preventDefault();
            this.showMenu = true;
            this.menuOptions = Object.assign([], this.defMenuOptions);
            if (type == 'h') {
                let categories = this.recordStore._hotelCategories;
                if (Object.keys(categories).length > 1) {
                    for (let id in categories) {
                        this.menuOptions.push({name: 'cat_' + id, label: 'Hotel Category ' + categories[id]})
                    }
                }
            }
            await this.$nextTick();
            setTimeout(() => {
                let id = `quoteMenu${this._uid}`
                if (this.$refs[id]) {
                    this.$refs[id].showMenu(event, {dayId, type});
                }
            }, 100);
        },
        setSelectedServices (day, value) {
            for (let service of day.BookingDayServices) {
                if (!service.Quote) continue;
                if (!service.Quote.QuoteList) continue;
                if (!service.Quote.QuoteList[this.baseId]) continue;
                for (let pType of this.recordStore.BaseQuotes[this.baseId].paxTypes) {
                    if (!service.Quote.QuoteList[this.baseId][pType]) continue;
                    service.Quote.QuoteList[this.baseId][pType].Include = value;
                }
            }
        },
        setSelectedRooms (day, value, catId) {
            for (let hotel of day.BookingDayHotels) {
                for (let room of hotel.BookingDayRooms) {
                    if (!room.QuoteList) continue;
                    if (!room.QuoteList[this.baseId]) continue;
                    if (value && catId) {
                        if (room.HotelCategoryId == catId) {
                            room.QuoteList[this.baseId].Include = true;
                        } else {
                            room.QuoteList[this.baseId].Include = false;
                        }
                    } else {
                        room.QuoteList[this.baseId].Include = value;
                    }
                }
            }
        },
        menuClicked (option, {dayId, type}) {
            if (option.name == 'deselectAll') {
                for (let day of this.recordStore.BookingDays) {
                    if (type == 's') {
                        this.setSelectedServices(day, false);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(day, false);
                    }
                }
            }
            if (option.name == 'selectAll') {
                for (let day of this.recordStore.BookingDays) {
                    if (type == 's') {
                        this.setSelectedServices(day, true);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(day, true);
                    }
                }
            }
            if (option.name == 'selectDay') {
                if (this.recordStore.BookingDays[dayId]) {
                    if (type == 's') {
                        this.setSelectedServices(this.recordStore.BookingDays[dayId], true);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(this.recordStore.BookingDays[dayId], true);
                    }
                }
            }
            if (option.name == 'deselectDay') {
                if (this.recordStore.BookingDays[dayId]) {
                    if (type == 's') {
                        this.setSelectedServices(this.recordStore.BookingDays[dayId], false);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(this.recordStore.BookingDays[dayId], false);
                    }
                }
            }
            if (type == 'h' && option.name.substring(0,3) == 'cat') {
                api.setProcessing(true)
                let catId = parseInt(option.name.split('_')[1]);
                for (let day of this.recordStore.BookingDays) {
                    this.setSelectedRooms(day, true, catId);
                }
                api.setProcessing(false)
            }
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        setVariants () {
            this.quoteVariants = true;
        }

    },
    beforeDestroy() {
        EventBus.$off('update-totals', this.setTotals);
    },
}
</script>

