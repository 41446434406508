<template>
    <div id="pstyles">
      <header id="header" class="header fixed-top" v-if="fullScreen || view">
        <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a class="logo d-flex align-items-center">
            <async-img :url="imageAgency" class="logo-back h-100" v-if="imageAgency" ></async-img>
            <img src="@/extra/custom/img/logo-itinerary.png" alt="" v-else>
          </a>
          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link cursor-pointer" @click="scrollTo('trip')">{{tr('Travel Details')}}</a></li>
              <li><a class="nav-link cursor-pointer" @click="scrollTo('blog')">{{tr('Itinerary')}}</a></li>
              <li><a class="nav-link cursor-pointer" @click="scrollTo('pricing')">{{tr('Price')}}</a></li>
              <li><a class="getstarted cursor-pointer" @click="print">{{tr('Print')}}</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
      <main id="main">
        <itinerary-header
            :canEdit="canEdit"
            :crop="crop"
            :expanded="expanded"
        ></itinerary-header>
        <itinerary-body
            :crop="crop"
            :expanded="expanded"
            :canEdit="canEdit"
        ></itinerary-body>
        <p v-if="expanded" class="page-break" style="page-break-after: always;">&nbsp;</p>
        <p v-if="expanded" class="page-break" style="page-break-before: always;">&nbsp;</p>

        <section id="sostenible" class="pricing" v-if="recordStore">
          <div class="container">
            <div class="row gy-4" >
                <div class="col-12 mt-4">
                  <div class="box">
                    <h3>{{tr('Viaje Sostenible')}}</h3>
                    <ul>
                      <li>
                          {{tr('Carbon Footprint')}}: {{recordStore.carbonFootprint}}
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
        </section>

        <itinerary-terms
            :expanded="expanded"
        ></itinerary-terms>
        <itinerary-conditions
            :expanded="expanded"
        ></itinerary-conditions>
      </main>
      <a class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </div>
</template>

<script>
const itinerary = importVueComp('components/itinerary', 'Itinerary', 'sostenible');
export default {
    name: 'itinerary',
    mixins: [itinerary]
}
</script>
