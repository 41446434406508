<template>
</template>

<script>
export default {
    name: 'rooms-mixin',
    methods: {
        evenListener (e) {
            if ([37, 39].indexOf(e.keyCode) > -1) {
                let x = document.activeElement;
                let value = x.value;
                let move;
                if (!value) {
                    move = true;
                } else {
                    let l = value.length;
                    let s = x.selectionStart;
                    if (s==0 && e.keyCode == 37) {
                        move = true
                    } else if (s==l && e.keyCode == 39) {
                        move = true;
                    } ;
                }
                if (move) {
                    let row = parseInt(x.getAttribute("row"));
                    let column = x.getAttribute("column");
                    if (!row || !column) {
                        x =  x.closest('div');
                        if (x) {
                            row = parseInt(x.getAttribute("row"));
                            column = x.getAttribute("column");
                        }
                    };
                    if (e.keyCode == 37) column = parseInt(column) -1;
                    if (e.keyCode == 39) column = parseInt(column) + 1;
                    let id = row + '-' + column;
                    let el = document.getElementById(id);
                    if (el && el.tagName == 'DIV') {
                        el = el.firstChild;
                    }
                    if (el) $(el)[0].focus();

                }
            }
            if ([38, 40].indexOf(e.keyCode) > -1) {
                let x = document.activeElement;
                if (x) {
                    let row = parseInt(x.getAttribute("row"));
                    let column = x.getAttribute("column");
                    if (!row || !column) {
                        x =  x.closest('div');
                        row = parseInt(x.getAttribute("row"));
                        column = x.getAttribute("column");
                    };
                    if (e.keyCode == 38) row = parseInt(row) -1;
                    if (e.keyCode == 40) row = parseInt(row) + 1;
                    let id = row + '-' + column;
                    let el = document.getElementById(id);
                    if (el && el.tagName == 'DIV') {
                        el = el.firstChild;
                    }
                    if (el) $(el)[0].focus();
                }
            }
        },
    },
    beforeDestroy () {
      document.removeEventListener('keydown', this.evenListener);
    },
    created () {
        setTimeout(()=> document.addEventListener("keydown", this.evenListener), 1000);
    }

}
</script>

