import store from '@/store/store';

let SuperLoadTables = vueTools.importModule('load-tables', 'tools', 'custom').default;

class LoadTables extends SuperLoadTables {

    async getPromises () {
        await super.getPromises();
        await api.setTable({table: "odstext"});
        if (api.currentUser && !this.currentRoute.meta.public) {
            await api.setTable({table: "bookingsetting"});
        }

    }

}

export default LoadTables;
