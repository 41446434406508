<template>
    <div id="pstyles" class="app-index d-flex flex-column min-vh-100" v-if="ready && recordStore">
        <header id="header" class="header">
          <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
            <a class="logo d-flex align-items-center">
              <img src="@/extra/custom/img/logo-itinerary.png" alt="" class="w-100">
            </a>
          </div>
        </header>
        <main class="flex-grow-1 d-flex flex-column">
            <price-breakdown
                :id="id"
                :hash="hash"
                class="flex-grow-1"
            ></price-breakdown>
        </main>
        <itinerary-footer class="mt-3"></itinerary-footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
const itineraryFooter = importVueComp('components/itinerary', 'ItineraryFooter');
const priceBreakdown = importVueComp('components/booking', 'PriceBreakdown');
export default {
    name: 'price-breakdown-view',
    props: ['id', 'hash'],
    components: {
        'price-breakdown': priceBreakdown,
        'itinerary-footer': itineraryFooter,
    },
    data () {
        return {
            ready: false,
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    async mounted (){
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r;
        if (this.version) {
            r = await api.get('/public/api/bookingversion/' + this.hash + '/' + this.id);
        } else {
            r = await api.get('/public/api/booking/' + this.hash + '/' + this.id);
        }
        if (r) {
            r._public = true;
            let record = await new model({record: r});
            this.$store.commit('initRecord', {record});
            if (r.DisableLinks) {
                api.setProcessing(false)
            }
        }
        this.ready = true;
    },

}
</script>

<style scoped>
.min-vh-100 {
    min-height: 100vh;
}

main {
    display: flex;
    flex-direction: column;
    min-height: 0;
}
</style>
