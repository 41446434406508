<template>
    <div class="service-selector">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label>{{title}}</label>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-8">
                <input class="form-control mr-sm-12" type="search" :placeholder="tr('Search')" aria-label="Search" v-model="searchBox">
            </div>
            <div class="form-group col-md-2">
                <button type="button" class="btn btn-primary" @click="remove">{{tr('Delete')}}</button>
            </div>
            <div class="form-group col-md-2">
                <button type="button" class="btn btn-primary" @click="add">{{tr('Done')}}</button>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <button v-for="x in tags" type="button" class="btn btn-secondary btn-service-selected">{{x}}
                  <span aria-hidden="true" class="remove-item" @click="removeTag(x)">&times;</span>
                </button>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <button v-for="s in selected" type="button" class="btn btn-primary btn-service-selected">{{s.Name}}
                  <span aria-hidden="true" class="remove-item" @click="removeSelected(s)">&times;</span>
                </button>
            </div>
        </div>
        <v-client-table :data="list" :columns="columnsNames" :options="options"
            v-if="dataList"  @row-click="onRowClick">
        </v-client-table>


        <div v-else>
            <img class="loading-gif" src="@/img/loading.gif">
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
export default {
    name: 'table-selector',
    props: ['dataList', 'title', 'columnsNames', 'fields', 'headings'],
    components: {
      ClientTable,
    },
    data () {
        return {
            searchBox: '',
            processing: false,
            options: {
                filterable: false,
                perPage: 10,
                headings: this.headings,
                destroyEventBus: true,
                texts: vueTools.vueTableText(),
                templates: {},
                skin: 'table table-striped table-sm table-bordered table-bordered-hover',
            },
            fieldOptions: {},
            selected: [],
            tags: []
        }
    },
    computed: {
        search () {
            let res = this.searchBox;
            for (let tag of this.tags){
                res += ' ' + tag
            }
            return res;
        },
        abmFields () {
            return tools.getAbmFields(this.fields);
        },
        list () {
            return tools.abmList(this);
        },
        getTemplates () {
            let self = this;
            let res = {}
            for (let field of this.abmFields) {
                res[field.name] = function(t, row) {
                        return self.getDisplayValue(row, field);
                    }
            }
            return res;
        },

    },
    created (){
        this.selected = [];
    },
    async mounted () {
        this.selected = [];
        this.fieldOptions = await tools.calculateFieldOptions(this.fields);
        this.options.templates = this.getTemplates;
    },
    methods: {
        getDisplayValue (record, field) {
            let r = tools.getDisplayValue(record, field, false, this.fieldOptions, false);
            return r;
        },
        removeSelected (record) {
            this.selected = [];
        },
        removeTag (record) {
            var i = this.tags.indexOf(record);
            if (i>-1) this.tags.splice(i, 1)
        },
        add () {
            this.$emit('add', this.selected)
            this.selected = [];
        },
        remove () {
            this.$emit('add', null)
            this.selected = [];
        },
        onRowClick (data) {
           this.selected = [data.row];
        },
        evenListener (e) {
            if (e.keyCode == 13) {
                let x = document.activeElement;
                if (x){
                    this.tags.push(x.value);
                    this.searchBox = '';
                }
            }
        }
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.evenListener);
    },
    created (){
        setTimeout(()=> document.addEventListener("keydown", this.evenListener), 1000);
    }
}
</script>


