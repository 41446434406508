<template>
    <div v-if="ready">
        <div class="card mb-3 no-border-top" v-if="recordStore.TemplateType == 'NONE'">
            <div class="card-header">
                <div class="form-row px-2">
                    <div v-for="(flight, i) of recordStore.BookingFlights" class="form-row col-md-12">
                        <booking-flight
                            class="form-row col-md-12"
                            :flight.sync="flight"
                            :i="i"
                        ></booking-flight>
                    </div>
                    <div class="col-md-12">
                        <i class="ml-3 add-item cursor-pointer" @click="addLine" v-if="canEdit">
                            <font-awesome-icon icon="plus-circle"/>
                        </i>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>{{tr('Total Cost')}}: {{totalFlights.Cost | formatNumber({c: recordStore.PriceCurrency})}}
                            {{recordStore.PriceCurrencyId}}
                        </label>
                    </div>
                    <div class="col-md-12 mt-0">
                        <label>{{tr('Total Price')}}: {{totalFlights.Price | formatNumber({c: recordStore.PriceCurrency})}}
                            {{recordStore.PriceCurrencyId}}
                        </label>
                    </div>
                    <div class="col-md-12 mt-3" v-if="!version && canAttach">
                      <attach
                        :title="tr('Tickets')"
                        :dataList.sync="recordStore.attachList"
                        :attached.sync="recordStore.attached"
                        :processing="recordStore.processingAttach"
                        :id="recordStore.id"
                        :canAttach="true"
                        :canDelete="true"
                        v-if="recordStore.attached"
                      ></attach>
                    </div>
                    <slot name="custom"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
const bookingFlight = importVueComp('components/booking', 'BookingFlight');
export default {
    name: 'booking-flights',
    props: ['version'],
    components: {
        'booking-flight': bookingFlight
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            managerActions: state => state.main.managerActions,
            operActions: state => state.main.operActions,
        }),
        canAttach () {
            return this.canEdit;
        },
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions || this.operActions;
            return true;
        },
        totalFlights () {
            let res = {Cost: 0, Price: 0};
            for (let id in this.recordStore.totalFlightBySuppliers) {
                let flight = this.recordStore.totalFlightBySuppliers[id];
                res.Cost += this.recordStore.currencyConvert(this.recordStore.CurrencyId,
                    this.recordStore.PriceCurrencyId, flight.Cost);
                res.Price += this.recordStore.currencyConvert(this.recordStore.CurrencyId,
                    this.recordStore.PriceCurrencyId, flight.Price);
            }
            return res;
        }
    },
    data  () {
        return {
            ready: false,
        }
    },
    async mounted () {
        this.ready = true;
    },
    methods: {
        addLine () {
            this.recordStore.addNewFlight(this.salesActions);
        },
        setOutput (i) {
            //this.recordStore.BookingFlights[i].setOutput(i);
        },
        async deleteFlight (i) {
            await vueTools.removeRow(this, null, 'BookingFlights', i);
        },
        canEditFlights (i) {
            let flight = this.recordStore.BookingFlights[i];
            if (flight.id && !this.canEdit) return;
            return true;
        },
        canDeleteFlights (i) {
            return this.canEditFlights(i);
        },
        async setValue (i) {
        },
        changeCost (i) {
        }

    }
};
</script>


