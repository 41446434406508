<template>
  <div class="photo-slider" id="slider">
    <input type="radio" name="slider" :id="'slide' + imageIndex" selected="false"
        v-for="(image, imageIndex) of imagesList2"
    >
    <div id="slides" class="col-12">
      <div id="overflow">
        <div class="inner" :style="style">
          <article v-for="(image, imageIndex) of imageList" :style="styleA" class="">
              <photo-croppa
                :url="image.url"
                :crop="toCrop"
                :key="image.id"
                :imgClass="imgClass"
                :resize="true"
              ></photo-croppa>
          </article>
        </div>
      </div>
      <div class="arrow-slide arrow-slide-left" v-if="arrows && p>0 && !crop" @click="setLeft()">
      </div>
      <div class="arrow-slide arrow-slide-right" v-if="arrows && p < imageList.length-1 && !crop" @click="setRight()">
      </div>
    </div>
    <div class="d-flex justify-content-center" v-if="thumbs && !crop">
      <label :for="'slide' + imageIndex" v-for="(image, imageIndex) of imagesList2" @click="setImage(imageIndex)"
        class="mt-2 mx-1">
      </label>
    </div>
  </div>
</template>

<script>

export default {
    name: 'photo-slider',
    props:  ['images', 'crop', 'thumbs', 'imgClass', 'margin', 'innerWidth', 'repeat', 'arrows', 'f', 'quantity', 'seconds'],
    data () {
        let articleWidth = this.innerWidth * this.f / (4 * this.images.length);
        return {
            imagesList2: this.images,
            imageList: this.images,
            style: 'margin-left: 0%',
            interval: null,
            t: 5000,
            p: 0,
            styleA: `width: ${articleWidth}% !important;`,
            toCrop: this.crop,
        }
    },
    mounted () {
        if (this.seconds) this.t = 1000 * this.seconds;
        if (this.repeat) {
            this.imageList = [...this.images, ...this.images]
        }
        this.setImage(0);
        this.startInterval();
    },
    methods: {
        startInterval () {
            if (this.imagesList2.length>1) {
                this.interval = setInterval(() => {
                    this.p += 1;
                    if (this.p >= this.imagesList2.length) this.p = 0;
                    this.setImage(this.p);
                }, this.t);
            }
        },
        resetInterval () {
            clearInterval(this.interval);
            this.startInterval();
        },
        setImage (i) {
            let v = this.margin * (i);
            this.p = i;
            let width = this.innerWidth * this.images.length / (this.quantity * this.f);
            this.style = `margin-left: -${v}%; width: ${width}% !important;`;
            if (!this.crop) this.resetInterval();
        },
        setLeft () {
            this.p += -1;
            this.setImage(this.p);
        },
        setRight () {
            this.p += 1;
            this.setImage(this.p);
        },
    },
    watch: {
        crop () {
            this.setImage(0);
            this.imageList = [this.images[0]];
            this.imagesList2 = [this.images[0]];
            clearInterval(this.interval);
            setTimeout(() => {
                this.toCrop = this.crop;
            }, 500);
        }
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
  }

</script>

<style scoped lang="scss">

.arrow-slide {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
    flex-shrink: 0;
    width: 2.25rem;
    height: 2.25rem;
    margin-left: auto;
    content: "";
    background-repeat: no-repeat;
    background-size: 2.25rem;
    position: absolute;
    top: 50%;
}

.arrow-slide-left {
    transform: rotate(90deg) translate(0%,-50%);
    left: 0%;
}

.arrow-slide-right {
    transform: rotate(-90deg) translate(0%,-50%);
    right: 0%;
}

.photo-slider {

    #slider {
      max-width: 600px;
      text-align: center;
      margin: 0 auto;
    }

    #overflow {
      width: 100%;
      overflow: hidden;
    }

    #slides .inner {
      width: 400%;
    }

    #slides .inner {
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -o-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);

      -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      -ms-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

      -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
      -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
      -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
      -ms-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
      transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
    }

    #slides article {
      float: left;
      padding-right: 10px;
      max-height: 300px;
    }


    input[name="slider"] {
      display: none !important;
    }

    label {
      background: #CCC;
      display: inline-block;
      cursor: pointer;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    .img-slide {
      max-width: 100%;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: bottom;
    }

}


</style>
