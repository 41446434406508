<template>
      <div class="container-fluid form-material">
        <div class="form-group row">
          <div class="col-sm-2 form-default">
            <input type="password" class="form-control" v-model="password" name="password"  placeholder="Old Password">
                <span class="form-bar"></span>
            </input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2 form-default">
            <input type="password" class="form-control" v-model="newPassword" name="newPassword"  placeholder="New Password" >
                <span class="form-bar"></span>
            </input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2 form-default">
            <input type="password" class="form-control" v-model="newPassword2" name="newPassword2"  placeholder="Re-Enter New Password" >
                <span class="form-bar"></span>
            </input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">
            <button type="button" id="submit" class="btn btn-primary" @click="change"
            :disabled="!checkNewPassword || !password">Change Password</button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-6">
            <div v-if="errors.length>0" class="alert alert-danger" role="alert">
                <div v-for="error of errors">
                    {{ error }}
                </div>
            </div>
          </div>
        </div>
      </div>
</template>



<script>
import { mapState } from 'vuex';
export default {
  name: 'change-password',
  data () {
    return {
        password: '',
        newPassword: '',
        newPassword2: '',
        errors: []
    }
  },
  computed: {
    ...mapState({
        user: state => state.main.user,
    }),
    checkNewPassword () {
        return (this.newPassword.length>0 && this.newPassword==this.newPassword2 && this.password!=this.newPassword);
    }
  },
  methods: {
    async change () {
        let data = {
            newpassword: this.newPassword,
            newpassword2: this.newPassword2,
            oldpassword: this.password
        }
        let res = await api.post('/change_password', JSON.stringify(data))
        if (res) {
            alert(tr('Password modified'));
            await api.updateUser()
            this.$store.commit('setUser', res);
            this.$router.push('/');
        }
    },
    setProcessing (value) {
        this.processing = value;
    },
    addError (msg) {
        alert(tr(msg));
    },

  }
}
</script>

