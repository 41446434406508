<template>
  <div class="mb-4 mt-2">
      <label><i>{{tr('New field to add or edit service title')}}</i></label>
      <img src="https://nubbo-change-log.s3.amazonaws.com/EditServiceTitle.png" class="w-100 border">
  </div>
</template>

<script>
export default {
    name: 'edit-service-title',
}
</script>
