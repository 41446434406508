<template>
    <report-window
        v-if="ready"
        endpoint="/reports/bookingcloselist"
        :perPage="500"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :current.sync="current"
        :slotsFields="slotsFields"
        :columnClick="columnClick"
        :headerColumns="headerColumns"
        :countOn="['PaxName']"
        :notFilterable="options && options.notFilterable"
        :totalsOn="totalsOn"
        :beforeRender="beforeRender"
        :columnsClasses="columnsClasses"
        :fieldClasses="fieldClasses"
        :requestTimeout="120000"
        :allowChart="allowChart"
        :chartOptions="chartOptions"
    ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow';
import { mapState } from 'vuex';
export default {
    name: 'booking-close-list-report',
    props: ['options'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        baseCurrency () {
            return api.baseCurrency;
        },
        columnsClasses () {
            return {
                Incomes: 'text-right',
                Cost: 'text-right',
                Profit: 'text-right',
                calcProfit: 'text-right',
                calcPercent: 'text-right',
                finalIncomes: 'text-right calc-border',
                finalCost: 'text-right',
                id: 'cursor-pointer-report',
            }
        },
        chartOptions () {
            if (this.current.GroupBy == '1') {
                return {
                  y: 'Seller',
                  x: ['Count', 'Incomes', 'Cost', 'Profit', 'percent'],
                }
            }
            if (this.current.GroupBy == '2') {
                return {
                  y: 'SalesOrigin',
                  x: ['Count', 'Incomes', 'Cost', 'Profit', 'percent'],
                }
            }
        },
        allowChart () {
            return this.current.GroupBy != null;
        },
    },
    async mounted () {
        frontTools.setReportValues(this);
        let currencyField = _.find(this.fields, (r) => r.name == 'CurrencyId');
        if (currencyField && this.baseCurrency) {
            currencyField.options.push({value: this.baseCurrency.BookingCurrency, label: this.baseCurrency.BookingCurrency})
            currencyField.options.push({value: this.baseCurrency.BaseCurrency, label: this.baseCurrency.BaseCurrency})
        }
        this.ready = true;
    },
    data () {
        return this.getData(this)
    },
    methods: {
        getData (self) {
            return {
                ready: false,
                current: {},
                slotsFields: [],
                callbacks: {},
                fieldClasses: {
                    Incomes: (row) => { return self.getFieldStyle(row, 'Incomes', 'finalIncomes'); },
                    Profit: (row) => { return self.getFieldStyle(row, 'Profit', 'calcProfit'); },
                    Cost: (row) => { return self.getFieldStyle(row, 'Cost', 'finalCost'); },
                    percent: (row) => { return self.getFieldStyle(row, 'percent', 'calcPercent'); },
                },
                onAccountOptions: [],
                originColumns: ['SalesUser', 'SalesOrigin', 'PaxName', 'id', 'Status', 'EndDate', 'CurrencyId', 'Incomes',
                  'Cost', 'Profit', 'percent'],
                headerColumns: null,
                /*headerColumns: ['SalesUser', 'SalesOrigin', 'PaxName', 'id', 'Status', 'EndDate', 'CurrencyId', 'Incomes',
                  'Cost', 'Profit', 'percent'],*/
                totalsOn: [ 'Profit', 'Incomes', 'Cost', 'finalIncomes', 'finalCost', 'calcProfit', 'Count'],
                fields: [
                  {name: 'FromDate', editor: 'date', label: 'From'},
                  {name: 'ToDate', editor: 'date', label: 'To'},
                  {name: 'SalesOriginId', label: 'Sales Origin', editor: 'select', list: true,
                    optionLabels: 'Name', relation: 'salesorigin', addBlank: true
                  },
                  {name: 'SalesUserId', label: 'Seller', editor: 'select', list: true,
                    optionLabels: 'UserName', relation: 'user', addBlank: true,
                    filters: (row) => {
                        if (row.Closed) return false;
                        if (row.Department && row.Department.Sales) return true;
                    }
                  },
                  {name: 'Status', label: 'Status', editor: 'select', defValue: '1',
                    options: [
                        {value: '0', label: 'All'},
                        {value: '1', label: 'Closed'},
                        {value: '2', label: 'Pending'},
                    ]
                  },
                  {name: 'SummaryRow', label: 'Add Totals', editor: 'select', defValue: '1',
                      options: [
                            {value: '0', label: 'NO'},
                            {value: '1', label: 'YES'},
                      ]
                  },
                  {name: 'CurrencyId', label: 'Currency', editor: 'select', required: true,
                        options: []
                  },
                  {name: 'GroupBy', label: 'Group by', editor: 'select', addBlank: true,
                      options: [
                            {value: '1', label: 'Seller'},
                            {value: '2', label: 'Sales Origin'},
                      ]
                  },
                  {name: 'Calculated', label: 'Updated Values', editor: 'select', defValue: '0',
                      options: [
                            {value: '0', label: 'NO'},
                            {value: '1', label: 'YES'},
                      ]
                  },
                ],
                title: 'Close List',
                headers: {
                    SalesUser: 'Seller',
                    EndDate: 'Out',
                    PaxName: 'Pax',
                    id: 'File Nr',
                    CurrencyId: 'Currency',
                    Cost: 'Expenses',
                    Adjustment: 'Adjust',
                    finalIncomes: 'Incomes',
                    finalCost: 'Cost',
                    calcProfit: 'Profit',
                    percent: '%',
                    calcPercent: '%',
                    Count: 'Quantity'
                },
                templates: [
                    {name: 'EndDate', callback: (row) => {
                        if (!row.EndDate) return '';
                        return moment(row.EndDate).format("DD/MM/YYYY")
                      }
                    },
                    {name: 'Profit', callback: (row) => {
                        return tools.toNumber(row.Profit, 0);
                      }
                    },
                    {name: 'Incomes', callback: (row) => {
                        return tools.toNumber(row.Incomes, 0);
                      }
                    },
                    {name: 'Cost', callback: (row) => {
                        return tools.toNumber(row.Cost, 0);
                      }
                    },
                    {name: 'percent', callback: (row) => {
                        if (row.calculated) {
                            return tools.toNumber(row.Profit / row.Cost * 100, 1);
                        }
                        return tools.toNumber(row.percent, 1);
                      }
                    },
                    {name: 'finalCost', callback: (row) => {
                        return tools.toNumber(row.finalCost, 0);
                      }
                    },
                    {name: 'calcProfit', callback: (row) => {
                        return tools.toNumber(row.calcProfit, 0);
                      }
                    },
                    {name: 'calcPercent', callback: (row) => {
                        return tools.toNumber(row.calcPercent, 1);
                      }
                    },
                    {name: 'finalIncomes', callback: (row) => {
                        return tools.toNumber(row.finalIncomes, 0);
                      }
                    },
                    {name: 'Status', callback: (row) => {
                        return tr(row.Status);
                      }
                    },
                ],
                columnClick: {
                    id: self.showBooking,
                },
            }
        },
        showBooking (row) {
            let route = this.$router.resolve({ name: 'sale', params: {id: row.id.toString(), TemplateType: 'NONE'} })
            window.open(route.href, '_blank');
        },
        async beforeRender (report) {
            if (this.current.GroupBy) {
                this.headerColumns = null;
                return;
            }
            this.headerColumns = Object.assign([], this.originColumns);
            if (this.current.Calculated == '1') {
                if (this.headerColumns.indexOf('finalIncomes') == -1) {
                    this.headerColumns.push('finalIncomes');
                    this.headerColumns.push('finalCost');
                    this.headerColumns.push('calcProfit');
                    this.headerColumns.push('calcPercent');
                }
            } else {
                return;
            }
            let model = await api.importMixinModule('booking', 'model');
            let newRows = {};
            let usersFilter = [];
            if (this.current.SalesUserId) {
                for (let userId of this.current.SalesUserId) {
                    let user = _.find(api.tables.user, (r) => r.id == userId);
                    if (user) usersFilter.push(user.UserName);
                }
            }
            for (let row of report.dataList) {
                let booking = await model.get({id: row.id, isTemplate: false, payments: true, receipts: true});
                booking.SummaryCurrencyId = this.current.CurrencyId;
                if (booking.multiSaleUsers && booking.salesUsers) {
                    for (let id in booking.salesUsers) {
                        let key = [id, row.id];
                        newRows[key] = true;
                    }
                    let user = _.find(api.tables.user, (r) => r.UserName == row.SalesUser);
                    if (user) {
                        booking.SummarySalesUserId = user.id;
                        row.finalCost = booking.finalCost;
                        row.finalIncomes = booking.finalIncomes;
                        row.calcProfit = row.finalIncomes - row.finalCost;
                        row.calcPercent = _.round(row.calcProfit / row.finalCost * 100, 1);
                        let key = [user.id, row.id];
                        newRows[key] = false;
                    }
                } else {
                    row.finalCost = booking.finalCost;
                    row.finalIncomes = booking.finalIncomes;
                    row.calcProfit = row.finalIncomes - row.finalCost;
                    row.calcPercent = _.round(row.calcProfit / row.finalCost * 100, 1);
                }
            }
            for (let key in newRows) {
                if (!newRows[key]) continue;
                let bookingId = key.split(',')[1];
                let userId = parseInt(key.split(',')[0]);
                let user = _.find(api.tables.user, (r) => r.id == userId);
                let row = _.find(report.dataList, (r) => r.id == bookingId);
                if (!user) continue;
                if (usersFilter.length > 0 && usersFilter.indexOf(user.UserName) == -1) continue;
                if (row) {
                    let newRow = Object.assign({}, row);
                    let booking = await model.get({id: newRow.id, isTemplate: false, payments: true, receipts: true});
                    booking.SummaryCurrencyId = this.current.CurrencyId;
                    booking.SummarySalesUserId = user.id;
                    newRow.SalesUser = user.UserName;
                    newRow.finalCost = booking.finalCost;
                    newRow.finalIncomes = booking.finalIncomes;
                    newRow.calcProfit = newRow.finalIncomes - newRow.finalCost;
                    newRow.calcPercent = _.round(newRow.calcProfit / newRow.finalCost * 100, 1);
                    report.dataList.push(newRow);
                }
            }
        },
        getFieldStyle (row, f1, f2) {
            if (this.current.Calculated == '0') return '';
            if (_.round(row[f1],1) != _.round(row[f2], 1)) return 'exclamation-alert';
            return '';
        },

    }
}
</script>

<style>
    .calc-border {
        border-left: 2px solid #000 !important;
    }
</style>



