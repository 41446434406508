<template>
  <div class="mb-4 mt-2">
      <label><i>{{tr('New option to include pickup information in services request')}}</i></label>
      <img src="https://nubbo-change-log.s3.amazonaws.com/PickupMail.png" class="w-100 border">
  </div>
</template>

<script>
export default {
    name: 'pickup-mail',
}
</script>
