<template>
      <report-window
        v-if="ready"
        endpoint="/reports/supplier_list"
        :fields="fields"
        :title="cardTitle || title"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :headerColumns="options? options.headerColumns: null"
        :current.sync="current"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'supplier-list-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [],
            title: 'Supplier List',
            templates: [
                {
                    name: "Closed",
                    callback: function(row) {
                        if (row.Closed) return tr("YES");
                        return '';
                    }
                },
            ],

        }
    },
}
</script>

