<template>
      <div v-if="ready">
        <report-window
          endpoint="/crm/reports/leads_review"
          :fields="fields"
          :title="cardTitle || title"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :columnsClasses="columnsClasses"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :headers="headers"
          :columnClick="columnClick"
          :slotsFields="slotsFields"
          :fieldStyles="fieldStyles"
          :afterRun="afterRun"
        ></report-window>
      </div>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow';
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'lead-reviews-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            operManager: state => state.main.operManager,
            user: state => state.main.user,
            recordStore: state => state.main.modalRecord,
        }),
        syncVersion () {
            return this.recordStore? this.recordStore.syncVersion: null;
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        let crm = tools.canAccess(this.user, "view", "GET", 'crm', false);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions && !this.operManager && !crm) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
        EventBus.$on('close-paxlead', this.updateReport);
    },
    data () {
        let self = this;
        return {
            current: {},
            reload: false,
            ready: false,
            fields: [
                {name: 'SalesUserId', label: 'Seller', editor: 'select',
                  optionLabels: 'UserName', relation: 'user', addBlank: true,
                  filters: (row) => {
                      if (row.Closed) return false;
                      if (row.Department && row.Department.Sales) return true;
                  }
                },
            ],
            title: 'Reviews',
            headers: {
                ReviewDate: 'Review',
                ID: 'Nº',
                LeadStatus: 'Status',
            },
            slotsFields: [
                { name: 'LeadStatus', editor: 'icon', icon: 'circle', classes: 'mb-0', label: true},
            ],
            templates: [
                {
                    name: "ReviewDate",
                    callback: (row) =>  {
                        if (!row.ReviewDate) return "";
                        return moment(row.ReviewDate).locale(api.language).format("DD MMM");
                    }
                },
            ],
            columnsClasses: {
                ID: 'cursor-pointer-report',
            },
            columnClick: {
                ID: this.showPaxLead,
            },
            fieldStyles: {
                LeadStatus: (row) => {
                    if (row.Color) return `color: ${row.Color};`;
                }
            },
        }
    },
    methods: {
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-booking', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        },
        showPaxLead (row) {
            EventBus.$emit('open-modal-record', {modalRecord: null, modalTable: 'paxlead', modalId: row.ID, closeAction: 'close-paxlead'});
        },
        updateReport () {
            if (this.reload) {
                this.reload = false;
                setTimeout(() => {
                    this.report.run()
                }, 100);
            }
        },
        afterRun (report) {
            this.report = report;
        },
    },
    watch: {
        syncVersion (newValue, oldValue) {
            if (oldValue && newValue) this.reload = true;
        }
    },
    beforeDestroy() {
        EventBus.$off('close-paxlead');
    },
}
</script>

