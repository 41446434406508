const modules = vueTools.importModule('modules', 'tools', 'admin').default;

modules.adminMenu = {
    label: 'Administration',
    user_access: 'admin_menu',
    items_access: 'admin_menu_items',
    def: true,
    endpoints: [
        'purchaseinvoice',
        'bookingpayment',
        'suppliercredit',
        'bookingreceipt',
        //'invoice',
        'cashout',
        'accountmovement',
        {endpoint: 'report', label: 'Credit List', componentName: 'CreditListReport', report: 'creditlist'},
        {endpoint: 'report', label: 'Administrate Payments', componentName: 'PaymentListReport', report: 'paymentlist'},
        //{endpoint: 'report', label: 'Sales Invoices List', componentName: 'InvoicesReport', report: 'invoice'},
        //  {endpoint: 'report', label: 'Invoice Purchases', componentName: 'PurchaseServiceReport', report: 'purchaseservice'},
        //{endpoint: 'report', label: 'Invoice Sales', componentName: 'InvoiceServiceReport', report: 'invoiceseservice'},
        {endpoint: 'report', label: 'Cash List', componentName: 'CashListReport', report: 'cashlist'},
        {endpoint: 'report', label: 'Expenses List', componentName: 'ExpenseListReport', report: 'expenselist'},
    ],
}


export default modules;
