<template>
  <div class="modal fade" id="yesNot" tabindex="-1" role="dialog" aria-labelledby="yesNotLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{tr(text)}}</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="setYes">{{tr('YES')}}</button>
          <button type="button" class="btn btn-secondary" @click="setNot">{{tr('NO')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'yes-not',
    props: ['text', 'show'],
    mounted () {
        $('#yesNot').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        setYes (record) {
            this.close();
            this.$emit('yes');
        },
        setNot (record) {
            this.close();
            this.$emit('not');
        },
        close () {
            //this.$emit('update:show', null);
            $('#yesNot').modal('hide');
        },
    },
}
</script>
