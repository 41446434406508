<template>
    <div class="col-12 row mt-2">
        <!--div class="custom-control custom-checkbox">
             <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="accept" >
             <label class="custom-control-label" for="customCheck1">{{tr('I accept the')}} {{tr('terms and conditions')}}
             </label>
        </div-->
    </div>
</template>

<script>
export default {
    name: 'reservation-form-terms',
    props: ['accept'],
}
</script>