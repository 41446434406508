
<script>
import '@/extra/custom/css/remixicon/remixicon.css';
import '@/extra/custom/css/style.scss';
const itinerary = importVueComp('components/itinerary', 'Itinerary', 'custom');
export default {
    name: 'custom-itinerary',
    mixins: [itinerary],
}
</script>
