<template>
    <div v-if="user && ready">
        <report-window
            v-if="ready"
            endpoint="/reports/table_list"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :slotsFields="slotsFields"
            :updateReport="updateReport"
            @afterEdit="afterEdit"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
let langDict = require('@/languages/system_languages.json');
let dict = langDict['es'];
export default {
    name: "dependencies-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        let tables = await api.get('/api/get_model_tables/');
        if (tables) {
            this.tables = tables;
            let tableField = _.find(this.fields, (r) => r.name == 'Table');
            for (let t of tables) {
                let label = tr(t.table);
                if (!label) continue;
                tableField.options.push({value: t.table, label: label})
            }
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
        if (this.id && this.tableName) {
            setTimeout(() => {
                this.updateReport = !this.updateReport;
            }, 1)
        }
    },
    data() {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ready: false,
            tables: null,
            fields: [
                {
                    name: "Table",
                    label: "Table",
                    required: true,
                    editor: 'vue-select',
                    options: []
                },
                {
                    name: "Fields",
                    editor: "vue-select",
                    options: [],
                    list: true,
                    required: true,
                }
            ],
            title: "Table List",
            headers: {
            },
            templates: [
            ],
            columnClick: {},
            slotsFields: [
            ],

        };
    },
    methods: {
        async afterEdit (fieldName) {
            if (fieldName == 'Table') {
                let fieldsList = _.find(this.fields, (r) => r.name == 'Fields');
                fieldsList.options = []
                if (this.current.Table) {
                    let serverFields = await api.getTableFields(this.current.Table);
                    let fields = tools.serverTypes(serverFields)
                    if (fields) {
                        for (let name in fields) {
                            let row = fields[name];
                            fieldsList.options.push({value: row.name, label: tr(row.label)})
                        }
                    }
                } else {
                    fieldsList.editor = 'text';
                }
                this.fields = Object.assign([], this.fields);
                this.ready = false;
                let self = this;
                setTimeout(() => {
                    self.ready = true;
                }, 1)
            }
            if (fieldName == 'Fields') {
                let fieldsList = _.find(this.fields, (r) => r.name == 'Fields');
                if (this.current.Fields && this.current.Table) {
                    let serverFields = await api.getTableFields(this.current.Table);
                    let fields = tools.serverTypes(serverFields)
                    let fieldsValues = this.current.Fields.split(',');
                    for (let name of fieldsValues) {
                        let label;
                        let option = _.find(fieldsList.options, (f) => f.value == name);
                        if (option && option.label) label = tr(option.label)
                        if (!this.headers[name]) {
                            this.headers[name] = label;
                            this.headers = Object.assign({}, this.headers);
                        }
                        let relationField = _.find(fields, (f) => {
                            return f.name == name && f.relation;
                        })
                        if (relationField) {
                            let serverFields2 = await api.getTableFields(relationField.relation);
                            let fields2 = tools.serverTypes(serverFields2)
                            if (fields2) {
                                for (let name2 in fields2) {
                                    let row = fields2[name2];
                                    let value = `${name}.${row.name}`;
                                    if (_.find(fieldsList.options, (v) => v.value == value)) continue;
                                    fieldsList.options.push({
                                        value: `${name}.${row.name}`, 
                                        label: `${label? tr(label): tr(name)}.${row.label? tr(row.label): tr(row.name)}`
                                    })
                                }
                            }

                        }
                    }
                    this.fields = Object.assign([], this.fields);
                    this.ready = false;
                    let self = this;
                    setTimeout(() => {
                        self.ready = true;
                    }, 1)
                }
            }
        }
    },
};
</script>
