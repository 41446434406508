<template>
    <div v-if="langCode && recordStore">
        <div v-if="day.hotelListByDay.length==1">
            <div class="entry-meta icon-box not-break-inside">
                <i class="ri-hotel-line"></i>
                <div>
                  <h4>{{tr('Accommodation')}}</h4>
                  <span v-if="day.hotelListByDay[0].HotelCategory"> {{day.hotelListByDay[0].HotelCategory}}: </span>
                  <span class="mb-1">{{day.hotelListByDay[0].Hotel.Name}} ({{day.hotelListByDay[0].rooms}})</span>
                  <span v-if="day.hotelListByDay[0].Hotel.Certified" class="ml-3">
                      <font-awesome-icon icon="recycle" color="green"/>
                      <span class="certified ml-2">{{tr('Certified Accommodation')}}</span>
                  </span>
                </div>
            </div>
            <div class="entry-meta icon-box pl-0">
                <text-rows-style tag="p"
                  class="text-justify"
                  :text="getHotelText(day.hotelListByDay[0].Hotel.HotelDescriptions)">
                </text-rows-style>
            </div>
            <photo-gallery
                :crop="crop"
                :images="hotelImageList"
            ></photo-gallery>
        </div>
        <div v-else-if="day.hotelListByDay.length>1">
            <div class="entry-meta icon-box not-break-inside">
                <i class="ri-hotel-line"></i>
                <div class="w-100">
                    <h4>{{tr('Accommodation')}}</h4>
                    <div class="border-bottom" :id="'hotels-' + day.DayNr" v-for="(hotel, hIdx) of day.hotelListByDay">
                        <div class="py-1" :id="'hotelsHeading-' + day.DayNr + '-' + hIdx">
                            <div class="accordion-button collapsed hotel-col d-flex align-items-center"
                                @click="setSelectedHotel(day.DayNr, hIdx)" data-toggle="collapse"
                                :data-target="'#hotelsCollapse-' + day.DayNr + '-' + hIdx"
                                aria-expanded="false" :aria-controls="'hotelsCollapse-' + day.DayNr + '-' + hIdx">
                                <div class="d-flex">
                                  <span v-if="hotel.HotelCategory">{{tr('Option')}} {{hotel.HotelCategory}}:
                                    {{hotel.Hotel.Name}} ({{hotel.rooms}})
                                  </span>
                                  <span v-else>
                                    {{hotel.Hotel.Name}} ({{hotel.rooms}})
                                  </span>
                                </div>
                            </div>
                        </div>

                        <div :id="'hotelsCollapse-' + day.DayNr + '-' + hIdx" class="collapse"
                            v-if="hotelText"
                            :aria-labelledby="'hotelsHeading-' + day.DayNr + '-' + hIdx"
                            :data-parent="'#hotels-' + day.DayNr">
                            <div class="hotel-text not-break-inside">
                                <text-rows-style tag="p"
                                  :text="getHotelText(hotel.Hotel.HotelDescriptions)"></text-rows-style>
                            </div>
                            <photo-gallery
                                v-if="hotel.Hotel && hotelImageListByHotel"
                                :images="hotelImageListByHotel[hotel.Hotel.id]" :crop="crop">
                            </photo-gallery>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const itineraryBodyHotelText = importVueComp('components/itinerary', 'ItineraryBodyHotelText', 'custom');
export default {
    name: 'custom-itinerary-body-hotel-text',
    mixins: [itineraryBodyHotelText]
}
</script>


<style scoped>
.certified {
  font-style: italic;
}
</style>