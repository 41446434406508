<template>
    <div v-if="recordStore.BookingDays[dayId].BookingDayHotels[0].BookingDayRooms" class="form-row rooms-config">
        <div class="col-md-12 mb-2 section-title">
            <label class="ml-4"><b>{{tr('Rooms')}}</b></label>
        </div>
        <div v-for="(room, idx) of recordStore.BookingDays[dayId].BookingDayHotels[0].BookingDayRooms" class="col-md-12 pl-3">
            <div class="form-row d-flex align-items-center">
                <div class="col" v-if="hotelsList">
                  <vue-select-input fieldName="HotelId" :fieldOptions="hotelOptions"
                  :label="idx? '': 'Hotel'" :id="idx" :disabled="exchange"
                  @change="setHotel(room, idx)"
                  :currentValue.sync="room.HotelId" :name="'HotelId-' + idx"></vue-select-input>
                </div>
                <div class="col">
                    <select-input fieldName="RoomTypeId" :fieldOptions="roomOptions[idx]"
                    :currentValue.sync="room.RoomTypeId"
                    :id="idx"
                    :label="(idx==0)? tr('Room'): ''"></select-input>
                </div>
                <div class="room-category-col col">
                  <select-input fieldName="RoomCategory" :fieldOptions="roomCategories[idx]"
                   :currentValue.sync="room.RoomCategory"
                   :id="idx" :label="(idx==0)? tr('Room Category'): ''"></select-input>
                </div>
                <div class="col" v-if="currencies.length>1">
                  <select-input fieldName="CurrencyId" :fieldOptions="{CurrencyId: currencies}"
                   :currentValue.sync="room.CurrencyId"
                   :id="idx"
                   :label="(idx==0)? tr('Currency'): ''"></select-input>
                </div>

                <div class="col hotel-category-col">
                  <select-input fieldName="HotelCategoryId" :fieldOptions="hotelCategoriesOptions"
                   :currentValue.sync="room.HotelCategoryId"
                   :id="idx" :addBlank="true"
                   :label="(idx==0)? tr('Hotel Category'): ''"></select-input>
                </div>
                <div class="col room-days-col">
                    <div class="form-group form-default">
                        <input v-model="room.Rooms" class="form-control text-right" type="number"
                            :class="{'fill': room.Rooms}">
                            <label v-if="idx==0" class="float-label">{{tr('Quantity')}}</label>
                        </input>
                    </div>
                </div>
                <div class="col hotel-supplier-col" v-if="hotelsList && changeSupplier">
                  <vue-select-input fieldName="SupplierId" :fieldOptions="supplierOptions"
                  :label="idx? '': tr('Supplier')" :id="idx" :disabled="exchange"
                  :currentValue.sync="room.SupplierId" :name="'SupplierId-' + idx"></vue-select-input>
                </div>
                <div class="col" >
                    <i class="cursor-pointer mx-1" @click="changeSupplier = !changeSupplier" v-if="hotelsList"
                     	:class="{'row-primary-button': changeSupplier, 'row-button-active': room.SupplierId}"
                    >
                     	<font-awesome-icon icon="user" size="lg"/>
                    </i>
                    <i class="cursor-pointer mx-1" v-if="canRemoveRoom[room.id] || !room.id"
                        :disabled="removing != null" @click="removeRoom(idx)" >
                        <font-awesome-icon icon="trash-alt" v-if="removing!=idx" size="lg"/>
                        <img class="processing-gif" src="@/img/loading.gif" v-else>
                    </i>
                    <i class="cursor-pointer mx-1" @click="changeCity = !changeCity"  v-if="hotelsList"
                        :class="{'row-primary-button': changeCity}">
                        <font-awesome-icon icon="map-marker-alt" size="lg"/>
                    </i>
                    <i class="cursor-pointer mx-1" @click="room.AllowBlackOut = !room.AllowBlackOut"  v-if="changeBlockOut"
                        :class="{'row-primary-button': room.AllowBlackOut}">
                        <bed-key class="bed-key" 
                            :class="{'row-primary-active': room.AllowBlackOut, 'back-white': !room.AllowBlackOut}"></bed-key>
                    </i>
                    <label v-if="room.TourLeader" class="pt-2"><h5 class="text-danger font-weight-bold mb-0">TL</h5 ></label>
                    <label v-if="room.SingleSupplement" class="pt-2"><h5 class="text-danger small font-weight-bold mb-0">SLG SUP</h5 ></label>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2" v-if="!exchange">
             <i class="add-item cursor-pointer" @click="addRoom">
                  <font-awesome-icon icon="plus-circle"/>
             </i>
        </div>
    </div>
</template>

<script>
import bedKey from '@/components/svg/BedKey';
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'hotel-rooms',
    props: ['record', 'dayId', 'exchange', 'hotelsList', 'defaultHotel', 'reportData'],
    components: {
        'bed-key': bedKey
    },
    data  () {
        return {
            removing: null,
            changeSupplier: false,
            changeCity: false,
            currencies: [],
            changeBlockOut: false,
        }
    },
    mounted () {
        this.changeBlockOut = tools.canAccess(api.currentUser, 'api','GET', 'change_black_out', false);
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        canRemoveRoom () {
            let res = {};
            for (let room of this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms) {
                if (!room.id) continue;
                res[room.id] = true;
            }
            return res;
        },
        hotelOptions () {
            let res = [];
            if (this.hotelsList) {
                for (let h of this.hotelsList) {
                    res.push({value: h.id, label: h.Name});
                }
            }
            return res;
        },
        hotelCategoriesOptions () {
            let res = [];
            if (api.tables.hotelcategory) {
                for (let h of api.tables.hotelcategory) {
                    res.push({value: h.id, label: h.Name});
                }
            }
            return res;
        },
        supplierOptions () {
            let hotels = {};
            let res = [];
            if (api.tables.supplier) {
                for (let s of api.tables.supplier) {
                    if (s.SupplierType=='MIXED' || s.SupplierType=='ALL') {
                        if (!hotels[s.id]) {
                            hotels[s.id] = s.id;
                        }
                        res.push({value: s.id, label: s.Name});
                    }
                }
            }
            return res;
        },
        roomCategories () {
            let res = {};
            for (let i in this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms) {
                let room = this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[i];
                res[i] = [];
                if (room.Hotel && room.Hotel.RoomCategories) {
                    let categories = _.filter(room.Hotel.RoomCategories, (r) => !r.Closed);
                    res[i] = room.roomCategories;
                }
            }
            return res;
        },
        roomOptions () {
            let res = {};
            if (!this.recordStore) return res;
            for (let i in this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms) {
                let room = this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[i];
                res[i] = [];
                if (room.Hotel && room.Hotel.RoomCategories) {
                    res[i] = room.roomsOptions;
                    for (let roomType of api.tables.roomtype) {
                        if (roomType.Checkin) {
                            res[i].push({value: roomType.id, label: roomType.Name});
                        }
                    }
                }
            }
            return res;
        },
    },
    methods: {
        async setHotel (room, idx){
            this.$emit('setHotel', room.HotelId, idx);
        },
        async addRoom () {
            await this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].addRoom(this.defaultHotel);
            EventBus.$emit('update-quotes-hotels');
        },
        async removeRoom (id) {
            this.removing = id;
            await this.$nextTick()
            await this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].removeRoom(id);
            this.removing = null;
            //console.log('remove row')
            this.$emit('removeRow')

        },
    },
    watch: {
        reportData () {
            let rows = _.chain(this.reportData).map((r) => { return r.CurrencyId }).uniq().value();
            this.currencies = _.map(rows, (r) => {return {label: r, value: r}})
        },
        changeCity: function () {
            this.$emit('changeCity');
        }
    },
}
</script>

<style scoped>
.bed-key {
    max-width: 30px;
    position: relative;
    bottom: -7px;
}
.back-white {
    color: #fff;
}
.back-white {
    color: #fff;
}
</style>

