<template>
    <div>
        <div class="section-title">
            <label>{{tr('Passenger Expenses')}}</label>
        </div>
        <div class="form-row form-material">
            <div class="col-md-6" >
                <label>{{ tr('Concept') }}</label>
            </div>
            <div class="col-md-2">
                <label>{{ tr('Amount') }}</label>
            </div>
            <div class="col-md-2" >
                <label>{{ tr('Currency') }}</label>
            </div>
        </div>
        <div class="form-row form-material" v-for="(row, rowNr) of recordStore.BookingExpenses" v-if="row.Type=='PAX'">
            <div class="col-md-6" >
                <select-input
                    fieldName="ExpenseTypeId"
                    :field="row.fieldsObject.ExpenseTypeId"
                    :record="row"
                    :currentValue.sync="row.ExpenseTypeId" :id="'BookingExpenses-ExpenseTypeId-' + rowNr">
                </select-input>
            </div>
            <div class="col-md-2">
                <number-input
                    :v.sync="row.Amount"
                    :currency="row.Currency"
                    :id="'BookingExpenses-Amount-' + rowNr"
                    @change="change"
                ></number-input>
            </div>
            <div class="col-md-2" >
                <select-input
                    fieldName="CurrencyId"
                    :field="row.fieldsObject.CurrencyId"
                    @change="change"
                    :currentValue.sync="row.CurrencyId" :id="'BookingExpenses-CurrencyId-' + rowNr">
                </select-input>
            </div>
            <div class="form-group col-md-1" :class="{'pt-4': rowNr==0}">
                <i class="add-item cursor-pointer" @click="removeRow(rowNr)">
                   <font-awesome-icon icon="trash-alt""/>
                </i>

            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-2 offset-1">
                <i class="add-item cursor-pointer" @click="addLine">
                   <font-awesome-icon icon="plus-circle"/>
                </i>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-expenses-pax',
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    methods: {
        async addLine () {
            await vueTools.addRow(this, null, 'BookingExpenses', {ExpenseType: {BookingPax: true}, Type: 'PAX'});
        },
        async removeRow (rowNr) {
            await vueTools.removeRow(this, null, 'BookingExpenses', rowNr);
            this.change();
        },
        change () {
            EventBus.$emit('update-totals');

        }

    },
}
</script>

