<script>
const salesByUserReport = importVueComp('components/reports', 'SalesByUserReport', 'custom');
export default {
    name: 'custom-sales-by-user-report',
    mixins: [salesByUserReport],
    methods: {
        getData (self) {
            let r = salesByUserReport.methods.getData(this);
            r.fields.push({
                name: 'PayemtCurrencyRate', 
                label: 'Cotización Pagos', 
                addBlank: true, 
                editor: 'select', 
                required: true,
                defValue: 'BNA',
                options: [
                    { label: 'MEP', value: 'MEP' },
                    { label: 'BNA', value: 'BNA' },
                ]
            });
            r.fields = Object.assign([], r.fields)
            return r;
        },

    }
}
</script>