<template>
    <div class="card no-border-top" :class="{'p-5': !supplierId}">
        <div v-if="!processingPayments">
          <div class="form-row mt-5" v-if="supplierId">
                <div class="section-title ml-4">
                    <label>{{tr('Payments')}}</label>
                    <font-awesome-icon icon="question-circle" v-if="help" @click="showHelp = true"/>
                </div>
          </div>
          <div class="form-row col-md-12 border-bottom font-weight-bold">
              <div class="col my-0" v-if="!supplierId">
                <label>{{tr('Supplier')}}</label>
              </div>
              <div class="col my-0">
                <label>{{tr('Due Date')}}</label>
              </div>
              <div class="col text-right my-0">
                <label>{{tr('To Pay')}}</label>
              </div>
              <div class="col my-0" style="min-width: 80px;">
                <label>{{tr('Status')}}</label>
              </div>
              <div class="col my-0">
                <label>{{tr('Payment Date')}}</label>
              </div>
              <div class="col text-right my-0">
                <label>{{tr('Paid')}}</label>
              </div>
              <div class="col my-0">
                <label>{{tr('Notes')}}</label>
              </div>
              <div class="col my-0">
              </div>
          </div>

          <div class="form-row col-md-12 border-bottom" v-for="(row, rowNr) of payments"
            v-if="(supplierId==row.SupplierId || !supplierId)" :class="getBGClass(rowNr)">
              <div class="col" v-if="!supplierId">
                <label v-if="row.SupplierId">{{row.Supplier.Name}}</label>
              </div>
              <div class="col">
                <label v-if="row.DueDate">{{getDate(row.DueDate)}}</label>
              </div>
              <div class="col text-right">
                <label>{{row.Amount | formatNumber({c: row.Currency})}} {{row.CurrencyId}}</label>
              </div>
              <div class="col"  style="min-width: 80px;">
                <label>{{toTitleCase(tr(row.PaymentStatus))}}</label>
              </div>
              <div class="col"  style="min-width: 80px;">
                <label v-if="row.PaymentDate">{{getDate(row.PaymentDate)}}</label>
              </div>
              <div class="col text-right">
                <label>{{row.PaymentAmount | formatNumber({c: row.PaymentCurrency})}} {{row.PaymentCurrencyId}}</label>
              </div>
              <div class="col">
                <label>{{row.Comment}}</label>
              </div>
              <div class="col text-right cursor-pointer" v-if="canEdit(row)">
                 <i @click="editPayment(row.id)">
                      <font-awesome-icon icon="edit"/>
                 </i>
              </div>
          </div>
          <div class="form-row col-md-12 border-bottom font-weight-bold" v-for="(p, currencyId) of toPay" v-if="supplierId && showTotals">
              <div class="col my-0">
                  <label>Totals</label>
              </div>
              <div class="col text-right my-0">
                <label>{{toPay[currencyId] | formatNumber({c: currencyId})}} {{currencyId}}</label>
              </div>
              <div class="col my-0" style="min-width: 80px;">
              </div>
              <div class="col my-0">
              </div>
              <div class="col text-right my-0">
                <label>{{paid[currencyId] | formatNumber({c: currencyId})}} {{currencyId}}</label>
              </div>
              <div class="col my-0">
              </div>
              <div class="col my-0">
              </div>
          </div>
          <div class="form-row col-md-12 mt-2" v-if="canAdd && supplierId">
             <i class="cursor-pointer" @click="newPayment">
                {{tr('Add payment')}}<font-awesome-icon icon="plus-circle" class="ml-2"/>
             </i>
          </div>

          <div class="form-row col-md-12 border-bottom font-weight-bold mt-4">
              <div class="col my-0">
                  <div class="section-title ml-1" v-if="supplierId">
                      <label>{{tr('Credits')}}</label>
                  </div>
                  <label v-else>{{tr('Credits')}}</label>
              </div>
              <div class="col my-0" v-if="!supplierId">
              </div>
              <div class="col my-0">
              </div>
              <div class="col my-0" style="min-width: 80px;">
              </div>
              <div class="col my-0">
                <label>{{tr('Date')}}</label>
              </div>
              <div class="col text-right my-0">
                <label>{{tr('Amount')}}</label>
              </div>
              <div class="col my-0">
                <label>{{tr('Notes')}}</label>
              </div>
              <div class="col my-0">
              </div>
          </div>

          <div class="form-row col-md-12 border-bottom" v-for="(row, rowNr) of supplierCredits"
            v-if="(supplierId==row.SupplierId || !supplierId)" :class="getBGClass(rowNr)">
              <div class="col" v-if="!supplierId">
                <label v-if="row.SupplierId">{{row.Supplier.Name}}</label>
              </div>
              <div class="col">
              </div>
              <div class="col">
              </div>
              <div class="col"  style="min-width: 80px;">
              </div>
              <div class="col"  style="min-width: 80px;">
                <label>{{getDate(row.TransDate)}}</label>
              </div>
              <div class="col text-right">
                <label>{{row.Amount | formatNumber({c: row.Currency})}} {{row.CurrencyId}}</label>
              </div>
              <div class="col">
                <label>{{row.Comment}}</label>
              </div>
              <div class="col text-right cursor-pointer" v-if="canEdit(row)">
                 <i @click="editSupplierCredit(row.id)">
                      <font-awesome-icon icon="edit"/>
                 </i>
              </div>
          </div>
          <div class="form-row col-md-12 border-bottom font-weight-bold mt-1" v-for="(p, currencyId) of credits" v-if="supplierId && showTotals">
              <div class="col my-0">
                  <label>Totals</label>
              </div>
              <div class="col my-0">
              </div>
              <div class="col my-0" style="min-width: 80px;">
              </div>
              <div class="col my-0">
              </div>
              <div class="col text-right my-0">
                <label>{{credits[currencyId] | formatNumber({c: currencyId})}} {{currencyId}}</label>
              </div>
              <div class="col my-0">
              </div>
              <div class="col my-0">
              </div>
          </div>

          <div class="form-row col-md-12 mt-2" v-if="canAdd && supplierId">
             <i class="cursor-pointer" @click="newSupplierCredit">
                {{tr('Add credit')}}<font-awesome-icon icon="plus-circle" class="ml-2"/>
             </i>
          </div>

          <div class="form-row mt-5" v-if="supplierId">
                <div class="section-title ml-4">
                    <label><span class="mr-2">{{tr('Balance Pending')}}:
                    </span> {{pending}}</label>
                </div>
          </div>

        </div>
        <div v-else>
            <img class="loading-gif" src="@/img/loading.gif">
        </div>
        <payments-help
            v-if="showHelp"
            :show.sync="showHelp"
            :supplierId="supplierId"
            :pending="pendingValues"
            :credits="credits"
            :paid="paid"
            :toPay="toPay"
            :supplierCredits="supplierCredits"
            :payments="payments"
            :bookingCurrency="recordStore.CurrencyId"
        ></payments-help>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
const paymentsHelp = importVueComp('components/tools', 'PaymentsHelp');
export default {
    name: 'booking-payments',
    props: ['supplierId', 'supplierData'],
    components: {
        'payments-help': paymentsHelp
    },
    data () {
        return {
            processingPayments: false,
            payments: [],
            supplierCredits: [],
            help: false,
            showTotals: false,
            showHelp: false,
        }
    },
    mounted () {
        this.getPayments();
        EventBus.$on('close-payment', this.getPayments);
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            operActions: state => state.main.operActions,
            salesActions: state => state.main.salesActions,
            adminActions: state => state.main.adminActions,
        }),
        paymentCurrency () {
            let list = [];
            if (!this.payments) return;
            for (let p of this.payments) {
                if (!p.CurrencyId) continue;
                if (list.indexOf(p.CurrencyId) == -1) list.push(p.CurrencyId);

            }
            if (list.length==1) return list[0];
        },
        toPay () {
            let res = {};
            if (!this.payments) return res;
            for (let p of this.payments) {
                if (p.PaymentStatus == 'ON_ACCOUNT') continue;
                if (p.PaymentStatus == 'CANCELED') continue;
                if (!p.CurrencyId) continue;
                if (!p.Amount) continue;
                if (!res[p.CurrencyId]) res[p.CurrencyId] = 0;
                res[p.CurrencyId] += p.Amount;
            }
            return res;
        },
        paid () {
            let res = {};
            for (let currency in this.toPay) {
               res[currency] = 0;
            }
            for (let p of this.payments) {
                if (p.PaymentStatus == 'CANCELED') continue;
                if (p.PaymentStatus == 'ON_ACCOUNT') continue;
                if (!p.PaymentCurrencyId) continue;
                if (!p.PaymentAmount) continue;
                let rates = [{FromCurrencyId: p.FromCurrencyId, ToCurrencyId: p.ToCurrencyId, Rate: p.CurrencyRate}]
                let toCurrency = p.CurrencyId
                let r = travelTools.currencyConvert(p.PaymentCurrencyId, toCurrency,
                    p.PaymentAmount, rates);
                if (!res[toCurrency]) res[toCurrency] = 0;
                res[toCurrency] += r;
            }
            return res;
        },
        credits () {
            let res = {};
            for (let currency in this.toPay) {
               res[currency] = 0;
            }
            if (this.supplierCredits) {
                for (let p of this.supplierCredits) {
                    if (!p.CurrencyId) continue;
                    if (!p.Amount) continue;
                    let rates = [{FromCurrencyId: p.FromCurrencyId, ToCurrencyId: p.ToCurrencyId, Rate: p.CurrencyRate}]
                    let toCurrency = p.CurrencyId
                    let r = travelTools.currencyConvert(p.CurrencyId, toCurrency, p.Amount, rates);
                    if (!res[toCurrency]) res[toCurrency] = 0;
                    res[toCurrency] += r;
                }
            }
            return res;
        },
        pendingValues () {
            let res = {};
            for (let currency in this.toPay) {
                res[currency] = this.toPay[currency] - this.paid[currency] + this.credits[currency];
            }
            return res;
        },
        pending () {
            let res = [];
            for (let currency in this.toPay) {
                let paid = 0;
                if (this.paid[currency]) paid = this.paid[currency];
                let credit = this.credits[currency];
                let c = _.find(api.tables.currency, (r) => r.id == currency);
                res.push(`${tools.formatNumber( this.toPay[currency] + credit - paid, {c})} ${currency}`);
            }
            return res.join('; ');
        },
        canAdd () {
            return true;
        },
    },
    watch: {
        supplierId () {
            this.updatePayments();
        }
    },
    methods: {
        canEdit (row) {
            return true;
        },
        updatePayments () {
            if (!this.supplierId)  {
                  this.payments = api.payments;
                  this.supplierCredits = api.supplierCredits;
            } else {
                this.payments = _.filter(api.payments, (p) => p.SupplierId == this.supplierId);
                this.supplierCredits = _.filter(api.supplierCredits, (p) => p.SupplierId == this.supplierId);;
            }
        },
        async getPayments () {
            this.processingPayments = true;
            await api.setPayments(this.recordStore.id);
            await api.setSupplierCredits(this.recordStore.id);
            this.updatePayments();
            this.processingPayments = false;
        },
        getDate (d) {
            return tools.getDate(d);
        },
        async editPayment (id) {
            this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'bookingpayment',
                modalId: id, closeAction: 'close-payment'});
        },
        async editSupplierCredit (id) {
            EventBus.$emit('open-modal-record', {modalRecord: null, modalTable: 'suppliercredit',
                modalId: id, closeAction: 'close-payment'});
        },
        async newPayment () {
            if (!this.supplierId) return;
            let model = await api.importMixinModule('bookingpayment', 'model');
            let newRecord = await new model({tableName: 'bookingpayment'});
            newRecord.SupplierId = this.supplierId;
            newRecord.BookingId = this.recordStore.id;
            this.$root.$children[0].openModalRecord({modalRecord: newRecord, modalTable: 'bookingpayment',
                modalId: 'new', closeAction: 'close-payment'});
        },
        async newSupplierCredit () {
            if (!this.supplierId) return;
            let model = await api.importMixinModule('suppliercredit', 'model');
            let newRecord = await new model({tableName: 'suppliercredit'});
            newRecord.SupplierId = this.supplierId;
            newRecord.BookingId = this.recordStore.id;
            EventBus.$emit('open-modal-record', {modalRecord: newRecord, modalTable: 'suppliercredit',
                modalId: 'new', closeAction: 'close-payment'});
        },
        getBGClass (idx) {
            if (idx % 2 == 0) return 'bg-silver';
            return '';
        },
        toTitleCase (t) {
            return tools.toTitleCase(t);
        }
    },
    beforeDestroy() {
        EventBus.$off('close-payment', this.getPayments);
    },
}
</script>

