<template>
    <ul v-if="node.children && node.children.length">
        <li v-for="child in node.children" :key="child.id">
            <span v-if="seletedGroups.includes(child.id)" style="background-color: yellow;"> {{ child.Description }}</span>
            <span v-else>{{ child.Description }}</span>
            <span > ({{countUsers(child.id)}})</span>
            <font-awesome-icon icon="users" size="xs" class="ml-2 mb-1" 
                @click="showUsers = showUsers == child.id ? false : child.id" :class="{'blue': showUsers == child.id}"/>
            <span v-if="showUsers == child.id">
                <span v-for="user in groupUsers(child.id)" :key="user.id" :class="{'closed': user.Closed}"> {{ user.UserName }}, </span>
            </span>
            <access-group-tree-node :node="child" :seletedGroups="seletedGroups"/>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'access-group-tree-node',
    data() {
        return {
            showUsers: false
        }
    },
    props: {
        node: {
            type: Object,
            required: true
        },
        seletedGroups: {
            type: Array,
        }
    },
    methods: {
        groupUsers (groupId) {
            return _.filter(api.tables.user, (u) => u.AccessGroupId == groupId);
        },
        countUsers (groupId) {
            return _.filter(api.tables.user, (u) => u.AccessGroupId == groupId).length;
        }
    }   
}
</script>

<style scoped>
.closed {
    color: red !important;
}
.blue {
    color: blue !important;
}
</style>
