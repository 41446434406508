let SuperLoadTables = vueTools.importModule('load-tables', 'tools', 'contacts').default;

class LoadTables extends SuperLoadTables {

    async getPromises () {
        await super.getPromises();
        if (api.currentUser && !api.currentUser.AgencyId && !this.currentRoute.meta.public) {
            api.setTable({table: "contact"});
        }

    }

}

export default LoadTables;
