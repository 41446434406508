<template>
  <div class="modal fade" id="image-names" tabindex="-1" role="dialog" aria-labelledby="image-names"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content form-material">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Input Image Name')}}</h5>
        </div>
        <div class="modal-body form-group form-default form-row" v-if="ready">
          <div class="d-flex align-items-end mt-2 px-3" v-for="(v, i) of names"
              :class="{'col-4': names.length==3, 'col-6': names.length!=3}" >
              <div class="text-center">
                  <img :src="v.src" class="img-fluid rounded mb-3" alt="Responsive image" >
                  <input type="text" v-model="names[i].value" class="form-control" :placeholder="tr('Add name')">
                    <span class="form-bar"></span>
                  </input>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">{{tr('Done')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'image-names',
    mixins: [escape],
    props: ['names'],
    data () {
        return {
            ready: false,
        }
    },
    mounted () {
        this.ready = true;
        $('#image-names').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        save () {
            this.$emit('save');
            this.close();
        },
        close () {
            this.$emit('close');
            $('#image-names').modal('hide');
        },
    },
}
</script>

<style scoped>
  img {
      //max-width: 200px !important;
      width: 100%;
      height: auto  !important;
  }
</style>