<template>

    <div class="form-row form-material">
        <div class="form-row col-md-8 card-header pb-2">
            <div class="form-row col-md-12 ml-4 section-title">
                <label>{{tr('Configuration')}}</label>
            </div>
            <div class="form-row col-md-9 mt-2">
                <div class="col-md-4 px-2">
                    <select-input fieldName="CustomerTypeId"
                      :field="fieldsObject.CustomerTypeId"
                      label="Customer Type"
                      :currentValue.sync="record.CustomerTypeId" :required="true"
                      :disabled="!salesActions || confirmed">
                      @change="change('CustomerTypeId')"
                    </select-input>
                </div>
                <div class="col-md-4 px-2">
                    <number-input
                        :v.sync="record.Markup"
                        label="Markup"
                        d="0"
                        :disabled="!salesActions || confirmed"
                        @change="change('Markup')"
                    ></number-input>
                </div>
                <div class="col-md-4 px-2" >
                    <date-picker
                        label="Start Date"
                        :def.sync="record.StartDate" id="Quote-StartDate"
                        @change="change('StartDate')"
                        :disabled="!salesActions"
                    ></date-picker>
                </div>
                <div class="col-md-4 px-2">
                    <select-input fieldName="CurrencyId"
                    :field="fieldsObject.CurrencyId" label="Quote in" id="CurrencyId"
                    :addClass="(record && record.invalid && record.invalid.CurrencyId)? 'border-danger': ''"
                    :currentValue.sync="record.CurrencyId" :required="true"
                    @change="change('CurrencyId')"
                    :disabled="!salesActions || confirmed"></select-input>
                </div>
                <div class="col-md-4 px-2">
                    <select-input fieldName="PriceCurrencyId" label="Customer Price"
                    :addClass="(record && record.invalid && record.invalid.PriceCurrencyId)? 'border-danger': ''"
                    :field="fieldsObject.PriceCurrencyId"
                    :currentValue.sync="record.PriceCurrencyId" :required="true" id="PriceCurrencyId"
                    @change="change('PriceCurrencyId')"
                    :disabled="!salesActions || confirmed"></select-input>
                </div>
                <div class="col-md-4 px-2" v-if="quoteByQuantity">
                    <select-input fieldName="QuoteBy" label="Quote per"
                    :field="fieldsObject.QuoteBy"
                    @change="change('QuoteBy')"
                    :disabled="!salesActions || confirmed || haveQuotations"
                    :currentValue.sync="record.QuoteBy">
                    </select-input>
                </div>
                <div class="col-md-4 px-2">
                    <select-input fieldName="ShowPriceBy" label="Show Prices per"
                    :field="fieldsObject.ShowPriceBy"
                    :currentValue.sync="record.ShowPriceBy" :required="true"
                    @change="change('ShowPriceBy')"
                    :disabled="!salesActions || confirmed">
                    </select-input>
                </div>
                <div class="col-md-4 px-2"
                    v-if="recordStore.ShowPriceBy=='TOTAL_TRIP'">
                    <select-input fieldName="SumByType" label="Sum By"
                    :field="fieldsObject.SumByType"
                    :currentValue.sync="record.SumByType" :required="true"
                    @change="change('SumByType')"
                    :disabled="!salesActions || confirmed">
                    </select-input>
                </div>
                <div class="col-md-4 px-2" v-if="recordStore.ShowPriceBy=='TOTAL_TRIP'">
                    <select-input fieldName="FlightsBy" label="Flights by"
                    :field="fieldsObject.FlightsBy"
                    @change="change('FlightsBy')"
                    :disabled="!salesActions || confirmed"
                    :currentValue.sync="record.FlightsBy">
                    </select-input>
                </div>

                <div class="col-md-4 px-2" v-if="recordStore.SplitQuoteDate">
                    <date-picker :def.sync="record.QuoteDate" id="Quote-QuoteDate"
                    label="Quote Date"
                    @change="change('QuoteDate')"
                    :disabled="!salesActions"></date-picker>
                </div>
                <div class="col-md-4 px-2" v-if="recordStore.PriceBreakdown">
                    <number-input
                        :v.sync="record.PaxFrom"
                        label="Pax From"
                        d="0"
                        @change="change('PaxFrom')"
                    ></number-input>
                </div>
                <div class="col-md-4 px-2" v-if="recordStore.PriceBreakdown">
                    <number-input
                        :v.sync="record.PaxTo"
                        label="Pax To"
                        d="0"
                        @change="change('PaxTo')"
                    ></number-input>
                </div>
            </div>
            <booking-quote-config-checkbox
                class="col-md-3 mt-2 d-flex flex-column pl-3"
            ></booking-quote-config-checkbox>
        </div>
        <div class="form-row card-header col-md-4 pb-2">
            <div class="col-md-12">
                <div class="ml-4 section-title">
                    <label>{{tr('Currency Rates')}}</label>
                    <font-awesome-icon icon="sync"  @click="updateCurrencyRates" class="ml-3"/>
                </div>
                <div v-for="(rate, rateId) of rates" class="form-row col-md-12">
                    <div class="form-default col-md-4 d-flex align-items-end">
                        <span>{{rate.FromCurrencyId}}</span>
                    </div>
                    <div class="form-default col-md-4 d-flex align-items-end">
                        <span>{{rate.ToCurrencyId}}</span>
                    </div>
                    <div class="col-md-4">
                        <number-input
                            class="mb-0"
                            :key="rate.FromCurrencyId + '-' + rate.ToCurrencyId"
                            :class="rate.Rate? '': 'border-danger'"
                            :v.sync="rate.Rate"
                            :disabled="!salesActions || confirmed"
                            @change="setCurrencyRate(rate.Rate, rateId)" d="2"
                        >
                        </number-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
const bookingQuoteConfigCheckbox = importVueComp('components/booking', 'BookingQuoteConfigCheckbox');


export default {
    name: 'booking-quote-config',
    components: {
        'booking-quote-config-checkbox': bookingQuoteConfigCheckbox
    },
    data () {
        return {
            rates: {},
            record: {},
        }
    },
    mounted () {
        this.setRecord();
        this.setRates();
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
        }),
        confirmed () {
            return this.recordStore.bookingConfirmed;
        },
        fieldsObject () {
            return this.recordStore.fieldsObject;
        },
        quoteByQuantity () {
            return api.quoteByQuantity;
        },
        haveQuotations () {
            return Object.keys(this.recordStore.BaseQuotes.bases).length > 0;
        }
    },
    methods: {
        setRecord () {
            this.record = tools.plainObject(this.recordStore, true);
        },
        setRates () {
            let r = tools.plainObject(this.recordStore.currencyRates, true);
            this.rates = r;
        },
        async setCurrencyRate (value, rateId) {
            await this.recordStore.CurrencyRates[rateId].setValue({fieldName: 'Rate', value});
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        async change (fieldName) {
            await this.recordStore.setValue({fieldName, value: this.record[fieldName]});
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        async updateCurrencyRates () {
            api.setProcessing(true)
            await this.recordStore.setCurrencyRates();
            EventBus.$emit('set-record');
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
            this.setRates();
            api.setProcessing(false)
        }
    },
}
</script>

