<template>
  <div class="mb-4 mt-2">
      <label><i>{{tr('New button in the right side of the text toolbar to reload updated service description')}}</i></label>
      <img src="https://nubbo-change-log.s3.amazonaws.com/service-text-refresh.png" class="w-100 border">
  </div>
</template>

<script>
export default {
    name: 'service-text-refresh',
}
</script>
