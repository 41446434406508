<template>
    <div>
        <label v-if="label">{{tr(label)}}</label>
        <div class="image-container mb-2" v-if="imageSrc" @dragover="dragOver" @dragleave="dragLeave" @drop="drop">
            <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" style="display: none"
              class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />

            <div data-file-uploader="preview">
                <img :src="imageSrc" class="img-fluid rounded" alt="Responsive image" v-show="imageSrc">
            </div>
            <div class="overlay form-row mx-0" v-if="!disabled">
                <div class="ml-2" :class="{'mt-2': !addClass}">
                    <button class="btn btn-primary action-button mt-0" @click="showPhotoPicker">
                        <font-awesome-icon icon="plus"/>
                    </button>
                </div>
                <div class="ml-2" v-if="showDelete" :class="{'mt-2': !addClass}">
                    <button class="btn btn-primary action-button mt-0" @click="deletePhoto">
                        <font-awesome-icon icon="trash-alt"/>
                    </button>
                </div>

            </div>
        </div>
        <div class="image-container" v-else-if="!disabled" @dragover="dragOver" @dragleave="dragLeave" @drop="drop">
            <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" style="display: none"
              class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
            <div class="default-image" :class="addClass">
                <font-awesome-icon icon="image" />
            </div>
            <div class="overlay"  >
                <a data-toggle="modal" data-target="#photoPicker" @click="showPhotoPicker" href="#">
                    <div class="add-image">
                        <font-awesome-icon icon="plus"/>
                    </div>
                </a>
                <div class="ml-2" v-if="showDelete" :class="{'mt-2': !addClass}">
                    <button class="btn waves-effect waves-light btn-danger" @click="deletePhoto">
                        <font-awesome-icon icon="trash-alt"/>
                    </button>
                </div>
            </div>
        </div>
        <photo-picker v-if="show" @setSrc="setSrc" :show.sync="show" :set="true" :matClass="matClass"></photo-picker>
        <select-value
            v-if="selectFolder"
            @save="setFolder"
            :show.sync="selectFolder"
            editor="vue-select"
            text="Select Folder"
            :fieldOptions="folders"
        ></select-value>
    </div>
</template>

<script>
export default {
    name: 'photo-div',
    props: ['label', 'image', 'imageId', 'addClass', 'showDelete', 'disabled', 'matClass'],
    data () {
        return {
            file: null,
            imageSrc: null,
            show: false,
            selectFolder: false,
            folders: [],
            imageApi: null,
        }
    },
    mounted () {
        if (this.image && this.image.url) {
            this.imageSrc = this.image.url;
        }
    },
    watch: {
        image () {
            if (this.image && this.image.url) {
                this.imageSrc = this.image.url;
            } else {
                this.imageSrc = null;
            }
        },
    },
    methods: {
        deletePhoto () {
            this.$emit('update:image', {});
            this.$emit('update:imageId', null);
            this.$emit('remove');
            this.imageSrc = null;
        },
        /*setSrc (img) {
            if (img.id) {
                this.imageSrc = img.url;
                this.$emit('update:image', Object.assign({}, img));
                this.$emit('update:imageId', img.id);
                this.$emit('change');
            }
            this.show = false;
        },*/
        setSrc (images) {
            for (let i = 0; i < images.length; i++) {
                let img = images[i];
                if (img.id && i == 0) {
                    this.imageSrc = img.url;
                    this.$emit('update:image', Object.assign({}, img));
                    this.$emit('update:imageId', img.id);
                    this.$emit('change');
                }
                if (img.id && i > 0) {
                    setTimeout(() => {
                        this.$emit('newImage', Object.assign({}, img));
                    }, 500 * i);
                }
            }
        },
        showPhotoPicker () {
            this.show = true;
        },
        onChange() {
          this.file = this.$refs.file.files[0];
        },
        remove(i) {
          this.file = null;
        },
        dragOver(event) {
          event.preventDefault();
          if (!event.currentTarget.classList.contains('drag-over')) {
            event.currentTarget.classList.add('drag-over');
          }
        },
        dragLeave(event) {
          event.currentTarget.classList.remove('drag-over');
        },
        async drop(event) {
          event.preventDefault();
          this.$refs.file.files = event.dataTransfer.files;
          this.onChange();
          event.currentTarget.classList.remove('drag-over');
          this.imageApi = await new imageApi();
          let folders = [];
          for (let n in this.imageApi.files) {
              folders.push({label: n, value: n})
          }
          this.folders = folders;
          this.selectFolder = true;
        },
        async setFolder (value) {
          if (!value) return;
          this.imageApi.path = value;
          api.setProcessing(true);
          let res = await this.imageApi.setImageName(this.file, null, true);
          if (res) this.setSrc(res);
          api.setProcessing(false);
        }
    },
}
</script>

<style scoped>
  .drag-over {
    border: dashed;
  }
</style>