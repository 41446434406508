<template>
    <div class="mr-auto p-2" v-if="record">
        <div class="form-row">
            <label class="mb-0">{{tr('Created')}}: {{createdUserName}} {{record.CreatedUTC | toLocalDateTime}}</label>
        </div>
        <div class="form-row">
            <label>{{tr('Edited')}}: {{lastUserName}} {{record.ModifiedUTC | toLocalDateTime}}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'edited-by',
    props: ['record'],
    computed: {
        lastUserName () {
            if (this.record.ModifiedUserId) {
                let user = _.find(api.tables.user, (r) => r.id==this.record.ModifiedUserId);
                if (user) {
                    return user.UserName;
                }
            }
        },
        createdUserName () {
            if (this.record.CreatedUserId) {
                let user = _.find(api.tables.user, (r) => r.id==this.record.CreatedUserId);
                if (user) {
                    return user.UserName;
                }
            }
        }
    },
}
</script>

<style scoped>
 label {
    font-size: 14px;
 }
</style>