<template>
    <div class="modal fade bd-example-modal-lg" id="booking-versions" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="templateBookingVersions" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="btn btn-primary btn-group"  @click="onlyDiff = true"  v-if="compare && !onlyDiff">
              {{tr('Only Differences')}}
            </button>
            <button type="button" class="btn btn-primary btn-group"  @click="onlyDiff = false"  v-if="compare && onlyDiff">
              {{tr('All')}}
            </button>
            <button type="button" class="btn btn-primary btn-group ml-2"  @click="showVersions"  v-if="compare">
              {{tr('Back')}}
            </button>
            <button type="button" class="btn btn-group ml-2"  @click="mode = 'Cost'" v-if="compare" :class="{'btn-success': mode == 'Cost'}">
              {{tr('Cost')}}
            </button>
            <button type="button" class="btn btn-group ml-2"  @click="mode = 'Price'" v-if="compare" :class="{'btn-success': mode == 'Price'}">
              {{tr('Price')}}
            </button>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
              <span aria-hidden="true">&times;</span>
            </button>

            <table class="table table-striped  table-sm table-bordered" v-if="!processing && !compare">
                <thead>
                    <tr class="table-secondary">
                        <th><span>{{tr('Version')}}</span></th>
                        <th><span>{{tr('Date')}}</span></th>
                        <th><span>{{tr('Status')}}</span></th>
                        <th><span>{{tr('Title')}}</span></th>
                        <th><span>{{tr('Comment')}}</span></th>
                        <th colspan="6"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="c in versions" class="service-card" >
                        <td>{{c.id}}</td>
                        <td>{{c.VersionDate | toLocalDateTime}}</td>
                        <td>{{tr(statusNames[c.BookingStatus])}}</td>
                        <td>{{c.Title}}</td>
                        <td>{{c.Comment}}</td>
                        <td>
                            <button type="button" class="btn btn-primary btn-sm" @click="openVersion(c.id, c.Hash)">
                                {{tr('Open link')}}
                            </button>
                        </td>
                        <td>
                            <button type="button" class="btn btn-primary btn-sm" @click="openBookingWindow(c.id, c.Hash)">
                                {{tr('View')}}
                            </button>
                        </td>
                        <td v-if="!recordStore.bookingConfirmed && (salesActions || managerActions)" >
                            <button type="button" class="btn btn-primary btn-sm" @click="restoreVersion(c.id, c.Hash)">
                                {{tr('Restore')}}
                            </button>
                        </td>
                        <td>
                            <button type="button" class="btn btn-primary btn-sm" @click="compareVersion(c.id, c.Hash)">
                                {{tr('Compare')}}
                            </button>
                        </td>
                        <td v-if="c.Disabled">
                            <button type="button" class="btn btn-warning btn-sm" @click="enable(c.id)">
                                {{tr('Enable')}}
                            </button>
                        </td>
                        <td v-else>
                            <button type="button" class="btn btn-primary btn-sm" @click="disable(c.id)">
                                {{tr('Disable')}}
                            </button>
                        </td>
                        <td v-if="salesActions || managerActions">
                            <button type="button" class="btn btn-danger action-button" @click="removeVersion(c.id)">
                                <font-awesome-icon icon="trash-alt"/>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-striped  table-sm table-bordered" v-if="!processing && compare">
                <thead>
                    <tr class="table-secondary">
                        <th><span>{{tr('Day')}}</span></th>
                        <th><span>{{tr('Service')}}</span></th>
                        <th><span>{{tr('Version')}}</span></th>
                        <th><span>{{tr('Current')}}</span></th>
                        <th><span>{{tr('Difference')}}</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="c in compare" class="service-card" v-if="!onlyDiff || c.diff">
                        <td>{{c.dayNr + 1}}</td>
                        <td>{{c.name}}</td>
                        <td v-if="c.cost">{{c.cost | formatNumber({c: recordStore.Currency})}}</td>
                        <td v-else></td>
                        <td v-if="c.currentCost">{{c.currentCost | formatNumber({c: recordStore.Currency})}}</td>
                        <td v-else></td>
                        <td v-if="c.diff && c.diff > 0" style="color: blue">+{{c.diff | formatNumber({c: recordStore.Currency})}}</td>
                        <td v-else-if="c.diff && c.diff < 0" style="color: red">{{c.diff | formatNumber({c: recordStore.Currency})}}</td>
                        <td v-else></td>
                    </tr>
                </tbody>
                <thead>
                    <tr class="table-secondary">
                        <th colspan="4"><span>Total</span></th>
                        <th><span>{{totalDiff | formatNumber({c: recordStore.Currency})}}</span></th>
                    </tr>
                </thead>
            </table>

            <div v-if="processing">
                <img class="loading-gif" src="@/img/loading.gif">
            </div>
          </div>
        </div>
      </div>
      <confirm-delete @confirmRemove="remove" v-if="canDelete && toDelete" @close="closeRemove"></confirm-delete>
    </div>
</template>

<script>
const confirmDelete = importVueComp('components/tools', 'ConfirmDelete');
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-versions',
    mixins: [escape],
    props: ['bookingId', 'showTabs'],
    components: {
        'confirm-delete': confirmDelete
    },
    data  () {
        return {
            versions: null,
            processing: false,
            toRemove: null,
            toDelete: false,
            compare: false,
            onlyDiff: false,
            totalDiff: 0,
            endpoint: '/api/bookingversion/',
            model: null,
            mode: 'Cost',
            id: null,
            hash: null
        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
        }),
        canDelete () {
            return tools.canAccess(this.user, 'api','DELETE', this.endpoint);
        },
        statusNames () {
            return this.recordStore? this.recordStore.bookingStatus: {};
        }
    },
    async mounted (){
        await this.getVersion();
        this.model = await api.importMixinModule('booking', 'model');
        $('#booking-versions').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        async getVersion () {
            this.processing = true;
            let res = await api.get(this.endpoint, {BookingId: this.recordStore.id })
            if (res) {
                this.versions = res;
                this.processing = false;
            }
        },
        openVersion (id, hash) {
            let route = this.$router.resolve({
                name: 'booking-version', params: {hash: hash, id: id, version: 'v'}})
            window.open(route.href, '_blank');
        },
        openBookingWindow (id, hash) {
            let route = this.$router.resolve({
                name: 'view-booking-version', params: {id: id, version: 'v'}})
            window.open(route.href, '_blank');
        },
        async remove () {
            this.processing = true;
            let res = await api.remove(this.endpoint, this.toRemove);
            if (res) {
                let i = _.findIndex(this.versions, (r) => r.id == this.toRemove);
                if (i>=0) {
                    this.versions.splice(i, 1);
                }
                this.processing = false;
            }
            this.toRemove = null;
        },
        removeVersion (id) {
            this.toRemove = id;
            this.toDelete = true;
        },
        async disable (id) {
            await api.post('/api/bookingversion/disable/' + id);
            await this.getVersion();
        },
        async enable (id) {
            await api.post('/api/bookingversion/enable/' + id);
            await this.getVersion();
        },
        closeRemove () {
            this.toDelete = false;
        },
        async restoreVersion (id, hash) {
            this.processing = true;
            let version = await api.get(this.endpoint + id);
            if (version) {
                this.$emit('update:showTabs', false);
                let booking = version.Data;
                booking.syncVersion = this.recordStore.syncVersion;
                let model = await api.importMixinModule('booking', 'model');
                let r = await new model({record: booking, isTemplate: this.recordStore.isTemplate});
                this.$store.commit('initRecord', {record: r});
                EventBus.$emit('set-record');
                this.$emit('update:showTabs', true);
                this.recordStore.setModified(true)
                this.processing = false;
                this.close();
            }
        },
        showVersions () {
            this.compare = false;
        },
        async compareVersion (id, hash) {
            this.id = id;
            this.hash = hash;
            this.totalDiff = 0;
            this.processing = true;
            let version = await api.get('/api/bookingversion/' + id);
            if (version) {
                let compare = [];
                let data = version.Data;
                let model = await api.importMixinModule('booking', 'model');
                let booking = await new model({record: data});
                let totalsByHotelByRoom = booking.totalsByHotelByRoom;
                let totalsByHotelByRoomCurrent = this.recordStore.totalsByHotelByRoom;
                for (let day of booking.BookingDays) {
                    for (let s of day.BookingDayServices) {
                        let sTotal = s.serviceTotal;
                        let r;
                        let currentService;
                        if (s.Service && s.Service.id) {
                            r = {dayNr: day.DayNr, id: s.Service.id, name: s.Service.Name, cost: sTotal[this.mode], currentCost: null, diff: -sTotal[this.mode]}
                            currentService = this.findServiceInCurrentBooking(day.DayNr, s.Service.id);
                        } else {
                            r = {dayNr: day.DayNr, id: 'F-' + s.id, name: s.Name, cost: sTotal[this.mode], currentCost: null, diff: -sTotal[this.mode]}
                            currentService = this.findServiceInCurrentBooking(day.DayNr, null, s.id);
                        }
                        if (currentService) {
                            let currentCost = currentService.serviceTotal;
                            if (currentCost) {
                                r.currentCost = currentCost[this.mode];
                                r.diff += r.currentCost;
                            }
                        }
                        if (r.diff) this.totalDiff += r.diff;
                        compare.push(r);
                    }
                    for (let hotel of day.BookingDayHotels) {
                        for (let room of hotel.BookingDayRooms) {
                            if (!totalsByHotelByRoom[room.Hotel.id]) continue;
                            if (!totalsByHotelByRoom[room.Hotel.id][room.id]) continue;
                            let price = totalsByHotelByRoom[room.Hotel.id][room.id][this.mode];
                            let name = room.Hotel.Name + " " + room.Rooms;
                            name += " " + room.Room.Name + " " + this.getRoomCategoryName(room.Hotel, room.RoomCategory);
                            name += " " + hotel.Days + " " + tr('Nights');
                            let r = {dayNr: day.DayNr, id: room.id, name: name, cost: price, currentCost: null, diff: -price}
                            let currentRoom = this.findRoomInCurrentBooking(day.DayNr, room, hotel.Days);
                            if (currentRoom) {
                                let currentCost = totalsByHotelByRoomCurrent[currentRoom.Hotel.id][currentRoom.id];
                                if (currentCost) {
                                    r.currentCost = currentCost[this.mode];
                                    r.diff += r.currentCost[this.mode];
                                }
                            }
                            if (r.diff) this.totalDiff += r.diff;
                            compare.push(r);
                        }
                    }
                }
                for (let day of this.recordStore.BookingDays) {
                    for (let s of day.BookingDayServices) {
                        if (s.Service && s.Service.id) {
                            let found = _.find(compare, (r) => r.dayNr == day.DayNr && r.id == s.Service.id);
                            if (found) continue;
                            let sTotal = s.serviceTotal;
                            let r = {dayNr: day.DayNr, id: s.Service.id, name: s.Service.Name, cost: null, currentCost: sTotal[this.mode], diff: sTotal[this.mode]}
                            if (r.diff) this.totalDiff += r.diff;
                            compare.push(r);
                        } else {
                            let sId = 'F-' + s.id;
                            let found = _.find(compare, (r) => r.dayNr == day.DayNr && r.id == sId);
                            if (found) continue;
                            let sTotal = s.serviceTotal;
                            let r = {dayNr: day.DayNr, id: sId, name: s.Name, cost: null, currentCost: sTotal[this.mode], diff: sTotal[this.mode]}
                            if (r.diff) this.totalDiff += r.diff;
                            compare.push(r);
                        }
                    }
                    for (let hotel of day.BookingDayHotels) {
                        for (let room of hotel.BookingDayRooms) {
                            if (!totalsByHotelByRoomCurrent[room.Hotel.id]) continue;
                            if (!totalsByHotelByRoomCurrent[room.Hotel.id][room.id]) continue;
                            let name = room.Hotel.Name + " " + room.Rooms;
                            name += " " + room.Room.Name + " " + this.getRoomCategoryName(room.Hotel, room.RoomCategory);
                            name += " " + hotel.Days + " " + tr('Nights');
                            let found = _.find(compare, (r) => r.dayNr == day.DayNr && r.name == name);
                            if (found) continue;
                            let sTotal = totalsByHotelByRoomCurrent[room.Hotel.id][room.id];
                            let r = {dayNr: day.DayNr, id: room.id, name: name, cost: null, currentCost: sTotal[this.mode], diff: sTotal[this.mode]}
                            if (r.diff) this.totalDiff += r.diff;
                            compare.push(r);
                        }
                    }
                }
                compare.sort(function(a, b){return a.dayNr - b.dayNr})
                this.compare = compare;
                this.processing = false;
            }
        },
        getRoomCategoryName (hotel, roomId) {
            for (let category of hotel.RoomCategories) {
                if (category.id == roomId) return category.Name;
            }
            return '';
        },
        findServiceInCurrentBooking (dayNr, serviceId, sId) {
            let day = _.find(this.recordStore.BookingDays, (r) => r.DayNr == dayNr);
            if (day) {
                let s = _.find(day.BookingDayServices, function(r) {
                    if (serviceId && r.Service.id == serviceId) return true;
                    if (!serviceId && sId==r.id) return true;
                });
                if (s) {
                    return s;
                }
            }
        },
        findRoomInCurrentBooking (dayNr, room, days) {
            let day = _.find(this.recordStore.BookingDays, (r) => r.DayNr == dayNr);
            if (day && day.BookingDayHotels[0] && day.BookingDayHotels[0].Days==days) {
                let row = _.find(day.BookingDayHotels[0].BookingDayRooms, function(r) {
                    if (r.RoomTypeId == room.RoomTypeId && r.RoomCategory==room.RoomCategory
                        && r.Hotel && room.Hotel && r.Hotel.id == room.Hotel.id) return true;
                });
                if (row) {
                    return row;
                }
            }
        },
        close (){
            $('#booking-versions').modal('hide');
            this.$emit('close');
        },
    },
    watch: {
        mode () {
            this.compareVersion(this.id, this.hash);
        }
    }
}
</script>
