<template>
    <div class="tab-content" id="TabsContent" v-if="recordStore">
        <div class="tab-pane fade show" :class="getClass('cover')" id="tab-cover" role="tabpanel" aria-labelledby="tab-cover">
            <booking-cover
                v-if="currentTab == 'cover'"
                @notification="$emit('notification', message)"
            ></booking-cover>
        </div>
        <div class="tab-pane fade show" :class="getClass('detail')" id="tab-detail" role="tabpanel" aria-labelledby="tab-detail">
            <div class="card no-border-top pt-2 tab-detail" v-if="currentTab == 'detail'">
                <div v-if="showDetailTab">
                    <div class="col-12">
                        <booking-detail
                            v-if="!byCity"
                            @notification="$emit('notification', message)"
                            @groupBy="byCity = !byCity"
                        ></booking-detail>
                        <booking-detail-city
                            v-else
                            @notification="$emit('notification', message)"
                            @groupBy="byCity = !byCity"
                        ></booking-detail-city>
                    </div>
                </div>
            </div>
      </div>
      <div class="tab-pane fade show" :class="getClass('quote')" id="tab-quote" role="tabpanel" aria-labelledby="tab-quote">
         <booking-quotes
            v-if="recordStore.notTemplate && currentTab == 'quote'"
         ></booking-quotes>
         <booking-quote-template
            v-else-if="currentTab == 'quote'"
         ></booking-quote-template>
      </div>

      <div class="tab-pane fade show" :class="getClass('info')" id="tab-info" role="tabpanel" aria-labelledby="tab-info">
         <booking-info v-if="currentTab == 'info'"></booking-info>
      </div>
      <div class="tab-pane fade show" :class="getClass('flights')" id="tab-flights" role="tabpanel" aria-labelledby="tab-flights">
         <booking-flights
            v-if="currentTab == 'flights'"
            :version="version"
         ></booking-flights>
      </div>
      <div class="tab-pane fade show text-center" :class="getClass('preview')" id="tab-preview" role="tabpanel" aria-labelledby="tab-preview">
         <div @contextmenu="handleClick($event)">
             <itinerary-edit
                class="w-100"
                v-if="currentTab == 'preview'"
             ></itinerary-edit>
            <div class="settings-buttons">
                <button type="button" class="settings-button p-0 btn" @click="exitFullScreen" v-if="fullScreen">
                    <span class="py-1"><font-awesome-icon icon="compress"/></span>
                </button>
            </div>
          </div>
      </div>
      <div class="tab-pane fade show" :class="getClass('operations')" id="tab-preview" role="tabpanel" aria-labelledby="tab-preview">
         <booking-operations
            v-if="recordStore.notTemplate && currentTab == 'operations'"
         ></booking-operations>
      </div>
      <div class="tab-pane fade show" :class="getClass('payments')" id="tab-preview" role="tabpanel" aria-labelledby="tab-preview">
         <booking-payments
            v-if="recordStore.notTemplate && currentTab == 'payments'"
         ></booking-payments>
      </div>
      <div class="tab-pane fade show" :class="getClass('receipts')" id="tab-preview" role="tabpanel" aria-labelledby="tab-preview">
         <booking-receipts
            v-if="recordStore.notTemplate && currentTab == 'receipts'"
         ></booking-receipts>
      </div>
      <div class="tab-pane fade show" :class="getClass('expenses')" id="tab-preview" role="tabpanel" aria-labelledby="tab-preview">
         <booking-expenses
            v-if="recordStore.notTemplate && currentTab == 'expenses'"
         ></booking-expenses>
      </div>
      <div class="tab-pane fade show" :class="getClass('mails')" id="tab-preview" role="tabpanel" aria-labelledby="tab-preview">
         <booking-mails
            v-if="recordStore.notTemplate && currentTab == 'mails'"
         ></booking-mails>
      </div>
      <div class="tab-pane fade show" :class="getClass('summary')" id="tab-summary" role="tabpanel" aria-labelledby="tab-summary">
            <booking-summary
                v-if="currentTab == 'summary'"
            ></booking-summary>
      </div>
      <div class="tab-pane fade show" :class="getClass('vouchers')" id="tab-vouchers" role="tabpanel" aria-labelledby="tab-vouchers">
         <div @contextmenu="handleClick($event)">
            <vouchers-preview
                v-if="currentTab == 'vouchers'"
                :edit="true"
            ></vouchers-preview>
          </div>
      </div>
        <div class="tab-pane fade show"
            v-for="tab of extraTabs"
            :class="getClass(tab.id)"
            :id="'tab-'+ tab.id" role="tabpanel"
            :aria-labelledby="'tab-' + tab.id">
            <component
                v-if="currentTab == tab.id"
                :is="tab.component"
                :options="tab.options"
            ></component>
        </div>

    </div>
</template>


<script>
const bookingCover = importVueComp('components/booking', 'BookingCover');
const bookingDetail = importVueComp('components/booking', 'BookingDetail');
const bookingDetailCity = importVueComp('components/booking', 'BookingDetailCity');
const bookingQuotes = importVueComp('components/booking', 'BookingQuotes');
const bookingQuoteTemplate = importVueComp('components/booking', 'BookingQuoteTemplate');
const bookingInfo = importVueComp('components/booking', 'BookingInfo');
const bookingFlights = importVueComp('components/booking', 'BookingFlights');
const bookingSummary = importVueComp('components/booking', 'BookingSummary');
const bookingOperations = importVueComp('components/booking', 'BookingOperations');
const itineraryEdit = importVueComp('components/itinerary', 'ItineraryEdit');
const bookingPayments = importVueComp('components/booking', 'BookingPayments');
const bookingReceipts = importVueComp('components/booking', 'BookingReceipts');
const bookingExpenses = importVueComp('components/booking', 'BookingExpenses');
const bookingMails = importVueComp('components/booking', 'BookingMails');
const vouchersPreview = importVueComp('components/voucher', 'VouchersPreview');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';

export default {
    name: 'booking-tabs-content',
    props: ['version'],
    components: {
        'booking-cover': bookingCover,
        'booking-detail': bookingDetail,
        'booking-detail-city': bookingDetailCity,
        'booking-quotes': bookingQuotes,
        'booking-quote-template': bookingQuoteTemplate,
        'booking-info': bookingInfo,
        'booking-flights': bookingFlights,
        'booking-summary': bookingSummary,
        'itinerary-edit': itineraryEdit,
        'booking-operations': bookingOperations,
        'booking-payments': bookingPayments,
        'booking-receipts': bookingReceipts,
        'booking-expenses': bookingExpenses,
        'booking-mails': bookingMails,
        'vouchers-preview': vouchersPreview
    },
    data () {
        return {
            byCity: false,
            showDetailTab: true,
            extraTabs: [],
        }
    },
    mounted () {
        let self = this;
        EventBus.$on('update-child', this.updateTab);
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            currentTab: state => state.booking.currentTab,
            recordStore: state => state.main.record,
            fullScreen: state => state.main.fullScreen,
        }),
    },
    methods: {
        getClass (tabId, field) {
            return tools.getClass(tabId, field, this.currentTab, this.recordStore.invalid);
        },
        updateTab () {
            this.showDetailTab = false;
            this.$nextTick().then(() => {
                this.showDetailTab = true;
            });
        },
        exitFullScreen () {
            this.$store.commit('setFullScreen', false);
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        }
    },
    beforeDestroy() {
        EventBus.$off('update-child', this.updateTab);
    },
}
</script>
