<template>
  <div class="modal fade" id="addService" tabindex="-1" role="dialog" aria-labelledby="addServiceLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addServiceLabel">{{tr('Add Service')}}</h5>
          <button type="button" class="close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form-material">
            <select-input fieldName="ServiceId" :fieldOptions="fieldOptions.ServiceId" :currentValue.sync="ServiceId"></select-input>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
          <button type="button" class="btn btn-outline-secondary" @click="add">{{tr('Done')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from "vuex";
export default {
    name: 'service-cost-add',
    mixins: [escape],
    props: ['services', 'period'],
    components: {
    },
    data () {
        return {
            ServiceId: null
        }
    },
    methods: {
        add () {
            let service = _.find(this.services, (s) => s.id == this.ServiceId);
            if (service) {
                let serviceClass = _.find(api.tables.serviceclass, (r) => r.id == service.ServiceClassId);
                let serviceType = _.find(api.tables.servicetype, (r) => r.id == service.ServiceTypeId);
                let data = {
                    name: service.Name,
                    id: service.id,
                    CurrencyId: service.CurrencyId,
                    CostBy: service.CostBy,
                }
                if (!data.CostBy) data.CostBy = service.ServiceType.CostBy;
                if (serviceClass) data.ServiceClass = serviceClass.Name;
                if (serviceType) data.type = serviceType.Name;
                this.$emit('addService', data, this.period)
            }
        },
        close () {
            $('#addService').modal('hide');
            this.$emit('close');
        },
    },
    computed: {
        fieldOptions () {
            let res = {ServiceId: []};
            for (let s of this.services) {
                res.ServiceId.push({label: s.Name, value: s.id})
            }
            return res;
        }
    },
}
</script>
