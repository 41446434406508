<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/bookigformlist"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "booking-form-list-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    required: true,
                    label: "Date Inn From",
                    columns: 2
                },
                {
                    name: "ToDate",
                    editor: "date",
                    required: true,
                    label: "Date Inn To",
                    columns: 2
                },
                {
                    name: "PaxName",
                    label: "Passenger Name",
                    required: true,
                    columns: 2
                },
                {
                    name: 'SupplierId',
                    relation: 'supplier',
                    optionLabels: 'Name',
                    columns: 2,
                    editor: 'select',
                    label: 'Supplier',
                    addBlank: true,
                    filters: function(r) {return r.SupplierType == 'FLIGHTS' ||  r.SupplierType == 'ALL';}
                },
            ],
            title: "Find Passenger",
            headers: {
                BookingId: "File Nr",
                StartDate: "Date Inn",
                PaxName: "Passenger",
                SalesUserName: "Seller",
            },
            templates: [
                {
                    name: "StartDate",
                    callback: function(row) {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
            ],

        };
    }
};
</script>
