<template>
      <div v-if="ready">
        <report-window
          endpoint="/agency/reports/agency_list"
          :fields="fields"
          :title="cardTitle || title"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :columnsClasses="columnsClasses"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :headers="headers"
          :columnClick="columnClick"
          :fieldStyles="fieldStyles"
        ></report-window>
      </div>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow';
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'agency-list-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
    },
    mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            reload: false,
            ready: false,
            fields: [
            ],
            title: 'Agency List',
            headers: {
            },
            templates: [
            ],
            columnsClasses: {
            },
            columnClick: {
            },
            fieldStyles: {
            },
        }
    },
    methods: {
    },
}
</script>

