var hotel = importMixin('fields', 'extra/taxes', 'hotel', 'views');

let i = _.findIndex(hotel.fields, (r) => r.name == 'CreateSupplier');
let f1 = { name: 'TaxCodeId', relation: 'taxcode', optionLabels: 'Name', hideFromList: true
                , editor: 'select', label: 'Tax', addBlank: true};
hotel.fields.splice(i+1, 0, f1);

let f2 = {name: 'HotelTaxCodes', editor: [], label: 'Taxes',
    rowFields: [
        {name: 'TaxCodeId', relation: 'taxcode', optionLabels: 'Name'
            , editor: 'select', label: 'Tax'},
        {name: 'PaxCountryType', label: 'Pax Type', editor: 'select',
            options: [
                {value: 'RESIDENT', label: 'Resident'},
                {value: 'NOT_RESIDENT', label: 'Not Resident'}
            ]
        },
   ]
};
hotel.fields.push(f2);


export default hotel;