<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="row ml-2 divPrint report-header text-center container">
              <div class="col-6">
                  <img src="@/extra/custom/img/logo-full.png" class="py-0 logo">
              </div>
              <div class="col-6 align-items-center text-left">
                  <h2>
                      {{getDocName}}
                  </h2>
              </div>
        </div>
        <table class="w-100 container">
          <thead>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr><td class="container" v-if="recordStore">
                <div class="row not-print text-center container">
                    <div class="col-6">
                        <img src="@/extra/custom/img/logo-full.png" class="py-0 logo">
                    </div>
                    <div class="col-6 align-items-center  text-left">
                        <h2>
                            {{getDocName}}
                        </h2>
                    </div>
                </div>

                <div class="container container-body mt-5">
                    <div class="row mb-5">
                        <div class="col-6 row">
                            <div class="col-12">
                                MATER TURISMO S.R.L
                            </div>
                            <div class="col-12">
                                Moldes 1811 Piso:1 Dpto:A - Capital Federal, Ciudad de Buenos Aires
                            </div>
                            <div class="col-12">
                                <a href="mater@matersustentable.tur.ar">
                                    mater@matersustentable.tur.ar
                                </a>
                            </div>
                            <div class="col-12">
                                Tel. (+54) 1156660848 / 32425880
                            </div>
                        </div>
                        <div class="col-6 row">
                            <div class="col-12">
                                {{getDocName}} Number: {{getNumber}}
                            </div>
                            <div class="col-12">
                                Date: {{getDate(new Date())}}
                            </div>
                            <div class="col-12">
                                Id. de cliente: {{booking.PaxName}}
                            </div>
                            <!--div class="col-12 row mt-3" v-if="booking.Agency">
                                <div class="col-12">
                                    <strong>Company:</strong> {{booking.Agency.Name}}
                                </div>
                                <div class="col-12" v-for="row of agencyAddress">
                                    {{row}}
                                </div>
                            </div-->
                        </div>
                    </div>
                    <div class="row mt-5 mr-5">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12" v-if="booking.SalesUser">
                                    Seller: {{booking.SalesUser.FirstName}} {{booking.SalesUser.LastName}}
                                </div>
                            </div>
                            <hr class="my-2 hr-strong">
                            <div class="row mt-3">
                                <div class="col-2">
                                    1
                                </div>
                                <div class="col-8">
                                    Travel Fee
                                </div>
                                <div class="col-2 text-right">
                                    {{amount}}
                                </div>
                            </div>
                            <div class="row mt-5 mb-5">
                            </div>
                            <div class="row mt-5 mb-5">
                            </div>
                            <div class="row mt-5 mb-5">
                            </div>
                            <div class="row mt-3">
                                <div class="col-3 offset-7">
                                    TOTAL TO BE PAID
                                </div>
                                <div class="col-2 text-right">
                                    {{amount}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row mt-2 footer-text not-print text-center footer container">
                    <div class="col-12">
                        <span>MATER SUSTENTABLE EVT– Legajo 17319</span>
                    </div>
                    <div class="col-12">
                        <u><a target="_blank" href="www.mater.travel">www.mater.travel</a></u>
                    </div>
                </div>
            </td></tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tfoot>

        </table>
        <div class="row ml-2 footer-text divPrint report-footer text-center">
            <div class="col-12">
                <span>MATER SUSTENTABLE EVT– Legajo 17319</span>
            </div>
            <div class="col-12">
                <u><a target="_blank" href="www.mater.travel">www.mater.travel</a></u>
            </div>
        </div>


    </div>
</template>

<script>
let bookingInvoice = importVueComp('components/documentation', 'BookingInvoice', 'custom');
export default {
    name: 'custom-booking-invoice',
    mixins: [bookingInvoice],
}
</script>
