<template>
    <div>
        <report-window
            v-if="ready"
            endpoint="/admin/reports/purchaseservices"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :templates="templates"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :current.sync="current"
            :slotsFields="slotsFields"
            :callbacks="callbacks"
            :columnsClasses="columnsClasses"
            :buttons="buttons"
            :notFilterable="options && options.notFilterable"
            :afterRun="afterRun"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
var EventBus = require('@/tools/event-bus').default;
import { mapState } from "vuex";
export default {
    name: "invoice-service-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        columnsClasses () {
            return {
                Cost: 'text-right'
            }
        }
    },
    mounted () {
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                }
            }
        }
        let currency = _.find(this.fields, (r) => r.name == 'CurrencyId');
        if (currency) {
            currency.options.push({
                value: api.baseCurrency.BookingCurrency, label: api.baseCurrency.BookingCurrency
            });
            currency.options.push({
                value: api.baseCurrency.BaseCurrency, label: api.baseCurrency.BaseCurrency
            });
            currency.defValue = api.baseCurrency.BaseCurrency;
        }
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            selected: [],
            notifications: [],
            current: {},
            ready: false,
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From', columns: 6},
              {name: 'ToDate', editor: 'date', label: 'to', columns: 6},
              {name: 'SupplierId', relation: 'supplier', optionLabels: 'Name'
                  , label: 'Supplier', addBlank: true, editor: 'vue-select'},
              {name: 'SupplierPaymentTypeId', relation: 'paymenttype', optionLabels: 'Name'
                  , editor: 'select', label: 'Supplier Payment Type', addBlank: true, list: true},
              {name: 'GroupBy', label: 'Group By', editor: 'select', addBlank: true, columns: 6,
                options: [
                    {value: 'BOOKING', label: 'File'},
                    {value: 'BOOKING_TAX', label: 'File - Tax'}
                    ]
                },
              {name: 'Invoiced', label: 'Invoiced', editor: 'select', addBlank: true, columns: 6,
                options: [
                    {value: '0', label: 'NO'},
                    {value: '1', label: 'YES'}
                    ]
                },
              {name: 'BookingId', label: 'File Nr', columns: 6},
              {name: 'CurrencyId', label: 'Currency', editor: 'select', required: true, columns: 6,
                options: []
                },

            ],
            title: "Invoice Sales",
            headers: {
                BookingId: 'File Nr',
                PaxName: 'Passenger',
                TaxCode: 'Tax',
                Cost: 'Amount',
                EndDate: 'Date Out',
                SupplierId: 'Supplier',
                Detail: 'Concept',
                PaymentTypeId: 'Payment Type',
                Selected: '',
                Invoice: 'Invoice'
            },
            templates: [
                {name: 'Cost', callback: (row) => {
                    return parseFloat(row.Cost).toFixed(2);
                  }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                }
            ],
            slotsFields: [
                {name: 'Selected', editor: 'checkbox', classes: 'bottom-base'},
            ],
            callbacks: {
                /*Selected: function(rowfieldname, newValue, row, report) {
                    let i = _.findIndex(self.selected, (r) => r.RowId == row.RowId);
                    if (i==-1 && row.Selected) {
                        self.selected.push(row);
                    }
                    if (i>-1 && !row.Selected) {
                        self.selected.splice(i, 1);
                    }
                }*/
            },
            buttons: [
                {callback: this.selectAll, label: 'Select All', buttonClass: 'btn btn-primary'},
                {callback: this.createInvoice, label: 'Invoice', buttonClass: 'btn btn-primary'},
            ]

        }
    },
    methods: {
        afterRun () {
            this.selected = [];
        },
        selectAll (self) {
            for (let row of self.list) {
                if (row.Invoice) continue;
                if (!row.RowId) continue;
                let r = _.find(self.dataList, (p) => p.RowId == row.RowId);
                if (r) {
                    r.Selected = true;
                    this.selected.push(r);
                }
            }
            self.dataList = Object.assign([], self.dataList);
        },
        createInvoice (self) {
            let invoice = {};
            invoice.CurrencyId = api.baseCurrency.BookingCurrency;
            let sum = 0;
            invoice.InvoiceDetails = [];
            for (let row of self.dataList) {
                if (row.Invoice) continue;
                if (!row.RowId) continue;
                if (!row.Selected) continue;
                let r = {
                    Detail: row.Detail,
                    Amount: row.Cost,
                    TaxCodeId: row.TaxCodeId
                }
                if (row.Cost) {
                    sum += parseFloat(row.Cost);
                }
                if (row.Type=='SERVICE') {
                    r.BookingDayServiceId = row.id;
                } else if (row.Type=='ROOM') {
                    r.BookingRoomId = row.id;
                } else if (row.Type=='FLIGHT') {
                    r.BookingFlightId = row.id;
                }
                invoice.InvoiceDetails.push(r);
            }
            let row = {
                Detail: 'Profit',
                Amount: sum * 20 / 100,
                TaxCodeId: null
            }
            invoice.InvoiceDetails.push(row);
            EventBus.$emit('open-modal-record', {modalRecord: invoice, modalTable: 'invoice', modalId: 'new'});
        },
        addNotification (msg) {
            app.addNotification(this, msg, true)
        },
        removeNotifications () {
            this.notifications = [];
        },
    }
};
</script>
