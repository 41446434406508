<template>
  <div class="mb-4 mt-2">
      <label><i>
          {{tr('New option to select which filters display in each report')}}.
          {{tr('Only avaible in reports with more than 4 filters')}}
      </i></label>
      <img src="https://nubbo-change-log.s3.amazonaws.com/ReportFiltersConfig.png" class="w-50 border">
  </div>
</template>

<script>
export default {
    name: 'edit-service-title',
}
</script>
