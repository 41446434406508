<template>
    <div :class="opt.cardView? 'card-container-report': 'container-report container-fluid'" class="mt-2" :id="_uid" v-if="isMounted && ready">
        <div class="form-row">
            <h6 class="section-title mb-0 col-4">
                <label class="ml-4 mb-0">{{tr('Management Summary')}}</label>
                <button class="btn btn-primary ml-2 report-button btn-sm"
                    v-if="opt.cardView"
                    @click="addToDashboard" :class="{'btn-success': inDashboard}">
                    <font-awesome-icon icon="home" size="sm"/>
                </button>
                <button class="btn btn-primary ml-3 report-button btn-sm" @click="run" v-if="!opt.hideFilters && opt.cardView">
                    <font-awesome-icon icon="play"/>
                </button>
            </h6>
            <div class="col-6" v-if="!opt.cardView">
                <breadcrumb
                    :list="[{title: module}, {title: title}]"
                ></breadcrumb>
            </div>
            <div v-if="!opt.hideFilters" :class="{'col-12': !opt.cardView, 'col-7': opt.cardView}">
              <div class="d-flex justify-content-left form-material">
                  <div v-for="field of fields" class="report-filter px-1 col-3">
                     <input-field
                          :field="field"
                          :defValue.sync="current[field.name]"
                          :fieldOptions="fieldOptions[field.name]"
                          :record="current"
                          :defHideList="opt.cardView"
                          @change="current = Object.assign({}, current)"
                      ></input-field>
                  </div>
              </div>
            </div>

            <div class="col-12 d-flex align-items-center justify-content-left mb-4" :class="{'mt--25': opt.cardView}">
                <div class="col-8 d-flex align-items-center justify-content-left" v-if="!opt.cardView">
                    <button class="btn btn-primary ml-3 report-button" @click="run">
                        <font-awesome-icon icon="play"/>
                    </button>
                    <button class="btn btn-primary ml-2 report-button" @click="addToDashboard" :class="{'btn-success': inDashboard}">
                        <font-awesome-icon icon="home"/>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="dataList" class="form-row mx-0" :class="{'back-grey': opt.cardView}">
            <div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Cobros Pendientes
                    </div>
                    <div class="form-row">
                        <div v-for="row of dataList.pending_receipts" class="text-right col-6 font-weight-bold pr-3">
                            {{row.Amount | formatNumber({d: 0})}} {{row.CurrencyId? row.CurrencyId: '----'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        No Liquidado
                    </div>
                    <div class="form-row">
                        <div v-for="row of dataList.paid_receipts" class="text-right col-6 font-weight-bold pr-3">
                            {{row.Amount | formatNumber({d: 0})}} {{row.CurrencyId? row.CurrencyId: '----'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Cobros Confirmados
                    </div>
                    <div class="form-row">
                        <div v-for="row of dataList.confirmed_receipts" class="text-right col-6 font-weight-bold pr-3">
                            {{row.Amount | formatNumber({d: 0})}} {{row.CurrencyId? row.CurrencyId: '----'}}
                        </div>
                    </div>
                </div>
            </div>
            <!--div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Files Cerrados: Cobros Recibidos
                    </div>
                    <div class="form-row">
                        <div v-for="row of dataList.closed_paid_receipts" class="text-right col-6 font-weight-bold pr-3">
                            {{row.Amount | formatNumber({d: 0})}} {{row.CurrencyId? row.CurrencyId: '----'}}
                        </div>
                    </div>
                </div>
            </div-->
            <div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Pagos Pendientes
                    </div>
                    <div class="form-row">
                        <div v-for="row of dataList.pending_payments" class="text-right col-6 font-weight-bold pr-3">
                            {{row.Amount | formatNumber({d: 0})}} {{row.CurrencyId? row.CurrencyId: '----'}}
                        </div>
                    </div>
                </div>
            </div>
            <!--div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Files Cerrados: Pagos realizados
                    </div>
                    <div class="form-row">
                        <div v-for="row of dataList.closed_payments" class="text-right col-6 font-weight-bold pr-3">
                            {{row.Amount | formatNumber({d: 0})}} {{row.CurrencyId? row.CurrencyId: '----'}}
                        </div>
                    </div>
                </div>
            </div-->
            <div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Total Ventas (fecha venta)
                    </div>
                    <div class="text-center font-weight-bold mb-2">
                        <h4 class="d-inline">{{dataList.Pax}}</h4><span class="ml-2 d-inline mr-2">Pasajeros</span>/
                        <span class="d-inline">{{dataList.Bookings}}</span><span class="ml-2 d-inline mr-2">Files</span>
                    </div>
                    <div class="text-center font-weight-bold">
                        <h4 class="d-inline">{{dataList.Sales | formatNumber({d: 0})}}</h4><span class="ml-2 d-inline">USD</span>
                    </div>
                </div>
            </div>
            <div class="p-2 col-4">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Total Ventas (fecha viaje)
                    </div>
                    <div class="text-center font-weight-bold mb-2">
                        <h4 class="d-inline">{{dataList.Pax2}}</h4><span class="ml-2 d-inline mr-2">Pasajeros</span>/
                        <span class="d-inline">{{dataList.Bookings2}}</span><span class="ml-2 d-inline mr-2">Files</span>
                    </div>
                    <div class="text-center font-weight-bold">
                        <h4 class="d-inline">{{dataList.Sales2 | formatNumber({d: 0})}}</h4><span class="ml-2 d-inline">USD</span>
                    </div>
                </div>
            </div>
            <div class="p-2 col-12">
                <div class="card p-3">
                    <div class="text-center mb-2">
                        Saldos de Cuentas
                    </div>
                    <div class="" v-if="dataList.balance">
                        <div v-for="row of dataList.balance" class="row">
                            <div class="text-left col-7 font-weight-bold">
                                {{row.PaymentType}}
                            </div>
                            <div class="text-right col-5 font-weight-bold">
                                {{row.Balance | formatNumber({d: 0})}} {{row.CurrencyId}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'management-summary-report',
    props: ['options', 'cardTitle'],
    components: {
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
        filterFields () {
            return tools.getAbmFields(this.fields);
        },
    },
    async mounted () {
        if (this.options) this.opt = this.options;
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        let pathList = this.$router.currentRoute.path.split('/');
        this.pathName = pathList[pathList.length-1];
        this.module = frontTools.getModuleByPath(this.pathName);
        this.fieldOptions = await tools.calculateFieldOptions(this.filterFields);
        this.isMounted = true;
        let r = this.getInDashboard();
        this.inDashboard = r.index > -1;
        if (this.options && this.options.runMounted) {
            await this.$nextTick();
            this.run();
        }
        this.ready = true;
    },
    data () {
        return {
            opt: {},
            current: {},
            fieldOptions: {},
            ready: false,
            isMounted: false,
            inDashboard: false,
            module: null,
            dataList: null,
            title: tr('Management Summary'),
            fields: [
                { name: 'FromDate', editor: 'date', label: 'From Date'},
                { name: 'ToDate', editor: 'date', label: 'To Date'},
                { name: 'EndDate', editor: 'date', label: 'End Date'},
                { name: 'PaymentTypeId', relation: 'paymenttype', optionLabels: 'Name', list: true,
                  label: 'Payment Type', editor: 'select', addBlank: true},
            ]
        }
    },
    methods: {
        getInDashboard () {
            let name = 'ManagementSummaryReport';
            let dashboard = localStorage.getItem('dashboard');
            if (dashboard) dashboard = JSON.parse(dashboard);
            if (!dashboard) dashboard = [];
            let index = _.findIndex(dashboard, (r) => {
                return r && r.component == name;
            });
            return {name, index, dashboard};
        },
        addToDashboard () {
            let r = this.getInDashboard();
            let dashboard = r.dashboard;
            if (r.index==-1) {
                let p = {
                    //hideFilters: true,
                    runMounted: true,
                    cardView: true,
                    reportViewClass: 'card-report-view',
                    notFilterable: true,
                }
                for (let field of this.fields) {
                    if (!this.current[field.name]) continue;
                    p[field.name] = this.current[field.name];
                    if (field.editor == 'date') {
                        let days = moment(this.current[field.name]).diff(moment(new Date()), 'days');
                        p[field.name] = {days}
                    }
                }
                dashboard.push({component: r.name, folder: 'components/reports', columns: 12,
                    props: {options: p}, class: 'px-0'});
                this.inDashboard = true;
            } else {
                dashboard.splice(r.index, 1);
                this.inDashboard = false;
            }
            localStorage.setItem('dashboard', JSON.stringify(dashboard));
        },
        async run () {
            let filters = {};
            if (this.current.FromDate) filters.FromDate = this.current.FromDate;
            if (this.current.EndDate) filters.EndDate = this.current.EndDate;
            if (this.current.ToDate) filters.ToDate = this.current.ToDate;
            if (this.current.PaymentTypeId) filters.PaymentTypeId = this.current.PaymentTypeId;
            api.setProcessing(true);
            let res = await api.get('/custom/reports/management_summary/', filters);
            if (res) {
                res.confirmed_receipts.sort((a, b) => {return b.Amount - a.Amount});
                res.paid_receipts.sort((a, b) => {return b.Amount - a.Amount});
                res.pending_receipts.sort((a, b) => {return b.Amount - a.Amount});
                res.closed_paid_receipts.sort((a, b) => {return b.Amount - a.Amount});
                res.closed_payments.sort((a, b) => {return b.Amount - a.Amount});
                res.pending_payments.sort((a, b) => {return b.Amount - a.Amount});
                filters.FilterDate = '0';
                filters.CurrencyId = 'USD';
                filters.SumBy = '1';
                let sales = await api.get('/reports/sales_summary', filters);
                if (sales) {
                    let pax = 0;
                    let quoted = 0;
                    let bookings = 0;
                    for (let row of sales.rows) {
                        if (row.Pax) pax += row.Pax;
                        if (row.Bookings) bookings += row.Bookings;
                        if (row.Quoted) quoted += row.Quoted;
                    }
                    res.Pax = pax;
                    res.Sales = quoted;
                    res.Bookings = bookings;
                }
                let filters2 = Object.assign({}, filters)
                filters2.FilterDate = '2';
                let sales2 = await api.get('/reports/sales_summary', filters2);
                if (sales2) {
                    let pax = 0;
                    let quoted = 0;
                    let bookings = 0;
                    for (let row of sales2.rows) {
                        if (row.Pax) pax += row.Pax;
                        if (row.Bookings) bookings += row.Bookings;
                        if (row.Quoted) quoted += row.Quoted;
                    }
                    res.Pax2 = pax;
                    res.Bookings2 = bookings;
                    res.Sales2 = quoted;
                }
                let filters3 = Object.assign({}, filters);
                delete filters3['CurrencyId'];
                //filters3.CurrencyId = 'ARS';
                filters3.FromDate = moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                let balance = await api.get('/admin/reports/payment_type_balance', filters3)
                if (balance) res.balance = balance.rows;
                api.setProcessing(false);
                this.dataList = res;

            }
        }
    }
}
</script>

<style scoped>
.card {
    margin-bottom: 0 !important;
    height: 100%;
}
.back-grey {
    background: #EBF2FF;
}
</style>
