<script>
import '@/extra/custom/css/fstyles.scss';
let bookingFinalPreview = importVueComp('components/documentation', 'BookingFinalPreview');
export default {
    name: 'custom-booking-final-preview',
    mixins: [BookingFinalPreview],
}
</script>


