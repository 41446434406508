<template>
    <div class="context-menu" v-if="show" :style="style" v-click-outside="close" id="context-menu">
        <div v-for="option of options" @click="click(option)" :class="option.class" v-if="!option.hide">
            <label>{{option.label}}</label>
            <label class="text-danger ml-2" v-if="toConfirm==option.name && option.confirm" @click="confirm(option)">{{tr('Confirm')}}?</label>
        </div>
        <div class="form-material" v-if="showInput">
            <div class="form-group form-default my-0">
                <input v-model="text" class="form-control" type="text" :class="{'fill': text}">
                    <span class="form-bar">
                    </span>
                </input>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
    name: 'context-menu',
    props: ['options', 'showInput'],
    data () {
        return {
            item: null,
            show: false,
            style: '',
            menuHeight: null,
            menuWidth: null,
            event: null,
            text: null,
            toConfirm: false,
            confirmed: false,
        }
    },
    mounted () {
    },
    methods: {
        confirm (option) {
            this.confirmed = true;
            this.toConfirm = false;
            this.click(option);
        },
        click (option) {
            if (option.confirm && !this.confirmed) {
                this.toConfirm = option.name;
                return
            }
            this.confirmed = false;
            this.$emit('clicked', option, this.item, this.event, this.text);
            this.close();
        },
        showMenu (event, value) {
            this.toConfirm = false;
            this.confirmed = false;
            this.event = event;
            /*let modal = $('.modal-content');
            let height = modal.height();
            let width = modal.width();
            if (!height) height = window.innerHeight;
            if (!width) width = window.innerWidth;
            let offset = (window.innerWidth - width) / 2;
            if (modal.length) {
                let height = window.innerHeight;
                let width = window.innerWidth;
                let left = parseInt((event.pageX - offset) / width * 100);
                let top = parseInt(event.clientY / height * 100);
                this.style = `left: ${left}%; top: ${top}%`;
                //this.style = `left: ${event.pageX}px; top: ${event.pageY}px`;
            } else {
                let left = parseInt((event.pageX - offset) / width * 100);
                let top = parseInt(event.clientY / height * 100);
                this.style = `left: ${left}%; top: ${top}%`;
            }*/
            let height = window.innerHeight;
            let modal = $('.modal')[0];
            let sTop = 0;
            if (modal && modal.scrollTop) sTop = modal.scrollTop;
            if (modal && modal.scrollHeight) height = modal.scrollHeight;
            let width = window.innerWidth;
            let offset = (window.innerWidth - width) / 2;
            let left = parseInt((event.pageX - offset) / width * 100);
            let top = parseInt((event.clientY + sTop) / height * 100);
            this.style = `left: ${left}%; top: ${top}%`;
            this.show = true;
            this.item = value;
        },
        close () {
            this.show = false;
        },
    },
    directives: {
      ClickOutside
    }
}
</script>

<style scoped>
    .context-menu {
        background-color: #fff;
        padding: 5px 15px 10px 15px;
        font-size: 13pt;
        border: 1px solid rgba(1, 41, 112, 0.2);
        border-radius: 4px 4px 4px 4px !important;
        position: fixed;
        z-index: 2000;
        line-height: initial;
    }

</style>
