<template>
    <div class="modal fade bd-example-modal-lg" id="text-summary-selector-modal" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="textSummarySelectorModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
            <transition name="fade" mode="out-in">
                <div class="service-selector" v-if="loaded">
                    <table class="table table-striped table-sm table-bordered">
                        <thead>
                            <tr class="table-secondary">
                                <th><span>{{tr('Name')}}</span></th>
                                <th><span>{{tr('Language')}}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, i) in rows" @click="add(row)">
                                <td>
                                    <span>{{row.Name}}</span>
                                </td>
                                <td>
                                    <span>{{row.Language.Name}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <img class="loading-gif" src="@/img/loading.gif">
                </div>
            </transition>

          </div>
        </div>
      </div>
    </div>
</template>


<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'text-summary-selector',
    mixins: [escape],
    data () {
        return {
            rows: [],
            loaded: false
        }
    },
    async mounted () {
        this.loaded = false;
        let res = await api.get('/api/textsummary/')
        if (res) {
            this.rows = res;
            this.loaded = true;
        }
        $('#text-summary-selector-modal').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        add  (row){
            this.$emit('add', row.Text)
            $('#text-summary-selector-modal').modal('hide');
        },
        close () {
            this.$emit('close');
            $('#text-summary-selector-modal').modal('hide');

        }
    },
}
</script>
