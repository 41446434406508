<template>
    <report-window
        v-if="ready"
        endpoint="/reports/hotelcost"
        :perPage="500"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :reportViewClass="options? options.reportViewClass: null"
        :colsView="options? options.colsView: null"
        :current.sync="current"
        :slotsFields="[]"
        :notFilterable="options && options.notFilterable"
        :updateReport="updateReport"
        :rowClick="rowClick"
        :afterRun="afterRun"
        :headerSticky="true"
        :emb="options? options.emb: false"
        :columnsClasses="columnsClasses"
    ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
export default {
    name: "hotel-cost-list-report",
    props: ["options", "reportData"],
    components: {
        ReportWindow
    },
    computed: {
        rowClick () {
            if (this.options && this.options.rowClick) return this.options.rowClick;
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        return {
            current: {},
            ready: false,
            updateReport: false,
            columnsClasses: {
                Amount: 'text-right'
            },
            fields: [
                {
                    name: "HotelId",
                    relation: "hotel",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "Hotel",
                    addBlank: true,
                    sort: true,
                    list: true,
                    endpoint: '/api/hotel/get_list_by_date_city_id'
                },
                {
                    name: "HotelCategoryId",
                    relation: "hotelcategory",
                    optionLabels: "Name",
                    editor: 'select',
                    label: "Hotel Category",
                    addBlank: true,
                    sort: true
                },
                {
                    name: "CityId",
                    relation: "city",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "City",
                    addBlank: true,
                    requiredCallback: (t, r) => {return !r.HotelId},
                    sort: true,
                },
                { name: 'FilterDate', label: 'Filter by', editor: 'select', defValue: '0', required: true,
                    options: [
                        {value: '0', label: 'Unique Date'},
                        {value: '1', label: 'Dates Range'},
                    ]
                },
                {
                    name: "TransDate",
                    editor: "date",
                    requiredCallback: (t, r) => {return !r.HotelId && r.FilterDate == '0'},
                    disabledIf: (r) => {return r.FilterDate == '1'},
                    label: "Date"
                },
                  {
                    name: "FromDate",
                    editor: "date",
                    requiredCallback: (t, r) => {return !r.HotelId && r.FilterDate == '1'},
                    disabledIf: (r) => {return r.FilterDate == '0'},
                    label: "From"
                },
                {
                    name: "ToDate",
                    editor: "date",
                    requiredCallback: (t, r) => {return !r.HotelId && r.FilterDate == '1'},
                    disabledIf: (r) => {return r.FilterDate == '0'},
                    label: "To"
                },
            ],
            title: "Hotel Rates",
            headers: {
                HotelName: "Hotel",
                CityName: "City",
                RoomType: "Room",
                RoomCategory: "Room Category",
                HotelCategory: "Hotel Category",
                CurrencyId: "Currency",
                NetBy: "Cost Per",
            },
            templates: [
                {
                    name: "Edited",
                    callback: (row) => {
                        if (!row.Edited) return "";
                        return moment(row.Edited).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "StartDate",
                    callback: (row) => {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                },
                {name: 'Amount', callback: (row) => {
                        return tools.toNumber(row.Amount, 0, row.CurrencyId);
                    }
                },
                {
                    name: "Days",
                    callback: (row) => {
                        if (!row.Days) return "";
                        let w = row.Days.split(',');
                        let a = [];
                        for (let d of w) {
                            a.push(tr(d));
                        }
                        return a.join(',');
                    }
                },
            ]
        };
    },
    watch: {
        options: {
            handler () {
                frontTools.setReportValues(this);
                this.updateReport = !this.updateReport;
            },
            deep: true,
        },
    },
    methods: {
        afterRun (report) {
            this.$emit("update:reportData", report.dataList);
        }
    }
};
</script>
