<template>
      <div v-if="ready">
        <report-window
          endpoint="/crm/reports/pax_lead"
          :fields="fields"
          :title="cardTitle || title"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :columnsClasses="columnsClasses"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :headers="headers"
          :columnClick="columnClick"
          :slotsFields="slotsFields"
          :fieldStyles="fieldStyles"
          :afterRun="afterRun"
        ></report-window>
        <input-value
            type="memo"
            v-if="comment"
            :disabled="true"
            @save="comment = null"
            @close="comment = null"
            :def="comment"
            text="">
        </input-value>
      </div>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow';
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'pax-lead-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            operManager: state => state.main.operManager,
            user: state => state.main.user,
            recordStore: state => state.main.modalRecord,
        }),
        syncVersion () {
            return this.recordStore? this.recordStore.syncVersion: null;
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        let crm = tools.canAccess(this.user, "view", "GET", 'crm', false);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions && !this.operManager && !crm) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
        EventBus.$on('close-paxlead', this.updateReport);
    },
    data () {
        let self = this;
        return {
            current: {},
            reload: false,
            comment: null,
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "From",
                },
                {
                    name: "ToDate",
                    editor: "date",
                    label: "To",
                },
                {
                    name: "TripFromDate",
                    editor: "date",
                    label: "Trip Date From",
                },
                {
                    name: "TripToDate",
                    editor: "date",
                    label: "Trip Date To",
                },
                {name: 'SalesUserId', label: 'Seller', editor: 'select',
                    optionLabels: 'UserName', relation: 'user', addBlank: true,
                    filters: (row) => {
                        if (row.Closed) return false;
                        if (row.Department && row.Department.Sales) return true;
                    }
                },
                {name: 'SalesOriginId', label: 'Sales Origin', editor: 'select',
                    optionLabels: 'Name', relation: 'salesorigin', addBlank: true, setRelationTo: 'SalesOrigin'
                },
                {name: 'CustomerTypeId', label: 'Customer Type', editor: 'select',
                    optionLabels: 'Name', relation: 'customertype', addBlank: true,
                },
                {name: 'MarketId', label: 'Market', editor: 'select',
                    optionLabels: 'Name', relation: 'market', addBlank: true,
                },
                {name: 'LeadValueId', label: 'Lead Value', editor: 'select',
                    optionLabels: 'Name', relation: 'leadvalue', addBlank: true,
                },
                {name: 'LeadStatusId', label: 'Lead Status', editor: 'select',
                    optionLabels: 'Name', relation: 'leadstatus', addBlank: true,
                },
                {name: 'LeadTypeId', label: 'Lead Type', editor: 'select',
                    optionLabels: 'Name', relation: 'leadtype', addBlank: true,
                },
                {name: 'LeadBudgetId', label: 'Budget USD', editor: 'select',
                    optionLabels: 'Name', relation: 'leadbudget', addBlank: true,
                },
                {name: 'CountryId', label: 'Country', editor: 'select', list: true,
                    optionLabels: 'Name', relation: 'country', addBlank: true,
                },
                {name: 'AgencyId', label: 'Agency', editor: 'vue-select',
                    optionLabels: 'Name', relation: 'agency', addBlank: true
                },
                {name: 'View', label: 'Vista', editor: 'select', defValue: '0',
                    options: [
                      {value: '0', label: 'Normal'},
                      {value: '1', label: 'Resumida'},
                      {value: '2', label: 'Resumida + Vendedor'},
                    ]
                },
                {name: 'CampaignId', label: 'Campaign', editor: 'vue-select', setRelationTo: 'Campaign',
                    optionLabels: 'Name', relation: 'campaign', addBlank: true,
                },
                {name: 'SubCampaignId', label: 'Sub Campaign', editor: 'vue-select',
                    optionLabels: 'Name', relation: 'subcampaign', addBlank: true,
                },

            ],
            title: 'Pax Leads Report',
            headers: {
                BookingId: 'File',
                TransDate: 'Lead Date',
                LeadValue: 'Lead Value',
                StatusDate: 'Status Date',
                TransDate: 'Date',
                TripDate: 'Trip Date',
                Note: 'Note',
                Comment: 'Pax Comment',
                ID: 'Nº',
                LeadStatus: 'Status'
            },
            slotsFields: [
                { name: 'LeadStatus', editor: 'icon', icon: 'circle', classes: 'mb-0', label: true},
            ],
            templates: [
                {
                    name: "TransDate",
                    callback: (row) =>  {
                        if (!row.TransDate) return "";
                        return moment(row.TransDate).locale(api.language).format("DD MMM");
                    }
                },
                {
                    name: "TripDate",
                    callback: (row) =>  {
                        if (!row.TripDate) return "";
                        return moment(row.TripDate).locale(api.language).format("DD MMM");
                    }
                },
                {
                    name: "StatusDate",
                    callback: (row) =>  {
                        if (!row.StatusDate) return "";
                        return moment(row.StatusDate).locale(api.language).format("DD MMM");
                    }
                },
                {
                    name: "Comment",
                    callback: (row) =>  {
                        if (!row.Comment) return "";
                        let maxLength = 30;
                        if (row.Comment.length > maxLength) {
                            return row.Comment.substring(0, maxLength) + '...';
                        }
                        return row.Comment;
                    }
                },
                {
                    name: "Note",
                    callback: (row) =>  {
                        if (!row.Note) return "";
                        let maxLength = 30;
                        if (row.Note.length > maxLength) {
                            return row.Note.substring(0, maxLength) + '...';
                        }
                        return row.Note;
                    }
                },
            ],
            columnsClasses: {
                BookingId: 'cursor-pointer-report',
                ID: 'cursor-pointer-report',
            },
            columnClick: {
                BookingId: this.showBooking,
                ID: this.showPaxLead,
                Comment: (row, report) => {
                    self.comment = row.Comment;
                },
                Note: (row, report) => {
                    self.comment = row.Note;
                },
            },
            fieldStyles: {
                LeadStatus: (row) => {
                    if (row.Color) return `color: ${row.Color};`;
                }
            },
        }
    },
    methods: {
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-booking', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        },
        showPaxLead (row) {
            this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'paxlead', modalId: row.ID, closeAction: 'close-paxlead'});
        },
        updateReport () {
            if (this.reload) {
                this.reload = false;
                setTimeout(() => {
                    this.report.run()
                }, 100);
            }
        },
        afterRun (report) {
            this.report = report;
        },
    },
    watch: {
        syncVersion (newValue, oldValue) {
            if (oldValue && newValue) this.reload = true;
        }
    },
    beforeDestroy() {
        EventBus.$off('close-paxlead');
    },
}
</script>

