<template>
    <div class="modal fade bd-example-modal-lg" id="hotel-cost-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-large" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="card-body pt-3" v-if="ready">
                <div class="form-material form-row">
                    <div class="col-md-4 row">
                        <div class="col-6">
                            <date-picker id="HotelCost-StartDate"
                                :label="'Period'"
                                fieldName="StartDate"
                                :def.sync="period.StartDate"></date-picker>
                        </div>
                        <div class="col-6" style="padding-left: 0px">
                            <date-picker id="HotelCost-EndDate"
                                fieldName="EndDate"
                                :startDate="period.StartDate"
                                :def.sync="period.EndDate"></date-picker>
                        </div>
                    </div>
                    <div class="col-md-4">
                      <input-field
                        @change="setModified"
                        :defValue.sync="period.Comment"
                        :field="{name: 'Comment', label: 'Comment', editor: 'text'}"
                      ></input-field>
                    </div>
                    <div class="col-md-2">
                        <div class="form-check mt-3 ml-3">
                            <input v-model="period.BlackOut" name="BlackOut" class="form-check-input"
                            type="checkbox" @change="setModified">
                            <label class="smaller form-check-label">{{tr('Black Out')}}</label>
                        </div>
                    </div>
                </div>
                <record-week-days
                    :record.sync="period"
                    :emits="false"
                    @change="setModified"
                ></record-week-days>
                <div class="form-material form-row" v-if="!period.BlackOut">
                    <div class="col-md-1">
                        <select-input
                            fieldName="CurrencyId"
                            :fieldOptions="fieldOptions.CurrencyId"
                            label="Currency"
                            @change="setModified"
                            :currentValue.sync="period.CurrencyId"
                            :required="true">
                        </select-input>
                    </div>
                    <div class="col-md-4">
                        <select-input fieldName="RoomCostBy" :fieldOptions="fieldOptions.RoomCostBy"
                        label="Cost Per"
                        :currentValue.sync="period.RoomCostBy" required="true" @change="clearPeriod">
                        </select-input>
                    </div>
                    <div class="col-md-2">
                        <select-input fieldName="UnitCostBy" :fieldOptions="fieldOptions.UnitCostBy"
                        label=" " :currentValue.sync="period.UnitCostBy" required="true">
                        </select-input>
                    </div>
                    <div class="col-md-2">
                        <select-input fieldName="PeriodCostBy" :fieldOptions="fieldOptions.PeriodCostBy"
                        label=" " :currentValue.sync="period.PeriodCostBy" required="true">
                        </select-input>
                    </div>
                    <div class="col-md-2">
                      <input-field
                        :defValue.sync="period.MinimumStay"
                        :field="{name: 'Comment', label: 'Minimum Stay', editor: 'number'}"
                      ></input-field>
                    </div>
                </div>
                <div class="form-material form-row" v-if="!period.BlackOut">
                    <div class="col-md-2">
                      <input-field
                        :defValue.sync="period.Commission"
                        :field="{name: 'Commission', label: 'Commission', editor: 'number', d: 0}"
                      ></input-field>
                    </div>
                    <div class="col-md-3">
                        <div>
                        <select-input fieldName="DiscountType" :fieldOptions="fieldOptions.DiscountType"
                        label="Discount" :currentValue.sync="period.DiscountType">
                        </select-input>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button type="button" 
                            class="btn btn-secondary col"
                            :class="{'icon-active': increase}"
                            @click="applyIncrease"
                            >{{tr('Apply Increase')}}
                        </button>
                    </div>
                    <div class="col-md-2">
                        <input-field
                            v-if="period.DiscountType=='CONSECUTIVE' || period.DiscountType=='TOTAL'"
                            :defValue.sync="period.Discount"
                            :field="{name: 'Discount', label: 'Discount Percentage', editor: 'number', decimal: 2}"
                            :readonlyIf="period.DiscountType=='NONE' || !period.DiscountType"
                        ></input-field>
                    </div>
                    <div class="col-md-2">
                      <input-field
                        v-if="period.DiscountType=='CONSECUTIVE' || period.DiscountType=='TOTAL'"
                        :defValue.sync="period.DiscountDays"
                        :field="{name: 'DiscountDays', label: 'Nights from', editor: 'number'}"
                        :readonlyIf="period.DiscountType=='NONE' || !period.DiscountType"
                      ></input-field>
                    </div>
                    <div class="col-12 form-material form-row" v-if="increase">
                        <number-input
                            label="%"
                            class="my-0 col-2"
                            :v.sync="increaseValue"
                            :d="2"
                        ></number-input>
                        <div class="offset-4 col-3 pl-1 pr-0">
                            <button type="button" 
                            class="btn btn-secondary w-100" 
                            @click="increase = false"
                            >{{tr('Cancel')}}</button>
                        </div>
                        <div class="col-3 pl-1 pr-0">
                            <button type="button" 
                            class="btn btn-outline-secondary w-100" 
                            @click="setIncrease"
                            >{{tr('Done')}}</button>
                        </div>
                    </div>
                </div>
                <div class="dropdown-divider"></div>
                <div v-if="!period.BlackOut" class="scroll-x">
                    <room-type-category
                        v-if="period.RoomCostBy=='ROOM_TYPE_CATEGORY'"
                        :period.sync="period"
                        :rooms="notProgramRooms"
                        :roomTypes="roomTypes"
                        @setModified="setModified"
                    ></room-type-category>
                    <nights-room-type
                        v-if="period.RoomCostBy=='NIGHT_ROOM_TYPE'"
                        :period.sync="period"
                        :nights="nights"
                        :roomTypes="roomTypes"
                        @setModified="setModified"
                    ></nights-room-type>
                    <nights-room-category
                        v-if="period.RoomCostBy=='NIGHT_ROOM_CATEGORY'"
                        :period.sync="period"
                        :nights="nights"
                        :rooms="programRooms"
                        @setModified="setModified"
                    ></nights-room-category>
                    <nights-room-type-category
                        v-if="period.RoomCostBy=='ROOM_TYPE_CATEGORY_NIGHT'"
                        :period.sync="period"
                        :nights="nights"
                        :rooms="programRooms"
                        :roomTypes="roomTypes"
                        @setModified="setModified"
                    ></nights-room-type-category>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2">
                        <div class="form-group">
                            <div class="form-check">
                                <input v-model="period.Closed" name="Closed"
                                class="form-check-input" type="checkbox" id="Closed">
                                <label class="smaller form-check-label">{{tr('Archive')}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <edited-by :record="period"></edited-by>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const roomTypeCategory = importVueComp('components/hotel', 'RoomTypeCategory');
const nightsRoomType = importVueComp('components/hotel', 'NightsRoomType');
const nightsRoomCategory = importVueComp('components/hotel', 'NightsRoomCategory');
const nightsRoomTypeCategory = importVueComp('components/hotel', 'NightsRoomTypeCategory');
const recordWeekDays = importVueComp('components/tools', 'RecordWeekDays');
const escape = importVueComp('components/tools', 'Escape');
const editedBy = importVueComp('components/tools', 'EditedBy');
import { mapState } from 'vuex';

export default {
    name: 'hotel-cost',
    mixins: [escape],
    props: ['record', 'period'],
    components: {
      'room-type-category': roomTypeCategory,
      'nights-room-category': nightsRoomCategory,
      'nights-room-type': nightsRoomType,
      'nights-room-type-category': nightsRoomTypeCategory,
      'record-week-days': recordWeekDays,
      'edited-by': editedBy,
    },
    data  () {
        return {
            fieldOptions: {},
            ready: false,
            increase: false,
            increaseValue: null,
            fields: [
                {name: 'StartDate', editor: 'date', required: true},
                {name: 'EndDate', editor: 'date', required: true},
                { name: 'DiscountType', label: 'Discount',  editor: 'select', hideFromList: true,
                    options: [
                        {value: 'NONE', label: 'Without Discount', tr: true},
                        {value: 'CONSECUTIVE', label: 'Consecutive Nights', tr: true},
                        {value: 'TOTAL', label: 'Total Nights', tr: true},
                    ]
                },
                { name: 'CurrencyId', relation: 'currency', optionLabels: 'id'
                    , editor: 'select', label: 'Currency', hideFromList: true},
                { name: 'RoomCostBy', label: 'Cost per', hideFromList: true, editor: 'select',
                    options: [
                        {value: 'ROOM_TYPE_CATEGORY', label: 'Category and Room Type', tr: true},
                        {value: 'NIGHT_ROOM_TYPE', label: 'Program - Night and Room Type', tr: true},
                        {value: 'NIGHT_ROOM_CATEGORY', label: 'Program - Night and Room Category', tr: true},
                        {value: 'ROOM_TYPE_CATEGORY_NIGHT', label: 'Program - Night, Category and Room Type', tr: true},
                    ]
                },
                { name: 'PeriodCostBy', label: '', hideFromList: true, editor: 'select',
                    options: [
                        {value: 'NIGHT', label: 'Night', tr: true},
                        {value: 'PROGRAM', label: 'Program', tr: true},
                    ]
                },
                { name: 'UnitCostBy', label: '', hideFromList: true, editor: 'select',
                    options: [
                        {value: 'ROOM', label: 'Room', tr: true},
                        {value: 'PERSON', label: 'Person', tr: true},
                    ]
                },
            ]
        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        rooms () {
        	return _.filter(this.record.RoomCategories, (r) => !r.Closed);
        },
        roomTypes () {
            return _.map(this.record.HotelRoomTypes, (r) => {
                return r.RoomType;
            })
        },
        nights () {
            if (!this.record.Nights) return [1, 2, 3, 4, 'extra']
            let res = [];
            for (let i=1; i<=parseInt(this.record.Nights); i++) {
                res.push(i);
            }
            res.push('extra');
            return res;
        },
        hasProgramRooms () {
            let rooms = _.filter(this.rooms, (r) => r.Program);
            if (rooms.length>0) return true;
        },
        programRooms () {
            if (this.hasProgramRooms) {
                return _.filter(this.rooms, (r) => r.Program);
            }
            return this.rooms;
        },
        notProgramRooms () {
            if (this.hasProgramRooms) {
                return _.filter(this.rooms, (r) => !r.Program);
            }
            return this.rooms;
        }
    },
    async mounted () {
        await this.getFieldOptions();
        $('#hotel-cost-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        this.ready = true;
    },
    methods: {
        async setModified () {
            this.period.isModified = true;
            this.$emit('setModified');
        },
        async getFieldOptions () {
            let res = await tools.calculateFieldOptions(this.fields, null, this.record);
            if (res) {
                this.fieldOptions = res;
                let roomCostBy = _.find(this.record.fields, (r) => r.name == 'RoomCostBy');
                if (!roomCostBy) return;
                for (let option of roomCostBy.options) {
                    if (option.value=='ROOM_TYPE_CATEGORY' && (this.rooms.length==0 || this.record.HotelRoomTypes.length==0)) {
                        option.disabled = true;
                    }
                    if (option.value=='NIGHT_ROOM_TYPE' && this.record.HotelRoomTypes.length==0) {
                        option.disabled = true;
                    }
                    if (option.value=='NIGHT_ROOM_CATEGORY' && this.rooms.length==0) {
                        option.disabled = true;
                    }
                    if (option.value=='ROOM_TYPE_CATEGORY_NIGHT' && (this.rooms.length==0 || this.record.HotelRoomTypes.length==0)) {
                        option.disabled = true;
                    }
                }
            }
        },
        clearPeriod (value, i) {
            this.period.Data = this.setPeriodBy(value)
            this.setRoomCostBy();
            this.setModified();
        },
        setRoomCostBy () {
            if (this.period.RoomCostBy=='ROOM_TYPE_CATEGORY'){
                this.period.UnitCostBy = 'ROOM';
                this.period.PeriodCostBy = 'NIGHT';
            }
            if (this.period.RoomCostBy=='NIGHT_ROOM_TYPE'){
                this.period.UnitCostBy = 'PERSON';
                this.period.PeriodCostBy = 'PROGRAM';
            }
            if (this.period.RoomCostBy=='NIGHT_ROOM_CATEGORY'){
                this.period.UnitCostBy = 'PERSON';
                this.period.PeriodCostBy = 'PROGRAM';
            }
            if (this.period.RoomCostBy=='ROOM_TYPE_CATEGORY_NIGHT'){
                this.period.UnitCostBy = 'PERSON';
                this.period.PeriodCostBy = 'PROGRAM';
            }
        },

        setPeriodBy (roomCostBy) {
            var data = {}
            if (roomCostBy=='ROOM_TYPE_CATEGORY'){
                for (let row of this.notProgramRooms) {
                    if (row.Closed) continue;
                    for (let roomType of this.roomTypes) {
                        data[[roomType.id, row.id]] = {value: null, roomType: roomType, roomClass: row};
                    }
                }
            }
            if (roomCostBy=='NIGHT_ROOM_TYPE'){
                for (let roomType of this.roomTypes) {
                    for (let n of this.nights) {
                        data[[n, roomType.id]] = {value: null, roomType: roomType, night: n};
                    }
                }
            }
            if (roomCostBy=='NIGHT_ROOM_CATEGORY'){
                for (let row of this.programRooms) {
                    if (row.Closed) continue;
                    for (let n of this.nights) {
                        data[[n, row.id]] = {value: null, roomClass: row, night: n};
                    }
                }
            }
            if (roomCostBy=='ROOM_TYPE_CATEGORY_NIGHT'){
                for (let roomType of this.roomTypes) {
                    for (let row of this.programRooms) {
                        if (row.Closed) continue;
                        for (let n of this.nights) {
                            data[[n, row.id, roomType.id]] = {value: null, roomClass: row, roomType: roomType, night: n};
                        }
                    }
                }
            }
            return data;
        },
        close () {
            this.$emit('close');
            $('#hotel-cost-modal').modal('hide');
        },

        applyIncrease () {
            this.increase = !this.increase;
        },

        setIncrease () {
            this.increase = false;
            if (!this.increaseValue) return;
            let data = this.period.Data;
            Object.keys(data).forEach(key => {
                if (data[key]) {
                    let price = data[key].value * (1 + this.increaseValue / 100);
                    data[key].value = price;
                } 
            });
        },
    },
}
</script>
