<template>
    <div>
      <payment-list-report></payment-list-report>
    </div>
</template>

<script>
const paymentListReport = importVueComp('components/reports', 'PaymentListReport', 'admin');
import { mapState } from 'vuex';
export default {
  name: 'payment-list',
  components: {
    'payment-list-report': paymentListReport
  },
}
</script>

