<template>
    <div class="dropdown1 pt-1 w-100" v-if="ready" :class="{'is-invalid': invalid && !selected}">
      <div class="dropdown1-toggle w-100" @click="toggleDropdown">
        <component :is="selectedOption.component" v-if="selectedOption.component" class="dropdown1-option-image w-100"></component>
        <svg class="dropdown1-option-image" version="1.0" xmlns="http://www.w3.org/2000/svg" v-else></svg>
      </div>
      <ul class="dropdown1-menu w-100" v-show="isDropdownOpen">
        <li v-for="option in options" @click="selectOption(option)" class="px-2 text-center">
          <component :is="option.component" v-if="option.component" class="dropdown1-option-image w-100"></component>
          <img :src="option.image" class="dropdown1-option-image" v-else-if="option.image">
          <span v-else class="dropdown1-option-image">{{tr(option.label)}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
    name: 'image-select',
    props: ['options', 'currentValue', 'invalid'],
    data() {
      return {
        isDropdownOpen: false,
        selectedOption: null,
        optionList: [],
        ready: false,
      };
    },
    computed: {
        selected () {
            return this.selectedOption && this.selectedOption.value;
        }
    },
    mounted () {
      this.selectedOption = this.options[0];
      this.optionList = Object.assign([], this.options);
      for (let f of this.optionList) {
          if (f.component){
              if (f.componentName) {
                  let c = importVueComp(f.componentPath, f.componentName);
                  if (c) f.component = c;
              }
          }
      }
      if (this.currentValue) {
          let res = _.find(this.optionList, (r) => r.value == this.currentValue);
          if (res) this.selectedOption = res;
      }

      this.ready = true;

    },
    methods: {
      toggleDropdown () {
        this.isDropdownOpen = !this.isDropdownOpen;
      },
      selectOption (option) {
        this.selectedOption = option;
        this.isDropdownOpen = false;
        this.$emit('update:currentValue', option.value);
      },
    },
}
</script>

<style scoped>

  .dropdown1 {
    position: relative;
    display: inline-block;
    height: 32px;
  }

  .dropdown1-toggle {
    //display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    user-select: none;
    max-height: 32px;
    min-height: 32px;
    //transition: background-color 0.2s ease;
  }

  .dropdown1-option-image {
    height: 28px;
  }


  .dropdown1-menu {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 12px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    list-style: none;
    z-index: 1;
    //transform: translateY(-12px);
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }

  .dropdown1-menu li {
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

</style>
