import store from '@/store/store';

class LoadTables {

    constructor (currentRoute) {
        this.promises = [];
        this.currentRoute = currentRoute;
    }

    async load () {
        await Promise.all(this.promises);
    }

    async getPromises () {
        api.setTable({table: "language"});
        api.setTable({table: "termsandconditions"});
        let user = api.currentUser;
        if (!api.currentUser) {
            user = await api.getCurrentUser();
        }
        if (user){
            store.commit('setUser', user);
            if (user && !this.currentRoute.meta.public) {
                api.setTable({table: "expensetype"});
                api.setTable({table: "department"});
                api.setTable({table: "user", closed: "true"});
                api.setTable({table: "service"});
                api.setTable({table: "station"});
                api.setTable({table: "roomcategory"});
                api.setTable({table: "hotelcategory"});
                api.setTable({table: "serviceclass"});
                api.setTable({table: "servicecategory"});
                api.setTable({table: "servicetype"});
                api.setTable({table: "city"});
                api.setTable({table: "roomtype"});
                api.setTable({table: "customertype"});
                api.setTable({table: "image"});
                api.setTable({table: "currency"});
                api.setTable({table: "currencyconversion"});
                api.setTable({table: "hotel"});
                api.setTable({table: "supplier"});
                store.dispatch("setBaseCurrency");
                api.setMyTemplates();
                api.addHotelsList();
            }
        }

    }
}

export default LoadTables;