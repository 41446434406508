<script>
import Vue from 'vue';
import { mapState } from 'vuex';
let bookingCoverPaxExtrasContact = Vue.component('booking-cover-pax-extras-contact', {
    props: [],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
        }),
    },
    template: `
        <div class="px-3 col">
            <select-input
                v-if="recordStore.CustomerType && recordStore.CustomerType.Agency && recordStore.AgencyId"
                :field="recordStore.fieldsObject.ContactId"
                fieldName="ContactId"
                :record="{'AgencyId': recordStore.AgencyId}"
                label="Contact"
                :currentValue.sync="recordStore.ContactId"
                :addBlank="true"
                :disabled="!salesActions"
            ></select-input>
        </div>
    `,
})


const bookingCoverPax = importVueComp('components/booking', 'BookingCoverPax', 'contacts');
export default {
    name: 'contact-booking-cover-pax',
    mixins: [bookingCoverPax],
    components: {
        'booking-cover-pax': bookingCoverPax,
    },
    mounted () {
        this.extraComp.push({
            component: bookingCoverPaxExtrasContact,
            ref: 'bookingCoverPaxContact',
            change: () => {},
        })
    },
}
</script>

