<template>
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ad" role="img" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" fill="none" class="svg-inline--fa fa-ad fa-w-16">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
        <path stroke="#ffffff" stroke-width="150" d="M1440 5104 c-451 -70 -827 -413 -940 -856 -69 -270 -42 -543 80 -794 151 -312 433 -540 770 -624 133 -33 355 -38 488 -11 317 66 576 237 747 496 97 147 147 270 181 451 22 119 15 344 -15 461 -42 168 -107 303 -210 438 -256 334 -683 505 -1101 439z m440 -269 c361 -115 605 -406 649 -774 44 -365 -143 -725 -471 -905 -137 -76 -272 -109 -443 -109 -483 1 -880 381 -902 863 -13 277 69 491 262 686 109 111 204 173 329 219 127 46 189 55 346 51 124 -3 157 -8 230 -31z"/>
        <path stroke="#ffffff" stroke-width="150" d="M1573 4676 c-18 -8 -42 -30 -53 -48 -19 -31 -20 -50 -20 -368 0 -314 1 -337 19 -364 37 -56 34 -55 454 -56 344 0 391 2 413 16 74 49 70 162 -6 208 -24 14 -68 16 -329 16 l-301 0 0 238 c0 130 -5 253 -10 273 -21 75 -100 115 -167 85z"/>
        <path stroke="#ffffff" stroke-width="120" d="M2900 4304 c0 -3 9 -49 19 -102 11 -54 22 -134 26 -179 l7 -83 622 0 c598 0 622 -1 653 -20 68 -41 63 98 63 -1767 0 -1657 0 -1689 -20 -1720 -41 -67 41 -63 -1230 -63 -1048 0 -1154 1 -1180 16 -64 38 -60 -39 -60 1163 l0 1091 -124 -1 c-69 0 -152 3 -185 7 l-61 7 0 -1114 c0 -1063 1 -1117 19 -1179 50 -172 182 -299 358 -345 49 -13 224 -15 1233 -15 1122 0 1180 1 1248 19 161 42 283 154 344 317 l23 59 3 1734 c3 1898 6 1785 -55 1911 -34 71 -142 179 -213 213 -119 57 -117 57 -832 57 -362 0 -658 -3 -658 -6z"/>
        <path stroke="#ffffff" stroke-width="120" d="M2375 2833 c-33 -8 -84 -64 -91 -100 -8 -44 22 -101 66 -125 33 -17 74 -18 723 -18 749 0 720 -2 766 56 28 36 28 96 0 136 -43 59 -25 58 -766 57 -373 -1 -687 -3 -698 -6z"/>
        <path stroke="#ffffff" stroke-width="120" d="M2354 2074 c-40 -19 -64 -62 -64 -114 0 -57 47 -105 111 -115 24 -3 343 -5 709 -3 616 3 668 4 693 21 58 38 77 117 42 171 -38 57 -21 56 -772 56 -592 0 -692 -3 -719 -16z"/>
        <path stroke="#ffffff" stroke-width="120" d="M2349 1295 c-39 -21 -59 -59 -59 -109 0 -39 5 -51 35 -81 l36 -35 714 0 714 0 36 35 c30 30 35 42 35 81 0 51 -21 89 -61 110 -41 21 -1411 20 -1450 -1z"/>
      </g>
    </svg>
</template>

<script>
export default {
    name: 'svg-record-history',
}
</script>
