<template>
    <div class="fstyles mt-3">
        <div class="row">
            <h3 class="col-12">
                {{toTitleCase(tr('Itinerary'))}} {{tr('from')}} {{startDay}} {{startMonth}} {{tr('to')}} {{endDay}} {{endMonth}}
            </h3>
        </div>
        <div class="row">
            <div class="col-12">
                <b>{{tr('Passengers')}}</b>: {{passengersNames}}
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <b>{{tr('Reservation Code')}}</b>: {{flights}}
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="getFlightsList.length">
                <text-rows-style tag="p" :text="f" v-for="f of getFlightsList" v-bind:key="f.id"></text-rows-style>
            </div>
        </div>
        <div class="col-12 mt-3">
          <ul class="border border-dark day-header">
                <li>{{tr("_pick-up time")}}</li>
                <li>{{tr("_airport_hotel")}}</li>
                <li>{{tr("_national_park")}}</li>
            </ul>
        </div>
        <div class="row">
            <div class="col-12 mt-4" v-for="(day,idx) of recordStore.BookingDays" v-if="!skipDays[day.DayNr]">
                <div class="mb-2 border border-dark px-1 day-header"><strong>
                    <span v-if="!day.GroupDays">{{tr('Day')}}</span>
                    <span v-else>{{tr('Days')}}</span>
                    {{getDayNumber(day)}} -
                    {{getDatesText(day)}}:
                    {{getCitiesNames(day)}}
                    <span v-if="day.Title"> - {{day.Title}}</span>
                </strong>
                </div>
                <div v-for="(s,i) of day.BookingDayServices">
                    <strong v-if="s.PickupInfo"><i>{{s.PickupInfo}}</i></strong>
                    <div v-if="s && s.Description && s.Description.length>0 && ifText(s.Description)" class="mb-3">
                        <text-rows-style :text="s.Description"></text-rows-style>
                        <div v-if="s.SupplierId && !s.Supplier.HideDocumentation && firstSupplierDay[s.SupplierId]==s.id">
                            <div v-if="s.Supplier.Name">
                                 <b>{{tr('Local operator')}}</b>: {{s.Supplier.Name}}
                            </div>
                            <!--div v-if="s.Supplier.Address">
                                 <b>{{tr('Address')}} </b>: {{s.Supplier.Address}}
                            </div-->
                            <div v-if="s.Supplier.Phone">
                                 <b>{{tr('Phone')}}</b>: {{s.Supplier.Phone}}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="hotelsByDay[day.DayNr] || day.PaxHotel==1" class="mt-3">
                    <div v-if="hotelsByDay[day.DayNr]">
                      <u>{{tr('Night')}}</u> {{tr('at')}} {{getHotelName(day)}}
                    </div>
                    <div v-else-if="day.PaxHotel==1">
                      <strong>{{tr('Accommodation')}}</strong> {{tr('Booked by yourself')}}
                    </div>
                </div>

            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h4>{{tr('End our services')}}</h4>
            </div>
        </div>
    </div>
</template>

<script>
const bookingFinalPreview = importVueComp('components/documentation', 'BookingFinalPreview', 'custom');
import { mapState } from 'vuex';
export default {
    name: 'custom-booking-final-preview',
    mixins: [bookingFinalPreview],
    data () {
        return {
        }
    },
    async mounted () {
    },
    computed: {
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        startDay () {
            if (this.recordStore) return moment(this.recordStore.StartDate).format("DD");
        },
        endDay () {
            if (this.recordStore) return moment(this.recordStore.EndDate).format("DD");
        },
        flights () {
            let reservation_number = []
            for (let i in this.recordStore.flights) {
                if (this.recordStore.flights[i].Flights) {
                    for (let j of this.recordStore.flights[i].Flights) {
                        reservation_number.push(j.ReservationNumber)
                    }
                }
            }
            return reservation_number.join(', ');
        },
        startMonth () {
            if (this.recordStore) return this.tr(moment(this.recordStore.StartDate).format("MMM"));
        },
        endMonth () {
            if (this.recordStore) return this.tr(moment(this.recordStore.EndDate).format("MMM"));
        },
        passengersNames () {
            if (this.passengerList.length>0) {
                return this.passengerList.join(' / ');
            }
            return this.toTitleCase(this.recordStore.PaxName);
        },
        firstSupplierDay () {
            let res = {};
            for (let day of this.recordStore.BookingDays) {
                if (this.skipDays[day.DayNr]) continue;
                for (let s of day.BookingDayServices) {
                    if (s.Service && s.Service.VoucherDisabled) continue;
                    let supplierId = s.SupplierId;
                    if (s.Service && s.Service.SupplierId) supplierId = s.Service.SupplierId;
                    if (!supplierId) continue;
                    if (res[supplierId]==undefined) res[supplierId] = s.id;
                }
            }
            return res;
        }
    },
    methods: {
        getHotelName (day) {
            let res = [];
            let ids = {};
            if (this.hotelsByDay[day.DayNr] && this.hotelsByDay[day.DayNr].BookingDayRooms) {
                for (let room of this.hotelsByDay[day.DayNr].BookingDayRooms) {
                    if (!room.Hotel) continue;
                    if (ids[room.Hotel.id]) continue;
                    ids[room.Hotel.id] = true;
                    res.push(`${room.Hotel.Name}, ${room.Hotel.Address}`);
                }
            }
            return res.join('; ');
        },
        toTitleCase (t) {
            return tools.toTitleCase(t);
        }


    },
}
</script>

