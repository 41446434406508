<template>
  <div class="modal fade" id="selectValue" tabindex="-1" role="dialog" aria-labelledby="selectValueLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{tr(text)}}</h5>
        </div>
        <div class="modal-body">
              <div class="form-material">
                  <vue-select-input
                    v-if="editor && editor=='vue-select'"
                    fieldName="value"
                    :fieldOptions="computedOptions"
                    :currentValue.sync="value"
                    :addBlank="addBlank"
                  ></vue-select-input>
                  <select-input v-else
                    fieldName="value"
                    :fieldOptions="computedOptions"
                    :currentValue.sync="value"
                    :addBlank="addBlank"
                  ></select-input>
              </div>
              <div class="form-group" v-if="value=='_add'">
                  <label>{{addValueText}}: </label>
                  <input v-model="userValue"  class="form-control" type="text" >
              </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">{{tr('Done')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'select-value',
    mixins: [escape],
    props: ['defValue', 'options', 'text', 'fieldOptions', 'addValue', 'addValueText', 'show', 'editor', 'addBlank'],
    components: {
    },
    data () {
        return {
            value: null,
            userValue: null
        }
    },
    mounted () {
        if (this.defValue) this.value = this.defValue;
        $('#selectValue').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    computed: {
        computedOptions () {
            let r =  _.cloneDeep(this.fieldOptions);
            if (this.addValue) {
                r.push({value: '_add', label: '(' + this.addValueText + ')'})
            }
            return r;
        }
    },
    methods: {
        save (record) {
            if (this.addValue && this.value=='_add' && this.userValue) {
                this.value = this.userValue;
            }
            this.$emit('save', this.value, this.options);
            this.close();
        },
        close () {
            $('#selectValue').modal('hide');
            this.$emit('update:show', false);
            this.$emit('close');
        },
    },
}
</script>
