<script>
import * as VueGoogleMaps from "vue2-google-maps";

var mixinMap =  {
    name: 'mixin-map',
    mixins: [VueGoogleMaps.Map],
    mounted () {
        setTimeout(() => {
            var element = this.$refs['vue-map'];
            if (!element) return;
            //this.$super(VueGoogleMaps.Map).mounted();
        }, 100)
    },
}
//mixinMap.mounted._override = true;

export default mixinMap;

</script>

