<template>
    <div :style="svg" class="svg-icon">
    </div>
</template>

<script>
export default {
    props: ['src', 'pSize', 'color'],
    name: 'svg-icon',
    computed: {
        svg () {
            let img = '';
            img = require(`@/${this.src}`);
            return {
              mask: "url(" + img + ") no-repeat",
              webkitMask:  "url(" + img + ") no-repeat",
              padding: this.pSize + 'px',
              backgroundColor: this.color,
            };
        }
    },
    async mounted () {

    }
}
</script>

<style>
  .svg-icon {
    display: inline-block;
    position: relative;
    top: 2px;
  }
</style>
