<template>
  <div class="modal fade" id="payModal" tabindex="-1" role="dialog" aria-labelledby="payModalLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Input Values')}}</h5>
        </div>
        <div class="modal-body form-material" v-if="ready">
            <div class="form-row">
                <div class="col-md-6">
                    <select-input fieldName="currencyId"
                      :fieldOptions="currencies"
                      :currentValue.sync="currencyId" label="Payment Currency">
                    </select-input>
                </div>
                <div class="col-md-6">
                    <select-input fieldName="paymentTypeId"
                      :fieldOptions="paymentTypes"
                      :currentValue.sync="paymentTypeId" label="Payment Type">
                    </select-input>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-3">
                    <select-input fieldName="fromCurrencyId"
                      :fieldOptions="currencies"
                      @change="setRates"
                      :currentValue.sync="fromCurrencyId" label="From">
                    </select-input>
                </div>
                <div class="col-md-3">
                    <select-input fieldName="toCurrencyId"
                      :fieldOptions="currencies"
                      @change="setRates"
                      :currentValue.sync="toCurrencyId" label="To">
                    </select-input>
                </div>
                <div class="col-md-3 form-group form-default">
                    <input v-model="currencyRate" class="form-control"
                        :class="{'fill': currencyRate}"
                        type="number" >
                        <span class="form-bar"></span>
                        <label class="float-label">{{tr('Currency Rate')}}</label>
                    </input>
                </div>
                <div class="col-md-3 form-group form-default">
                    <input v-model="mepRate" class="form-control"
                        :class="{'fill': mepRate}"
                        type="number" >
                        <span class="form-bar"></span>
                        <label class="float-label">MEP</label>
                    </input>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save"
            :disabled="!currencyId || !currencyRate || !fromCurrencyId || !toCurrencyId || !paymentTypeId">
            {{tr('Done')}}
          </button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'pay-modal',
    mixins: [escape],
    props: ['show'],
    data () {
        return {
            currencyRate: null,
            mepRate: null,
            currencyId: null,
            fromCurrencyId: null,
            toCurrencyId: null,
            paymentTypeId: null,
            paymentTypes: [],
            currencies: [],
            ready: false,
        }
    },
    async mounted () {
        $('#payModal').modal({backdrop: 'static', keyboard: false}, 'show');
        let currencies = await api.getTable('currency');
        this.currencies = _.map(currencies, (r) => {
            return {value: r.id, label: r.id}
        });
        let paymentTypes = await api.getTable('paymenttype');
        this.paymentTypes = _.map(paymentTypes, (r) => {
            return {value: r.id, label: r.Name}
        });
        this.ready = true;
    },
    methods: {
        save () {
            this.$emit('save', this.currencyRate, this.currencyId, this.fromCurrencyId, this.toCurrencyId,
                this.paymentTypeId, this.mepRate);
            this.close();
        },
        close () {
            this.$emit('update:show', false);
            $('#payModal').modal('hide');
        },
        async setRates () {
            if (this.fromCurrencyId && this.toCurrencyId && this.fromCurrencyId != this.toCurrencyId) {
                let res = await api.get('/api/currencyrate/get_pair/mep/' + this.fromCurrencyId + '/' + this.toCurrencyId);
                if (res) {
                    if (this.fromCurrencyId == res.FromCurrencyId) {
                        this.mepRate = res.ConversionRate;
                    } else {
                        this.mepRate = 1 / parseFloat(res.ConversionRate);
                    }
                }
                let res1 = await api.get('/api/currencyrate/get_pair/purchase/' + this.fromCurrencyId + '/' + this.toCurrencyId);
                if (res1) {
                    if (this.fromCurrencyId == res.FromCurrencyId) {
                        this.currencyRate = res1.ConversionRate;
                    } else {
                        this.currencyRate = 1 / parseFloat(res1.ConversionRate);
                    }
                }
            }
        }
    }
}
</script>

