<template>
    <div class="row table-voucher">
        <div class="col-12 col-sm-6 text-center header-blue">{{ tr('PREPAID TRAVEL VOUCHER') }}</div>
        <div class="col-12 col-sm-6 text-center"><span>{{ tr('_SIGNATURE') }}:</span> {{ recordStore.SalesUser.FirstName }}</div>

        <div class="col-12 vc-border-bottom voucher-supplier">
            <p><span>{{ tr('TO') }}:</span> {{ supplierData.Supplier.Name }}; {{ supplierData.Supplier.Address }} - Tel. {{ supplierData.Supplier.Phone }}</p>
            <p><span>{{ tr('SERVICES INCLUDED') }}: </span><br>
                <div v-if="supplierData.Services">
                    <span v-for="(s, tId) in supplierData.Services" class="w-100">
                        <span> - </span>
                        <span class="voucher-service" v-if="!edit">
                            {{s.VoucherText || s.serviceName}} {{getDateServiceType(s)}}
                        </span>
                        <span class="voucher-service" v-else-if="edit && editing!= sId + '-' + tId" @click="editText(tId, s)">
                            {{s.VoucherText || s.serviceName}} {{getDateServiceType(s)}}
                        </span>
                        <input type="text" class="input-voucher w-75" v-else v-model="s.VoucherText">
                        <span v-if="s.PickupInfo" class="w-25 font-italic bold">
                            <span @click="editPickup(sId, s)">PICK UP:</span>
                            <span v-if="!edit" @click="editPickup(sId, s)" class="bold">{{ s.PickupInfo }}</span>
                            <span v-else-if="edit && editing!= sId + '-pickup'" @click="editPickup(sId, s)" class="bold">
                                {{ s.PickupInfo }}
                            </span>
                            <input type="text" class="input-voucher" v-else-if="edit && editing == sId + '-pickup'" v-model="s.PickupInfo">
                        </span>
                        <br>
                    </span>
                </div>
                <div v-else-if="supplierData.RoomsText">
                    <span class="voucher-service" v-for="(t, hId) of supplierData.RoomsText">
                        <span class="voucher-service" v-if="!edit">
                            {{t}}
                        </span>
                        <span class="voucher-service" v-else-if="edit && editingRoom!= hId" @click="editRoomText(hId, t)">
                            {{t}}
                        </span>
                        <input type="text" class="input-voucher w-100" v-else v-model="roomText" @change="setRoomText">
                        <br>
                    </span>
                </div>
                <div v-else-if="supplierData.VoucherText">
                    <span class="voucher-service">
                        {{supplierData.VoucherText }}
                    </span>
                </div>
            </p>
        </div>

        <div class="col-sm-6 vc-border-bottom">
            <p class="vcenter"><span>{{ tr('CLIENT') }}:</span> {{ recordStore.PaxName }}</p>
        </div>
        <div class="col-sm-6">
            <div class="row vc-border-left" v-if="recordStore.BaseQuotes[Object.keys(recordStore.BaseQuotes)[0]]">
                <div class="col-12 vc-border-bottom">
                    <p><span>{{ tr('N° OF ADULTS') }}:</span>
                     {{ recordStore.BaseQuotes[Object.keys(recordStore.BaseQuotes)[0]].ADT }}
                    </p>
                </div>
                <div class="col-12 vc-border-bottom">
                    <p><span>{{ tr('N° OF CHILDREN') }}:</span>
                        {{ recordStore.BaseQuotes[Object.keys(recordStore.BaseQuotes)[0]].CHD }}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 no-border">
            <div class="row no-border">
                <div class="col-12 vc-border-bottom">
                    <p><span>{{ tr('DATE IN') }}:</span> {{ supplierData.DateIn }}</p>
                </div>
                <div class="col-12 vc-border-bottom">
                    <p><span>{{ tr('DATE OUT') }}:</span> {{ supplierData.DateOut }}</p>
                </div>
                <div class="col-12 no-border">
                    <p>
                        <span @click="setComment('Comment', supplierData, supplierData.key)">{{ tr('COMMENTS') }}:</span>
                        <span v-if="!edit" class="voucher-service">{{ supplierData.Comments }}</span>
                        <span class="voucher-service" v-else-if="edit && editing!= sId + '-Comment'" @click="setComment('Comment', supplierData, supplierData.key)">
                            {{ supplierData.Comments }}
                        </span>
                        <input type="text" class="input-voucher w-75" v-else v-model="recordStore.VoucherComments[supplierData.key]">
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 vc-border-left">
            <async-img :path="imgPath" img="logo-voucher.png" class="voucher-logo"></async-img>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'voucher',
    props: ['supplierData', 'sId', 'edit'],
    data  () {
        return {
            editing: null,
            editingRoom: null,
            roomText: null,
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        imgPath () {
            let res = 'img';
            if (api.EXTRAS.indexOf('custom')>-1) {
                res = 'extra/custom/img';
            }
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.ImagesPath) {
                return res  + '/' + this.recordStore.Agency.ImagesPath;
            }
            return res;
        },
    },
    methods:  {
        editText (tId, s) {
            if (!s.VoucherText) {
                s.VoucherText = s.serviceName;
            }
            this.editing = this.sId + '-' + tId;
        },
        editRoomText (hId, t) {
            this.editingRoom = hId;
            this.roomText = t;
        },
        editPickup (s) {
            this.editing = this.sId + '-pickup';
        },
        async setComment (tId, s, key) {
            let v = this.recordStore.VoucherComments;
            v[key] = s.Comments;
            await this.recordStore.setValue({fieldName: 'VoucherComments', value: v});
            this.editing = this.sId + '-' + tId;
        },
        getDateServiceType (s) {
            let r = [];
            let st = this.getServiceType(s);
            if (st) r.push(st);
            r.push(this.getDateFormat(s.ServiceDate));
            return "(" + r.join(",") + ")";
        },
        getServiceType (s) {
            if (s.Service && s.Service.ServiceType) {
                return tr(s.Service.ServiceType.Name);
            }
        },
        getDateFormat (d) {
            return moment(d).format('DD/MM');
        },
        setRoomText () {
            let roomData = this.supplierData.Rooms[this.editingRoom];
            let day = this.recordStore.BookingDays[roomData.DayNr];
            if (day.BookingDayHotels[0]) {
                let room = _.find(day.BookingDayHotels[0].BookingDayRooms, r => r.id == roomData.id);
                if (room) {
                    room.VoucherText = this.roomText;
                    //this.$store.commit('setBooking', {booking: this.booking});
                }
            }
        }

    }
}
</script>
