<template>
  <div class="container-fluid container-dashboard">
    <div class="form-row" v-dragula bag="home" id="dashboard-home">
        <div v-for="(c, i) in cards" :class="'form-group col-md-' + c.columns" :id="c.component" :i="i">
            <dashboard-card :card="c"></dashboard-card>
        </div>
    </div>
  </div>
</template>

<script>
import dashboardCard from '@/components/tools/DashboardCard';
import Vue from 'vue';
var VueDragula = require('vue-dragula');
Vue.use(VueDragula);
export default {
    name: 'dashboard',
    props: ['cards'],
    components: {
        'dashboard-card': dashboardCard,
    },
    mounted () {
        Vue.vueDragula.eventBus.$on('drop', async function ([bag, el, target, source]) {
            let moveFrom = $(el).attr('i');
            let div = $('#dashboard-home')[0].childNodes;
            let dashboard = localStorage.getItem('dashboard');
            let newDashboard = [];
            if (dashboard) {
                dashboard = JSON.parse(dashboard);
            }
            for (let i = 0; i < div.length; i++) {
                let node = div[i];
                let c = node.getAttribute('id');
                let comp = _.find(dashboard, (r) => {
                    return r && r.component == c;
                });
                newDashboard.push(comp);
            }
            localStorage.setItem('dashboard', JSON.stringify(newDashboard));
        })
        Vue.vueDragula.eventBus.$on('cancel',function() {
            self.isOver = false;
            return true;
        })
    },
    beforeDestroy() {
        Vue.vueDragula.eventBus._events = {}
    },
}
</script>
