<template>
    <div class="mb-2 cursor-pointer">
        <label class="mb-0"><b>{{supplier.Supplier.Name}}</b></label> / 
        <i :class="color">{{serviceStatusLabel}}</i>
    </div>
</template>

<script>
export default {
    name: 'operations-mixed-link',
    props: ['supplier', 'hotel', 'current'],
    data () {
        let serviceView = api.importMixinModule('bookingdayservice', 'views');
        return {
            serviceStatusOptions: serviceView.serviceStatus,
            order: ['PENDING', 'REQUESTED', 'CONFIRMED', 'CANCELED'],
            colors: {
                'text-danger': ['PENDING', 'CANCELED', 'NOT_SERVICES'],
                'text-success': ['CONFIRMED'],
                'text-primary': ['REQUESTED']
            }
        }
    },
    computed: {
        color () {
            for (let id in this.colors) {
                if (this.colors[id].indexOf(this.serviceStatus)>-1) return id;
            }
        },
        serviceStatusLabel () {
            if (this.serviceStatusOptions[this.serviceStatus]) {
                return tr(this.serviceStatusOptions[this.serviceStatus]);
            }
            if (this.serviceStatus=='NOT_SERVICES') {
                return tr('Without Services');
            }
            return tr('Without Services');
        },
        serviceStatus () {
            let status = {}
            for (let id in this.serviceStatusOptions) {
                status[id] = 0;
            }
            if (this.supplier.Services.length == 0) return 'NOT_SERVICES';
            for (let s of this.supplier.Services) {
                if (status[s.Status]!=undefined) {
                    status[s.Status] += 1;
                }
            }
            for (let s of this.hotel.Rooms) {
                if (status[s.Status]!=undefined) {
                    status[s.Status] += 1;
                }
            }
            for (let s of this.order){
                if (status[s] > 0) {
                    return s;
                }
            }
        }
    },
    methods: {
    },
}
</script>
