<template>
    <div class="container">
        <div class="col-12 info-box">
            <h6>{{tr('Terms & Conditions')}}</h6>
            <div class="info-box-body">
                <p>Following the
                <a target="_blank">
                link</a>
                that appears below you will be able to read our terms and conditions</p>
                <p><a target="_blank">
                www.nubbo.io/{{langCode}}/terms-and-conditions</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-final-terms',
    props: ['id', 'hash'],
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
           let r = _.find(api.tables.language, (c) => c.id == this.recordStore.LanguageId)
           if (r) return r.Code;
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
    }
}
</script>

