<template>
    <div>
        <form class="form-inline azul header-row" action="">
           <div class="form-group col-md-2">
              <div class="label">{{tr('How booked your trip?')}}*</div>
              <select class="custom-select" name="" id="" disabled>
                 <option v-for="opt of fieldOptions['SalesUserId']" :value="opt.value"
                    :selected="opt.value == bookingForm.SalesUserId">{{opt.label}}</option>
              </select>
           </div>
           <div-input fieldName="PaxName" :v.sync="bookingForm.PaxName" :cols="2"
                label="Reservation under which name" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           <div-input fieldName="Country" :v.sync="bookingForm.Country" :cols="2"
                label="Country" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           <div-input fieldName="Address" :v.sync="bookingForm.Address" :cols="2"
                label="Address" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           <div-input fieldName="Phone" :v.sync="bookingForm.Phone" :cols="2"
                label="Phone Number" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
           <div-input fieldName="Mobile" :v.sync="bookingForm.Mobile" :cols="2"
                label="Mobile Phone Number" :langCode="langCode"
                :invalid="invalid"
           ></div-input>
        </form>
        <form class="form-inline azul header-row mt-2" action="">
           <div class="form-group col-md-12 label">
              <label>{{tr('_contact_person')}}</label>
           </div>
           <div-input fieldName="ContactName" :v.sync="bookingForm.ContactName" :cols="6"
                label="Name and Surname" :langCode="langCode" :invalid="invalid"
           ></div-input>
           <div-input fieldName="ContactPhone" :v.sync="bookingForm.ContactPhone" :cols="6"
                label="Phone Number" :langCode="langCode" :invalid="invalid"
           ></div-input>
        </form>
        <form class="form-inline azul header-row mt-2" action="">
           <div class="form-group col-md-12 label">
              <label>{{tr('_speak_spanish')}}</label>
           </div>
           <div class="form-group col-md-3">
                <select class="custom-select"
                    :class="(!bookingForm.SpeakSpanish && invalid)? 'is-invalid': ''"
                    :required="true"
                    v-model="bookingForm.SpeakSpanish">
                    <option value="ES" :selected="'ES' == bookingForm.SpeakSpanish">
                        {{tr('_Spanish')}}
                    </option>
                    <option value="EN" :selected="'NO' == bookingForm.SpeakSpanish">
                        {{tr('_English')}}
                    </option>
              </select>
           </div>
        </form>

        <form class="form-inline azul header-row mt-2" action="">
           <div class="form-group col-md-12 label text-justify">
              <label>{{tr('_insurance')}}</label>
           </div>
           <div class="form-group col-md-3">
                <select class="custom-select"
                    :class="(!bookingForm.Insurance && invalid)? 'is-invalid': ''"
                    :required="true"
                    v-model="bookingForm.Insurance">
                    <option value="YES" :selected="'YES' == bookingForm.Insurance">
                        {{tr('YES')}}
                    </option>
                    <option value="NO" :selected="'NO' == bookingForm.Insurance">
                        {{tr('NO')}}
                    </option>
              </select>
           </div>
        </form>
        <form class="form-inline azul header-row mt-2" action="">
           <div class="form-group col-md-12 label">
              <label>{{tr('_share_pictures')}}</label>
           </div>
           <div class="form-group col-md-3">
                <select class="custom-select"
                    :class="(!bookingForm.SharePictures && invalid)? 'is-invalid': ''"
                    :required="true"
                    v-model="bookingForm.SharePictures">
                    <option value="YES" :selected="'YES' == bookingForm.SharePictures">
                        {{tr('Yes, I agree')}}
                    </option>
                    <option value="NO" :selected="'NO' == bookingForm.SharePictures">
                        {{tr("I don't agree")}}
                    </option>
              </select>
           </div>

        </form>

    </div>
</template>

<script>
const reservationFormMain = importVueComp('components/reservationform', 'ReservationFormMain', 'custom');
export default {
    name: 'custom-reservation-form-main',
    mixins: [reservationFormMain],
}
</script>

