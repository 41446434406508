<template>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'optionals',
    props: ['langCode'],
    data: function () {
        return {
            services: null,
        }
    },
    computed:{
        cities: function() {
            let res = {}
            if (!this.services) return;
            for (let s of this.services) {
                if (!res[s.CityId]) res[s.CityId] = {city: s.City, services: []}
                res[s.CityId].services.push(s)
            }
            return res;
        }
    },
    mounted: async function () {
        api.setProcessing(true);
        this.processing = true;
        let res = await api.get('/public/service/', {filters: JSON.stringify({Optional: true, Relations: true })});
        if (res) {
            this.services = res;
            api.setProcessing(false);
        }

    },
    methods: {
        getServiceTitle (s) {
            return s.title;
        },
        getServiceText: function(s) {
            for (let d of s.ServiceDescriptions) {
                if (d.Language.Code==this.langCode) {
                    return d.Description;
                }
            }
        },
        getCardClass: function(idx) {
            if (idx % 2) return 'acc-grey';
            return 'acc-white';
        },
        tr (t) {
            return tr(t, this.langCode);
        },
    },
}
</script>
