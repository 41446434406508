<template>
  <div class="modal fade" id="support" tabindex="-1" role="dialog" aria-labelledby="supportLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Support')}}</h5>
        </div>
        <div class="modal-body form-material">
            <div class="form-row">
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="sendLogs">
                    {{tr('Send Logs by Email')}}
                </button>
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="clearDashboard">
                    {{tr('Clear Dashboard')}}
                </button>
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="$emit('memoryUsage')">
                    {{tr('Memory Usage')}}
                </button>
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="clearCache" v-if="systemManager">
                    {{tr('Clear Cache')}}
                </button>
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="testDownloadSpeed('testfile', 10)">
                    {{tr('Test connection 10 MB')}}
                </button>
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="testDownloadSpeed('testfile1M', 1)">
                    {{tr('Test connection 1 MB')}}
                </button>
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="testDownloadSpeed('testfile100M', 100)">
                    {{tr('Test connection 100 MB')}}
                </button>
                <button type="button" class="btn btn-primary mb-2 mx-1" @click="testDownloadSpeed('testfile100k', 0.1)">
                    {{tr('Test connection 100 KB')}}
                </button>
            </div>
            <div class="form-row text-small">
                <label class="col-12"><b>Commit Front</b>: {{ date(commit) }} </label>
            </div>
            <div class="form-row text-small">
                <label class="col-12"><b>Commit Back</b>: {{ dateBack(commitBack) }} </label>
            </div>

            <!--input type="file"  multiple accept="*" @change="uploadFile($event)" id="input-file">
            <input type="file"  multiple accept="*" @change="uploadFile2($event)" id="input-file2"-->

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import commit from '@/commit.txt';
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'support',
    mixins: [escape],
    data () {
        return {
            userId: null,
            commit: commit,
            commitBack: null,
        }
    },
    computed: {
        ...mapState({
            systemManager: state => state.main.systemManager,
        }),
    },

    async mounted () {
        this.commitBack = await api.get('/api/get_commit/');
        $('#support').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        async sendLogs () {
            let res = await api.post('/api/send_logs/', JSON.stringify(console.logs));
            alert(tr('Message sent'));
        },
        async clearCache () {
            let res = await api.get('/api/clear_cache_all/');
            alert(tr('Done'));
        },
        date (t) {
            console.log(t)
            if (!t) return '';
            try {
                let d = t.replace(/\t/g, '' ).replace(/\n/g, '' ).replace(/\\/g, '' ).replace(/\|/g, '' )
                d = d.split(' ');
                d = _.filter(d, (r) => r)
                lang = api.language
                return `${d[1]}  -  ${moment.utc(d[2]).locale(lang).format('DD MMM YYYY HH:mm')}`
            } catch {
                return t;
            }
        },
        dateBack (d) {
            if (!d) return '';
            try {
                d = _.filter(d, (r) => r)
                lang = api.language
                return `${d[1]}  -  ${moment.utc(d[2]).locale(lang).format('DD MMM YYYY HH:mm')}`
            } catch {
                return t;
            }
        },
        close () {
            if (this.userId) {
                let user = _.find(api.tables.user, (r) => r.id == this.userId);
                //api.currentUser = user;
                //this.$store.commit('setUser', user);
            }
            this.$emit('update:show', false);
            this.$emit('close');
            $('#support').modal('hide');
        },
        clearDashboard () {
            localStorage.setItem('dashboard', '');
        },
        async uploadFile (event) {
            let input = event.target;
            var files = new FormData();
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    files.append(i, input.files[i]);
                }
            }
            let res = await api.post('/api/test_cipher/', files, {}, (error) => {
                console.log(error)
            });
            if (res) {
                alert(res)
            }
        },
        async uploadFile2 (event) {
            let input = event.target;
            var files = new FormData();
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    files.append(i, input.files[i]);
                }
            }
            let res = await api.post('/api/test_cipher2/', files, {}, (error) => {
                console.log(error)
            });
            if (res) {
                alert(res)
            }
        },
        async testDownloadSpeed (fileName, fileSizeInBytes) {
            api.testDownloadSpeed(fileName, (value) => {
                alert(`Download Speed for ${fileSizeInBytes} MB file: ${value.toFixed(2)} Mbps`);
            }, (error) => {
                if (error.message) {
                    alert(`Error: ${error.message}`);
                    return;
                } else {
                    alert(`Failed to download the file. Status: ${error.status}`);
                }
            })
        }
    },
}
</script>
