<template>
  <div class="modal fade bd-example-modal-lg" id="select-hotel-modal" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-extra-large" role="document">
      <div class="modal-content">
        <div class="modal-header py-2 section-title">
            <label class="mt-1 mb-0 ml-4">
                {{tr('Edit Hotel')}}
            </label>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body body-hotel-selector">
          <transition name="fade" mode="out-in">
            <div v-if="showSelector">
              <div class="form-row">
                  <div class="form-group col-md-10">
                      <search-box v-model="searchBox"></search-box>
                  </div>
                  <div class="form-group col-md-2">
                       <div class="form-check">
                          <input v-model="sostenible" name="Sostenible"
                          class="form-check-input" type="checkbox" id="Sostenible">
                          <label class="smaller form-check-label">{{tr('Sostenible')}}</label>
                      </div>
                  </div>
              </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <button v-for="x in tags" type="button" class="btn btn-secondary btn-service-selected">{{x}}
                          <span aria-hidden="true" class="remove-item" @click="removeTag(x)">&times;</span>
                        </button>
                    </div>
                </div>

              <v-client-table :data="list" :columns="columnsNames" :options="options"
                v-if="list && !processing" @row-click="onRowClick">
              </v-client-table>
              <div v-if="processing">
                  <img class="loading-gif" src="@/img/loading.gif">
              </div>

            </div>
            <div v-else-if="recordStore.BookingDays && recordStore.BookingDays[dayId]">
                <div class="form-row">
                    <div class="form-group col-md-12 row ml-3 form-material">
                        <div class="form-group form-default col-md-2">
                            <input v-model="recordStore.BookingDays[dayId].BookingDayHotels[0].Days"
                                class="form-control text-right" type="number"
                                :class="{'fill': recordStore.BookingDays[dayId].BookingDayHotels[0].Days}"
                                :disabled="exchange">
                                <label class="float-label ml-3">{{tr('Nights Quantity')}}</label>
                            </input>
                        </div>
                        <div class="col-md-10 text-right" v-if="recordStore.bookingConfirmed">
                            <label class="mr-4 text-danger font-weight-bold cursor-pointer" @click="setExchange"
                              v-if="canChangeHotel && !exchange">
                                {{tr('Passenger prices unlocked')}}
                            </label>
                            <label class="mr-4 text-danger font-weight-bold cursor-pointer" @click="setExchange"
                              v-if="canChangeHotel && exchange">
                                {{tr('Passenger prices locked')}}
                            </label>
                            <i class="cursor-pointer mx-2" @click="setExchange"
                             	:class="{'row-primary-button': exchange}">
                                  <font-awesome-icon icon="exchange-alt"/>
                            </i>
                        </div>

                        <div class="form-group col-md-12 hotel-room-row">
                            <hotel-rooms
                                v-if="list"
                                :dayId="dayId"
                                :nights="recordStore.BookingDays[dayId].BookingDayHotels[0].Days"
                                :exchange="exchange"
                                :hotelsList="list"
                                :defaultHotel="selected"
                                :reportData="reportData"
                                @setHotel="updateHotelId"
                                @changeCity="changeCity"
                            ></hotel-rooms>
                        </div>
                    </div>
                    <hotel-cost-list-report
                        v-if="this.reportParams.HotelId"
                        :options="reportParams"
                        :reportData.sync="reportData"
                    ></hotel-cost-list-report>
                </div>
            </div>
          </transition>
        </div>
        <div class="modal-footer">
          <button v-if="showSelector" type="button" class="btn btn-outline-secondary"
              :disabled="!selected" @click="select">{{tr('Select')}}</button>
          <button v-if="!showSelector && canChangeHotel" type="button" class="btn btn-warning"
               @click="change">{{tr('Change Hotel')}}</button>
          <button v-if="completed && !showSelector" type="button" class="btn btn-primary"
            @click="close">{{tr('Done')}}</button>
          <button type="button" class="btn btn-danger" @click="deleteHotel" v-if="salesActions && canDeleteHotel && !exchange">
            {{tr('Delete')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const hotelSelectorModal = importVueComp('components/hotel', 'HotelSelectorModal', 'sostenible');
export default {
    name: 'hotel-selector-modal',
    mixins: [hotelSelectorModal],
    data () {
        return {
            sostenible: false,
        }
    },
    computed:{
        dataList () {
            if (this.sostenible) {
                return _.filter(this.hotelsList, (r) => r.Sostenible == true);
            }
            return this.hotelsList;
        }
    }
}
</script>

