<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"  class=" svg-inline--fa fa-ad fa-w-16"
      width="382.000000pt" height="340.000000pt" viewBox="0 0 382.000000 340.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,440.000000) scale(0.130000,-0.130000)" stroke="none">
      <path fill="currentColor" d="M1759 3290 c-695 -62 -1274 -573 -1419 -1252 -61 -285 -46 -561 47
      -843 172 -524 610 -924 1148 -1046 502 -114 1034 15 1407 342 267 234 454 552
      522 887 99 486 -36 1003 -358 1367 -286 323 -651 507 -1080 545 -122 11 -141
      11 -267 0z m391 -316 c220 -43 432 -148 605 -298 212 -183 354 -430 422 -731
      24 -110 25 -366 0 -485 -87 -415 -345 -751 -717 -932 -162 -79 -312 -116 -505
      -125 -277 -12 -527 56 -770 212 -227 145 -424 402 -516 672 -50 147 -63 233
      -63 413 -1 180 12 260 63 413 163 481 591 824 1091 877 100 11 297 3 390 -16z"/>
      <path fill="currentColor" d="M1856 2793 c-126 -19 -196 -197 -120 -305 39 -55 91 -81 164 -81 73
      0 125 26 164 81 25 35 30 52 30 104 1 135 -102 222 -238 201z"/>
      <path fill="currentColor"  d="M1255 2491 c-54 -14 -95 -41 -122 -81 -35 -55 -37 -159 -4 -214 44
      -71 133 -106 219 -85 99 25 145 83 146 189 0 58 -4 74 -27 109 -42 64 -137
      101 -212 82z"/>
      <path fill="currentColor"  d="M2405 2493 c-11 -3 -34 -19 -52 -35 -33 -31 -43 -54 -329 -703 -70
      -159 -130 -294 -134 -298 -4 -4 -35 -15 -69 -22 -138 -33 -235 -136 -262 -279
      -19 -106 22 -237 98 -306 73 -69 132 -90 244 -90 87 0 102 3 156 30 121 61
      186 169 186 310 -1 79 -18 140 -53 190 -11 15 -20 35 -20 44 0 8 95 228 211
      488 183 411 211 480 211 524 1 56 -21 98 -66 127 -27 18 -90 28 -121 20z"/>
      <path fill="currentColor"  d="M956 1888 c-94 -21 -149 -90 -149 -188 0 -71 30 -129 87 -167 34 -23
      52 -28 106 -28 54 0 72 5 106 28 87 59 114 179 60 271 -39 67 -128 103 -210
      84z"/>
      <path fill="currentColor"  d="M2756 1888 c-95 -21 -148 -88 -148 -188 0 -73 29 -128 86 -167 34
      -23 52 -28 106 -28 54 0 72 5 106 28 88 59 115 178 61 270 -40 68 -129 104
      -211 85z"/>
      </g>
    </svg>

</template>

<script>
export default {
    name: 'svg-speed',
}
</script>

<style scoped>
</style>
