<template>
    <div class="mb-2 cursor-pointer" v-if="supplier">
        <label class="mb-0"><b>{{supplier.Supplier.Name}}</b></label> /
        <i :class="{'text-danger': serviceStatus=='PENDING' || serviceStatus=='CANCELED' || serviceStatus=='NOT_SERVICES', 'text-success': serviceStatus=='CONFIRMED' || serviceStatus=='RE_CONFIRMED' , 'text-primary': serviceStatus=='REQUESTED'}">{{serviceStatusLabel}}</i>
    </div>
</template>

<script>
export default {
    name: 'custom-operations-service-link',
    props: ['supplier', 'current'],
    computed: {
        serviceStatusLabel () {
            if (this.serviceStatus=='PENDING') {
                return tr('Pending');
            } else if (this.serviceStatus=='REQUESTED') {
                return tr('Requested');
            } else if (this.serviceStatus=='CONFIRMED') {
                return tr('Confirmed');
            } else if (this.serviceStatus=='RE_CONFIRMED') {
                return tr('Re-confirmado');
            } else if (this.serviceStatus=='CANCELED') {
                return tr('Canceled');
            } else if (this.serviceStatus=='NOT_SERVICES') {
                return tr('Without Services');
            }
        },
        serviceStatus () {
            let status = {
                PENDING: 0,
                REQUESTED: 0,
                CONFIRMED: 0,
                CANCELED: 0,
                RE_CONFIRMED: 0,
            }
            if (this.supplier.Services.length == 0) return 'NOT_SERVICES';
            for (let s of this.supplier.Services) {
                if (status[s.Status]!=undefined) {
                    status[s.Status] += 1;
                }
            }
            if (status.PENDING>0) {
                return 'PENDING';
            } else if (status.REQUESTED>0) {
                return 'REQUESTED';
            } else if (status.CONFIRMED>0) {
                return 'CONFIRMED';
            } else if (status.RE_CONFIRMED>0) {
                return 'RE_CONFIRMED';
            } else if (status.CANCELED>0) {
                return 'CANCELED';
            }
        }
    },
    methods: {
    },
}
</script>
