<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="416.000000pt" height="378.000000pt" viewBox="0 0 416.000000 378.000000"
     preserveAspectRatio="xMidYMid meet" class="memo-info-svg svg-inline--fa fa-ad fa-w-16">
        <g transform="translate(0.000000,378.000000) scale(0.100000,-0.100000)" fill="currentColor" fill-rule="evenodd">
          <path d="M380 1870 l0 -1600 1033 0 1032 1 -35 26 c-19 15 -72 63 -116 108 -214 213 -328 512 -311 815 13 215 75 405 188 572 52 76 200 225 274 275 78 52 205 116 278 139 l57 18 0 623 0 623 -1200 0 -1200 0 0 -1600z m2000 700 l0 -100 -800 0 -800 0 0 100 0 100 800 0 800 0 0 -100z m-400 -600 l0 -100 -600 0 -600 0 0 100 0 100 600 0 600 0 0 -100z m-400 -600 l0 -100 -400 0 -400 0 0 100 0 100 400 0 400 0 0 -100z"/>
          <path d="M3000 2064 c-14 -2 -56 -9 -95 -15 -328 -54 -603 -316 -696 -665 -30 -112 -30 -316 0 -428 93 -349 355 -600 696 -667 91 -18 281 -16 370 4 281 63 527 267 631 527 51 125 66 206 66 350 0 144 -15 225 -66 350 -104 259 -354 468 -628 525 -71 15 -236 27 -278 19z m180 -494 l0 -100 -105 0 -105 0 0 100 0 100 105 0 105 0 0 -100z m10 -550 l0 -250 45 0 45 0 0 -100 0 -100 -200 0 -200 0 0 100 0 100 55 0 55 0 0 150 0 150 -55 0 -55 0 0 100 0 100 155 0 155 0 0 -250z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'memo-info2',
}
</script>

<style scoped>
  .memo-info-svg {
      max-height: 20px;
      width: auto;
  }
</style>