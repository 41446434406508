<template>
    <div class="">
        <div v-if="imageSrc">
            <img :src="imageSrc" class="img-fluid rounded image-small" alt="Responsive image" v-show="imageSrc">
        </div>
        <div v-else>
            <font-awesome-icon icon="image" class="image-small"/>
        </div>

    </div>
</template>

<script>
export default {
    name: 'photo-small',
    props: ['label', 'image'],
    data () {
        return {
            imageSrc: null,
            show: false,
        }
    },
    mounted () {
        if (this.image && this.image.url) {
            this.imageSrc = this.image.url;
        }
    },
    watch: {
        image () {
            if (this.image && this.image.url) {
                this.imageSrc = this.image.url;
            } else {
                this.imageSrc = null;
            }
        },
    },
    methods: {
    },
}
</script>

<style scoped>
.image-small {
    width: 50px;
    max-height: 30px !important;
    object-fit: cover !important;
}
</style>
