<template>
  <div>
    <gmap-map
      :center="center"
      :options="options"
      :style="mapStyle"
      ref="mapRef"
    >
      <directions-renderer
        v-if="directions"
        :origin="directions.origin"
        :destination="directions.destination"
        :wayPoints="directions.wayPoints"
      />
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :animation="m.animation"
        :label="m.label"
        :icon="m.icon"
        :z-index="m.zIndex"
        @mouseover="openWindow(m, m.cityId)"
        @mouseout="windowOpen=false"
      ></gmap-marker>
      <gmap-info-window
          @closeclick="windowOpen=false"
          :opened="windowOpen"
          :position="infoWindow"
          :options="{
            pixelOffset: {
              width: 0,
              height: -35
            }
          }"
      ><img class="" :src="cityImg" height="120">
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
import DirectionsRenderer from '@/tools/DirectionsRenderer';
export default {
  name: "google-map",
  components: {
      'directions-renderer': DirectionsRenderer
  },
  props: ['bookingCities', 'bookingCitiesObject', 'directions', 'languageCode'],
  data() {
    return {
      ready: false,
      map: null,
      currentPlace: null,
      windowOpen: false,
      infoWindow: null,
      markers: [],
      cityImg: null,
      mapStyle: 'width:100%;  height: 590px;',
      citiesMark: [],
      options: this.getOptions(),
      strokeColor: '#00BCD4',
      pinURL: 'custom_favicon.ico',
      zoomMax: 21,
      lat1: null,
      lat2: null,
      mapDim: null,
      bounds: null
    };
  },
  mounted () {
    let self = this;
    this.getZoom();
    this.$refs.mapRef.$mapPromise.then((map) => {
      self.map = map;
      self.$nextTick(function() {
          setTimeout( function() {
            self.bounds = map.getBounds();
            self.markers = self.getMarkers();
          }, 500)
      })

    })
    this.$refs.mapRef.$on('zoom_changed', (zoom) => {
      //console.log(zoom); // true
      self.options.zoom = zoom;
      self.markers = self.getMarkers();
    })
    EventBus.$on('show-city', this.showCity);
  },
  computed: {
    ...mapState({
        recordStore: state => state.main.record,
    }),
    center () {
        let lat = -41;
        let lng = -63;
        let lats = [];
        let longs = [];
        for (let cityId of this.bookingCities) {
            let city = this.bookingCitiesObject[cityId];
            if (city && city.Latitude && city.Longitude) {
                lats.push(city.Latitude);
                longs.push(city.Longitude);
            }
        }
        let lat1 = Math.max.apply(null, lats);
        let lat2 = Math.min.apply(null, lats);
        lat = (lat1 + lat2) / 2;
        let lng1 = Math.max.apply(null, longs);
        let lng2 = Math.min.apply(null, longs);
        lng = (lng1 + lng2) / 2;
        return { lat: lat, lng: lng };
    },
  },
  methods: {
    tr (t) {
        return tr(t, this.languageCode);
    },
    updateCurveMarker (myPos1, myPos2) {

        if (!this.map) return;
        let pos1 = {lat: myPos1.lat, lng: myPos1.lng};
        let pos2 = {lat: myPos2.lat, lng: myPos2.lng};
        var LatLng = new google.maps.LatLng;
        var bounds = new google.maps.LatLngBounds();
        var Marker = new google.maps.Marker;
        var Point = new google.maps.Point;
        var curvature = 0.3;
        var curveMarker;

        var projection = this.map.getProjection();
        let p1 = new google.maps.LatLng(pos1);
        let p2 = new google.maps.LatLng(pos2);
        if (!projection) return;
        p1 = projection.fromLatLngToPoint(p1);
        p2 = projection.fromLatLngToPoint(p2);

        var e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint (p2 relative to p1)
            m = new google.maps.Point(e.x / 2, e.y / 2), // midpoint
            o = new google.maps.Point(e.y, -e.x), // orthogonal
            c = new google.maps.Point( // curve control point
                m.x + curvature * o.x,
                m.y + curvature * o.y);
        var pathDef = 'M 0,0 ' +
            'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;
        //var zoom = this.map.getZoom();
        let zoom = this.options.zoom;
        var scale = 1 / (Math.pow(2, -zoom));

        var symbol = {
            path: pathDef,
            scale: scale,
            strokeWeight: 1.5,
            strokeColor: this.strokeColor,
        };

        curveMarker = {
            position: pos1,
            icon: symbol,
            zIndex: -10, // behind the other markers
        };
        return curveMarker;
    },
    latRad (lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    },
    zoomPX (mapPx, worldPx, fraction) {
        return Math.log(mapPx / worldPx / fraction) / Math.LN2;
    },
    getZoom () {
        let lats = [];
        let longs = [];
        for (let cityId of this.bookingCities) {
            let city = this.bookingCitiesObject[cityId];
            if (city && city.Latitude && city.Longitude) {
                lats.push(city.Latitude);
                longs.push(city.Longitude);
            }
        }
        let lat1 = Math.max.apply(null, lats);
        let lat2 = Math.min.apply(null, lats);
        let lng1 = Math.max.apply(null, longs);
        let lng2 = Math.min.apply(null, longs);
        let latDiff = (lat1 - lat2);
        let lngDiff = (lng1 - lng2);
        this.lat1 = lat1;
        this.lat2 = lat2;
        let rel = latDiff / lngDiff;
        if (rel > 0.2 && rel < 5) {
            this.mapStyle = 'width:100%;  height: 690px;'
        }

        var WORLD_DIM = { height: 360, width: 360 };

        var $mapDiv = $('#mapDiv');

        var mapDim = {
            height: $mapDiv.height(),
            width: $mapDiv.width()
        }
        this.mapDim = mapDim;

        var latFraction = (this.latRad(lat1) - this.latRad(lat2)) / Math.PI;

        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        var latZoom;
        var lngZoom;
        latZoom = this.zoomPX(mapDim.height, WORLD_DIM.height, latFraction);
        lngZoom = this.zoomPX(mapDim.width, WORLD_DIM.width, lngFraction);
        if (!latFraction && !lngFraction) {
            latZoom = 7;
            lngZoom = 7;
        }
        this.options.zoom = Math.min(latZoom, lngZoom, this.zoomMax) - 0.5;
    },
    openWindow (m, cityId) {
        if (!cityId) return;
        if (!this.bounds) return;
        if (!this.bounds.Ua) return;
        let l = this.mapDim.height / (this.bounds.Ua.hi - this.bounds.Ua.lo);
        let md = (this.bounds.Ua.hi + this.bounds.Ua.lo) / 2;
        let p = 120 / l;
        let p1 = md + p;
        let images = _.map(this.recordStore.BookingDays, (day) => {
            for (let s of day.BookingDayServices) {
                let city;
                if (s.CityId == cityId) city = true;
                if (s.Service && s.Service.CityId == cityId) city = true;
                if (city) {
                    if (s.BookingDayServiceImages && s.BookingDayServiceImages[0] && s.BookingDayServiceImages[0].Image) {
                        return s.BookingDayServiceImages[0].Image;
                    } else if (day.Image) {
                        return day.Image;
                    }
                }
            }
        });
        images = _.map(_.filter(images, (r) => r && r.url), (r) => r.url);
        images = [...new Set(images)]
        var middle = images[Math.round((images.length - 1) / 2)];
        let pos = Object.assign({}, this.center);
        let p2 = (p1 + md) / 2;
        if (m.position.lat > md && m.position.lat < p2) {
            let k = 1 / l;
            pos.lat = m.position.lat + 3 * k;
        } else if (m.position.lat >= p2 && m.position.lat < p1) {
            let k = 1 / l;
            pos.lat = m.position.lat - 5 * k;
        } else if (m.position.lat > p1) {
            pos.lat = pos.lat - p / 2;
        }
        if (middle) {
            this.cityImg = middle;
            this.infoWindow = pos;
            this.windowOpen = true;
            return;
        }
        if (images[0]) {
            this.cityImg = images[0];
            this.infoWindow = pos;
            this.windowOpen = true;
        }
    },
    showCity (cities) {
        this.citiesMark = Object.assign([], _.map(cities, (r) => r.id));
        this.markers = this.getMarkers();
    },
    getMarkers () {
        let res = [];
        let markers = [];
        let image = {
            url: this.pinURL,
            scaledSize: {height: 13, width: 13},
            //scale: 3,
            labelOrigin: {x: 10, y: 28},
        }
        for (let cityId of this.bookingCities) {
            let city = this.bookingCitiesObject[cityId];
            if (!city) continue;
            if (city.MapHidden) continue;
            if (city && city.Latitude && city.Longitude) {
                let pos = {lat: city.Latitude, lng: city.Longitude}
                let marker = {
                    position: pos,
                    label: {text: this.markerName(city), color: '#224061', fontSize: '15px', fontWeight: '700', className: 'marker-label'},
                    icon: Object.assign({}, image),
                    zIndex: 1,
                    cityId: city.id,
                };
                if (this.citiesMark.indexOf(city.id)>-1) {
                  //marker.label.color = 'red';
                  marker.animation = 1;
                }
                markers.push(marker);
            }
        }
        let lines = [];
        for (let i in markers) {
            let m1 = markers[i];
            let m2;
            if (i<markers.length-1) {
                m2 = markers[parseInt(i)+1];
                let curve = this.updateCurveMarker(m1.position, m2.position);
                if (curve) {
                    lines.push(this.updateCurveMarker(m1.position, m2.position))
                }
            }
        }
        for (let m of markers) {
            res.push(m);
        }
        for (let line of lines) {
            res.push(line);
        }
        return res;
    },
    markerName (city) {
        return city.Name;
    },
    getOptions () {
      return {
        zoom: 4,
        disableDefaultUI: true,
        scaleControl: false,
        draggable: false,
        navigationControl: false,
        scrollwheel: false,
        mapTypeControl: false,
        styles: [
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#ffffff'}]
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [{color: '#DFDDDD'}]
          },
          {
            featureType: "administrative",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.stroke",
            stylers: [
              {
                  color: '#ffffff',
                  lightness: 10
               }
            ]
          },
        ]
      }
    }
  },
  beforeDestroy() {
      EventBus.$off('show-city', this.showCity);
  },
};
</script>

<style>
    .marker-label {
        bottom: 21%;
        left: 65%;
        position: relative;
        //text-shadow: #fff 1px 0 10px;
        text-shadow: 1px 1px 2px #fff, 0 0 1em #fff, 0 0 0.2em #fff;
        //background-color: white;
        z-index: 2000;
    }
</style>