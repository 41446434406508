<template>
      <report-window endpoint="/reports/service_qty"
          v-if="ready"
          :fields="fields"
          :title="cardTitle || title"
          :headers="headers"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :slotsFields="[]"
          :totalsOn="totalsOn"
          :totalLabel="totalLabel"
          :allowChart="true"
          :beforeRender="beforeRender"
          :chartOptions="chartOptions"
          :columnsClasses="columnsClasses"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'service-qty',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            columnsClasses: {
                Total: 'text-right'
            },
            ready: false,
            fields: [
                {
                    name: 'FromDate', 
                    editor: 'date', 
                    label: 'From', 
                    required:true
                },
                {
                    name: 'ToDate', 
                    editor: 'date', 
                    label: 'To',
                    required: true
                },
                {
                    name: 'CityId', 
                    label: 'City', 
                    editor: 'select',
                    optionLabels: 'Name', 
                    relation: 'city', 
                    addBlank: true,
                },
                {
                    name: 'SupplierId', 
                    label: 'Supplier', 
                    editor: 'vue-select',
                    optionLabels: 'Name', 
                    relation: 'supplier', 
                    addBlank: true,
                },
                {
                    name: 'View', 
                    label: 'View', 
                    editor: 'select', 
                    required: true, 
                    defValue: '0',
                    options: [
                        {label: 'Quantity', value: '0'},
                        {label: 'Amount', value: '1'},
                        {label: 'Pax Quantity', value: '2'},
                    ]
                },
                { name: 'IncludeFlights', editor: 'checkbox', label: 'Include Flights'},
            ],
            title: 'Booked Services',
            headers: {
            },
            templates: [
                {
                    name: 'Total', callback: (row) => {                        
                        if (this.current.View == '1') {
                            return tools.toNumber(row.Total, 0);
                        } else {
                            return row.Total;
                        }
                    }
                }
            ],
            totalsOn: [],
            totalLabel: ['Service'],
            chartOptions: {
              y: 'Service',
              x: []
            }
        }
    },
    methods: {
        beforeRender (report) {
            for (let column of report.serverColumnsHeader) {
                if (column != 'Service' && column != 'Supplier') {
                    if (this.current.View == '1') {
                        let f = _.find(this.templates, (r) => r.name == column);
                        if (!f) {
                            this.templates.push({
                                name: column, callback: (row) => {
                                    return tools.toNumber(row[column], 0);
                                }
                            })
                        }
                    }
                    this.columnsClasses[column] = 'text-right';
                    this.totalsOn.push(column)
                    this.chartOptions.x.push(column)
                }
            }
            this.totalsOn = Object.assign([], this.totalsOn);
            this.templates = Object.assign([], this.templates);
            this.columnsClasses = Object.assign({}, this.columnsClasses);
        },
    }

}
</script>

