<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width="444.000000pt" height="390.000000pt" viewBox="0 0 444.000000 390.000000"
   preserveAspectRatio="xMidYMid meet" fill="none" class="memo-info-svg svg-inline--fa fa-ad fa-w-16">
    <g transform="translate(0.000000,390.000000) scale(0.100000,-0.100000)" fill="currentColor" fill-rule="evenodd">
      <path d="M855 3596 c-139 -31 -255 -142 -296 -281 -21 -75 -21 -2535 0 -2610 30 -102 98 -189 189 -240 95 -54 108 -55 1004 -55 463 0 829 4 832 9 3 5 -11 20 -32 34 -20 15 -66 56 -102 92 l-64 65 -749 0 c-722 0 -749 1 -786 20 -48 24 -87 70 -101 118 -14 54 -14 2473 1 2527 14 51 73 110 124 124 54 15 1674 15 1727 1 48 -14 94 -53 118 -101 19 -36 20 -62 20 -524 l0 -485 48 23 c26 14 71 30 100 38 l52 13 0 430 c0 468 -5 520 -55 608 -48 86 -124 148 -230 187 -57 21 -1707 27 -1800 7z"/>
      <path d="M994 2796 c-68 -30 -67 -143 2 -172 50 -21 1438 -21 1488 0 70 29 70 143 0 172 -49 20 -1443 20 -1490 0z"/>
      <path d="M994 2196 c-68 -30 -67 -143 2 -172 49 -21 1239 -21 1288 0 70 29 70 143 0 172 -49 20 -1243 20 -1290 0z"/>
      <path d="M3121 2200 c-381 -53 -689 -342 -762 -715 -17 -90 -17 -260 0 -350 43 -222 167 -413 354 -552 173 -127 409 -189 622 -164 174 21 314 76 451 180 165 124 285 312 331 516 20 89 22 279 4 370 -67 340 -322 607 -664 695 -91 23 -247 32 -336 20z m320 -219 c136 -43 260 -130 353 -245 194 -241 194 -611 0 -852 -143 -178 -338 -274 -554 -274 -256 0 -486 137 -613 366 -83 150 -109 333 -68 494 65 257 256 451 511 520 100 27 273 23 371 -9z"/>
      <path d="M3196 1800 c-106 -32 -136 -174 -52 -248 59 -52 141 -50 198 5 79 76 44 213 -63 243 -39 11 -44 11 -83 0z"/>
      <path d="M3093 1395 c-71 -31 -64 -150 11 -175 l36 -12 0 -98 0 -98 -36 -12 c-76 -26 -82 -145 -9 -176 47 -19 243 -19 290 0 73 31 67 150 -9 176 l-36 12 0 152 c0 178 -8 212 -56 232 -42 18 -149 17 -191 -1z"/>
      <path d="M994 1596 c-68 -30 -67 -143 2 -172 49 -21 839 -21 888 0 70 29 70 143 0 172 -48 20 -844 20 -890 0z"/>
    </g>
  </svg>
</template>

<script>
export default {
    name: 'memo-info',
}
</script>

<style scoped>
  .memo-info-svg {
      max-height: 20px;
      width: auto;
  }
</style>