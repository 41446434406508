<template>
    <div>
      <p v-for="text in getTextRows" v-if="tag=='p'">
        {{text}}
      </p>

    </div>
</template>

<script>
export default {
    name: 'text-rows',
    props: ['tag', 'text'],
    computed: {
        getTextRows () {
            let res = [];
            if (!this.text) return;
            return this.text.split('\n');
        }
    }
}
</script>
