<template>
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ad" role="img" xmlns="http://www.w3.org/2000/svg" width="430.000000pt" height="420.000000pt" viewBox="0 0 430.000000 420.000000">
        <g transform="translate(0.000000,420.000000) scale(0.100000,-0.100000)" fill="curretColor">
          <path d="M1205 4100 c-44 -10 -74 -31 -98 -70 -21 -33 -22 -47 -25 -295 -3 -254 -2 -261 20 -304 30 -58 65 -83 125 -88 59 -5 108 18 140 66 23 33 23 40 23 311 0 271 -1 278 -23 310 -37 56 -103 84 -162 70z"/>
          <path d="M3037 4095 c-21 -7 -47 -21 -59 -32 -46 -41 -48 -57 -48 -346 0 -268 1 -275 23 -307 33 -49 82 -72 140 -67 60 5 95 30 125 88 22 43 23 50 20 304 -3 239 -5 263 -23 293 -37 62 -112 90 -178 67z"/>
          <path d="M525 3788 c-179 -34 -326 -193 -355 -383 -7 -48 -10 -522 -8 -1495 l3 -1425 23 -59 c62 -165 172 -263 337 -301 56 -13 269 -15 1667 -13 l1603 3 57 22 c108 41 220 140 259 229 49 111 49 83 49 1584 0 855 -4 1428 -10 1464 -23 140 -118 270 -244 331 -85 42 -178 55 -377 55 l-137 0 -4 -197 c-4 -224 -9 -242 -79 -318 -67 -73 -127 -100 -224 -100 -68 0 -89 4 -137 28 -60 30 -115 85 -143 144 -24 51 -35 139 -35 301 l0 142 -609 0 -609 0 -4 -187 c-6 -211 -15 -253 -74 -321 -20 -24 -62 -55 -101 -74 -56 -27 -77 -32 -137 -32 -121 0 -219 63 -273 172 -27 55 -28 63 -31 250 l-4 192 -176 -1 c-98 -1 -199 -6 -227 -11z m3323 -2160 c-3 -1079 -3 -1093 -23 -1128 -12 -19 -36 -43 -55 -55 -35 -20 -44 -20 -1617 -20 l-1582 0 -27 21 c-15 11 -37 33 -48 48 -21 2 -21 34 -24 1127 l-2 1099 1690 0 1690 0 -2 -1092z"/>
          <path d="M2095 2111 l-280 -97 -3 -112 c-1 -62 1 -112 5 -112 4 0 69 18 14 40 74 22 136 40 137 40 2 0 4 -213 5 -472 l3 -473 148 -3 147 -3 0 646 c0 51 -3 645 -12 644 -7 0 -139 -44 -293 -98z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'day-one',
}
</script>
