<template>
   <div class="form-group" :class="'col-md-' + cols">
      <div class="label" v-if="label">{{tr(label)}}
        <span v-if="required">*</span>
      </div>
      <input
        maxlength="150"
        class="form-control"
        type="text"
        :class="(!value && invalid && required)? 'is-invalid': ''"
        :required="required"
        v-model="value"
        @change="change"
   ></div>

</template>

<script>
export default {
    name: 'form-div-input',
    props: ['fieldName', 'label', 'v', 'cols', 'langCode', 'invalid', '_required'],
    data () {
        return {
            value: null,
        }
    },
    mounted () {
        this.value = this.v;
    },
    computed: {
        required () {
            if (this._required) return true;
            return this.$parent.isRequired(this.fieldName);
        },
    },
    watch: { v () { this.value = this.v;}},
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        change () {
            this.$emit('update:v', this.value);
            this.$emit('change');
        },
    }
};
</script>

