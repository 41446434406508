<template>
      <header id="header" class="header fixed-top" v-if="fullScreen || view">
        <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a class="logo d-flex align-items-center">
            <async-img :url="imageAgency" class="logo-back h-100" v-if="imageAgency" ></async-img>
            <img src="@/extra/custom/img/logo-itinerary.png" alt="" class="h-100" v-else>
          </a>
          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link cursor-pointer" @click="scrollTo('trip')">{{tr('Travel Details')}}</a></li>
              <li><a class="nav-link cursor-pointer" @click="scrollTo('blog')">{{tr('Itinerary')}}</a></li>
              <li><a class="nav-link cursor-pointer" @click="scrollTo('pricing')">{{tr('Price')}}</a></li>
              <li><a class="getstarted cursor-pointer" @click="print">{{tr('Print')}}</a></li>
              <li v-if="showWord">
                  <a class="getstarted cursor-pointer" @click="getWord" v-if="!processing">{{tr('_Word')}}</a>
                  <a class="getstarted cursor-pointer" v-else>
                      <img class="processing-gif" src="@/img/loading.gif">
                  </a>
              </li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
</template>

<script>
import { mapState } from 'vuex';
import { saveAs } from 'file-saver';
import { Packer } from "docx";
export default {
    name: 'itinerary-header-bar',
    props: [ 'view'],
    data () {
        return {
            expanded: false,
            counter: 0,
            intervalDocx: null,
            showWord: false,
            processing: false,
        }
    },
    mounted () {
    },
    computed:{
        ...mapState({
            fullScreen: state => state.main.fullScreen,
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        imageAgency () {
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.Image) {
                return this.recordStore.Agency.Image.url;
            }
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        async print () {
            this.$emit('print');
        },
        scrollTo (id) {
            frontTools.scrollTo(id, -70);
        },
        async getDocx () {
            let docx = vueTools.importModule('docx', 'tools').default;
            this.doc = new docx(this.recordStore);
            let r = await this.doc.create();

            let fileName = this.recordStore.Title + ".docx"
            Packer.toBlob(r).then(blob => {
              saveAs(blob, fileName);
            });
            this.processing = false;
            clearInterval(this.intervalDocx);
        },
        getWord  () {
            this.processing = true;
            this.getDocx();
            let lastDay;
            let daySeconds;
            let limitTime = (this.recordStore.BookingDays.length * 3 + 10) * 1000;
            this.counter = 0;
            setTimeout(() => {
                if (this.$root.$children[0].$refs.blockUI) {
                    this.$root.$children[0].$refs.blockUI.requestTimeout = limitTime;
                }
            }, 1000);
            this.intervalDocx = setInterval(() => {
                this.counter += 1;
                this.$root.$children[0].loadingMessage = this.doc.message;
                if (this.doc.dayNr ==  lastDay) {
                    daySeconds += 1;
                } else {
                    daySeconds = 0;
                }
                lastDay = this.doc.dayNr;
                if (this.counter > limitTime || daySeconds > 20) {
                    this.counter = 0;
                    api.setProcessing(false);
                    let e = tr('Cannot get documment');
                    alert(e);
                    clearInterval(this.intervalDocx)
                }
            }, 1000);
        },
   },
   beforeDestroy() {
      clearInterval(this.intervalDocx);
  },

}
</script>

<style scoped>
.processing-gif {
    max-width: 20px;
}
</style>
