<template>
  <div class="modal fade printable" id="booking-form-view" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-large" role="document">
      <div class="modal-content">
        <div class="modal-body booking-form-print" id="print-content" v-if="bookingForm && ready">
            <div class="text-uppercase">
                <h6><b>{{tr('Information')}}</b></h6>
            </div>
            <hr class="hr-row mt-0 mb-1">
            <div class="row mb-3">
                <div v-for="block of fieldsBlocks" class="col-6">
                    <div v-for="field of block">
                        <label><b>{{tr(field.label)}}:</b> {{bookingForm[field.name]}}</label>
                    </div>
                </div>
            </div>
            <div class="text-uppercase">
                <h6><b>{{tr('Passengers')}}</b></h6>
            </div>
            <hr class="hr-row mt-0  mb-1">
            <div v-for="pax of bookingForm.BookingFormPaxs" class="row mb-3">
                <div v-for="field of paxRowFields.rowFields" class="col-6" v-if="!field.hidden">
                    <label v-if="field.editor=='date'"><b>{{tr(field.label)}}:</b> <span>{{pax[field.name] | toDate }}</span></label>
                    <label v-else><b>{{tr(field.label)}}:</b> <span>{{pax[field.name]}}</span></label>
                </div>
            </div>
            <div class="text-uppercase">
                <h6><b>{{tr('Flights')}}</b></h6>
            </div>
            <hr class="hr-row mt-0  mb-1">
            <div v-for="flight of bookingForm.BookingFormFlights" class="mb-3">
                <div class="row" v-if="flight.FlightNumber || flight.FlightDate">
                    <div class="col-12"><b>{{tr(flightOptions[flight.FlightType])}}</b></div>
                    <div class="col-12" v-if="flight.FlightNumber"><b>{{tr('Number')}}:</b> <span>{{flight.FlightNumber}}</span></div>
                    <div class="col-6" v-if="flight.FlightDate"><b>{{tr('Date')}}:</b> <span>{{flight.FlightDate | toDate }}</span></div>
                    <div class="col-6" v-if="flight.FlightDate"><b>{{tr('Time')}}:</b> <span>{{flight.FlightDate | toHourMinute }}</span></div>
                    <div class="col-6" v-if="flight.ArrivalCity && flight.FlightType!='DEPARTURE_FLIGHT'">
                        <b>{{tr('Arrival City')}}:</b> <span>{{flight.ArrivalCity}}</span>
                    </div>
                    <div class="col-6" v-if="flight.DepartureCity && flight.FlightType!='ENTRY_FLIGHT'">
                        <b>{{tr('Departure City')}}:</b> <span>{{flight.DepartureCity}}</span>
                    </div>
                </div>
            </div>
            <hr class="hr-row  mt-0  mb-1">
            <div class="attach-list">
                <attach :dataList.sync="attachList" :attached.sync="attached"></attach>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="print">{{tr('Print')}}</button>
            <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'booking-form-view',
    mixins: [escape],
    props: ['id', 'hash', 'show', 'bookingId'],
    data () {
        return {
            bookingForm: null,
            attachList: [],
            attached: null,
            fields: [],
            fieldsBlocks: [],
            ready: false,
            flightOptions: {
                ENTRY_FLIGHT: 'Entry Flight',
                DEPARTURE_FLIGHT: 'Departure Flight',
                DOMESTIC: "Domestic"
            }
        }
    },
    async mounted () {
        $('#booking-form-view').modal({backdrop: 'static', keyboard: false}, 'show');
        let record;
        if (this.id) {
            record = await api.get('/api/bookingform/' + this.id);
        } else {
            record = await api.get('/api/bookingform/', {filters: JSON.stringify({Hash: this.hash, BookingId: parseInt(this.bookingId)})});
            if (record) record = record[0]
        }
        if (record) {
            let model = api.importMixinModule('bookingform', 'model');
            this.bookingForm = await new model({record: record, notFieldOptions: true});
            let filterFields = _.filter(this.bookingForm.fields, (r) => {
                if (r.name == 'Closed') return false;
                if (r.name == 'id') return false;
                if (Array.isArray(r.editor)) return false;
                if (r.editor == 'attach') return false;
                if (r.hidden) return false;
                return true;
            });
            let blocks = parseInt((filterFields.length / 2) + 0.5)
            this.fieldsBlocks = _.chunk(filterFields, blocks);
            this.fields = this.bookingForm.fields;
            if (this.bookingForm.id) {
                let res = await api.get('/api/get_attach_list/bookingform/' + this.bookingForm.id);
                if (res) {
                    this.attached = res;
                }
            }
        }
        this.ready = true;
    },
    computed: {
        paxRowFields () {
            return _.find(this.fields, (r) => r.name == 'BookingFormPaxs');
        },
        flightRowFields () {
            return _.find(this.fields, (r) => r.name == 'BookingFormFlights');
        }
    },
    methods: {
        print () {
            window.print();
        },
        close () {
            this.$emit('update:show', false);
            $('#booking-form-view').modal('hide');
        },
    },
}
</script>

