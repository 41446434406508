<template>
    <article :id="'day-' + day.DayNr" class="entry" v-if="!recordStore.skipDays[day.DayNr]">
      <div class="row">
        <div class="icon not-break-inside day-number print-hide">
          <h4 class="d-flex justify-content-center">{{getDaysNumbers}}</h4>
        </div>
          <day-images
            v-if="dayImageList && dayImageList.length>0"
            imgClass="img-fluid big-img"
            :images="dayImageList"
            :day="day"
            :crop="crop"
            class="col-md-6 col-sm-12 print-col-4 day-images"
        ></day-images>
        <div class="col-sm-12"
            :class="{'col-md-6 print-col-8': dayImageList && dayImageList.length > 0}">
          <itinerary-body-day-title
              :day="day"
          ></itinerary-body-day-title>
          <div class="entry-content">
            <itinerary-body-service
                v-for="(s, i) of day.BookingDayServices"
                :key="day.DayNr + '-' + i"
                :s="s"
                :i="i"
                :day="day"
                :canEdit="canEdit"
                :readMore="readMore"
                :limit="limit"
                :textLength="showTextMode[i]"
                :id="'day-service' + (s.id? s.id: s.tempId)">
            </itinerary-body-service>
            <div class="read-more print-hide">
              <a class="cursor-pointer"  @click="readMore = !readMore" v-if="acum > limit && !readMore">
                {{tr('See More')}} <i class="ri-arrow-right-s-line"></i>
              </a>
              <a class="cursor-pointer"  @click="readMore = !readMore" v-if="acum > limit && readMore">
                {{tr('See Less')}} <i class="ri-arrow-left-s-line"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="print-row mt-4">
          <itinerary-body-day-types
              :day="day"
          ></itinerary-body-day-types>
          <itinerary-body-hotel
              v-if="day.DayNr < recordStore.daysLength - 1 && !day.ifHotel"
              :day.sync="day"
              :crop="crop"
              class="col-lg-4 pl-0"
          ></itinerary-body-hotel>
      </div>
      <div class="print-row" v-if="day.ifHotel">
          <itinerary-body-hotel-text
              :day.sync="day"
              :crop="crop"
          ></itinerary-body-hotel-text>
      </div>

    </article>
</template>

<script>
const itineraryBodyHotelText = importVueComp('components/itinerary', 'ItineraryBodyHotelText');
const itineraryBodyHotel = importVueComp('components/itinerary', 'ItineraryBodyHotel');
const itineraryBodyService = importVueComp('components/itinerary', 'ItineraryBodyService');
const itineraryBodyDayTitle = importVueComp('components/itinerary', 'ItineraryBodyDayTitle');
const itineraryBodyDayTypes = importVueComp('components/itinerary', 'ItineraryBodyDayTypes');
const optionalServices = importVueComp('components/itinerary', 'ItineraryBodyOptional');
const dayImages = importVueComp('components/itinerary', 'DayImages');

import { mapState } from "vuex";

export default {
    name: 'itinerary-body-day',
    props: ['crop', 'day', 'canEdit', 'expanded'],
    components: {
      'itinerary-body-hotel': itineraryBodyHotel,
      'itinerary-body-hotel-text': itineraryBodyHotelText,
      'itinerary-body-service': itineraryBodyService,
      'itinerary-body-day-title': itineraryBodyDayTitle,
      'itinerary-body-day-types': itineraryBodyDayTypes,
      'itinerary-body-optionals': optionalServices,
      'day-images': dayImages,
    },
    data () {
        return {
            readMore: false,
            limit: 500,
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        showTextMode () {
            let res = {};
            let acum = 0;
            for (let i in this.day.BookingDayServices) {
                let s = this.day.BookingDayServices[i];
                let start = acum;
                let length = 0;
                if (s.Description) {
                    acum += s.Description.length;
                    length = s.Description.length;
                }
                res[i] = {start: start, length: length, acum: acum};
            }
            return res;
        },
        acum () {
            let res = 0;
            for (let i in this.day.BookingDayServices) {
                let s = this.day.BookingDayServices[i];
                if (s.Description) res += s.Description.length;
            }
            return res;
        },
        getDaysNumbers () {
            if (this.day.GroupDays && this.day.GroupDayTo) {
                return `${this.tr('_From')} ${this.tr('Day')} ${this.day.DayNr + 1} ${this.tr('_TO')} ${this.day.GroupDayTo}`;
            }
            return `${this.tr('Day')} ${this.day.DayNr + 1}`
        },

    },
    asyncComputed: {
        async dayImageList () {
            let res = [];
            for (let image of this.day.imagesServices) {
                let imageSRC = await api.getImage(image.url);
                res.push({id: image.id, src: imageSRC, thumbnail: imageSRC});
            }
            return res;
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getServiceTitle (s) {
            return s.title;
        },
        ifText (s) {
            return tools.ifText(s);
        },
        getCitiesNames (day) {
            return day.getCitiesNames(this.recordStore.hotelsByDay[day.DayNr]);
        },
        getDayMonth (day) {
            return tools.toTitleCase(moment(this.recordStore.StartDate).add(day.DayNr,'days').locale(this.recordStore.Language.Code).format("MMM"));
        },
        getDayNr (day) {
            return moment(this.recordStore.StartDate).add(day.DayNr,'days').format("DD");
        },
   },
   watch: {
      expanded () {
          if (this.expanded) {
              this.readMore = true;
          }
      }
   }
}
</script>


