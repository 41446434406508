<template>
    <div v-if="user">
      <dashboard :cards="getDashboardCards"></dashboard>
    </div>
</template>

<script>
import dashboard from '@/components/tools/Dashboard';

import { mapState } from 'vuex';
export default {
    name: 'home',
    components: {
        dashboard
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        getDashboardCards () {
            let res = [];
            let p = {
                hideFilters: true,
                runMounted: true,
                cardView: true,
                reportViewClass: 'card-report-view',
                notFilterable: true,
                perPage: 8,
                hideSearch: true,
                SalesUserId: this.user.id,
            }
            //res.push({component: 'ReceiptPendingReport', folder: 'components/reports', columns: 6, props: {options: p}});
            //res.push({component: 'BookingsSummaryReport', folder: 'components/reports', columns: 6, props: p});

            let dashboard = localStorage.getItem('dashboard');
            if (dashboard) {
                dashboard = JSON.parse(dashboard);
                for (let c of dashboard) {
                    if (!c) continue;
                    res.push(c);
                }
            }
            return res;
        },
    },
}
</script>

