<template>
    <div class="modal fade bd-example-modal-lg" id="booking-views" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="templateBookingViews" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
            <booking-views-report
                v-if="recordStore && ready"
                :options="options"
            ></booking-views-report>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
const bookingViewsReport = importVueComp('components/reports', 'BookingViewsReport');
import { mapState } from 'vuex';
export default {
    name: 'booking-views',
    props: ['show'],
    mixins: [escape],
    components: {
      'booking-views-report': bookingViewsReport
    },
    data () {
        return {
            options: {
                hideFilters: true,
                runMounted: true,
                cardView: true,
                reportViewClass: 'card-report-view',
                notFilterable: true,
                perPage: 8,
                hideSearch: true,
            },
            ready: false,
        }
    },
    mounted () {
        $('#booking-views').modal({backdrop: 'static', keyboard: false}, 'show');
        this.options.BookingId = this.recordStore.id;
        this.options.SalesUserId = this.user.id;
        this.ready = true;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            user: state => state.main.user,
        }),
    },
    methods: {
        close () {
            this.$emit('update:show', false);
            $('#booking-views').modal('hide');
        }
    },
}
</script>
