import abm from "@/components/tools/abm";
import { mapState } from "vuex";
let SupplierAll = importMixinComponent('extra/taxes', 'SupplierAll');
export default {
    name: "supplier-all",
    template: `
        <abm v-if="user" endpoint="/api/supplier/" :options="options" tablename="supplier"></abm>
    `,
    mixins: [SupplierAll],
    data() {
        let d = SupplierAll.data();
        let i = _.findIndex(d.options.fields, (r) => r.name == 'PaymentCondition');
        let f1 = { name: "TaxNumber", label: "CUIT", columns: 3 };
        let f2 = { name: 'TaxCodeId', relation: 'taxcode', optionLabels: 'Name', hideFromList: true
                        , editor: 'select', label: 'Impuesto', addBlank: true, columns: 3};
        d.options.fields.splice(i+1, 0, f1);
        d.options.fields.splice(i+2, 0, f2);
        return d;
    },
};
