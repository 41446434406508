<template>
    <div>
    </div>
</template>

<script>

export default {
    name: 'logout',
    data () {
        return {
        }
    },
    async mounted () {
        api.setProcessing(true);
        let res = await api.get("/logout");
        if (res) {
            localStorage.setItem('notifications', JSON.stringify([]))
            await api.logout();
            api.setProcessing(false);
            this.$router.push("/login");
        }
    },
}
</script>

