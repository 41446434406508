<template>
    <div>
        <div class="entry-meta icon-box not-break-inside col-lg-4 pl-0" v-if="getMeals(day.DayNr)">
          <i class="ri-restaurant-fill"></i>
          <div>
            <h4>{{tr('Meals Included')}}</h4>
            <p>{{getMeals(day.DayNr)}}</p>
          </div>
        </div>
    </div>
</template>

<script>

import { mapState } from "vuex";
export default {
    name: 'itinerary-body-day-types',
    props: ['day'],
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        trList (list) {
            let res = [];
            for (let t of list) {
                res.push(this.tr(t));
            }
            return res.join(', ');
        },
        getMeals (dayNr) {
            let day = _.find(this.recordStore.BookingDays, (d) => d.DayNr == dayNr);
            if (day) {
                let meals = [];
                if (day._breakfast) meals.push(this.tr('Breakfast'));
                if (day._lunch) meals.push(this.tr('Lunch'));
                if (day._snack) meals.push(this.tr('Snack'));
                if (day._boxLunch) meals.push(this.tr('Box Lunch'));
                if (day._dinner) meals.push(this.tr('Dinner'));
                if (meals.length==0) return
                let res = meals.join(', ');
                let lastC = res.lastIndexOf(', ');
                if (lastC==-1) return res
                res = res.substring(0, lastC) + ' & ' + res.substring(lastC+2, res.length);
                return res;
            }
        },
   },
}
</script>


