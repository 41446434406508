<template>
    <div :class="{'row-area-style': !nonClass}" @click="$emit('edit')">
    </div>
</template>

<script>
//import '@/quill/quill.snow.css';
export default {
    name: 'text-rows-style',
    props: ['text', 'nonClass', 'langCode'],
    mounted () {
        if (this.text) {
            if (this.langCode) {
                this.$el.innerHTML = this.text;
                let el = $(this.$el);
                for (let child of el[0].childNodes) {
                    if (child.innerText) {
                        let values = [];
                        let list = child.innerText.split('\n');
                        for (let v of list) {
                              values.push(this.trl(v));
                        }
                        child.innerText = values.join('\n');
                    }
                }
            } else {
                this.$el.innerHTML = this.text.split('\n').join('<br>')
            }
        }
    },
    methods: {
        trl (t) {
            if (!this.langCode) return t;
            return tr(t, this.langCode);
        },

    },
    watch: {
        text (val) {
            if (this.text) this.$el.innerHTML = this.text.split('\n').join('<br>');;
        }
    },
}
</script>

<style scoped>
.row-area-style {

ol {
  padding-left: 1.5em;
}
li {
  list-style-type: none;
  text-align: left;
}
li:before {
  display: inline-block;
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
  white-space: nowrap;
  width: 1.2em;
}
li[data-list=checked],
li[data-list=unchecked] {
  pointer-events: none;
}
li[data-list=checked] *,
li[data-list=unchecked] * {
  pointer-events: all;
}
li[data-list=checked]:before,
li[data-list=unchecked]:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
li[data-list=bullet]:before {
  content: '\2022';
  font-weight: bolder;
}
li[data-list=checked]:before {
  content: '\2611';
}
li[data-list=unchecked]:before {
  content: '\2610';
}
li[data-list=ordered] {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
li[data-list=ordered]:before {
  content: counter(list-0, decimal) '. ';
}
}

</style>