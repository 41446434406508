<template>
  <section id="pricing" class="pricing" v-if="recordStore">
    <div class="container">
      <div class="row gy-4" >
          <itinerary-prices
              v-if="!recordStore.HideTotalPrice"
          ></itinerary-prices>
          <div class="col-sm-12 mt-4" :class="flightsCol" v-if="recordStore.flightsListInternal.length">
            <div class="box-header">
              <h3>{{tr('Internal operational flights')}}</h3>
            </div>
            <div class="box">
              <div v-if="gf(recordStore.flightsListInternal[0]).number">
                  <div class="pt-1" v-for="f of recordStore.flightsListInternal" v-if="gf(f).number">
                      <div>{{gf(f).number}}
                        <i class="ri-calendar-2-line ml-2"></i> {{gf(f).date}}
                        <i class="ri-time-line ml-2"></i> {{gf(f).departureTime}} / {{gf(f).arrivalTime}}
                        <i class="ri-map-pin-fill ml-2"></i> {{gf(f).stations}}
                      </div>
                  </div>
              </div>
              <ul v-else>
                <li class="p-2 text-center">
                    <span class="" v-for="(f, fId) of recordStore.flightsListInternal" :key="fId" v-if="f && f.length > 0">
                        {{f}}<br>
                    </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12 mt-4" :class="flightsCol" v-if="recordStore.flightsListInternational.length">
            <div class="box-header">
              <h3>{{tr('International flights')}}</h3>
            </div>
            <div class="box">
              <div v-if="gf(recordStore.flightsListInternational[0]).number">
                  <div class="pt-1" v-for="f of recordStore.flightsListInternational" v-if="gf(f).number">
                      <div>{{gf(f).number}}
                        <i class="ri-calendar-2-line ml-2"></i> {{gf(f).date}}
                        <i class="ri-time-line ml-2"></i> {{gf(f).departureTime}} / {{gf(f).arrivalTime}}
                        <i class="ri-map-pin-fill ml-2"></i> {{gf(f).stations}}
                      </div>
                  </div>
              </div>
              <ul v-else>
                <li  class="p-2 text-center">
                    <span class="ri" v-for="(f, fId) of recordStore.flightsListInternational" :key="fId">
                        {{f}}
                    </span>
                 </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Prices include")}}</h3>
                </div>
                <div class="box-body box-include">
                  <text-rows-style tag="p" class="px-2" :text="recordStore.Include"></text-rows-style>
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Prices DON'T include")}}</h3>
                </div>
                <div class="box-body box-not-include">
                  <text-rows-style tag="p" class="px-2" :text="recordStore.NotInclude"></text-rows-style>
                </div>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 mt-4" v-if="ifText(recordStore.Comment)">
            <div class="box">
              <div class="box-header">
                  <h3>{{tr('Observations')}}</h3>
              </div>
              <div class="box-body">
                  <text-rows-style tag="p" :text="recordStore.Comment"></text-rows-style>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
const itineraryPrices = importVueComp('components/itinerary', 'ItineraryPrices');
import { mapState } from 'vuex';
export default {
    name: 'itinerary-terms',
    props: ['expanded'],
    components: {
      'itinerary-prices': itineraryPrices,
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        flightsCol () {
            if (this.recordStore.flightsListInternal.length && !this.recordStore.flightsListInternational.length) return 'col-lg-12';
            if (!this.recordStore.flightsListInternal.length && this.recordStore.flightsListInternational.length) return 'col-lg-12';
            return 'col-lg-6';
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        ifText (s) {
            return tools.ifText(s);
        },
        gf (f) {
            let res = {};
            let l = f.split(' ');
            if (l.length < 7) return {};
            let l2 = f.split(' ').reverse();
            let l3 = f.split(' ');
            l3.shift();
            l3.shift();
            l3.pop();
            l3.pop()
            l3.join(' ');
            res.number = l[0];
            res.date = l[1];
            res.stations = l3.join(' ')
            res.departureTime = l2[1];
            res.arrivalTime = l2[0];
            return res;
        }

    },
}
</script>


