import recordWindow from '@/components/tools/RecordWindow';
import Vue from 'vue';

var c = Vue.component('mixin-window', {
    extends: recordWindow,
    props: {
        table: {
            type: String,
            default: 'contact'
        },
        id: {
            type: String
        },
    }
});

export default {
    name: 'supplier-contact-window',
    props: ['table', 'id', 'supId', 'parentTable', 'parentRoute'],
    mixins: [c],
    methods: {
        closePath () {
            this.$router.push({ name: 'record', params: { table: this.parentTable, id: this.supId }});
        },
    }
}
