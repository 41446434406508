<script>
let bookingFinalView = importVueComp('components/documentation', 'BookingFinalView', 'custom');
export default {
    name: 'custom-booking-prefinal-view',
    mixins: [BookingFinalView],
    methods: {
        mount: async function() {
            this.$store.commit('setProcessing', true);
            let record = await app.get('/public/api/booking/' + this.hash + '/' + this.id + '/PRE_FINAL');
            if (record) {
                this.$store.commit('setBooking', {booking: record, sumUp: false, skipLocalStorage: true});
                this.$store.commit('setProcessing', false);
            }
        },
    }


}
</script>
