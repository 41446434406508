<template>
    <div>
        <iframe :id="_uid" class="i-frame-viewer"></iframe>
    </div>
</template>

<script>
export default {
    name: 'html-view',
    props: ['value'],
    mounted () {
        this.setHtml();
    },
    watch: {
        value () {
            this.setHtml();
        }
    },
    methods:  {
        setHtml () {
            if (!this.value) return;
            var dstFrame = document.getElementById(this._uid);
            if (!dstFrame.contentDocument && !dstFrame.contentWindow) return;
            var dstDoc = dstFrame.contentDocument || dstFrame.contentWindow.document;
            let t = this.value.split('<p>').join('<div>');
            t = t.split('</p>').join('</div>');
            dstDoc.write(t);
            dstDoc.close()

        }
    }
}
</script>
