<template>
    <report-window
        v-if="ready"
        endpoint="/reports/passengers_list"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :current.sync="current"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :headerColumns="options? options.headerColumns: null"
        :allowChart="allowChart"
        :chartOptions="chartOptions"
        :averageOn="averageOn"
        :totalsOn="totalsOn"
        :averageLabel="averageLabel"
        :columnsClasses="columnsClasses"
    ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow';
import { mapState } from 'vuex';
export default {
    name: 'passengers-list-report',
    props: ['options'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        columnsClasses () {
            return {
                Incomes: 'text-right',
                Cost: 'text-right',
                Profit: 'text-right',
                id: 'cursor-pointer-report',
                AverageDays: 'text-right',
                AverageAge: 'text-right',
                AveragePrice: 'text-right',
                PaxCount: 'text-right',
                PricePerPerson: 'text-right',
                PriceSum: 'text-right',
                BookingCount: 'text-right',
                PricePerDay: 'text-right',
            }
        },
        averageOn () {
            if (!this.current.GroupBy) return ['Age', 'Days', 'PricePerPerson', 'PricePerDay'];
            return ['AveragePrice', 'AverageAge', 'AverageDays', 'PricePerDay'];
        },
        totalsOn () {
            if (this.current.GroupBy) return ['PriceSum', 'PaxCount', 'AgeSum', 'DaysSum', 'BookingCount'];
        },
        averageLabel () {
            if (!this.current.GroupBy) return ['BookingId'];
        },
        chartOptions () {
            if (this.current.GroupBy == '1') {
                return {
                  y: 'PassportCountry',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
            if (this.current.GroupBy == '2') {
                return {
                  y: 'Language',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
            if (this.current.GroupBy == '3') {
                return {
                  y: 'AgeRange',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
            if (this.current.GroupBy == '4') {
                return {
                  y: 'SalesOrigin',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
            if (this.current.GroupBy == '5') {
                return {
                  y: 'PassportCountry,AgeRange',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
            if (this.current.GroupBy == '6') {
                return {
                  y: 'PassportCountry,SalesOrigin',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
            if (this.current.GroupBy == '7') {
                return {
                  y: 'AgeRange,SalesOrigin',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
            if (this.current.GroupBy == '8') {
                return {
                  y: 'Language,SalesOrigin',
                  x: ['AverageDays', 'AverageAge', 'AveragePrice', 'PaxCount', 'PriceSum', 'BookingCount'],
                }
            }
        },
        allowChart () {
            return this.current.GroupBy != null;
        }
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
        let countriesJSON = require(`@/languages/countries/${api.language}/world.json`);
        let values = {};
        let countriesList = [];
        for (let row of countriesJSON) {
            values[row.alpha3] = row.name;
            countriesList.push({label: row.name, value: row.alpha3})
        }
        let field = _.find(this.fields, (r) => r.name == "Country");
        field.options = countriesList;
        this.countries = values;
    },
    data () {
        let self = this;
        return {
            ready: false,
            countries: {},
            current: {},
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "Date Inn From",
                    required: true,
                },
                {
                    name: "ToDate",
                    editor: "date",
                    label: "Date Inn To",
                    required: true,
                },
                {
                    name: "PersonalData",
                    editor: "select",
                    label: "Personal Data",
                    defValue: '0',
                    options: [
                          {value: '0', label: 'NO'},
                          {value: '1', label: 'YES'},
                    ]
                },
                {
                    name: "AgeFrom",
                    editor: "number",
                    label: "Age From",
                },
                {
                    name: "AgeTo",
                    editor: "number",
                    label: "Age To",
                },
                {
                    name: "DaysFrom",
                    editor: "number",
                    label: "Days From",
                },
                {
                    name: "DaysTo",
                    editor: "number",
                    label: "Days To",
                },
                {
                    name: "PriceFrom",
                    editor: "number",
                    label: "Price From",
                },
                {
                    name: "PriceTo",
                    editor: "number",
                    label: "Price To",
                },
                { name: 'WithChildren', label: 'With Children', editor: 'select', defValue: '',
                    options: [
                          {value: '', label: 'All'},
                          {value: '0', label: 'NO'},
                          {value: '1', label: 'YES'},
                    ]
                },
                { name: 'Country', label: 'Country', editor: 'vue-select', addBlank: true,
                    options: []
                },
                {
                    name: "LanguageId",
                    editor: "select",
                    label: "Language",
                    relation: "language",
                    optionLabels: 'Name',
                    addBlank: true
                },
                {
                    name: "SalesOriginId",
                    editor: "select",
                    label: "Sales Origin",
                    relation: "salesorigin",
                    optionLabels: 'Name',
                    addBlank: true
                },
                {
                    name: "CityId",
                    editor: "vue-select",
                    label: "City",
                    relation: "city",
                    optionLabels: 'Name',
                    addBlank: true,
                },
                {
                    name: "CountryId",
                    editor: "select",
                    label: "Destination Country",
                    relation: "country",
                    optionLabels: 'Name',
                    addBlank: true,
                },
                { name: 'GroupBy', label: 'Group by', editor: 'select', addBlank: true, columns: 2,
                    options: [
                        {value: '1', label: 'Country'},
                        {value: '2', label: 'Language'},
                        {value: '3', label: 'Age Range'},
                        {value: '4', label: 'Sales Origin'},
                        {value: '5', label: 'Country/Age Range'},
                        {value: '6', label: 'Country/Sales Origin'},
                        {value: '7', label: 'Age Range/Sales Origin'},
                        {value: '8', label: 'Language/Sales Origin'},
                    ]
                },

            ],
            title: 'Passengers List',
            headers: {
              BookingId: 'File Nr',
              PassportCountry: 'Country',
              PassportValidTo: 'Passport Expiration Date',
              WithChildren: 'With Children',
              PaxCount: 'Pax. Quant.',
              PriceSum: 'Total',
              BookingCount: 'Files'
            },
            templates: [
                {name: 'PricePerPerson', callback: (row) => {
                    return tools.toNumber(row.PricePerPerson, 0);
                  }
                },
                {name: 'PricePerDay', callback: (row) => {
                    if (row.calculated) {
                        return tools.toNumber(row.PriceSum / row.DaysSum, 0);
                    }
                    return tools.toNumber(row.PricePerDay, 0);
                  }
                },
                {name: 'AveragePrice', callback: (row) => {
                    if (row.calculated) {
                        return tools.toNumber(row.PriceSum / row.PaxCount, 0);
                    }
                    return tools.toNumber(row.AveragePrice, 0);
                  }
                },
                {name: 'PriceSum', callback: (row) => {
                    return tools.toNumber(row.PriceSum, 0);
                  }
                },
                {name: 'AverageDays', callback: (row) => {
                    if (row.calculated) {
                        return tools.toNumber(row.DaysSum / row.PaxCount, 1);
                    }
                    return tools.toNumber(row.AverageDays, 1);
                  }
                },
                {name: 'AverageAge', callback: (row) => {
                    if (row.calculated) {
                        return tools.toNumber(row.AgeSum / row.PaxCount, 1);
                    }
                    return tools.toNumber(row.AverageAge, 1);
                  }
                },
                {name: 'StartDate', callback: (row) => {
                    return moment(row.StartDate).format("DD/MM/YYYY")
                  }
                },
                {name: 'BirthDate', callback: (row) => {
                    if (!row.BirthDate) return '';
                    return moment(row.BirthDate).format("DD/MM/YYYY")
                  }
                },
                {name: 'PassportCountry', callback: (row) => {
                    if (!row.PassportCountry) return '';
                    return this.countries[row.PassportCountry]? this.countries[row.PassportCountry]: row.PassportCountry;
                  }
                },
                {name: 'PassportCountry,AgeRange', callback: (row) => {
                    if (!row['PassportCountry,AgeRange']) return '';
                    let value = row['PassportCountry,AgeRange'];
                    return this.countries[value.split(' / ')[0]]  + ' / ' + value.split(' / ')[1];
                  }
                },
                {name: 'PassportCountry,SalesOrigin', callback: (row) => {
                    if (!row['PassportCountry,SalesOrigin']) return '';
                    let value = row['PassportCountry,SalesOrigin'];
                    return this.countries[value.split(' / ')[0]]  + ' / ' + value.split(' / ')[1];
                  }
                },
            ],
        }
    },
    methods: {
    }
}
</script>



