<template>
    <div v-if="recordStore" class="card no-border-top p-3 form-material expenses-tab">
        <div class="form-row">
              <div class="col mb-0 mw-20">
                  <label>{{tr('Concept')}}</label>
              </div>
              <div class="col mb-0 mw-15">
                  <label>{{tr('Payment Type')}}</label>
              </div>
              <div class="col mb-0">
                  <label>{{tr('Payment Date')}}</label>
              </div>
              <div class="col mb-0">
                  <label>{{tr('Amount')}}</label>
              </div>
              <div class="col mb-0">
                  <label>{{tr('Currency')}}</label>
              </div>
              <div class="col mb-0">
                  <label>{{tr('Rate')}}</label>
              </div>
              <div class="col mb-0">
                  <label>{{tr('Status')}}</label>
              </div>
              <div class="col mb-0 mw-15">
                  <label>{{tr('Comment')}}</label>
              </div>
              <div class="col mb-0 mw-5">
              </div>
        </div>

        <div class="form-row" v-for="(row, rowNr) of recordStore.BookingExpenses" v-if="row.Type=='NOT_PAX'">
              <div class="col my-0 mw-20" >
                  <select-input fieldName="ExpenseTypeId"
                  :field="row.fieldsObject.ExpenseTypeId"
                  :disabled="!canEdit(row)"
                  :required="true"
                  :record="row"
                  :currentValue.sync="row.ExpenseTypeId" :id="'BookingExpenses-ExpenseTypeId-' + rowNr">
                  </select-input>
              </div>
              <div class="col my-0 mw-15" >
                  <select-input fieldName="PaymentTypeId"
                  :field="row.fieldsObject.PaymentTypeId"
                  :required="true"
                  :disabled="!canEdit(row)"
                  :currentValue.sync="row.PaymentTypeId" :id="'BookingExpenses-PaymentTypeId-' + rowNr">
                  </select-input>
              </div>
              <div class="col my-0">
                  <date-picker :id="'BookingExpenses-PaymentDate-' + rowNr"
                  :def.sync="row.PaymentDate"
                  :readonly="!canEdit(row)"
                  ></date-picker>
              </div>
              <div class="col my-0">
                  <row-input-field
                    :arrayField="recordStore.fieldsObject.BookingExpenses"
                    :disabled="!canEdit(row)"
                    :getId="true"
                    :rowField="row.fieldsObject.Amount"
                    :defValue.sync="row.Amount"
                    :row="row"
                    :invalid="row.invalid.Amount"
                  ></row-input-field>

                  <!--number-input
                      :v.sync="row.Amount"
                      :currency="row.Currency"
                      :disabled="!canEdit(row)"
                      :id="'BookingExpenses-Amount-' + rowNr"
                  ></number-input-->
              </div>
              <div class="col my-0" >
                  <select-input fieldName="CurrencyId"
                  :field="row.fieldsObject.CurrencyId"
                  :disabled="!canEdit(row)"
                  :currentValue.sync="row.CurrencyId" :id="'BookingExpenses-CurrencyId-' + rowNr">
                  </select-input>
              </div>
              <div class="col my-0">
                  <number-input
                      :v.sync="row.CurrencyRate"
                      :currency="row.Currency"
                      :disabled="!canEdit(row)"
                      :id="'BookingExpenses-CurrencyRate-' + rowNr"
                  ></number-input>
              </div>
              <div class="col my-0">
                  <select-input fieldName="Status"
                    :field="row.fieldsObject.Status"
                    :disabled="!canEdit(row)"
                    :required="true"
                    :currentValue.sync="row.Status" :id="'BookingExpenses-Status-' + rowNr">
                  </select-input>
              </div>
              <div class="col my-0 mw-15">
                  <div class="form-group form-default">
                      <input v-model="row.Comment" class="form-control" type="text"
                      :class="{'fill': row.Comment}"
                      :id="'BookingExpenses-Comment-' + rowNr"
                      :readonly="!canEdit(row)">
                          <span class="form-bar"></span>
                      </input>
                  </div>
              </div>
              <div class="col my-0 mw-5" v-if="canEdit(row)">
                 <i class="add-item cursor-pointer" @click="removeRow(rowNr)">
                    <font-awesome-icon icon="trash-alt" size="lg"/>
                 </i>
              </div>
        </div>
        <i class="add-item cursor-pointer" @click="addLine">
            <font-awesome-icon icon="plus-circle" size="lg"/>
        </i>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-expenses',
    props: ['fields'],
    computed: {
        ...mapState({
            user: state => state.main.user,
            adminActions: state => state.main.adminActions,
            recordStore: state => state.main.record,
        }),
        canDelete () {
            return tools.canAccess(api.currentUser, 'api', 'DELETE', '/bookingpexpense/', false);
        },
        canEditExpenses () {
            return tools.canAccess(api.currentUser, 'api', 'POST', '/bookingpexpense/', false);
        }
    },
    methods: {
        canEdit (row) {
            if (this.adminActions) return true;
            if (this.canEditExpenses) return true;
            if (!row.id) return true;
            //if (!this.canDelete) return false;
            return true;
        },
        async addLine (){
            await vueTools.addRow(this, null, 'BookingExpenses', {ExpenseType: {BookingExpense: true}, Type: 'NOT_PAX'});
        },
        async removeRow (rowNr) {
            await vueTools.removeRow(this, null, 'BookingExpenses', rowNr);
        },

    },
}
</script>

<style>
    .expenses-tab .form-group {
        margin-top: 0px !important;
    }
</style>