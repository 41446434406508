<template>
    <div>

      <report-window endpoint="/admin/reports/paymentlist"
      v-if="ready"
      :fields="fields" :title="title" :headers="headers"
      :templates="templates"
      :perPage="(options && options.perPage)? options.perPage: 500"
      :hideFilters="options && options.hideFilters"
      :runMounted="options && options.runMounted"
      :cardView="options && options.cardView"
      :columnsClasses="columnsClasses"
      :notFilterable="options && options.notFilterable"
      :slotsFields="this.current.Format=='0'? slotsFields: []"
      :afterRun="afterRun"
      :callbacks="callbacks"
      :columnClick="columnClick"
      :buttons="buttons"
      :headerColumns="headerColumns"
      :hideFilteredFields="true"
      :current.sync="current"
      :totalsOn="totalsOn"
      :fieldClasses="fieldClasses"
      @afterEdit="afterEdit"
    ></report-window>

    <select-value
        v-if="selectInvoice"
        @save="setInvoiceToPayments"
        @close="closeSelectInvoice"
        text="Select Purchase Invoice"
        :fieldOptions="selectInvoice">
    </select-value>
    <pay-modal
        v-if="paidInput"
        :show.sync="paidInput"
        @save="pay"
    ></pay-modal>
    </div>
</template>

<script>
const payModal = importVueComp('components/reports', 'PaymentPayModal');
import ReportWindow from '@/components/tools/ReportWindow';
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
let bookingView = api.importMixinModule('booking', 'views');
export default {
    name: 'payment-list-report',
    props: ['options'],
    components: {
        ReportWindow,
        'pay-modal': payModal
    },
    computed: {
        ...mapState({
            me: state => state.main.me,
            adminActions: state => state.main.adminActions,
        }),
        columnsClasses () {
            let res = {};
            for (let field of this.slotsFields) {
                res[field.name] = 'table-td-input';
                if (field.editor=='date') res[field.name] += ' table-date';
                if (field.editor=='number') res[field.name] += ' table-number';
            }
            res.Amount = 'width-200 text-right';
            res.PaxName = 'width-200';
            res.CurrencyRate = 'width-200';
            res.PaymentAmount = 'width-200';
            res.PaymentTypeId = 'width-200';
            res.SupplierCreditId = 'width-200';
            res.InvoiceStatus = 'width-200';
            res.PaymentStatus = 'width-200';
            res.PaymentDate = 'width-200';
            res.Comment = 'width-200';
            res.SupplierId = 'width-200';
            res.BookingId = 'width-150 cursor-pointer-report';
            return res;
        },
        totalsOn () {
            if (this.current.SummaryRow!='1') return;
            if (this.current.PaymentCurrencyId && this.current.CurrencyId) return ['Amount', 'PaymentAmount'];
            if (this.current.CurrencyId) return ['Amount'];
            if (this.current.PaymentCurrencyId) return [ 'PaymentAmount'];
        },
    },
    async mounted () {
      this.paymentTypes = await api.getTable('paymenttype');
      let creditPayment = _.find(this.paymentTypes, (r) => r.OnAccount == true);
      for (let f of this.slotsFields) {
          if (f.name!='Copy' && f.name!='Edit' && f.name!='Selected' && f.name!='Divide') {
              this.callbacks[f.name] = this.editField;
          }
          f.getDisabled = function(row) {
              if (!row.id) return true;
              if (f.name=='SupplierCreditId') {
                  if (creditPayment && creditPayment.id != row.PaymentTypeId) return true;
              }
          }
      }
      frontTools.setReportValues(this);
      this.ready = true;
    },
    data () {
        return this.getData(this);
    },
    methods: {
        getData (self) {
            let bookingPaymentView = api.importMixinModule('bookingpayment', 'views');
            return {
                notifications: [],
                paidInput: false,
                fieldClasses: {
                    SupplierId: null,
                    DueDate: (row) => {
                        return self.getFieldStyle(row);
                    },
                },
                selectInvoice: null,
                selected: [],
                ready: false,
                report: null,
                current: {},
                paymentTypes: null,
                lastSupplierId: null,
                creditOptions: {},
                headerColumns: ['BookingId', 'DueDate', 'DateIn', 'PaxName','SupplierId', 'Comment',
                           'Amount', 'CurrencyId', 'PaymentTypeId', 'PaymentStatus', 'PaymentCurrencyId', 'SupplierCreditId', 'InvoiceStatus',
                           'PaymentDate', 'PaymentAmount', 'PaymentCurrencyId', 'CurrencyRate', 'PaymentStatus',
                           'SupplierPaymentType', 'ResNumber', 'OperUserId', 'Selected', 'Copy', 'Divide', 'Edit'],
                fields: [
                  {name: 'FromDate', editor: 'date', label: 'From', requiredCallback: (t, r) => {return !r.BookingId}},
                  {name: 'ToDate', editor: 'date', label: 'To', requiredCallback: (t, r) => {return !r.BookingId}},
                  {name: 'FilterDate', label: 'Filter Date by', editor: 'select', required: true, defValue: '0',
                      options: [
                          {value: '0', label: 'Due Date'},
                          {value: '1', label: 'Date Inn'},
                          {value: '2', label: 'Payment Date'}
                      ]
                  },
                  {name: 'PaymentStatus', label: 'Payment Status', editor: 'select', addBlank: true, defValue: 'PENDING',
                      options: bookingPaymentView.paymentStatusOptions()},
                  {name: 'SupplierId', relation: 'supplier', optionLabels: 'Name', list: true
                      , label: 'Supplier', addBlank: true, editor: 'vue-select'},
                  {name: 'CurrencyId', relation: 'currency', optionLabels: 'id'
                        , editor: 'select', label: 'Currency', addBlank: true},
                  {name: 'PaymentCurrencyId', relation: 'currency', optionLabels: 'id'
                        , editor: 'select', label: 'Payment Currency', addBlank: true},
                  {name: 'BookingId', label: 'File', list: true},
                  {name: 'CountryId', relation: 'country', optionLabels: 'Name'
                      , label: 'Country', addBlank: true, editor: 'select'},
                  {name: 'SupplierType', label: 'Supplier Type', editor: 'select', addBlank: true, defValue: '0',
                      options: [
                          {value: '0', label: 'All'},
                          {value: '1', label: 'Services/Hotels'},
                          {value: '2', label: 'Services'},
                          {value: '3', label: 'Hotels'},
                          {value: '4', label: 'Flights'},
                      ]
                  },
                  {name: 'Importance', label: 'Importance', editor: 'select', addBlank: true,
                      options: [
                          {value: '1', label: 'Red'},
                          {value: '2', label: 'Yellow'},
                          {value: '3', label: 'Green'},
                        ]
                  },
                  {name: 'SupplierPaymentTypeId', relation: 'paymenttype', optionLabels: 'Name'
                      , editor: 'select', label: 'Supplier Payment Type', addBlank: true},
                  {name: 'PaymentTypeId', relation: 'paymenttype', optionLabels: 'Name'
                      , editor: 'select', label: 'Used Payment Type', addBlank: true},
                  {name: 'PaymentConditionId', relation: 'paymentcondition', optionLabels: 'Name'
                      , editor: 'select', label: 'Payment Condition', addBlank: true},
                  {name: 'InvoiceStatus', label: 'Invoice', editor: 'select', addBlank: true,
                      options: [
                          {value: 'PENDING', label: 'Pending'},
                          {value: 'RECEIVED', label: 'Received'},
                          {value: 'CONFIRMED', label: 'Confirmed'},
                          {value: 'NOT_APPLY', label: 'Not Apply'},
                      ], columns: 6
                  },
                  {name: 'SummaryRow', label: 'Foot Summary', editor: 'select', defValue: '1',
                      options: [
                          {value: '0', label: 'NO'},
                          {value: '1', label: 'YES'},
                      ]
                  },
                  {name: 'Format', label: 'Format', editor: 'select', defValue: '0',
                      options: [
                          {value: '0', label: 'Editable'},
                          {value: '1', label: 'Excel'},
                      ]
                  },

                ],
                title: 'Administrate Payments',
                headers: {
                    id: 'Payment Number',
                    PaymentDate: 'Date',
                    Amount: 'To Pay',
                    PaymentAmount: 'Paid Amount',
                    PaxName: 'Passenger',
                    BookingId: 'File Nr',
                    Comment: 'Notes',
                    SupplierId: 'Supplier',
                    CurrencyId: 'Currency',
                    PaymentCurrencyId: 'Payment Currency',
                    PaymentTypeId: 'Payment Type',
                    SupplierCreditId: 'Credit Number',
                    Selected: '',
                    Copy: 'Copy',
                    Divide: 'Divide',
                    DateIn: 'Date Inn',
                    InvoiceStatus: 'Invoice',
                    ResNumber: 'Reservation Number',
                    OperUserId: 'Operations',
                    Selected: 'Select',
                },
                slotsFields: [
                    {name: 'Comment', editor: 'text', hideLabel: true, classes: 'mb-0'},
                    {name: 'Copy', editor: 'button', label: 'Copy', icon: 'copy', classes: 'mb-0'},
                    {name: 'Divide', editor: 'button', label: 'Create Balance', icon: 'divide', classes: 'mb-0'},
                    {name: 'Edit', editor: 'button', label: 'Edit', icon: 'pen', classes: 'mb-0'},
                    {name: 'DueDate', editor: 'date', classes: 'mb-0'},
                    {name: 'PaymentDate', editor: 'date', classes: 'mb-0', invalidIf: self.invalidIf},
                    {name: 'PaymentAmount', editor: 'number', decimal: 2, classes: 'mb-0', invalidIf: self.invalidIf,
                      currencyField: 'PaymentCurrencyId'},
                    {name: 'CurrencyRate', editor: 'number', decimal: 2, classes: 'mb-0', invalidIf: self.invalidIf},
                    {name: 'Selected', editor: 'checkbox', classes: 'bottom-base mb-0'},
                    {name: 'PaymentCurrencyId', relation: 'currency', optionLabels: 'id', invalidIf: self.invalidIf
                        , editor: 'select', label: 'Currency', addBlank: true, classes: 'mb-0'},
                    {name: 'PaymentTypeId', relation: 'paymenttype', optionLabels: 'Name', required: true
                        , editor: 'select', label: 'Payment Type', addBlank: true, classes: 'mb-0', invalidIf: self.invalidIf
                    },
                    {name: 'PaymentStatus', editor: 'select', classes: 'mb-0',
                        options: bookingPaymentView.paymentStatusOptions },
                    {name: 'InvoiceStatus', editor: 'select', addBlank: true, classes: 'mb-0',
                        options: [
                            {value: 'PENDING', label: 'Pending'},
                            {value: 'RECEIVED', label: 'Received'},
                            {value: 'CONFIRMED', label: 'Confirmed'},
                            {value: 'NOT_APPLY', label: 'Not Apply'},
                        ]},
                    {name: 'SupplierCreditId', editor: 'select', options: [], addBlank: true, classes: 'mb-0',
                        filterOptions: (row, options) => {
                            let res = [];
                            if (row.PaymentStatus != 'USED') return res;
                            return self.creditOptions[row.SupplierId];
                        },
                    }
                ],
                templates: [
                    {name: 'SupplierId', callback: (row) => {return row.SupplierName} },
                    {name: 'DateIn', callback: (row) => {
                        if (!row.DateIn) return '';
                        return moment(row.DateIn).format("DD/MM/YYYY");
                      }
                    },
                    {name: 'DueDate', callback: (row) => {
                        if (!row.DueDate) return '';
                        return moment(row.DueDate).format("DD/MM/YYYY");
                      }
                    },
                    {name: 'PaymentDate', callback: (row) => {
                        if (!row.PaymentDate) return '';
                        return moment(row.PaymentDate).format("DD/MM/YYYY");
                      },
                    },
                    {name: 'Amount', callback: (row) => {
                        if (row.calculated && self.current.CurrencyId) {
                            return tools.toNumber(row.Amount, 0, self.current.CurrencyId);
                        }
                        return tools.toNumber(row.Amount, 0, row.CurrencyId);
                      }
                    },
                    {name: 'PaymentAmount', callback: (row) => {
                        if (row.calculated && self.current.PaymentCurrencyId) {
                            return tools.toNumber(row.PaymentAmount, 0, self.current.PaymentCurrencyId);
                        }
                        return row.PaymentAmount;
                      }
                    },
                    {name: 'PaymentStatus', callback: (row) => {
                        return {
                            PENDING: 'Pending',
                            PAID: 'Paid',
                            ON_ACCOUNT: 'Credit',
                            CANCELED: 'Canceled',
                            USED: 'Credit Used',
                            }[row.PaymentStatus];
                        }
                    },
                    {name: 'InvoiceStatus', callback: (row) => {
                        return {
                            PENDING: 'Pending',
                            RECEIVED: 'Received',
                            CONFIRMED: 'Confirmed',
                            }[row.InvoiceStatus];
                        }
                    },
                    {name: 'Status', callback: (row) => {return tr(bookingView.bookingStatus[row.Status])}
                    },
                ],
                buttons: [
                    /*{callback: self.applyInvoice, label: 'Apply Invoice', buttonClass: 'btn btn-primary', id: 'applyInvoice',
                        disabled: false},*/
                    {callback: self.setPaid, label: 'Pay', buttonClass: 'btn btn-primary', id: 'setPaid',
                        disabled: false},
                ],
                callbacks: {
                    'Copy': async (fieldName, row, report) => {
                        let res = await api.post('/api/bookingpayment/copy_payment/',
                            JSON.stringify({BookingId: row.BookingId, PaymentId: row.id}))
                        if (res) {
                            report.run();
                        }
                    },
                    'Divide': async (fieldName, row, report) => {
                        let res = await api.post('/api/bookingpayment/divide/',
                            JSON.stringify({BookingId: row.BookingId, PaymentId: row.id}))
                        if (res) {
                            report.run();
                        }
                    },
                    'Selected': (fieldName, row, report) => {
                        self.selected = _.filter(report.dataList, (r) => r.Selected);
                        self.getDisableInvoice();
                        self.getDisablePaid();
                    },
                    'Edit': async (fieldName, row, report) => {
                        let res = await api.get('/api/bookingpayment/' + row.id);
                        if (res) {
                            EventBus.$emit('open-modal-record', {modalRecord: null, modalTable: 'bookingpayment', modalId: res.id});
                        }
                    },
                },
                columnClick: {
                    BookingId: self.showBooking,
                },

              }
        },
        showBooking (row) {
            let route = this.$router.resolve({ name: 'sale', params: {id: row.BookingId.toString(), TemplateType: 'NONE'} })
            window.open(route.href, '_blank');
        },
        async setField (fieldName, row, report) {
            let res = await api.post('/api/bookingpayment/set_field/',
                JSON.stringify({FieldName: fieldName, Value: row[fieldName], BookingId: row.BookingId, PaymentId: row.id}),
                null,
                (response) => {
                    report.errors = [response.detail? response.detail: response.err];
                }
            )
            if (res) {
                report.errors = [];
                for (let fieldName in res.fields) {
                    let listRow = _.find(report.list, (r) => r.id==row.id);
                    if (listRow) {
                        listRow[fieldName] = res.fields[fieldName];
                    }
                }
                if (fieldName=='SupplierCreditId') {
                    await this.afterRun(report)
                }
                if (res.messages) {
                    report.message = res.messages.join('; ');
                } else {
                    report.message = null;
                }
            }
        },
        async editField (fieldName, row, report) {
            if (row.SupplierCreditId) {
                let credit = _.find(this.slotsFields, (r) => r.name == 'SupplierCreditId');
                if (credit) {
                    let creditRow = _.find(credit.options[row.SupplierCreditId], (r) => r.value == row.SupplierCreditId);
                    if (creditRow) {
                        if (row.PaymentCurrencyId && creditRow.currency!=row.PaymentCurrencyId) {
                            report.addError(tr('Incorrect currency. Record not updated'));
                            return;
                        }
                        if (row.PaymentAmount > creditRow.amount) {
                            report.addError(tr('Amount greater than Credit balance'));
                            return;
                        }
                    }
                }
            }
            if (this.selected.length>0) {
                let value = row[fieldName];
                for (let sRow of this.selected) {
                    sRow[fieldName] = value;
                    this.setField(fieldName, sRow, report);
                }
            } else {
                await this.setField(fieldName, row, report);
            }
        },
        async afterRun (report) {
            this.selected = [];
            this.getDisableInvoice();
            this.getDisablePaid();
            let credit = _.find(this.slotsFields, (r) => r.name == 'SupplierCreditId');

            if (credit) {
                let data = {Balance: 0};
                if (this.current.SupplierId && this.current.SupplierId.length==1) {
                    data.SupplierId = this.current.SupplierId[0];
                }
                let res = await api.get('/admin/reports/get_credit/', data);
                if (res && res.rows) {
                    credit.options = {};
                    for (let row of res.rows) {
                        if (!credit.options[row.SupplierId]) {
                            credit.options[row.SupplierId] = [];
                        }
                        let label;
                        if (row.BookingId) {
                          label = ` File ${row.BookingId? row.BookingId: ''} ${row.PaxName? row.PaxName: ''} `;
                        } else if (row.CreditId) {
                          label = ` ${tr('Credit Number')} ${row.CreditId}`;
                        }
                        if (row.Balance) {
                            label += `. ${tr('Balance')}: ${tools.toNumber(-row.Balance, null, row.CurrencyId)} ${row.CurrencyId}`;
                        }
                        credit.options[row.SupplierId].push({
                            value: row.CreditId,
                            label: label,
                            currency: row.CurrencyId,
                            supplierId: row.SupplierId,
                        })
                    }
                    for (let reportRow of report.dataList) {
                        if (!reportRow.SupplierCreditId) continue;
                        if (!credit.options[reportRow.SupplierId]) {
                            credit.options[reportRow.SupplierId] = []
                        }
                        let f = _.find(credit.options[reportRow.SupplierId], (r) => r.value == reportRow.SupplierCreditId);
                        if (!f) {
                            let label;
                            let row = reportRow.SupplierCredit;
                            if (row.BookingId) {
                                label = ` File ${row.BookingId} ${row.PaxName? row.PaxName: ''} `;
                            } else if (row.id) {
                                label = ` ${tr('Credit Number')} ${row.id}`;
                            }
                            credit.options[reportRow.SupplierId].push({
                                value: reportRow.SupplierCreditId,
                                label: label,
                                currency: reportRow.PaymentCurrencyId,
                                supplierId: reportRow.SupplierId,
                            })

                        }
                    }
                    this.creditOptions = credit.options;
                    let amount = {};
                    for (let row of res.rows) {
                        let supplier = _.find(report.dataList, (r) => r.SupplierId == row.SupplierId);
                        if (!supplier) continue;
                        if (!amount[row.CurrencyId]) amount[row.CurrencyId] = 0
                        amount[row.CurrencyId] += -parseFloat(row.Balance);
                    }
                    if (_.keys(amount).length>0) {
                        report.message = tr('Credit') + ' ';
                        for (let currency in amount) {
                            report.message += currency + ' ' + tools.toNumber(amount[currency], null, currency) + '. ';
                        }
                    }
                    this.fieldClasses.SupplierId = (row) => {
                        let found = _.find(res.rows, (r) => r.SupplierId == row.SupplierId);
                        if (found) return 'alert-warning';

                    }
                }
            }
            this.slotsFields = Object.assign([], this.slotsFields);
        },
        addNotification   (msg) {
            app.addNotification(this, msg, true)
        },
        removeNotifications () {
            this.notifications = [];
        },
        async applyInvoice (self)  {
            if (this.selected.length == 0) return;
            let values = [];
            let res = await api.get('/api/purchaseinvoice/get_pending/', {filters: JSON.stringify({SupplierId: this.selected[0].SupplierId})});
            if (res) {
                for (let row of res) {
                    values.push({
                      value: row.id,
                      label: `${row.InvoiceNumber} - ${tr('Files')}: ${row.BookingIds} - ${tr('Balance')}: ${row.CurrencyId} ${row.Balance.toFixed(2)}`,
                    });
                }
                this.selectInvoice = values;
                this.report = self;
                await this.$nextTick();
                $('#selectValue').modal({backdrop: 'static', keyboard: false}, 'show');
            }
        },
        setPaid (self) {
            this.report = self;
            this.paidInput = true;
        },
        getDisableInvoice () {
            let res = _.countBy(this.selected, (r) => {
                return r.SupplierId
            })
            let button = _.find(this.buttons, (b) => b.id == 'applyInvoice');
            if (button) {
                if (_.keys(res).length==1) {
                    button.disabled = false;
                } else {
                    button.disabled = true;
                }
            }
            this.buttons = Object.assign([], this.buttons);
        },
        getDisablePaid () {
            let res = _.countBy(this.selected, (r) => {
                return r.SupplierId
            })
            let button = _.find(this.buttons, (b) => b.id == 'setPaid');
            if (button) {
                if (_.keys(res).length==1) {
                    button.disabled = false;
                } else {
                    button.disabled = true;
                }
            }
            this.buttons = Object.assign([], this.buttons);
        },
        closeSelectInvoice () {
            this.selectInvoice = null;
        },
        async setInvoiceToPayments (value) {
            this.closeSelectInvoice();
            let promises = [];
            for (let row of this.selected) {
                let data = {
                    BookingPaymentId: row.id,
                    InvoiceId: value,
                }
                let p = api.post('/api/bookingpayment/apply_invoice', JSON.stringify(data));
                promises.push(p);
            }
            await Promise.all(promises).then(values => {
                this.report.message = tr('Invoice Applied');
            });
        },
        afterEdit (fieldName) {
        },
        async pay (currencyRate, currencyId, fromCurrencyId, toCurrencyId, paymentTypeId) {
            let data = {
                FromCurrencyId: fromCurrencyId,
                ToCurrencyId: toCurrencyId,
                CurrencyId: currencyId,
                CurrencyRate: currencyRate,
                PaymentTypeId: paymentTypeId,
                Payments: _.map(this.selected, (r) => {
                    return r.id
                }),
            }
            let res = await api.post('/api/bookingpayment/pay_many', JSON.stringify(data));
            if (res) {
                this.report.message = tr('Payments Applied');
                let fields = ['PaymentCurrencyId', 'PaymentStatus', 'PaymentAmount', 'PaymentDate', 'CurrencyRate', 'PaymentTypeId']
                for (let payment of res) {
                    for (let fieldName of fields) {
                        let listRow = _.find(this.report.list, (r) => r.id==payment.id);
                        if (listRow) {
                            listRow[fieldName] = payment[fieldName];
                        }
                    }
                }
            }
        },
        getFieldStyle (row) {
            if (row.DueDate && row.PaymentStatus == 'PENDING') {
                if (moment(row.DueDate)< moment(new Date())) return 'exclamation-alert';
                let d = moment(row.DueDate).add(-7, 'days');
                if (d < moment(new Date())) return 'exclamation-yellow';
                let d2 = moment(row.DueDate).add(-14, 'days');
                if (d2 < moment(new Date())) return 'exclamation-green';
            }
            return '';
        },
        invalidIf (row, fieldName) {
            if (!row[fieldName] && row.PaymentStatus == 'PAID') return true;
        },
    },
    watch: {
        current: {
            handler: function() {
                if (this.lastSupplierId != this.current.SupplierId) {

                }
                this.lastSupplierId = this.current.SupplierId;
            },
            deep: true,
        }
    }
}
</script>

