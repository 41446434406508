<template>
    <report-window
        v-if="ready"
        endpoint="/reports/booking_views"
        :fields="fields"
        :title="title"
        :headers="headers"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :current.sync="current"
        :columnClick="columnClick"
        :templates="templates"
        :slotsFields="slotsFields"
        :updateReport="updateReport"
    ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "booking-views",
    props: ['options'],
    components: {
        ReportWindow,
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ready: false,
            fields: [
                {name: 'BookingId', editor: 'number', label: 'File', columns: 2},
                {name: 'FromDate', editor: 'date', label: 'From', columns: 2},
                {name: 'ToDate', editor: 'date', label: 'To', columns: 2},
                {name: 'SalesUserId', label: 'Seller', editor: 'select', addBlank: true, columns: 2,
                    optionLabels: 'UserName', relation: 'user', filters: (row) => {return !row.Closed}
                },
                {name: 'ShowURL', label: 'Show URL', editor: 'select', defValue: '0',
                    options: [
                      {value: '0', label: 'NO'},
                      {value: '1', label: 'YES'},
                    ]
                },
            ],
            title: "Booking Views",
            headers: {
                BookingId: 'File',
                Salesman: 'Seller',
                City: tr('_City'),
                RemoteAddr: tr('IP Address'),
            },
            templates: [
                {name: 'Date', callback: (row) => {
                        if (!row.CreatedUTC) return '';
                        return moment(row.CreatedUTC).local().format("DD MMM");
                    }
                },
                {name: 'Time', callback: (row) => {
                        if (!row.CreatedUTC) return '';
                        return moment(row.CreatedUTC).local().format("HH:mm");
                    }
                },
            ],
            columnClick: {},
            slotsFields: [],
        };
    },

};
</script>
