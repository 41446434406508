<template>
    <div v-if="showService">
        <b v-if="s.Optional">
            {{titleOptional}} <i class="text-danger">{{tr('Optional Service')}} </i>
        </b>
        <b v-else-if="title">
            {{title}}
        </b>
        <text-rows-style
            class="italic text-justify mb-0"
            :text="text"
            v-if="s && ifDescription && !edit"
            @edit="setEdit"
        ></text-rows-style>
        <text-area-edit-style
            v-else-if="s && edit"
            :text.sync="s.Description"
         ></text-area-edit-style>
        <p v-if="s.Service.ServiceType && !s.Service.NotPrice && ifText && (readMore || text.length < limit)"  class="mb-0">
            <i><b>{{tr('Type of service')}}:</b> {{tr(s.Service.ServiceType.Name)}}</i>
        </p>
        <p v-if="s.EntranceIncluded">
            <strong><i>{{tr('_entrance_includes')}}</i></strong>
        </p>
        <p v-if="recordStore.ShowServiceLanguage && s.Service.languageOffered && show">
            <strong>
                <i>{{tr('Languages')}}: {{getLanguageOffered(s.Service)}}</i>
            </strong>
        </p>
        <br>
    </div>
</template>

<script>
const textAreaEditStyle = importVueComp('components/tools', 'TextAreaEditStyle');
import { mapState } from "vuex";

export default {
    name: 'itinerary-body-service',
    props: ['crop', 'day', 's', 'i', 'canEdit', 'readMore', 'textLength', 'limit'],
    components: {
      'text-area-edit-style': textAreaEditStyle,
    },
    data () {
        return {
            edit: false,
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getServiceTitle (s) {
            return s.title;
        },
        setEdit () {
            if (!this.canEdit) return;
            this.edit = true;
        },
        getCityImage (city) {
            if (!city) return;
            if (city.Image && city.Image.url) return city.Image.url;
        },
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        showService () {
            if (this.title) return true;
            if ((this.readMore || this.textLength.start < this.limit) && this.ifText) return true;
        },
        ifDescription () {
            if (tools.ifText(this.s.Description)) return true;
        },
        ifText () {
            if (tools.ifText(this.s.Description)) return true;
        },
        title () {
            if (this.descriptionTitle) return this.descriptionTitle;
            //return this.s.Name;
        },
        descriptionTitle () {
            return this.s.title;
        },
        titleOptional () {
            if (this.s.title) return this.s.title;
            return this.s.Name;
        },
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        end () {
            return this.textLength.start + this.textLength.length;
        },
        text () {
            if (this.readMore) return this.s.Description;
            let end = this.textLength.start + this.textLength.length;
            if (this.limit > end) return this.s.Description;
            if (this.limit > this.textLength.start && this.limit < end) {
                return this.s.Description.substring(0, this.limit - this.textLength.start) + '...';
            }
            return '';
        },
        show () {
            if (this.readMore) return true;
            return this.limit > this.end;
        }
    },
}
</script>


