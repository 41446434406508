<template>
    <report-window
      endpoint="/reports/sales_summary"
      v-if="ready"
      :fields="fields"
      :title="cardTitle || title"
      :headers="headers"
      :templates="templates"
      :perPage="(options && options.perPage)? options.perPage: 500"
      :hideFilters="options && options.hideFilters"
      :runMounted="options && options.runMounted"
      :cardView="options && options.cardView"
      :columnsClasses="columnsClasses"
      :notFilterable="options && options.notFilterable"
      :current.sync="current"
      :columnsFormat="columnsFormat"
      :allowChart="true"
      :chartOptions="chartOptions"
      :totalsOn="totalsOn"
    >
    </report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'sales-summary-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
        columnsClasses () {
            return {
                Quoted: 'text-right',
                QuotedBookingCurrency: 'text-right',
                QuotedSalesPrice: 'text-right',
                Percent: 'text-right',
            }
        },
        chartOptions () {
          return {
            y: {
              '0': 'SalesUser', 
              '1': 'SalesOrigin', 
              '2': 'Seller/Sales Origin', 
              '3': 'Agency', 
              '4': 'Trip Mode',
              '5': 'Language'
            }[this.current.SumBy],
            x: ['Quoted', 'Percent'],
            beforeLabel: {
              Quoted: '$ ',
            },
            afterLabel: {
              Percent: ' %'
            }
          }
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.current = Object.assign({}, this.current);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        return this.getData(this);
    },
    methods: {
        getData (self) {
            return {
                current: {},
                ready: false,
                fields: [
                  { name: 'FromDate', editor: 'date', label: 'From Date'},
                  { name: 'ToDate', editor: 'date', label: 'To Date'},
                  { name: 'FilterDate', label: 'Filter Date by', editor: 'select', addBlank: true, required: true,
                      options: [
                          {value: '0', label: 'Sales Date'},
                          {value: '1', label: 'Date Inn'},
                          {value: '2', label: 'Date Out'},
                      ]
                  },
                  { name: 'PeriodId', label: 'Season', editor: 'select',
                      optionLabels: 'Name', relation: 'period', addBlank: true,
                  },
                  { name: 'CurrencyId', label: 'Currency View', editor: 'select', required: true,
                    relation: 'currency', optionLabels: 'id'
                  },
                  { 
                    name: 'SalesUserId', 
                    label: 'Seller', 
                    editor: 'vue-select',
                    optionLabels: 'UserName', 
                    relation: 'user', 
                    addBlank: true,
                    list: true,
                    filters: (row) => {
                        if (row.Closed) return false;
                        if (row.Department && row.Department.Sales) return true;
                    }
                  },
                  { name: 'SalesOriginId', label: 'Sales Origin', editor: 'select',
                    optionLabels: 'Name', relation: 'salesorigin', addBlank: true
                  },
                  { name: 'CustomerTypeId', label: 'Customer Type', editor: 'select',
                    optionLabels: 'Name', relation: 'customertype', addBlank: true,
                  },
                  { name: 'TripModeId', label: 'Trip Mode', editor: 'select',
                    optionLabels: 'Name', relation: 'tripmode', addBlank: true
                  },
                  { name: 'SumBy', label: 'Sum by', editor: 'select', required: true, defValue: '0',
                      options: [
                          {value: '0', label: 'Seller'},
                          {value: '1', label: 'Sales Origin'},
                          {value: '2', label: 'Seller/Sales Origin'},
                          {value: '3', label: 'Customer'},
                          {value: '4', label: 'Trip Mode'},
                          {value: '5', label: 'Language'},
                      ]
                  },
                  { name: 'ExcludeCanceled', label: 'Exclude Canceled', editor: 'checkbox'},
                ],
                title: 'Sales Summary',
                headers: {
                  Agency: 'Customer',
                  SalesUser: 'Seller',
                  Quoted: 'Sales',
                  Bookings: 'Files'
                },
                totalsOn: [ 'Quoted', 'Percent', 'Pax', 'Bookings'],
                templates: [
                    {name: 'Quoted', callback: (row) => {
                        return tools.toNumber(row.Quoted, 0);
                      }
                    },
                    {name: 'Percent', callback: (row) => {
                        if (row.calculated) return tools.toNumber(row.Percent, 0);
                        return tools.toNumber(row.Percent, 1);
                      }
                    },
                ],
                columnsFormat: {
                    'Quoted': (value) => {
                        if (value) return '$ ' + value;
                        return value;
                    },
                    'Percent': (value) => {
                        if (value) return value + ' %';
                        return '0 %';
                    }
                }
            }
        }
    }
}
</script>

