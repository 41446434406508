<template>
    <div class="">
        <div class="" v-if="record.SurveyQuestion">
          <div v-if="record.SurveyQuestion.QuestionType=='TEXT'">
              <textarea
                type="text"
                v-model="record.Response"
                class="text-control"
                rows="2"
                @change="change"
              >
              </textarea>
              <span class="form-bar"></span>
          </div>
          <select-input
              class="my-0"
              v-if="record.SurveyQuestion.QuestionType=='LIST'"
              :fieldOptions="fieldOptions"
              @change="change"
              :currentValue.sync="record.Response">
          </select-input>
          <select-input
              class="my-0"
              v-if="record.SurveyQuestion.QuestionType=='TABLE'"
              :fieldOptions="tableOptions"
              @change="change"
              :currentValue.sync="record.TableId">
          </select-input>

          <div v-if="record.SurveyQuestion.QuestionType=='OPTIONS'" class="px-3 form-row text-left mt-1">
              <div class="col-sm-4 my-0 form-group form-default" v-for="(v, id) of optionsValues" style="margin-bottom: 0px !important;">
                  <div v-if="id != 'others'" class="">
                      <input v-model="optionsValues[id]" class="form-check-input" type="checkbox" @change="change">
                      <label class="smaller form-check-label">{{tr(optionsLabels[id])}}</label>
                  </div>
                  <div v-else class="form-row my-0 px-0 d-flex align-items-baseline">
                      <label class="col-2 px-1 smaller text-left">{{tr('Others')}}</label>
                      <div class="col-7">
                          <input
                              v-model="optionsValues.others"
                              class="form-control"
                              :class="{'fill': optionsValues.others}"
                              type="text">
                              <span class="form-bar"></span>
                          </input>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'survey-pax-question',
    props: [ "record", "field" , "langCode", "extras"],
    components: {},
    async mounted () {
        this.optionsValues = this.getOptionsValues();
    },
    computed: {
        fieldOptions () {
            let res = [];
            if (!this.record) return res;
            if (!this.record.SurveyQuestion) return res;
            if (this.record.SurveyQuestion.QuestionType == 'LIST') {
                for (let row of this.record.SurveyQuestion.SurveyQuestionOptions) {
                    res.push({value: row.id, label: this.tr(row.Label)})
                }
            }
            return res;
        },
        optionsLabels () {
            let res = {"others": null};
            if (!this.record) return res;
            if (!this.record.SurveyQuestion) return res;
            if (this.record.SurveyQuestion.QuestionType == 'OPTIONS') {
                for (let row of this.record.SurveyQuestion.SurveyQuestionOptions) {
                    res[row.id] = this.tr(row.Label);
                }
            }
            return res;
        },
        canDelete () {
            return false;
        }
    },
    asyncComputed: {
        async tableOptions () {
            let res = [];
            if (!this.record) return res;
            if (!this.record.SurveyQuestion) return res;
            if (this.record.SurveyQuestion.TableType == 'HOTEL') {
                if (this.extras && this.extras.BookingId) {
                    let b = await api.get('/api/booking/' + this.extras.BookingId);
                    if (b) {
                        for (let day of b.BookingDays) {
                            for (let hotel of day.BookingDayHotels) {
                                for (let room of hotel.BookingDayRooms) {
                                    if (room.HotelId) {
                                        if (_.find(res, (r) => r.value == room.HotelId)) continue;
                                        res.push({value: room.HotelId, label: room.Hotel.Name});
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (this.record.SurveyQuestion.TableType == 'SERVICE') {
                if (this.extras && this.extras.BookingId) {
                    let b = await api.get('/api/booking/' + this.extras.BookingId);
                    if (b) {
                        for (let day of b.BookingDays) {
                            for (let s of day.BookingDayServices) {
                                if (s.ServiceId) {
                                    if (_.find(res, (r) => r.value == s.ServiceId)) continue;
                                    res.push({value: s.ServiceId, label: s.Service.Name});
                                }
                            }
                        }
                    }
                }
            }
            if (this.record.SurveyQuestion.TableType == 'CITY') {
                if (this.extras && this.extras.BookingId) {
                    let b = await api.get('/api/booking/' + this.extras.BookingId);
                    if (b) {
                        for (let day of b.BookingDays) {
                            for (let s of day.BookingDayServices) {
                                if (s.Service && s.Service.CityId) {
                                    if (_.find(res, (r) => r.value == s.Service.CityId)) continue;
                                    res.push({value: s.Service.CityId, label: s.Service.City.Name});
                                }
                            }
                            for (let hotel of day.BookingDayHotels) {
                                for (let room of hotel.BookingDayRooms) {
                                    if (room.Hotel && room.Hotel.CityId) {
                                        if (_.find(res, (r) => r.value == room.Hotel.CityId)) continue;
                                        res.push({value: room.Hotel.CityId, label: room.Hotel.City.Name});
                                    }
                                }
                            }

                        }
                    }
                }
            }
            return res;
        }
    },
    data () {
        return {
            optionsValues: {}
        }
    },
    methods: {
        tr (t) {
            if (!this.langCode) return t;
            return tr(t, this.langCode);
        },
        getOptionsValues () {
            let res = {"others": null};
            if (!this.record) return res;
            if (!this.record.SurveyQuestion) return res;
            if (this.record.SurveyQuestion.QuestionType == 'OPTIONS') {
                let response = {};
                if (this.record.Response) response = JSON.parse(this.record.Response);
                for (let row of this.record.SurveyQuestion.SurveyQuestionOptions) {
                    res[row.id] = false;
                    if (response[row.id]) res[row.id] = response[row.id];
                }
                if (response.others) res.others = response.others;
            }
            return res;
        },
        change () {
            if (this.record && this.record.SurveyQuestion && this.record.SurveyQuestion.QuestionType == 'OPTIONS') {
                this.record.Response = this.optionsValues;
            }
            if (this.record && this.record.SurveyQuestion && this.record.SurveyQuestion.QuestionType == 'TABLE') {
                let table = this.record.SurveyQuestion.TableType.toLowerCase();
                let record = _.find(api.tables[table], (r) => r.id == this.record.TableId);
                if (record) {
                    this.record.Response = record.Name;
                }
            }
            this.$emit('update:record', this.record);
            this.$emit('change');
        }
    }
};
</script>
