<template>
    <div :class="{'form-material': !material}" class="search-box">
        <div class="form-group form-default my-0 mb-2" :class="addClass">
            <input class="form-control" type="text"  v-model="search" :id="addId" @focus="$emit('focusOn')"
                v-on:input="change()" :class="{'fill': search}">
                <span class="form-bar">
                </span>
                <label class="float-label">
                    <font-awesome-icon icon="search"/>
                    {{tr('Search')}}
                </label>

            </input>
        </div>
    </div>
</template>

<script>
export default {
    name: 'search-box',
    props: ['value', 'addClass', 'material', 'addId'],
    data () {
        return {
            search: null,
            reading: false,
            typingTimer: null,
            doneTypingInterval: 500,
        }
    },
    mounted () {
        this.search = this.value;
    },
    methods: {
        change () {
            this.reading = true;
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                this.reading = false;
            }, this.doneTypingInterval);
        }
    },
    watch: {
        reading () {
            if (!this.reading) {
                this.$emit('input', this.search);
            }
        }
    }
}
</script>
