<script>
import recordWindow from '@/components/tools/RecordWindow';
import Vue from 'vue';

var c = Vue.component('mixin-window', {
    extends: recordWindow,
    props: {
        table: {
            type: String,
            default: 'contact'
        },
        id: {
            type: String
        },
    }
});

export default {
    name: 'agency-contact-window',
    props: ['table', 'id', 'agencyId', 'parentTable', 'parentRoute'],
    mixins: [c],
    methods: {
        replacePath () {
            if (this.modal) return;
            this.$router.push({ name: 'agencyContact', params: { table: 'contact', agencyId: this.agencyId, id: this.recordStore.id.toString() }});
        },
        closePath () {
            this.$router.push({ name: 'record', params: { table: 'agency', id: this.agencyId, tab: 'detail-tab-AgencyContactList' }});
        },
        async afterMount () {
            if (this.agencyId) {
                this.record.AgencyId = this.agencyId;
                vueTools.change(this, null, 'AgencyId', this.agencyId);
            }
        },
    }
}
</script>
