<template>
    <itinerary
        :view="view"
        :canEdit="true"
        :showMap="true"
    ></itinerary>
</template>

<script>
import { mapState } from 'vuex';
const itinerary = importVueComp('components/itinerary', 'Itinerary');
import { saveAs } from 'file-saver';
import { Packer } from "docx";
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'itinerary-edit',
    props: ['processingPDF', 'view'],
    components: {
        'itinerary': itinerary,
    },
    data () {
        return {
            expanded: false,
            crop: false,
            counter: 0,
            intervalDocx: null,
            intervalPDF: null,
            processingText: null,
            processingDayNr: null,
            images: true,
            doc: null
        }
    },
    mounted () {
        $('.terms-collapse1').collapse('show');
        EventBus.$on('get-document', this.getDocxListener);
        EventBus.$on('processing-day', this.processingDay);
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            fullScreen: state => state.main.fullScreen,
            recordStore: state => state.main.record,
        }),
    },
    methods: {
        processingDay (dayNr) {
            this.processingDayNr = dayNr;
        },
        getDocxListener  () {
            this.getDocx();
            let lastDay;
            let daySeconds;
            let limitTime = (this.recordStore.BookingDays.length * 3 + 10) * 1000;
            this.counter = 0;
            setTimeout(() => {
                if (this.$root.$children[0].$refs.blockUI) {
                    this.$root.$children[0].$refs.blockUI.requestTimeout = limitTime;
                }
            }, 1000);
            this.intervalDocx = setInterval(() => {
                this.counter += 1;
                this.$root.$children[0].loadingMessage = this.doc.message;
                if (this.doc.dayNr ==  lastDay) {
                    daySeconds += 1;
                } else {
                    daySeconds = 0;
                }
                lastDay = this.doc.dayNr;
                if (this.counter > limitTime || daySeconds > 20) {
                    this.counter = 0;
                    api.setProcessing(false);
                    let e = tr('Cannot get documment');
                    if (this.processingDayNr) {
                        e += `. ${tr('Error in day')} ${this.processingDayNr}`;
                        if (this.processingText) {
                           e += ` ${tr('in text')} ${this.processingText}}`;
                        }
                    }
                    this.recordStore.addError(e);
                    clearInterval(this.intervalDocx)
                }
            }, 1000);
        },
        getPdfListener () {
            let self = this;
            this.printPDF();
            this.intervalPDF = setInterval(function() {
                self.counter += 1;
                if (self.counter>30) {
                    self.counter = 0;
                    self.$emit('update:processingPDF', false);
                    self.$emit('addError', 'No se pudo generar el PDF');
                    clearInterval(self.intervalPDF)
                }
            }, 1000);
        },
        async printPDF () {

            /*let pdfModule = require('@/app/pdf').default;
            let doc = new pdfModule(this.record);
            let r = doc.create();*/
        },
        async getDocx () {
            let docx = vueTools.importModule('docx', 'tools').default;
            this.doc = new docx(this.recordStore);
            let r = await this.doc.create();

            let fileName = this.recordStore.Title + ".docx"
            Packer.toBlob(r).then(blob => {
              saveAs(blob, fileName);
            });
            api.setProcessing(false);
            clearInterval(this.intervalDocx);
        },
        exitFullScreen () {
            this.$store.commit('setFullScreen', false);
        },
        scrollTo (id) {
            frontTools.scrollTo(id, -70);
        }
   },
   beforeDestroy() {
        clearInterval(this.interval);
        clearInterval(this.intervalPDF);
        EventBus.$off('get-document', this.getDocxListener);
        EventBus.$off('processing-day', this.processingDay);
        EventBus.$off('get-pdf', this.getPdfListener);
   },
}
</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>