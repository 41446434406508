<template>
    <div class="form-group col-md-12 mb-0">
        <div class="form-row">
            <input-field
                :field="recordStore.fieldsObject.Title"
                :defValue.sync="recordStore.Title"
                :invalid="recordStore.invalid.Title"
                :readonly="!canEdit"
                :maxLength="recordStore.fieldsObject.Title.length"
                class="col-md-4 px-3"
            ></input-field>
            <input-field
                v-if="recordStore.notTemplate"
                :field="recordStore.fieldsObject.SubTitle"
                :defValue.sync="recordStore.SubTitle"
                :readonly="!canEdit"
                :maxLength="recordStore.fieldsObject.Title.length"
                class="col-md-4 px-3"
            ></input-field>
            <div class="px-3 col-md-2">
                <select-input fieldName="LanguageId"
                    :field="recordStore.fieldsObject.LanguageId"
                    label="Language"
                    :disabled="!canEdit"
                    :currentValue.sync="recordStore.LanguageId" :required="true"
                    :addClass="recordStore.invalid.LanguageId? 'border-danger': ''"
                ></select-input>
            </div>
            <div class="px-3 col-md-2" v-if="recordStore.isTemplate">
                <select-input fieldName="TemplateType"
                    :field="recordStore.fieldsObject.TemplateType"
                    label="Template"
                    :disabled="!canEdit"
                    :addClass="recordStore.invalid.TemplateType? 'border-danger': ''"
                    :currentValue.sync="recordStore.TemplateType">
                </select-input>
            </div>
            <div class="px-3 col-md-2" v-if="recordStore.isTemplate">
                <select-input fieldName="UserId" label="User"
                    :field="recordStore.fieldsObject.UserId"
                    :disabled="!canEdit"
                    :currentValue.sync="recordStore.UserId" :required="true"
                ></select-input>
            </div>
            <div class="form-group col-md-2" v-if="recordStore.isTemplate">
                <div class="form-check form-check mt-3 ml-3">
                    <input v-model="recordStore.Closed" name="Closed" class="form-check-input"
                    :disabled="!canEdit"
                    type="checkbox" id="Closed">
                    <label class="smaller form-check-label">{{tr('Archive')}}</label>
                </div>
            </div>
            <div class="px-3 col-md-2" v-if="recordStore.isTemplate">
                <div class="form-check form-check mt-3 ml-3">
                    <input v-model="recordStore.TemplateKeepText" name="Closed" class="form-check-input"
                    :disabled="!canEdit"
                    type="checkbox" id="TemplateKeepText">
                    <label class="smaller form-check-label">{{tr('Keep Edited Text')}}</label>
                </div>
            </div>
            <date-picker :def.sync="recordStore.StartDate" id="StartDate"
                v-if="recordStore.notTemplate"
                :disabled="!canEdit"
                label="Start Date" class="col-md-2 px-3" labelClass="px-3"
                :addClass="recordStore.invalid.StartDate? 'border-danger': ''"
            ></date-picker>
        </div>
        <div class="form-row" v-if="recordStore.notTemplate">
            <div class="px-3 col-md-2">
                <select-input
                    :field="recordStore.fieldsObject.Status"
                    fieldName="Status" label="Status"
                    :currentValue.sync="recordStore.Status" :notSort="true"
                    :addClass="recordStore.invalid.Status? 'border-danger': ''"
                    @change="setStatus"
                ></select-input>
            </div>
            <div class="px-3 col-md-2">
                <select-input fieldName="TripModeId"
                    :field="recordStore.fieldsObject.TripModeId"
                    :disabled="!canEdit"
                    label="Trip Type"
                    :addClass="recordStore.invalid.TripModeId? 'border-danger': ''"
                    :currentValue.sync="recordStore.TripModeId" :required="true"></select-input>
            </div>
            <div class="px-3 col-md-2">
                <select-input fieldName="Difficulty"
                    :field="recordStore.fieldsObject.Difficulty"
                    :disabled="!canEdit"
                    label="Difficulty"
                    :addClass="recordStore.invalid.Difficulty? 'border-danger': ''"
                    :currentValue.sync="recordStore.Difficulty" :required="true"></select-input>
            </div>
            <div class="px-3 col-md-2">
                <select-input fieldName="SalesOriginId"
                    :field="recordStore.fieldsObject.SalesOriginId"
                    :disabled="!canEdit"
                    label="Origin"
                    :currentValue.sync="recordStore.SalesOriginId" :required="true"
                    :addClass="recordStore.invalid.SalesOriginId? 'border-danger': ''"
                    ></select-input>
            </div>
            <div class="px-3 col-md-2">
                <select-input fieldName="SalesUserId"
                    :field="recordStore.fieldsObject.SalesUserId"
                    :disabled="!canEdit"
                    label="Seller"
                    :currentValue.sync="recordStore.SalesUserId" :required="true"
                    :addClass="recordStore.invalid.SalesUserId? 'border-danger': ''"
                    ></select-input>
            </div>
            <div class="px-3 col-md-2">
                <select-input fieldName="OperUserId"
                    :field="recordStore.fieldsObject.OperUserId"
                    :disabled="!canEdit && !operActions"
                    label="Operations"
                    :addClass="recordStore.invalid.OperUserId? 'border-danger': ''"
                    :currentValue.sync="recordStore.OperUserId" :required="true"></select-input>
            </div>
            <component
              v-for="extra of extraComp"
              :is="extra.component"
              :ref="extra.ref"
              @change="extra.change"
            ></component>
        </div>
        <slot name="custom"></slot>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'booking-cover-main',
    components: {
    },
    data () {
        return {
            extraComp: []
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            operActions: state => state.main.operActions,
            managerActions: state => state.main.managerActions,
            recordStore: state => state.main.record,
            salesLeader: state => state.main.salesLeader,
        }),
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        }
    },
    watch: {
    },
    methods: {
        setStatus () {
        },
    }
}
</script>

