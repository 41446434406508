<template>
    <div class="form-row">
        <table class="table table-sm table-bordered " v-if="roomTypes && rooms">
            <thead>
                <tr class="table-secondary">
                    <th class="table-head-th">{{tr('Room')}}</th>
                    <th v-for="n in nights" class="table-head-th">
                        {{getNightsHeaders(n)}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(r, i) in getRoomsCombination" class="form-material">
                    <td class="hotel-table-row">
                        <span v-if="r.room.Comment">{{r.room.Name}} {{r.roomType.Name}}, {{r.room.Comment}}</span>
                        <span v-else>{{r.room.Name}} {{r.roomType.Name}}</span>
                    </td>
                    <td v-for="(n, k) in nights"  class="hotel-table-row-input">
                        <number-input
                            v-if="period.Data[[n, r.room.id, r.roomType.id]]"
                            class="m-0"
                            :v.sync="period.Data[[n, r.room.id, r.roomType.id]].value"
                            :currency="currency"
                            :id="r.cnt + '-' + k" :column="k" :row="r.cnt"
                        ></number-input>
                        <div v-else @click="setValue(n, r.room, r.roomType)">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</template>

<script>
const roomsMixin = importVueComp('components/tools', 'TableMixin');
export default {
    name: 'nights-room-type-category',
    props: ['roomTypes', 'rooms', 'period', 'nights', 'isModified'],
    mixins: [roomsMixin],
    data () {
        return {
            ready: false,
            currency: null,
        }
    },
    mounted () {
        this.currency = _.find(api.tables.currency, (r) => r.id == this.period.CurrencyId);
        let found;
        for (let roomType of this.roomTypes) {
            for (let row of this.rooms) {
                if (row.Closed) continue;
                for (let n of this.nights) {
                    if (!this.period.Data[[n, row.id, roomType.id]]) {
                        this.period.Data[[n, row.id, roomType.id]] = {value: null, roomClass: row, roomType: roomType, night: n};
                        found = true;
                    }
                }
            }
        }
        if (found) {
            this.period.isModified = true;
            this.$emit('setModified');
        }
        this.ready = true;
    },
    watch: {
        period: {
            handler () {
                this.period.isModified = true;
                this.$emit('setModified');
            },
            deep: true,
        },
    },
    computed: {
        getRoomsCombination () {
            let res = {};
            let cnt = 0;
            for (let room of this.rooms) {
                for (let roomType of this.roomTypes) {
                    for (let roomType of this.roomTypes) {
                        if (room && roomType) {
                            if (!res[[room.id, roomType.id]]) {
                                res[[room.id, roomType.id]] = {roomType: roomType, room: room, cnt};
                                cnt += 1;
                            }
                        };
                    }
                }
            }
            return res;
        }
    },
    methods: {
        getNightsHeaders (n) {
            if (n==1) return "1 " + tr('Night');
            if (parseInt(n)) return n + " " + tr('Nights');
            return n;
        },
        getId (i) {
            return i.split(',')[1]
        },
        async setValue (n, room, roomType) {
            this.period.Data[[n, room.id, roomType.id]] = {value: null, roomClass: room, roomType: roomType, night: n};
            this.period.Data = Object.assign({}, this.period.Data);
            await this.$nextTick();
            /*let id = this._uid + ' ' + n + '-' + room.id + '-' + roomType.id;
            setTimeout(() => {
                $('#' + id).focus();
            }, 100)*/
        }
    }

}
</script>
