<template>
    <div>
        <div class="card card-operations no-border-top p-3" v-if="recordStore">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12" id="oper-navs">
                    <div class="flex-column" id="operTabs" role="tablist" v-if="loaded">
                      <div class="text-center text-uppercase" v-if="sortedSupplierByServiceDate.length>0">
                          <label><b>{{tr('Services')}}</b></label>
                      </div>
                      <div class="border-bottom" v-for="id of sortedSupplierByServiceDate" v-if="!mixed[id]">
                        <div class="active oper-nav-link" :id="id" data-toggle="tab" :href="'#tab-' + id"
                        role="tab" :aria-controls="'tab-' + id" aria-selected="true" @click="setCurrent(id, 'S')">
                            <operations-service-link :supplier="suppliers[id]" :current="current == id && type=='S'"></operations-service-link>
                        </div>
                      </div>

                      <div class="text-center text-uppercase mt-3 mb-2">
                          <label><b>{{tr('Flights')}}</b></label>
                      </div>
                      <div class="" v-for="(flight, supplierId) of flights">
                        <div class="nav-link-left active oper-nav-link" :id="supplierId" data-toggle="tab" :href="'#tab-' + supplierId"
                        role="tab" :aria-controls="'tab-' + supplierId" aria-selected="true" @click="setCurrent(supplierId, 'F')">
                            <operations-flight-link :flight.sync="flight" :current="current == supplierId && type=='F'"></operations-flight-link>
                        </div>
                      </div>

                      <div class="text-center text-uppercase mt-3" v-if="Object.keys(mixed).length>0">
                          <label><b>{{tr('Hotels')}}/{{tr('Services')}}</b></label>
                      </div>
                      <div class="border-bottom" v-for="(row, id) of mixed">
                        <div class="nav-link-left active oper-nav-link" :id="id" 
                            data-toggle="tab" :href="'#tab-' + id"
                            role="tab" :aria-controls="'tab-' + id" aria-selected="true" 
                            @click="setCurrent(id, 'M')">
                            <operations-mixed-link :hotel="mixed[id].hotel" :supplier="mixed[id].supplier"
                                :current="current == id && type=='M'">
                            </operations-mixed-link>
                        </div>
                      </div>

                      <div class="text-center text-uppercase mt-3" v-if="sortedHotelsByDate.length>0">
                          <label><b>{{tr('Hotels')}}</b></label>
                      </div>
                      <div class="border-bottom" v-for="id of sortedHotelsByDate"  v-if="!mixed[id]">
                        <div class="nav-link-left active oper-nav-link" :id="id" data-toggle="tab" :href="'#tab-' + id"
                        role="tab" :aria-controls="'tab-' + id" aria-selected="true" @click="setCurrent(id, 'H')">
                            <operations-hotel-link :hotel="hotels[id]" :current="current == id && type=='H'"></operations-hotel-link>
                        </div>
                      </div>

                    </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 oper-tabs" :style="'overflow-y: scroll; min-height: 700px; max-height:' + height +'px'">
                    <operations-service :supplier.sync="suppliers[current]" v-if="current && type=='S'"></operations-service>
                    <operations-flight :flight.sync="flights[current]" :supplierId="current" v-else-if="current && type=='F'"></operations-flight>
                    <operation-hotel :hotel.sync="hotels[current]"v-else-if="current && type=='H'"></operation-hotel>
                    <operation-mixed v-else-if="current && type=='M' && mixed[current]"
                        :hotel.sync="mixed[current].hotel"
                        :supplier.sync="mixed[current].supplier"
                    ></operation-mixed>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const operationsService = importVueComp('components/booking', 'BookingOperationsServices');
const operationHotel = importVueComp('components/booking', 'BookingOperationsHotels');
const operationMixed = importVueComp('components/booking', 'BookingOperationsMixed');
const operationsFlight = importVueComp('components/booking', 'BookingOperationsFlights');
const operationsServiceLink = importVueComp('components/booking', 'BookingOperationsServicesNavLink');
const operationsHotelLink = importVueComp('components/booking', 'BookingOperationsHotelsNavLink');
const operationsMixedLink = importVueComp('components/booking', 'BookingOperationsMixedNavLink');
const operationsFlightLink = importVueComp('components/booking', 'BookingOperationsFlightsNavLink');
import { mapState } from 'vuex';

export default {
    name: 'booking-operations',
    components: {
      'operations-service': operationsService,
      'operation-hotel': operationHotel,
      'operation-mixed': operationMixed,
      'operations-flight': operationsFlight,
      'operations-service-link': operationsServiceLink,
      'operations-flight-link': operationsFlightLink,
      'operations-hotel-link': operationsHotelLink,
      'operations-mixed-link': operationsMixedLink
    },
    data () {
        return {
            loaded: false,
            current: null,
            type: null,
            height: 600,
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
        }),
        suppliers () {
            return this.recordStore.suppliers;
        },
        hotels  () {
            return this.recordStore.hotels;
        },
        flights () {
            return this.recordStore.flights;
        },
        sortedSupplierByServiceDate () {
            let suppliers = _.filter(this.suppliers, (r) => r.Supplier.id);
            let r = _.map(suppliers, (p) => {
                if (p.Services.length==0) return p;
                p.Services.sort(function(a, b) {
                    if (a.ServiceDate > b.ServiceDate) return 1;
                    if (a.ServiceDate < b.ServiceDate) return -1;
                    return 0;
                })
                p.FirstServiceDate = p.Services[0].ServiceDate;
                return p;
            })
            r.sort(function(a, b) {
                if (a.FirstServiceDate > b.FirstServiceDate) return 1;
                if (a.FirstServiceDate < b.FirstServiceDate) return -1;
                return 0;
            })
            let res = _.map(r, (p) => p.Supplier.id);
            return res;
        },
        sortedHotelsByDate () {
            let hotels = _.cloneDeep(this.hotels);
            let r = _.map(hotels, function(p){
                if (p.Rooms.length==0) return p;
                p.Rooms.sort(function(a, b) {
                    if (a.TransDate > b.TransDate) return 1;
                    if (a.TransDate < b.TransDate) return -1;
                    return 0;
                })
                p.TransDate = p.Rooms[0].TransDate;
                return p;
            })
            r.sort(function(a, b) {
                //if (a.Rooms.length==0) return 2;
                if (b.Rooms.length==0) return -2;
                if (a.TransDate > b.TransDate) return 1;
                if (a.TransDate < b.TransDate) return -1;
                return 0;
            })
            let res = _.map(r, (p) => p.Supplier.id);
            return res;
        },
        mixed () {
            let res = {};
            for (let hotelId in this.hotels) {
                let hotel = this.hotels[hotelId];
                let s = _.find(this.suppliers, (r) => {
                    return r.Supplier.id == hotel.Supplier.id;
                })
                if (s) {
                    res[hotel.Supplier.id] = {hotel, supplier: s};
                }
            }
            return res;
        },

    },
    mounted  () {
        this.loaded = true;
        setTimeout(() => {
            let h = $('#oper-navs').height();
            //$('#oper-navs').height(h);
            this.height = h;
        }, 100);
    },
    methods: {
        setCurrent (id, type) {
            this.current = id;
            this.type = type;
        }
    },
}
</script>
