<template>
    <div class="form-row" v-if="ready">
        <div class="col-4" v-for="field of filterFields" v-if="!field.hidden && current[field.name]">
             <b>{{tr(field.label)}}:</b> <i>{{getFilterValue(field)}}</i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'report-labels',
    props: ['record'],
    data () {
        return {
            fieldOptions: {},
            ready: false,
            current: null,
            fields: null,
        }
    },
    async mounted () {
        await this.onMount();
        this.ready = true;
    },
    computed: {
        filterFields () {
            if (!this.fields) return [];
            return tools.getAbmFields(this.fields);
        },
    },
    methods: {
        async onMount () {
            this.current = this.record.current;
            this.fields = this.record.reportFields;
            this.fieldOptions = await tools.calculateFieldOptions(this.filterFields);
        },
        getFilterValue (field) {
            let selectEditor;
            if (field.editor=='select') selectEditor = true;
            if (field.editor=='vue-select') selectEditor = true;
            if (selectEditor && this.fieldOptions[field.name]) {
                let row = _.find(this.fieldOptions[field.name], (r) => r.value == this.current[field.name]);
                if (row) return tr(row.label);
            }
            if (field.editor=='date') {
                let v = this.current[field.name];
                console.log(v)
                return `${v.days} ${tr('Days')}`;
                //return moment(this.current[field.name], "YYYY-MM-DD").format("DD/MM/YYYY")
            }
            return this.current[field.name]
        },

    },
}
</script>
