<template>
    <div>
        <font-awesome-icon icon="brush"/>
    </div>
</template>

<script>
export default {
    name: 'paste-format',
}
</script>
