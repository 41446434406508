<template>
      <report-window endpoint="/reports/cashflow"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        v-if="ready"
        :columnsClasses="columnsClasses"
        :afterRun="afterRun"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :slotsFields="[]"
        :current.sync="current"></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
  name: 'cash-flow-report',
  props: ['options'],
  components: {
    ReportWindow,
  },
  computed: {
    columnsClasses () {
        let res = {};
        for (let h of this.addTemplates) {
          res[h] = 'text-right';
        }
        return res;
    },
    templates () {
      let res = [
          { name: 'DueDate', callback: function(row) {
              if (!row.DueDate) return '';
              return moment(row.DueDate).format("DD/MM/YYYY")
            }
          },
          { name: 'PaymentDate', callback: function(row) {
              if (!row.PaymentDate) return '';
              return moment(row.PaymentDate).format("DD/MM/YYYY")
            },
          },
          { name: 'CashIn', callback: function(row) {
              if (!row.CashIn) return '';
              return tools.toNumber(row.CashIn, 2);
            },
          },
          { name: 'CashOut', callback: function(row) {
              if (!row.CashOut) return '';
              return tools.toNumber(row.CashOut, 2);
            },
          },
          { name: 'Balance', callback: function(row) {
              if (!row.Balance) return '';
              return tools.toNumber(row.Balance, 2);
            },
          },
      ];
      /*for (let h of this.addTemplates) {
          res.push({ name: h, callback: (row) => {
              if (!row[h]) return '';
              let v = row[h];
              if (row.Detail == 'Saldo' && this.current.Balance) {
                v += this.current.Balance;
              }
              return tools.toNumber(v, 2);
            }
          })
      }*/
      return res;
    }
  },
  mounted: async function() {
    frontTools.setReportValues(this);
    this.ready = true;
  },
  data () {
    let self = this;
    return {
        ready: false,
        current: {},
        onAccountOptions: [],
        addTemplates: [],
        fields: [
          {name: 'FromDate', editor: 'date', label: 'From', required: true},
          {name: 'ToDate', editor: 'date', label: 'To', required: true},
          {name: 'CurrencyId', relation: 'currency', optionLabels: 'Name'
              , label: 'Currency', required: true, editor: 'select'},
          {name: 'CurrencyType',
              options : [
                  {label: 'Por Moneda', value: 'currency'},
                  {label: 'Consolidado', value: 'all'},
              ]
              , label: 'Ver', required: true, editor: 'select'},
          {name: 'PaymentTypeId', relation: 'paymenttype', optionLabels: 'Name'
              , label: 'Payment Type', editor: 'select', addBlank: true},
          {name: 'Balance', editor: 'number', label: 'Saldo Inicial'},
        ],
        title: 'Cash Flow',
        headers: {
            '0Due': 'Vencido',
        },
        fieldClasses: {
        },
      }
    },
    methods: {
        afterRun (report) {
            for (let row of report.dataList) {
              for (let id in row) {
                  if (report.serverColumnsHeader.indexOf(id)>-1) {
                      if (id != 'Detail') {
                          if (this.addTemplates.indexOf(id)==-1) {
                            this.addTemplates.push(id)
                          }
                      }
                  }
              }
            }
            for (let h of this.addTemplates) {
              report.columnsClasses[h] = 'text-right';
            }
        }

    }
}
</script>

