<template>
  <h2 class="entry-title mt-1 not-break-inside row">
    <div class="col-12">
        <span class="only-print">{{getDaysNumbers}} - </span>
        <span v-if="recordStore.ShowDates">
            <span v-if="!day.GroupDays">{{getDatesText()}} - </span>
            <span v-else class="multi-dates">{{getDatesText()}} - </span>
        </span>
        {{day.citiesNames}}
    </div>
    <div class="col-12 day-title" v-if="day.Title">
        <i> {{day.Title}}</i>
    </div>
  </h2>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: 'itinerary-body-day-title',
    props: ['day'],
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        getDaysNumbers () {
            if (this.day.GroupDays && this.day.GroupDayTo) {
                return `${this.tr('_From')} ${this.tr('Day')} ${this.day.DayNr + 1} ${this.tr('_TO')} ${this.day.GroupDayTo}`;
            }
            return `${this.tr('Day')} ${this.day.DayNr + 1}`
        },

    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getDayMonth (day) {
            return tools.toTitleCase(moment(this.recordStore.StartDate).add(day.DayNr,'days').locale(this.recordStore.Language.Code).format("MMM"));
        },
        getDayNr (day) {
            return moment(this.recordStore.StartDate).add(day.DayNr,'days').format("DD");
        },
        getDatesText () {
            if (this.day.GroupDays && this.day.GroupDayTo) {
                let r1 = this.getDayMonth(this.day) + ' ' + this.getDayNr(this.day);
                let r2 = '';
                let dayTo = _.find(this.recordStore.BookingDays, (p) => p.DayNr == this.day.GroupDayTo - 1);
                if (dayTo) {
                    r2 = this.getDayMonth(dayTo) + ' ' + this.getDayNr(dayTo);
                }
                return `${this.tr('From')} ${r1} ${this.tr('TO_')} ${r2}`;
            }
            return this.getDayMonth(this.day) + ' ' + this.getDayNr(this.day);
        },
   },
}
</script>


