<template>
    <div class="fstyles" @contextmenu="handleClick($event)">
        <button type="button" class="btn btn-primary action-button button-fixed" @click="printPDF">
            <font-awesome-icon icon="print"/>
        </button>
        <div class="row ml-2 divPrint report-header text-center">
            <div class="col-12">
                <img src="@/extra/custom/img/logo-full.png" class="py-0 logo">
            </div>
        </div>
        <table class="w-100 container">
          <thead>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr><td class="container" v-if="recordStore">
                <div class="row not-print text-center">
                    <div class="col-12">
                        <img src="@/extra/custom/img/logo-full.png" class="py-0 logo">
                    </div>
                </div>
                <booking-final-preview
                ></booking-final-preview>
                <div class="row mt-2 footer-text not-print text-center">
                    <div class="col-12">
                        <span>MATER SUSTENTABLE EVT– Legajo 17319</span>
                    </div>
                    <div class="col-12">
                        <u><a target="_blank" href="www.mater.travel">www.mater.travel</a></u>
                    </div>
                </div>
            </td></tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tfoot>

        </table>
        <div class="row ml-2 footer-text divPrint report-footer text-center">
            <div class="col-12">
                <span>MATER SUSTENTABLE EVT– Legajo 17319</span>
            </div>
            <div class="col-12">
                <u><a target="_blank" href="www.mater.travel">www.mater.travel</a></u>
            </div>
        </div>
    </div>
</template>

<script>
let bookingFinalPreview = importVueComp('components/documentation', 'BookingFinalPreview');
import '@/extra/custom/css/fstyles.scss';
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-final-view',
    props: ['id', 'hash'],
    components: {
        'booking-final-preview': bookingFinalPreview
    },
    data () {
        return {}
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        langCode () {
           let r = _.find(api.tables.language, (c) => c.id == this.recordStore.LanguageId)
           if (r) return r.Code;
        },
    },
    async mounted () {
        await this.mount();
        this.afterMount();
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        printPDF () {
            window.print();
        },
        async mount() {
            api.setProcessing(true)
            let model = await api.importMixinModule('booking', 'model');
            let r = await api.get('/public/api/get_booking/' + this.hash + '/' + this.id);
            if (r) {
                let record = await new model({record: r, notFieldOptions: true});
                this.$store.commit('initRecord', {record});
            }
            api.setProcessing(false)
        },
        afterMount () {
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        }

    },
    beforeDestroy() {
        this.$store.commit('initRecord', {record: null});
    },

}
</script>

