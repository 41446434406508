<template>
    <div v-if="table" id="data-table" class="table-wrapper-2 table-editor-responsive" v-on:scroll="scrollFunction">
        <search-box class="form-row" addClass="col-md-10" v-model="searchBox"></search-box>
        <table class="table table-striped table-sm table-editor scroll-x">
            <thead>
                <tr class="table-secondary">
                    <th v-for="(v, i) in table.header" class="table-head-th text-center" :class="v.className"
                      @contextmenu.prevent="handleClick($event, {i: i, v: v})"
                    >
                        <div style="{display: inline;">
                            <span>{{v.value}}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) in table.rows" v-if="!row.hidden.value && !row.Closed.value && search(row)"
                    class="form-material" @click="rowClicked(i)"
                    :class="{'row-border': currentRow == i, 'row-modified': row.modified.value}">
                    <td v-for="(j, k) in row.values" :bgcolor="getBGColor(j)" :align="getAlign(j)" class="table-row form-group form-default border"
                        :class="getClassName(j, i)" v-if="!j.hidden" :style="getStyle(j)"
                        @contextmenu.prevent="handleClick2($event, {i: i, fieldname: j.fieldname})">
                        <input v-if="(j.editable || j.value) && (!j.editor || j.editor=='text')"
                            v-model="j.value" class="table-input form-control"
                            @change="$emit('changed', i, k, tableId)"
                            :column="k" :row="i" :id="i + '-' + k" type="text" >
                        </input>
                        <number-input
                            v-else-if="j.editor=='number' && ((j.editable || j.value))" class="table-input form-control my-0"
                            @change="$emit('changed', i, k, tableId)"
                            :column="k" :row="i" :id="i + '-' + k"
                            :v.sync="j.value"
                            :currency="findCurrency(row)"
                            :disabled="!(j.editable==true)"
                        ></number-input>
                        <date-picker v-else-if="j.editable && j.editor=='date'" :def.sync="j.value" @change="$emit('changed', i, k, tableId)"
                        :column="k" :row="i" :id="i + '-' + k" ></date-picker>
                        <select-input v-else-if="j.editable && j.editor=='select'" :fieldOptions="j.fieldOptions[j.fieldName]"
                        class="my-0" :addBlank="j.addBlank"
                        label="" :currentValue.sync="j.value" :id="i + '-' + k" :fieldName="j.fieldName" @change="$emit('changed', i, k, tableId)">
                        </select-input>
                        <x-mark v-else-if="!(j.editable==true) && !j.value" class="x-mark"></x-mark>
                        <span v-else>{{getValue(j)}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <!--vue-context ref="menu">
          <ul slot-scope="child">
              <li @click="remove($event.target.innerText, child.data)">{{tr('Delete')}}</li>
          </ul>
        </vue-context>
        <vue-context ref="delete">
          <ul slot-scope="child">
              <li @click="removeBase(child.data)">{{tr('Delete')}}</li>
          </ul>
        </vue-context-->
        <context-menu
            v-show="baseMenu"
            :options="menuOptionsBases"
            ref="contextMenuBases"
            @clicked="menuClicked"
        ></context-menu>
        <context-menu
            v-show="rowsMenu"
            :options="menuOptionsRows"
            ref="contextMenuRows"
            @clicked="menuClicked"
        ></context-menu>
    </div>
</template>

<script>
import xMark from '@/components/svg/XMark';
const tableMixin = importVueComp('components/tools', 'TableMixin');

export default {
    name: 'table-editor',
    props: ['table', 'tableId'],
    mixins: [tableMixin],
    components: {
      'x-mark': xMark
    },
    data () {
        return {
            cols: 6,
            searchBox: null,
            currentRow: null,
            menuOptionsBases: [
                {label: tr('Delete base'), name: 'deleteBase'},
                //{label: tr('Edit base'), name: 'editBase'}
            ],
            menuOptionsRows: [
                {label: tr('Delete row'), name: 'deleteRow'},
                {label: tr('Delete all rows'), name: 'deleteAllRow'},
                {label: tr('Delete empty rows'), name: 'deleteEmptyRow'},
            ],
            rowsMenu: false,
            baseMenu: false,
        }
    },
    computed: {
        maxNameLength () {
            let res = 0;
            if (!this.table) return 0;
            if (!this.table.rows) return 0;
            for (let id in this.table.rows) {
                let row = this.table.rows[id];
                let field = _.find(row.values, (r) => r.fieldName == 'Name');
                if (field && field.value) {
                    if (field.value.length > res) res = field.value.length;
                }
            }
            return res;
        },
    },
    methods: {
        getStyle (j) {
            if (j.fieldName != 'Name') return;
            return `min-width: ${this.maxNameLength * 6}px !important;`
        },
        rowClicked (i) {
            this.currentRow = i;
        },
        findCurrency(row) {
            let currencyId = _.find(row.values, (r) => {
                if (r.fieldName == 'CurrencyId') return r.value;
            })
            if (currencyId) {
                return _.find(api.tables.currency, (r) => r.id == currencyId);
            }
        },
        scrollFunction () {
            var main_div = $('#data-table')[0];
            var vTop = main_div.scrollTop;
            var hLeft = main_div.scrollLeft;
            this.setPosition('table-head-th', $('.table-head-th').length, this.cols, vTop, hLeft, "#d6d8db")
            //for (var i=0;i<self.table.rows.length;i++) {
            for (let i in this.table.rows){
                this.setPosition('row' + i, this.cols, this.cols, null, hLeft, "white");
            }
        },
        setPosition (headClass, colsNr, k, vTop, hLeft, background) {
            var cols = $('.' + headClass )
            for (var i=0; i<colsNr; i++){
                if (headClass=='table-head-th'){
                    if (i<k) cols[i].style = "z-index: 4;"
                    else cols[i].style = "z-index: 3;"
                    cols[i].style.top = vTop + "px";
                }else{
                    if (i<k && cols[i]) {
                        cols[i].style = "z-index: 2;"
                    }
                }
                if (i<k && cols[i]){
                    cols[i].style.left = hLeft + "px";
                }
                if (cols[i] && cols[i].style) {
                    cols[i].style.background = background;
                }
            }
        },
        getValue (v) {
            return tools.getValue(v);
        },
        getBGColor (v) {
            return tools.getBGColor(v);
        },
        getAlign (v) {
            return tools.getAlign(v);
        },
        getClassName (v, i) {
            let res = '';
            if (v.editable) {
                res += 'table-td-input row' + i;
            } else {
                res +=  'table-td-input row' + i;
            }
            if (v.className) {
                res += ' ' + v.className;
            }
            return res;
        },
        getRowClassName (row) {
            if (row.modified.value) return 'row-modified';
            return '';
        },
        remove (data) {
            this.$emit('remove', data.i, data.fieldName, this.tableId)
        },
        removeAll (data) {
            this.$emit('removeAll')
        },
        removeEmpty (data) {
            this.$emit('removeEmpty')
        },
        removeBase (data) {
            this.$emit('removeBase', data.i, this.table.header[data.i].value)
        },
        sorted (list) {
            return list;
        },
        getDisplayValue (field) {
            if (field.editor=='select' && field.fieldOptions) {
                if (field.fieldOptions[field.fieldName]) {
                    for (let option of field.fieldOptions[field.fieldName]) {
                        if (option.value == field.value) {
                            return option.label;
                        }

                    }
                }
            }
            return field.value;
        },
        search (row) {
            if (!this.searchBox) return true;
            if (this.searchBox.length < 3) return true;
            let searchBox = this.searchBox.replace(/\./g, ' ')
            let values = searchBox.split(' ')
            for (let value of values){
                let found = _.find(row.values, (r) => {
                    let re = new RegExp(tools.normalize(value), 'i')
                    let displayValue = this.getDisplayValue(r);
                    if (displayValue){
                        let m = tools.normalize(displayValue).match(re)
                        if (m) return true;
                    }
                })
                if (!found) return false;
            };
            return true;
        },
        handleClick (event , obj) {
            this.baseMenu = true;
            this.rowsMenu = false;
            if (obj.i < 5) return;
            if (obj.v && obj.v.value == 'ADT') return;
            if (obj.v && obj.v.value == 'CHD') return;
            setTimeout(() => {
                if (this.$refs.contextMenuBases) {
                    this.$refs.contextMenuBases.showMenu(event, obj);
                }
            }, 100);
        },
        handleClick2 (event , obj) {
            this.baseMenu = false;
            this.rowsMenu = true;
            setTimeout(() => {
                if (this.$refs.contextMenuRows) {
                    this.$refs.contextMenuRows.showMenu(event, obj);
                }
            }, 100);
        },
        menuClicked (option, item) {
            this.baseMenu = false;
            this.rowsMenu = false;
            if (option.name == 'deleteBase') {
                this.removeBase(item);
            }
            if (option.name == 'editBase') {
                console.log(item)
                //this.removeBase(item);
            }
            if (option.name == 'deleteRow') {
                this.remove(item);
            }
            if (option.name == 'deleteAllRow') {
                this.removeAll();
            }
            if (option.name == 'deleteEmptyRow') {
                this.removeEmpty();
            }
        }
    },
}
</script>

<style scoped>
    .scroll-x {
        orverflow: scroll;
    }
    .x-mark {
        max-width: 30px;
        max-height: 30px;
        position: relative;
        display: block;
        width: 100%;
        left: 30%;
    }
    .row-border td {
        //border-top:  2px solid blue !important;
        border-bottom:  2px solid blue !important;
    }
    .table-row {
        padding: 0.1rem !important;
    }
</style>