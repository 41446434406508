<template>
  <div :class="divClass" :id="'crop-' + _uid">
    <img :id="'photo-crop-' + _uid" :class="imgClass" :src="url" v-show="show" v-if="!gallery">
    <div v-show="show" v-if="gallery"
      :id="'gallery-crop-' + _uid"
      :key="imageIndex"
      @click="$emit('set', imageIndex)"
    >
        <img :id="'photo-crop-' + _uid" :src="url" :class="imgClass">
    </div>
    <div v-if="image && h && w && crop">
        <croppa v-model="myCroppa"
            :width="w"
            :height="h"
            :quality="1"
            :disable-drag-and-drop="true"
            :disable-click-to-choose="true"
            :disable-drag-to-move="true"
            :disable-scroll-to-zoom="true"
            :disable-rotation="true"
            :show-remove-button="false"
            :initial-image="image"
        ></croppa>
     </div>
  </div>
</template>

<script>
export default {
    name: 'photo-croppa',
    props: ['url', 'crop', 'imgClass', 'gallery', 'imageIndex', 'divClass', 'resize'],
    components: {
    },
    mounted () {
    },
    data () {
        return {
            myCroppa: {},
            image: null,
            show: true,
            w: null,
            h: null,
        }
    },
    watch: {
        url () {
            if (!this.w && !this.h) this.setStyles();
            this.setCroppa()
        },
        crop () {
            if (!this.w && !this.h) this.setStyles();
            this.setCroppa()
        }
    },
    methods: {
        setStyles () {
            let id = '#photo-crop-' + this._uid;
            let e = $(id)[0];
            if (!e) return;
            let k = 1;
            if (!this.gallery && this.resize) k = 0.85;
            this.h = e.height * k;
            this.w = e.width * k;
            if (this.h > 300) {
                let r = this.w / this.h;
                this.h = 300;
                this.w = this.h * r;
            }
        },
        async setCroppa () {
            let self = this;
            let e = document.getElementById('photo-crop-' + this._uid);
            var img = new Image();
            img.onload = e => {
                self.image = img;
                if (this.crop) {
                    self.show = false;
                } else {
                    self.show = true;
                }
            };
            let src = e.src;
            if (!src && this.url) src = this.url;
            img.src = src;
            if (!this.resize) {
                let id = '#crop-' + this._uid;
                setTimeout(function() {
                    let e1 = $(id + ' .croppa-container');
                    if (e1[0]) {
                        e1[0].setAttribute('style', 'width: 100%');
                    }
                    let e2 = $(id + ' .croppa-container canvas');
                    if (e2[0]) {
                        e2[0].setAttribute('style', 'width: 100%; width: 100%; background-color: transparent');
                    }
                }, 1000);
            }
        }
    }
}
</script>

