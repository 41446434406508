<template>
    <ul class="breadcrumb desktop">
        <li class="breadcrumb-item">
            <a class="cursor-pointer" @click="redirect('home')"><font-awesome-icon icon="home"/> {{tr('Home')}}</a>
        </li>
        <li class="breadcrumb-item" v-for="t of list" @click="redirectPath(t.route)" :class="{'cursor-pointer': t.route}">
            {{tr(t.title)}}
        </li>
    </ul>
</template>

<script>
export default {
    props: ['list'],
    name: 'breadcrumb',
    components: {
    },
    computed: {
    },
    data () {
        return {
        }
    },
    methods: {
        redirect (endpoint) {
            if (!endpoint) return;
            if (this.$router.currentRoute.name == endpoint) return;
            this.$router.push({ name: endpoint})
        },
        redirectPath (endpoint) {
            if (!endpoint) return;
            this.$router.push({ path: endpoint})
        },
    }
}
</script>

