<template>
    <div class="my-1 px-2 pb-2">
        <span class="mr-1 font-weight-bold">{{row.CreatedUser.UserName}}:</span>
        <div v-if="notes.length==1">
            <span class="mr-1 font-weight-bold" v-if="row.UserId">@{{row.User.UserName}}</span>
            <span v-for="n in notes">{{n}}</span>
            <span v-if="row.SupplierId" class="mr-1 font-italic font-weight-bold"><br>{{tr('supplier')}}: {{row.Supplier.Name}}</span>
        </div>
        <div v-else>
            <div v-for="n in notes" class="my-0 py-0 row-note"><span>{{n}}</span></div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-0">
          <span>{{dateFormat(row.CreatedUTC)}}</span>
              <div class="mr-3">
                  <i class="add-item cursor-pointer" @click="reply">
                     <font-awesome-icon icon="reply" size="xs"/>
                  </i>
                  <i class="add-item cursor-pointer ml-4" @click="editNote">
                     <font-awesome-icon icon="edit" size="xs"/>
                  </i>
                  <i class="add-item cursor-pointer ml-4" @click="setImportant">
                     <font-awesome-icon icon="bell" size="xs" :class="{'row-primary-button': row.Important}"/>
                  </i>
                  <i class="add-item cursor-pointer ml-4 text-danger" @click="deleteNote" v-if="canRemove">
                     <font-awesome-icon icon="trash-alt"  size="xs"/>
                  </i>
              </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'booking-note',
    props: ['row', 'canRemove', 'mainId', 'replyToUserId'],
    data () {
        return {
            breakNote: false,
        }
    },
    methods: {
        deleteNote (i) {
            this.$emit('deleteNote', this.row.id);
        },
        reply (i) {
            this.$emit('reply', this.mainId? this.mainId: this.row.id, this.replyToUserId);
        },
        editNote (i) {
            this.$emit('editNote', this.row.id);
        },
        setImportant (i) {
            this.$emit('setImportant', this.row.id);
        },
        dateFormat (d) {
            return moment.utc(d).local().format("DD/MM/YYYY HH:mm");
        },
    },
    computed: {
        notes () {
            if (this.row && this.row.Note) {
                if (!this.breakNote) return [this.row.Note];
                return this.row.Note.split('\n')
            };
            return [];
        }
    }
}
</script>
<style scoped>
.row-note {
    line-height: 1.1;
    margin-bottom: 0;
    padding-bottom: 0;
}
</style>
