<template>
    <section id="faq" class="faq">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="accordion accordion-flush" id="faqlist1">
              <div class="accordion-item">
                <h2 class="accordion-header cursor-pointer border-bottom">
                  <div class="accordion-button collapsed d-flex"  data-toggle="collapse" data-target="#faq-content-1">
                    <span>{{tr('Terms and Conditions')}}</span>
                  </div>
                </h2>
                <div id="faq-content-1" class="accordion-collapse collapse" data-parent="#faqlist1">
                  <div class="accordion-body">
                    <div class="d-flex align-items-center mb-2">
                      <i class="bi bi-check2"></i>
                      <text-rows-style :text="getTermsAndConditions"></text-rows-style>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: ['expanded', 'id', 'version', 'crop'],
    name: 'itinerary-conditions',
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        getTermsAndConditions () {
            if (!this.recordStore) return '';
            let r = _.find(api.tables.termsandconditions, (c) => c.LanguageId == this.recordStore.LanguageId);
            if (r) return r.Terms;
            return '';
        },
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        async showBookingForm () {
            let res;
            if (this.recordStore.BookingFormId) {
                res = this.recordStore.BookingFormId;
            } else {
                res = await api.post('/api/booking/create_booking_form/' + this.recordStore.id, {})
            }
            if (res) {
                let route = this.$router.resolve({name: 'reservation-form'
                    , params: {hash: this.recordStore.Hash, id: this.recordStore.id, langCode: this.recordStore.Language.Code}})
                window.open(route.href, '_blank');
            }
        },
        expand () {
            $('#faq-content-1').collapse('show');
        }
    },
    watch: {
        expanded () {
            if (this.expanded) {
                this.expand();
            }
        }
    }
}
</script>
