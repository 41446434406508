const surveyPaxView = importVueComp('components/tools', 'SurveyPaxView');

function getCustomRoutes () {
    let res = [];
    res.push({ path: '/public/survey/:hash/:id/:langCode', name: 'survey-pax-view',
            meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true},
            component: surveyPaxView, props: true });
    return {routes: res, remove: []};
}

export default getCustomRoutes();