<template>
    <div id="fstyles" v-if="langCode">
        <button type="button" class="btn btn-primary action-button button-fixed" @click="printPDF">
            <font-awesome-icon icon="print"/>
        </button>


        <div class="container title-box">
            <div class="row">
                <div class="col-1">
                    <img src="@/extra/custom/img/logo.png" alt="aotg logo" style="width: 50px">
                </div>
                <div class="col-11">
                    <h1>Itinerary & Confirmation</h1>
                </div>
            </div>
        </div>

        <div v-if="booking && !processing">
            <bookingfinalpreview></bookingfinalpreview>
        </div>

        <footer>
            <img :src="require(`@/extra/custom/img/itinerary/logo-footer.png`)">
        </footer>

    </div>
</template>

<script>
let bookingFinalView = importVueComp('components/documentation', 'BookingFinalView', 'custom');
import '@/extra/custom/css/fstyles.scss';
import { mapState } from 'vuex';
export default {
    name: 'custom-booking-final-view',
    mixins: [BookingFinalView]
}
</script>
