<template>
    <div class="form-group form-default d-flex">
        <input type="color" id="colorWell" value="#FF0000" class="vue-color">
        <div class="w-100">
          <input type="text"
              v-model="color"
              class="form-control fill pl-2"
              @change="changedColor">
                  <span class="form-bar"></span>
              </input>
              <label v-if="label" class="float-label">{{tr(label)}}</label>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'color-picker',
    props: ['def', 'label'],
    data () {
        return {
            color: '#FF0000',
        };
    },
    mounted () {
        this.startup();
    },
    watch: {
        def () {
            if (this.def) {
                this.startup();
            }
        }
    },
    methods: {
        startup () {
            if (this.def) this.color = this.def.substring(0, 7);
            let colorWell = document.querySelector("#colorWell");
            colorWell.value = this.color;
            colorWell.addEventListener("input", this.updateFirst, false);
            colorWell.select();
        },
        updateFirst(event) {
            this.color = event.target.value;
            this.$emit('update:def', this.color);
            this.$emit('change');
            //this.recordStore.setValue({fieldName: this.field.name, value: event.target.value});
        },
        changedColor () {
            this.$emit('update:def', this.color);
            this.$emit('change');
            //this.recordStore.setValue({fieldName: this.field.name, value: this.color});
        },
    },
}
</script>

