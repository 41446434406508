<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/user_session"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :slotsFields="slotsFields"
            :updateReport="updateReport"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "user-session",
    props: [],
    components: {
        ReportWindow,
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ready: false,
            fields: [
                {name: 'FromDate', editor: 'date', label: 'From', required: true},
                {name: 'ToDate', editor: 'date', label: 'To', required: true},
                {name: 'UserId', label: 'User', editor: 'vue-select', addBlank: true,
                    optionLabels: 'UserName', relation: 'user',
                },
                {name: 'Device', editor: 'checkbox', label: 'Device'},
            ],
            title: "User Sessions",
            headers: {
            },
            templates: [
                {name: 'MinDate', callback: (row) => {
                        if (!row.MinDate) return '';
                        return moment(row.MinDate).format("DD MMM HH:mm");
                    }
                },
                {name: 'MaxDate', callback: (row) => {
                        if (!row.MaxDate) return '';
                        return moment(row.MaxDate).format("DD MMM HH:mm");
                    }
                },
            ],
            columnClick: {},
            slotsFields: [],
        };
    },

};
</script>
