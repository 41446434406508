<template>
    <div v-if="user">
      <report-window
        v-if="ready"
        endpoint="/reports/servicelistquote"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :perPage="500"
        :headerColumns="headerColumns"
        :current.sync="current"
        :slotsFields="[]">
      </report-window>
    </div>

</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
  name: 'service-list-quote-report',
  props: ['options', 'cardTitle'],
  components: {
    ReportWindow,
  },
  computed: {
      ...mapState({
          managerActions: state => state.main.managerActions,
          adminActions: state => state.main.adminActions,
          operManager: state => state.main.operManager,
          user: state => state.main.user,
      }),
  },
  mounted: function() {
    frontTools.setReportValues(this);
    if (this.user.Department && this.user.Department.Sales && !this.adminActions && !this.operManager) {
        this.current.SalesUserId = this.user.id;
        let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
        field.disabled = true;
    }
    this.fields = Object.assign([], this.fields);
    this.ready = true;

  },
  data () {
    let bookingView = api.importMixinModule('booking', 'views');
    let bookingDayServiceView = api.importMixinModule('bookingdayservice', 'views');
    let bookingStatusOptions = bookingView.bookingStatusOptions;
    return {
        current: {},
        ready: false,
        headerColumns: ['UserName', 'BookingStatus', 'File', 'PaxName', 'ServiceDate', 'ServiceName', 'ServiceCategory',
            'SupplierName', 'Status', 'PaxType', 'Base', 'Modified', 'CurrencyId', 'QuoteCost', 'SupplierCost'],
        fields: [
          {name: 'FromDate', editor: 'date', label: 'From', columns: 6},
          {name: 'ToDate', editor: 'date', label: 'To', columns: 6},
          {name: 'BookingId', editor: 'number', label: 'File', columns: 6},
          {name: 'ServiceStatus', label: 'Service Status', editor: 'select', columns: 6,
                  options: bookingDayServiceView.serviceStatusOptions, addBlank: true},
          {name: 'BookingStatus', label: 'File Status', editor: 'select', columns: 6,
                  options: bookingStatusOptions, addBlank: true},
          {name: 'SalesUserId', relation: 'user', optionLabels: 'UserName', filters: (row) => {return !row.Closed}
              , editor: 'select', label: 'Seller', addBlank: true, columns: 6},
          {name: 'Modified', label: 'Modified', editor: 'select', columns: 6,
                  options: [
                      {label: 'YES', value: 'YES'},
                      {label: 'NO', value: 'NO'},
                       ], addBlank: true},
          {name: 'UnderQuoted', label: 'Under Quoted', editor: 'select', columns: 6,
                  options: [
                      {label: 'YES', value: 'YES'},
                       ], addBlank: true},
          {name: 'Margin', editor: 'number', label: 'Margin', columns: 6},
          {name: 'SupplierId', relation: 'supplier', optionLabels: 'Name'
              , label: 'Supplier', addBlank: true, editor: 'vue-select'}
              ],
        title: 'Service List Quote',
        headers: {
          UserName: 'Seller',
          PaxName: 'Passenger',
          ServiceName: 'Service',
          BookingStatus: 'File Status',
          Status: 'Service Status',
          SupplierName: 'Supplier',
          ServiceCategory: 'Category',
          CurrencyId: 'Currency',
          SupplierCost: 'Fares',
          QuoteCost: 'Quoted',
          CurrencyRate: 'Rate',
        },
        templates: [
            {name: 'Modified', callback: function(row) {
                if (!row.Modified) return '';
                return tr('YES');
              }
            },
            {name: 'ServiceDate', callback: function(row) {
                if (!row.ServiceDate) return '';
                return moment(row.ServiceDate).format("DD/MM/YYYY")
              }
            },
            {name: 'Status', callback: function(row) {
                return tr(row.Status);
            }},
            {name: 'BookingStatus', callback: function(row) {
                return tr(row.BookingStatus);
            }},
        ]
    }
  },
}
</script>

