<template>
    <div>
        <div class="form-row form-material">
            <div class="form-group form-default mb-0" :class="{'col-md-3': compressView, 'col-md-12': !compressView}">
                <photo-div
                    :disabled="!canEdit"
                    :image.sync="recordStore.BookingDays[dayId].Image"
                    :imageId.sync="recordStore.BookingDays[dayId].ImageId"
                    :showDelete="true"
                ></photo-div>
            </div>
            <div class="form-group form-default" :class="{'col-md-9 my-2': compressView, 'col-md-12': !compressView}">
                <div class="col-md-12 mb-1 ml-2 mt-2 form-row">
                    <div class="col-3" v-if="recordStore.SkipDays">
                        <input v-model="day._DayNr" class="form-control text-right" type="number" :class="{'fill': day._DayNr}">
                            <label class="float-label ml-3">{{tr('Day Nr.')}}</label>
                        </input>
                    </div>
                    <div :class="{'col-9': recordStore.SkipDays, 'col-12': !recordStore.SkipDays}">
                        <input
                            v-model="recordStore.BookingDays[dayId].Title"
                            name="Title"
                            class="form-control"
                            :class="{'fill': recordStore.BookingDays[dayId].Title}"
                            type="text"
                            :maxlength="recordStore.BookingDays[dayId].fieldsObject.Title.length">
                            <span class="form-bar"></span>
                            <label class="float-label pl-3">{{tr('Title')}}</label>
                        </input>
                    </div>
                </div>
                <div class="col-md-12 pl-5">
                    <div class="form-row">
                        <day-option-checkbox :class="cols"
                            label="Breakfast"
                            :day.sync="day"
                            :getter="day.breakfast"
                            :canEdit="canEditOptions"
                            fieldName="Breakfast"
                        ></day-option-checkbox>
                        <day-option-checkbox :class="cols"
                            label="Lunch"
                            :day.sync="day"
                            :getter="day.lunch"
                            :canEdit="canEditOptions"
                            fieldName="Lunch"
                        ></day-option-checkbox>
                        <day-option-checkbox :class="cols"
                            label="Dinner"
                            :day.sync="day"
                            :getter="day.dinner"
                            :canEdit="canEditOptions"
                            fieldName="Dinner"
                        ></day-option-checkbox>
                        <day-option-checkbox :class="cols"
                            label="Snack"
                            :day.sync="day"
                            :getter="day.snack"
                            :canEdit="canEditOptions"
                            fieldName="Snack"
                        ></day-option-checkbox>
                        <day-option-checkbox :class="cols"
                            label="Box Lunch"
                            :day.sync="day"
                            :getter="day.boxLunch"
                            :canEdit="canEditOptions"
                            fieldName="BoxLunch"
                        ></day-option-checkbox>
                        <day-option-checkbox :class="cols" v-if="day.entranceIncluded"
                            label="Entrance Included"
                            :day.sync="day"
                            :getter="day.entranceIncluded"
                            :canEdit="canEditOptions"
                            fieldName="EntranceIncluded"
                        ></day-option-checkbox>
                        <div class="form-check" :class="cols" v-else>
                            <input v-model="recordStore.BookingDays[dayId].EntranceIncluded" name="EntranceIncluded"
                            :disabled="!canEditOptions"
                            class="form-check-input" type="checkbox" id="EntranceIncluded">
                            <label class="smaller form-check-label">{{tr('Entrance Included')}}</label>
                        </div>
                        <div class="form-check" :class="cols">
                            <input v-model="recordStore.BookingDays[dayId].ShowOptionals" name="ShowOptionals"
                            :disabled="!canEdit"
                            class="form-check-input" type="checkbox" id="ShowOptionals">
                            <label class="smaller form-check-label">{{tr('Optionals')}}</label>
                        </div>
                        <div class="form-check" :class="cols" v-for="extra of extras">
                            <input v-model="recordStore.BookingDays[dayId][extra]" :name="extra"
                            :disabled="!canEditOptions"
                            class="form-check-input" type="checkbox" :id="extra">
                            <label class="smaller form-check-label">{{tr(extra)}}</label>
                        </div>
                        <div class="form-check" :class="cols">
                            <input v-model="recordStore.BookingDays[dayId].GroupDays" name="GroupDays"
                            :disabled="!canEdit"
                            class="form-check-input" type="checkbox" id="GroupDays">
                            <label class="smaller form-check-label">{{tr('Group Days')}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4" v-if="recordStore.BookingDays[dayId].GroupDays">
                            <input v-model="recordStore.BookingDays[dayId].GroupDayTo" class="form-control"
                              :disabled="!canEdit"
                              :class="{'fill': recordStore.BookingDays[dayId].GroupDayTo}"
                              type="number">
                              <span class="form-bar"></span>
                              <label class="float-label pl-3">{{tr('To Day Nr.')}}</label>
                            </input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
let dayOptionCheckbox = Vue.component('day-option-checkbox', {
    props: ['day', 'canEdit', 'label', 'fieldName', 'getter'],
    template: `
        <div class="form-check">
            <input v-model="day[fieldName]" name="fieldName" v-show="day[fieldName]" :disabled="!canEdit"
            class="form-check-input" type="checkbox" id="fieldName">
            <input :checked="getter" name="fieldName" v-show="!day[fieldName]" disabled
            class="form-check-input" type="checkbox" id="fieldName">
            <label class="smaller form-check-label">{{tr(label)}}</label>
        </div>
    `,
})


import { mapState } from 'vuex';
export default {
    name: 'booking-day-options',
    props: ['dayId', 'dayNr', 'compressView'],
    components: {
        'day-option-checkbox': dayOptionCheckbox,
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
            operActions: state => state.main.operActions,
        }),
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        },
        canEditOptions () {
            return this.canEdit;
        },
        day () {
            return this.recordStore.BookingDays[this.dayId];
        },
        cols () {
            if (this.compressView) return 'col-md-4';
            return 'col-md-6';
        },
        extras () {
            return [];
        }
    },
    methods: {
        getCities () {
            return this.day.getCitiesNames(this.recordStore.hotelsByDay[this.day.DayNr]);
        },
        getDate () {
            if (!this.day.TransDate) return "";
            return moment(this.day.TransDate).locale(api.language).format('dddd DD MMM YYYY')
        },
    }
}
</script>



