<template>
    <div class="w-100" v-if="ready">
        <ul class="nav flex-column md-tabs px-4" id="langTabs" role="tablist">
            <nav-item
                v-for="item of items"
                v-if="item.vIf"
                :key="item.id"
                :showTabs="item.showTabs"
                :id="item.id"
                :invalid="recordStore.invalid"
                :label="item.label"
                :action="item.action"
                :icon="item.icon"
                :field="item.field"
                :svg="item.svg"
                :class="item.class"
            ></nav-item>
        </ul>
    </div>
</template>


<script>
import Vue from 'vue';
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

let navItem = Vue.component('nav-item', {
    props: ['showTabs', 'field', 'id', 'label', 'invalid', 'action', 'icon', 'svg'],
    template: `
        <li class="nav-item" v-if="showTabs">
            <span class="nav-link" :class="{'active': active(), 'tab-alert': alert}" :id="id" data-toggle="tab"
            :href="'#tab-' + id" role="tab" :aria-controls="'tab-' + id" aria-selected="true">
                <font-awesome-icon :icon="icon" size="lg" v-if="icon" class="mr-1"/>
                <img class="" :src="svg" v-if="svg" height="20">
                <span class="desktop">{{tr(this.label)}}</span>
            </span>
        </li>
    `,
    computed: {
        ...mapState({
            currentTab: state => state.booking.currentTab,
        }),
        alert () {
            return this.invalid[this.field];
        }
    },
    methods: {
        active () {
            return this.currentTab == this.id;
        },
        getClass (tabId, field) {
            return tools.getClass(tabId, field, this.currentTab, this.invalid);
        },
        call (action) {
            if (action) action();
        }
    }
})

export default {
    name: 'booking-nav-items',
    props: ['version'],
    components: {
        'nav-item': navItem
    },
    data () {
        return {
            itemsList: [],
            items: [],
            ready: false,
        }
    },
    mounted () {
        EventBus.$on('after-save', this.getItems);
        this.getItems();
        this.ready = true;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            operActions: state => state.main.operActions,
            adminActions: state => state.main.adminActions,
            managerActions: state => state.main.managerActions,
            recordStore: state => state.main.record,
        }),
        bookingConfirmed () {
            if (this.recordStore) return this.recordStore.bookingConfirmed;
        },
        showDocumentation () {
            if (this.recordStore) return this.recordStore.showDocumentation;
        },
        isTemplate () {
            if (this.recordStore) return this.recordStore.isTemplate;
        },
        showTabs () {
            if (this.recordStore.TemplateType == 'NONE' && this.recordStore.Title && this.recordStore.PaxName
                    && this.recordStore.LanguageId && this.recordStore.StartDate && this.recordStore.PaxCountryType)
                    return true;
            if (this.recordStore.TemplateType != 'NONE' && this.recordStore.Title && this.recordStore.LanguageId) return true
            return false;
        },
    },
    watch: {
        showTabs () {
            this.getItems();
        }
    },
    methods: {
        getItems () {
            this.itemsList = this.getItemList();
            this.setItems();
        },
        setItems () {
            let r = [];
            for (let i of this.itemsList) {
                r.push({
                    id: i.id,
                    field: i.field,
                    label: i.label,
                    vIf: i.vIf() && this.canViewTab(i.id),
                    showTabs: i.showTabs(),
                    action: i.action,
                    icon: i.icon,
                    svg: i.svg,
                    class: i.class
                })
            }
            this.items = r;
        },
        updateItems (tabIds) {
            for (let id in tabIds) {
                let item = _.find(this.items, (r) => r.id == id);
                if (item) {
                    item.vIf = tabIds[id];
                }
            }
            this.items = Object.assign([], this.items);
        },
        canViewTab (id) {
            let tab = `booking_tab_${id}`;
            if (!tools.canAccess(api.currentUser, 'view','GET', tab, true, true)) return false;
            return true;
        },
        getItemList () {
            return [
                {id: 'cover', field: '_tabData', label: 'Basic Data', vIf: () => {return true},
                    showTabs: () => {return true}, icon: 'calendar-check'},
                {id: 'detail', label: 'Activities', vIf: () => {return true}, showTabs: () => { return this.showTabs },
                    icon: 'hiking', class: 'desktop'},
                {id: 'info', label: 'Info', vIf: () => {return true},
                    showTabs: () => { return this.showTabs && !this.isTemplate},
                    icon: 'info-circle'},
                {id: 'flights', field: 'BookingFlights', label: 'Flights', vIf: () => {return true},
                    showTabs: () => { return this.showTabs  && !this.isTemplate}, icon: 'plane'},
                {id: 'quote', field: 'BookingQuote', label: 'Quote', vIf: () => {return true},
                    showTabs: () => { return this.showTabs }, icon: 'dollar-sign', class: 'desktop'},
                {id: 'preview', label: 'Preview', vIf: () => {return true}, showTabs: () => { return this.showTabs },
                    icon: 'eye', class: 'desktop'
                },
                /*{id: 'optionals', label: 'Optionals', vIf: () => {return !this.isTemplate && this.salesActions },
                    showTabs: () => { return this.showTabs }, icon: 'ad'},*/
                {id: 'operations', label: 'Operations',
                    vIf: () => {return !this.isTemplate && this.bookingConfirmed && !this.version},
                    showTabs: () => { return this.showTabs }, icon: 'cogs'},
                {id: 'payments', label: 'Payments', icon: 'money-check-alt', class: 'desktop',
                    vIf: () => {return !this.isTemplate && this.bookingConfirmed  && (this.adminActions || this.operActions) && !this.version},
                    showTabs: () => { return this.showTabs }},
                {id: 'receipts', label: 'Receipts', icon: 'hand-holding-usd', class: 'desktop',
                    vIf: () => {return !this.isTemplate && this.bookingConfirmed && !this.version},
                    showTabs: () => { return this.showTabs }},
                {id: 'expenses', label: 'Expenses', svg: require('@/img/expenses.svg'), class: 'desktop', field: 'BookingExpenses',
                    vIf: () => {return !this.isTemplate && this.bookingConfirmed  && (this.adminActions || this.salesActions) && !this.version},
                    showTabs: () => { return this.showTabs }},
                {id: 'mails', label: 'Mails', icon: 'envelope', class: 'desktop',
                    vIf: () => {return !this.isTemplate && this.bookingConfirmed  && (this.managerActions || this.operActions || this.salesActions ) && !this.version},
                    showTabs: () => { return this.showTabs }},
                {id: 'summary', label: '_Close', icon: 'chart-bar', class: 'desktop',
                    vIf: () => {return !this.isTemplate && (this.managerActions || this.salesActions)},
                    showTabs: () => { return this.showTabs }},
                {id: 'vouchers', label: 'Vouchers', vIf: () => {return !this.isTemplate && this.showDocumentation && !this.version},
                    showTabs: () => { return this.showTabs }, icon: 'ticket-alt'
                 },
            ];
        }
    },
    beforeDestroy() {
        EventBus.$off('after-save', this.getItems);
    },
}
</script>
