<template>
      <div v-if="ready">
        <report-window
          endpoint="/crm/reports/pax_lead_summary"
          :fields="fields"
          :title="cardTitle || title"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :beforeRender="beforeRender"
          :totalsOn="totalsOn"
          :columnsClasses="columnsClasses"
        ></report-window>
      </div>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow';
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'pax-lead-summary-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            operManager: state => state.main.operManager,
            user: state => state.main.user,
            recordStore: state => state.main.modalRecord,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        let crm = tools.canAccess(this.user, "view", "GET", 'crm', false);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions && !this.operManager && !crm) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            reload: false,
            comment: null,
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "From",
                },
                {
                    name: "ToDate",
                    editor: "date",
                    label: "To",
                },
                {
                    name: "TripFromDate",
                    editor: "date",
                    label: "Trip Date From",
                },
                {
                    name: "TripToDate",
                    editor: "date",
                    label: "Trip Date To",
                },
                {name: 'SalesUserId', label: 'Seller', editor: 'select', list: true,
                  optionLabels: 'UserName', relation: 'user', addBlank: true,
                  filters: (row) => {
                      if (row.Closed) return false;
                      if (row.Department && row.Department.Sales) return true;
                  }
                },
                {name: 'SalesOriginId', label: 'Sales Origin', editor: 'select', list: true,
                    optionLabels: 'Name', relation: 'salesorigin', addBlank: true
                },
                {name: 'CustomerTypeId', label: 'Customer Type', editor: 'select',
                    optionLabels: 'Name', relation: 'customertype', addBlank: true,
                },
                {name: 'MarketId', label: 'Market', editor: 'select', list: true,
                    optionLabels: 'Name', relation: 'market', addBlank: true,
                },
                {name: 'LeadValueId', label: 'Lead Value', editor: 'select', list: true,
                    optionLabels: 'Name', relation: 'leadvalue', addBlank: true,
                },
                {name: 'LeadStatusId', label: 'Lead Status', editor: 'select',
                    optionLabels: 'Name', relation: 'leadstatus', addBlank: true,
                },
                {name: 'LeadTypeId', label: 'Lead Type', editor: 'select',
                    optionLabels: 'Name', relation: 'leadtype', addBlank: true,
                },
                {name: 'GroupBy', label: 'Group by', editor: 'select', defValue: '0', required: true,
                    options: [
                        {value: '0', label: 'Sales Origin'},
                        {value: '1', label: 'Market'},
                        {value: '2', label: 'Lead Value'},
                        {value: '3', label: 'Agency'},
                        {value: '4', label: 'Campaign'},
                        {value: '5', label: 'Sub Campaign'},
                    ]
                },
                /*{name: 'LanguageId', label: 'Language', editor: 'select',
                    optionLabels: 'Name', relation: 'language', addBlank: true,
                }*/

            ],
            title: 'Pax Leads Summary',
            columnsClasses: {
                Total: 'font-weight-bold'
            },
            totalsOn: ['Total']
        }
    },
    methods: {
        beforeRender (report) {
            let columnsNames = report.columnsNames;
            if (columnsNames.length==0) columnsNames = report.serverColumnsHeader;
            for (let column of columnsNames) {
                if (column == 'UserName') continue;
                if (column == 'SalesOrigin') continue;
                if (column == 'Agency') continue;
                if (column == 'Market') continue;
                if (column == 'LeadValue') continue;
                if (column == 'Campaign') continue;
                if (column == 'Sub Campaign') continue;
                if (this.totalsOn.indexOf(column) > -1 ) continue;
                this.totalsOn.push(column)
            }
            this.totalsOn = Object.assign([], this.totalsOn);
        },

    }
}
</script>

