<template>
    <div class="card-header clearfix card-header-day form-row mx-0 pb-1 pt-1 d-flex align-items-center">
        <photo-small class="col-1" :image="dayImage"></photo-small>
        <label class="cursor-pointer float-left mb-0 col-11">
            <b><span>{{tr('Day')}} {{day.DayNr + 1}} - </span>
            <i class="header-black">{{getDate(day)}}</i> -
            <span>{{getCities(day)}}</span>
            <i v-if="day.Title" class="header-black">- {{day.Title}}</i></b>
        </label>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-day-header',
    props: ['day', 'dayId'],
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        dayImage () {
            if (this.day.ImageId) return this.day.Image;
            for (let s of this.day.BookingDayServices) {
                for (let row of s.BookingDayServiceImages) {
                    if (row.ImageId) {
                        return row.Image;
                    }
                }
            }
        }
    },
    methods: {
        getDate (day) {
            if (!day.TransDate) return "";
            return moment(day.TransDate).locale(api.language).format('dddd DD MMM YYYY')
        },
        getCities (day) {
            return day.getCitiesNames(this.recordStore.hotelsByDay[day.DayNr]);
        },
    },
}
</script>



