<template>
    <div class="w-100">
        <transition name="fade" mode="out-in">
            <div class="service-selector" v-if="loaded">
                <div class="form-row" v-if="!modal">
                    <div class="form-group col-md-12 section-title mb-3 mt-1">
                        <label class="ml-4 mb-0">{{tr('Add Service')}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-10 mb-0">
                        <search-box v-model="searchBox" :addId="_uid"></search-box>
                    </div>
                    <div class="form-group col-md-2 mb-0 d-flex justify-content-end">
                        <div class="form-row">
                            <i class="cursor-pointer filters-div mr-3" @click="filters = !filters" :class="{'row-primary-button': filters}">
                                <svg-filters class="service-actions"></svg-filters>
                                <span v-if="filtersCnt" class="badge badge-filters">{{filtersCnt}}</span>
                            </i>
                            <i class="cursor-pointer mr-3" @click="quoteServices"
                             	v-if="modal"
                             	:class="{'row-primary-button': showPrices}"
                                :disabled="list.length>100">
                                <font-awesome-icon icon="dollar-sign"/>
                            </i>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <button v-for="x in tags" type="button" class="btn btn-secondary btn-service-selected ml-1"
                            @click="removeTag(x)">{{x}}
                        </button>
                    </div>
                </div>

                <div class="form-row" v-if="modal">
                    <div class="form-group col-md-12">
                        <button v-for="s in selected" type="button" class="btn btn-primary btn-service-selected mx-1">{{s.Name}}
                          <span aria-hidden="true" class="remove-item" @click="removeService(s)">&times;</span>
                        </button>
                    </div>
                </div>

                <div class="form-row form-material" v-if="filters">
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="SupplierId"
                            :fieldOptions="fieldOptions.SupplierId"
                            label="Supplier"
                            :addBlank="true"
                            :currentValue.sync="supplierId"
                        ></vue-select-input>
                    </div>
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="CityId"
                            :fieldOptions="fieldOptions.CityId"
                            label="City"
                            :addBlank="true"
                            :currentValue.sync="cityId"
                        ></vue-select-input>
                    </div>
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="ServiceTypeId"
                            :fieldOptions="fieldOptions.ServiceTypeId"
                            label="Type"
                            :addBlank="true"
                            :currentValue.sync="serviceTypeId"
                        ></vue-select-input>
                    </div>
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="ServiceClassId"
                            :fieldOptions="fieldOptions.ServiceClassId"
                            label="Classification"
                            :addBlank="true"
                            :currentValue.sync="serviceClassId"
                        ></vue-select-input>
                    </div>
                    <div class="form-group col-md-6 my-0">
                        <div class="form-check">
                            <input v-model="sostenible" name="Sostenible"
                            class="form-check-input" type="checkbox" id="Sostenible">
                            <label class="smaller form-check-label">{{tr('Sostenible')}}</label>
                        </div>
                    </div>
                </div>
                <div class="service-report-view" >
                    <v-client-table :data="list" :columns="columnsNames" :options="options"
                        v-if="list && loaded"  @row-click="onRowClick">
                          <template slot-scope="props" slot="Name">
                              <div class="row services-selector-drag services-table" v-dragula bag="detailbag" v-if="!modal">
                                  <div class="col-12 form-row pb-2 service-drag-card text-drag">
                                      <div class="col-1 d-flex align-items-center pl-3"
                                        :serviceCategoryId="props.row.ServiceCategoryId"
                                        :serviceId="props.row.sId" v-if="!showPrices">
                                          <font-awesome-icon :icon="getIcon(props.row.Icon)"/>
                                      </div>
                                      <photo-small v-if="!showPrices" class="col-1 d-flex align-items-center" :image="props.row.Image"></photo-small>
                                      <div class="col-10 form-row service-name" :class="{'d-flex align-items-center': !isBreakLine(props.row) || getLargeTextClass(props.row)}">
                                            <div class="col-12 service-selector-row"
                                                :class="{'text-small': getLargeTextClass(props.row)}">
                                                <b class="text-drag">{{props.row.Name}}</b> -
                                                <i class="text-drag">{{listValues[['ServiceTypeId', props.row.ServiceTypeId]]}} - </i>
                                                <b class="text-drag" v-if="props.row.ServiceCategoryId">{{listValues[['ServiceCategoryId', props.row.ServiceCategoryId]]}} - </b>
                                                <b class="text-drag">{{listValues[['SupplierId', props.row.SupplierId]]}}</b> -
                                                <i class="text-drag">{{listValues[['CityId', props.row.CityId]]}}</i>
                                            </div>
                                      </div>
                                      <!--div class="col-1 d-flex align-items-center justify-content-end cursor-move">
                                        <font-awesome-icon icon="arrows-alt"/>
                                      </div-->
                                  </div>
                              </div>

                              <div class="row services-table" v-else>
                                  <div class="col-12 form-row pb-2">
                                      <div class="col-1 d-flex align-items-center pl-3" :serviceId="props.row.sId">
                                          <font-awesome-icon :icon="getIcon(props.row.Icon)"/>
                                      </div>
                                      <photo-small class="col-1 d-flex align-items-center" :image="props.row.Image"></photo-small>
                                      <div class="col-10 form-row" :class="{'d-flex align-items-center': !isBreakLine(props.row)}">
                                            <div class="col-12 service-selector-row">
                                                <b>{{props.row.Name}}</b> -
                                                <i>{{listValues[['ServiceTypeId', props.row.ServiceTypeId]]}}</i> -
                                                <b v-if="props.row.ServiceCategoryId">{{listValues[['ServiceCategoryId', props.row.ServiceCategoryId]]}} - </b>
                                                <b>{{listValues[['SupplierId', props.row.SupplierId]]}}</b> -
                                                <i>{{listValues[['CityId', props.row.CityId]]}}</i>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </template>
                    </v-client-table>
                </div>
                <div class="text-right">
                    <button type="button" class="btn btn-primary " @click="add" v-if="modal"
                        :disabled="selected.length==0">
                        {{tr('Done')}}
                    </button>
                </div>
            </div>
            <div v-else>
                <div class="linear-activity">
                    <div class="indeterminate"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
const serviceSelector = importVueComp('components/service', 'ServiceSelector', 'sostenible');
export default {
    name: 'service-selector',
    mixins: [serviceSelector],
    data () {
        return {
            sostenible: false
        }
    },
    computed: {
        dataList () {
            let rows = this.serviceList;
            if (this.supplierId) {
                rows = _.filter(rows, (r) => r.SupplierId == this.supplierId);
            }
            if (this.cityId) {
                rows = _.filter(rows, (r) => r.CityId == this.cityId);
            }
            if (this.serviceTypeId) {
                rows = _.filter(rows, (r) => r.ServiceTypeId == this.serviceTypeId);
            }
            if (this.serviceClassId) {
                rows = _.filter(rows, (r) => r.ServiceClassId == this.serviceClassId);
            }
            if (this.sostenible) {
                rows = _.filter(rows, (r) => {
                    if (r.LocalService) return true;
                    if (r.LessCrowded) return true;
                    if (r.LowPollution) return true;
                })
            }
            if (this.prices) {
                for (let row of rows) {
                    let priceRow = _.find(this.prices, (r) => r.Service.id == row.sId);
                    if (priceRow && priceRow.Data) {
                        for (let dataKey in priceRow.Data) {
                            row[dataKey] = priceRow.Data[dataKey];
                        }
                    }
                }
            }
            return rows;
        },

    }
}
</script>

