<template>
    <div>
        <div class="form-row col-md-12 mt-5" v-if="showIncludeFlights">
            <div class="form-row col-md-12">
                <div class="form-row col-md-12">
                    <div class="col-md-7 service-row section-title">
                        <label class="ml-3">{{tr('Flights Included')}}</label>
                    </div>
                    <div class="row col-md-4 flight-row text-center">
                        <div class="form-group col col-input service-row">
                            <label>{{tr('Cost')}}</label>
                        </div>
                        <div class="form-group col col-input service-row">
                            <label>{{tr('Markup')}}</label>
                        </div>
                        <div class="form-group col col-input service-row">
                            <label>{{tr('Gross')}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row col-md-12 border-top" v-if="IncludedFlights.length>0">
            <div v-for="flight of IncludedFlights" class="form-row col-md-12"
                v-if="flight.QuoteList && flight.QuoteList[baseId] && (flight.QuoteList[baseId].Include || edit)"
            >
                <div class="form-row col-md-12" v-if="flight.QuoteType=='INCLUDED'">
                    <div class="form-group col-md-7 service-row mt-0">
                        <label>{{getFlightRoute(flight)}}</label>
                    </div>
                    <div class="row col-md-4 flight-row">
                        <input v-model="flight.QuoteList[baseId].Include" type="checkbox"
                            class="form-check-input" v-if="edit" @change="setIncluded"/>
                        <div class="form-group col col-input service-row">
                            <number-input :v="getFlightCost(flight)" disabled="true" :currency="recordStore.Currency" class="my-0"></number-input>
                        </div>
                        <div class="form-group col col-input service-row">
                            <number-input :v="flight.Markup" disabled="true" :currency="recordStore.Currency" class="my-0"></number-input>
                        </div>
                        <div class="form-group col col-input service-row">
                            <number-input :v="getFlightPrice(flight)" disabled="true" :currency="recordStore.Currency" class="my-0"></number-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row col-md-12 mt-5" v-if="showNotIncludeFlights">
            <div class="form-row col-md-12">
                <div class="form-row col-md-12">
                    <div class="col-md-7 service-row section-title">
                        <label class="ml-3">{{tr('Flights NOT Included')}}</label>
                    </div>
                    <div class="row col-md-4 flight-row text-center">
                        <div class="form-group col col-input service-row">
                            <label>{{tr('Cost')}}</label>
                        </div>
                        <div class="form-group col col-input service-row">
                            <label>{{tr('Markup')}}</label>
                        </div>
                        <div class="form-group col col-input service-row">
                            <label>{{tr('Gross')}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row col-md-12 border-top" v-if="NotIncludedFlights.length>0">
            <div v-for="flight of NotIncludedFlights" class="form-row col-md-12"
                v-if="flight.QuoteList && flight.QuoteList[baseId] && (flight.QuoteList[baseId].Include || edit)"
            >
                <div class="form-row col-md-12" v-if="flight.QuoteType=='NOT_INCLUDED'">
                    <div class="form-group col-md-7 service-row mt-0">
                        <label>{{getFlightRoute(flight)}}</label>
                    </div>
                    <div class="row col-md-4 flight-row">
                        <input v-model="flight.QuoteList[baseId].Include" type="checkbox"
                            class="form-check-input" v-if="edit" @change="setIncluded"/>
                        <div class="form-group col col-input service-row">
                            <number-input :v="getFlightCost(flight)" disabled="true" :currency="recordStore.Currency" class="my-0"></number-input>
                        </div>
                        <div class="form-group col col-input service-row">
                            <number-input :v="flight.Markup" disabled="true" :currency="recordStore.Currency" class="my-0"></number-input>
                        </div>
                        <div class="form-group col col-input service-row">
                            <number-input :v="getFlightPrice(flight)" disabled="true" :currency="recordStore.Currency" class="my-0"></number-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="hr-row" v-if="showNotIncludeFlights">
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-quote-flights',
    props: ['baseId', 'edit'],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        NotIncludedFlights () {
            return _.filter(this.recordStore.BookingFlights, (f)=> f.QuoteType=='NOT_INCLUDED');
        },
        IncludedFlights () {
            return _.filter(this.recordStore.BookingFlights, (f)=> f.QuoteType=='INCLUDED');
        },
        showNotIncludeFlights () {
            if (this.NotIncludedFlights.length==0) return false;
            for (let flight of this.NotIncludedFlights) {
                for (let baseId in flight.QuoteList) {
                    if (flight.QuoteList[baseId] && flight.QuoteList[baseId].Include) return true;
                    if (this.edit) return true;
                }
            }
            return false;
        },
        showIncludeFlights () {
            if (this.IncludedFlights.length==0) return false;
            for (let flight of this.IncludedFlights) {
                for (let baseId in flight.QuoteList) {
                    if (flight.QuoteList[baseId] && flight.QuoteList[baseId].Include) return true;
                    if (this.edit) return true;
                }
            }
            return false;
        }

    },
    methods: {
        getFlightPrice (flight) {
            let value = flight.getFlightPrice(this.baseId);
            return this.recordStore.currencyConvert(flight.CurrencyId, this.recordStore.CurrencyId, value);
        },
        getFlightCost (flight) {
            let value = flight.getFlightCost(this.baseId);
            return this.recordStore.currencyConvert(flight.CurrencyId, this.recordStore.CurrencyId, value);
        },
        getFlightRoute (flight) {
            if (flight.Comment) return flight.Comment;
            if (!flight.Output) return '';
            let cities = [];
            try{
                let lines = flight.Output.split('\n');
                for (let line of lines){
                    let segments = line.split(' - ');
                    if (segments.length==0) continue;
                    let words = segments[0].split(' ')
                    if (words.length==0) continue;
                    words.splice(0, 1);
                    words.splice(0, 1);
                    let city1 = words.join(' ');
                    if (city1 && cities.indexOf(city1)==-1) cities.push(city1);
                    words = segments[1].split(' ');
                    if (words.length==0) continue;
                    words.splice(words.length-1, 1);
                    words.splice(words.length-1, 1);
                    let city2 = words.join(' ');
                    if (city2 && cities.indexOf(city2)==-1) cities.push(city2);
                }
                }catch(err){
                }
            return cities.join(' - ');
        },
        setIncluded () {
            EventBus.$emit('update-totals');
        },
    },
}
</script>

