<template>
    <div>
        Importar Facturas
         <input type="file" class="form-control" @change="setFile($event)">
         <button class="btn btn-primary mx-2" @click="processInvoices" :disabled="!file">Procesar</button>
         <button class="btn btn-primary" @click="saveInvoices" :disabled="!save">Guardar</button>
         <div v-if="invoices.length > 0">
            <h3 v-if="!saved">Facturas Correctas</h3>
            <h3 v-if="saved">Facturas Guardadas</h3>
            <div v-for="(invoice, index) in invoices" :key="index">{{ invoice }}</div>
         </div>
         <div v-if="errors.length > 0">
            <h3 v-if="!saved">Errores</h3>
            <h3 v-if="saved">Errores de Guardado</h3>
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
         </div>
    </div>
</template>

<script>

export default {
    name: 'import-invoices',
    data () {
        return {
            file: null,
            errors: [],
            invoices: [],
            save: false,
            saved: false,
        }
    },
    computed: {
    },
    methods: {
        async processInvoices() {
            var files = new FormData(); 
            this.errors = [];
            this.invoices = [];
            this.saved = false;
            this.save = false;
            files.append('file', this.file);
            let res = await api.post('/api/importinvoices', files, {}, (err) => {
                this.errors = err.detail;
            })
            if (res) {
                this.invoices = res.invoices;
                this.errors = res.errors;
                this.save = true;
            }
        },
        setFile(event) {
            console.log('setFile', event.target.files);
            this.file = event.target.files[0];
        },
        async saveInvoices() {
            this.saved = false;
            this.errors = [];
            this.invoices = [];
            var files = new FormData(); 
            files.append('file', this.file);
            let res = await api.post('/api/saveinvoices', files, {}, (err) => {
                this.errors = err.detail;
            })
            if (res) {
                this.saved = true;
                this.invoices = res.invoices;
                this.errors = res.errors;
            }
        }
    },
};
</script>
