<template>
    <div>
        <div v-if="recordStore">
            <button type="button" class="btn btn-primary action-button button-fixed"
                @click="printPDF">
                <font-awesome-icon icon="print"/>
            </button>
            <vouchers-preview
            ></vouchers-preview>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
const vouchersPreview = importVueComp('components/voucher', 'VouchersPreview');
export default {
    name: 'voucher-view',
    props: ['id', 'hash'],
    components: {
        'vouchers-preview': vouchersPreview
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            user: state => state.main.user,
            processing: state => state.main.processing,
        }),
    },
    async mounted () {
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r = await api.get('/public/api/get_booking/' + this.hash + '/' + this.id);
        if (r) {
            r._public = true;
            let record = await new model({record: r, notFieldOptions: true});
            this.$store.commit('initRecord', {record});
        }
        api.setProcessing(false)
    },
    methods: {
         printPDF () {
            window.print();
        },
    }
}
</script>
