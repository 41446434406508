<template>
      <report-window endpoint="/crm/reports/pax_lead_conversion"
          v-if="ready"
          :fields="fields"
          :title="cardTitle || title"
          :headers="headers"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :slotsFields="[]"
          :totalsOn="totalsOn"
          :allowChart="true"
          :chartOptions="chartOptions"
          :totalLabel="totalLabel"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'pax-lead-conversion-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        let crm = tools.canAccess(this.user, "view", "GET", 'crm', false);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions && !crm) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From'},
              {name: 'ToDate', editor: 'date', label: 'To'},
              {name: 'SalesUserId', label: 'Seller', editor: 'select',
                optionLabels: 'UserName', relation: 'user', addBlank: true,
                filters: (row) => {
                    if (row.Closed) return false;
                    if (row.Department && row.Department.Sales) return true;
                }
              },
              {name: 'SalesOriginId', label: 'Sales Origin', editor: 'select',
                optionLabels: 'Name', relation: 'salesorigin', addBlank: true,
              },
              {name: 'CustomerTypeId', label: 'Customer Type', editor: 'select',
                optionLabels: 'Name', relation: 'customertype', addBlank: true,
              },
              {name: 'CampaignId', label: 'Campaign', editor: 'select',
                optionLabels: 'Name', relation: 'campaign', addBlank: true,
              },
              {name: 'GroupBy', label: 'Group by', editor: 'select', required: true, defValue: '0',
                  options: [
                        {value: '0', label: 'Sales Origin'},
                        {value: '1', label: 'Seller'},
                        {value: '2', label: 'Campaign'},
                        {value: '3', label: 'Sub Campaign'}
                  ]
              },
            ],
            title: 'Reporte de Conversión',
            headers: {
                PaxLeads: 'Consultas',
                Bookings: 'Quotations',
                Rate: '% Ventas / Cotizaciones',
                RateToBook: '% Cotizaciones / Consultas',
                RateToSale: '% Ventas / Consultas',
            },
            templates: [
                {name: 'Rate', callback: (row) => {
                    if (!row.calculated) return row.Rate;
                    return (row.Confirmed / row.Bookings * 100).toFixed(0);
                  }
                },
                {name: 'RateToBook', callback: (row) => {
                    if (!row.calculated) return row.RateToBook;
                    return (row.Bookings / row.PaxLeads * 100).toFixed(0);
                  }
                },
                {name: 'RateToSale', callback: (row) => {
                    if (!row.calculated) return row.RateToSale;
                    return (row.Confirmed / row.PaxLeads * 100).toFixed(0);
                  }
                },
            ],
            totalsOn: [ 'PaxLeads', 'Bookings', 'Confirmed'],
            totalLabel: ['Name'],
            chartOptions: {
              y: 'Name',
              x: ['PaxLeads', 'Bookings', 'Confirmed', 'Rate']
            }
        }
    },
}
</script>

