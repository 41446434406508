<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="822.000000pt" height="846.000000pt" viewBox="0 0 822.000000 846.000000"  preserveAspectRatio="xMidYMid meet" class="svg-inline--fa fa-ad fa-w-14 mx-1">
  <g transform="translate(0.000000,846.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
    <path d="M1275 8380 c-139 -12 -267 -50 -410 -122 -69 -35 -190 -110 -205 -127 -3 -3 -27 -26 -55 -51 -183 -165 -304 -372 -367 -630 l-23 -95 0 -2715 c0 -2803 -1 -2726 37 -2865 70 -258 199 -457 409 -630 87 -73 251 -160 369 -198 91 -30 178 -47 290 -57 141 -12 3359 -14 3378 -1 11 7 5 33 -29 132 -23 68 -46 142 -51 164 -37 165 -49 241 -56 375 l-7 115 -1625 5 c-894 3 -1634 9 -1645 13 -158 67 -245 166 -274 312 -9 46 -11 689 -9 2665 3 2515 4 2607 22 2655 45 121 138 212 258 252 50 17 132 18 1463 18 l1410 0 6 -280 c3 -154 9 -293 13 -310 4 -16 12 -50 18 -75 49 -210 168 -420 324 -572 109 -105 181 -155 319 -222 109 -53 152 -67 285 -98 25 -6 173 -13 330 -17 l285 -6 3 -788 2 -787 393 2 392 3 3 930 c1 558 -1 976 -7 1045 -10 106 -31 217 -51 265 -4 11 -18 47 -31 80 -31 81 -76 163 -135 245 -56 79 -1073 1098 -1149 1152 -135 96 -283 161 -467 205 -68 16 -193 17 -1703 19 -896 1 -1666 -2 -1710 -6z m4115 -1564 c0 -5 -33 -6 -76 -3 -190 17 -340 162 -359 349 -4 36 -4 72 0 78 5 8 82 -63 221 -201 118 -118 214 -218 214 -223z"/>
    <path d="M1942 6407 c-51 -14 -106 -57 -129 -100 -24 -46 -22 -144 3 -188 11 -19 41 -49 68 -66 l48 -33 643 0 c718 0 685 -3 749 71 95 112 38 277 -110 318 -56 16 -1214 13 -1272 -2z"/>
    <path d="M1935 5222 c-90 -31 -139 -96 -139 -187 1 -36 7 -73 17 -91 20 -40 79 -91 115 -101 15 -5 223 -8 462 -8 396 0 438 2 472 18 64 30 118 116 118 185 0 25 -35 109 -52 125 -7 7 -29 24 -48 37 l-35 25 -445 2 c-245 1 -454 -2 -465 -5z"/>
    <path d="M1975 4043 c-60 -7 -75 -14 -110 -45 -51 -44 -68 -83 -69 -153 0 -49 5 -68 28 -102 16 -23 45 -53 65 -65 l36 -23 857 0 856 0 39 31 c64 52 83 88 83 159 0 69 -15 108 -57 145 -66 58 -9 55 -888 56 -445 1 -823 0 -840 -3z"/>
    <path d="M5909 4041 c-159 -23 -224 -210 -115 -329 41 -46 74 -56 206 -62 l125 -5 0 -219 0 -219 -45 -14 c-84 -27 -155 -55 -240 -96 -82 -40 -210 -113 -220 -126 -3 -4 -21 -18 -40 -31 -19 -13 -37 -27 -40 -30 -3 -3 -36 -32 -73 -64 -80 -69 -182 -188 -257 -300 -55 -83 -150 -265 -150 -288 0 -8 -4 -18 -8 -24 -5 -5 -18 -40 -29 -79 -20 -68 -28 -98 -55 -215 -7 -33 -12 -131 -13 -250 0 -192 8 -270 42 -400 8 -30 18 -68 23 -85 47 -179 214 -461 366 -617 160 -164 369 -306 562 -379 12 -5 32 -13 45 -18 40 -16 161 -49 217 -60 148 -28 184 -31 320 -31 137 0 187 5 315 31 341 69 664 259 883 522 193 230 304 468 358 767 21 116 21 396 0 510 -27 140 -52 240 -75 295 -5 11 -17 38 -26 60 -91 214 -198 368 -368 532 -184 176 -403 305 -642 376 l-50 14 0 219 0 219 131 6 c116 4 134 8 165 29 66 46 99 104 99 173 0 38 -34 109 -66 137 -67 58 -28 55 -689 56 -335 1 -631 -1 -656 -5z m791 -1596 c124 -31 166 -49 265 -116 129 -86 240 -223 292 -359 105 -272 68 -536 -106 -771 -74 -101 -213 -208 -321 -248 -19 -7 -44 -17 -55 -21 -95 -38 -330 -47 -435 -17 -30 9 -66 19 -80 23 -37 11 -151 72 -194 104 -201 150 -326 393 -326 637 0 132 45 297 109 398 120 189 275 307 477 364 86 24 291 27 374 6z"/>
    <path d="M6462 2063 c-37 -7 -108 -83 -121 -128 -6 -22 -11 -108 -11 -191 l0 -150 178 -175 c156 -155 183 -177 225 -189 121 -33 247 67 247 197 0 64 -20 95 -140 218 l-108 110 -6 90 c-6 73 -12 98 -34 134 -45 74 -126 104 -230 84z"/>
    <path d="M1884 2828 c-65 -45 -88 -86 -88 -162 0 -37 7 -73 17 -93 25 -47 81 -88 138 -101 68 -16 1207 -17 1263 -1 148 41 205 206 110 318 -64 74 -31 71 -749 71 l-643 0 -48 -32z"/>
  </g>
</svg>
</template>

<script>
export default {
    name: 'svg-changelog',
}
</script>
