<template>
    <div class="card">
        <div class="card-header">
            <h6 class="h-row-section">{{tr('Services')}}</h6>
        </div>
        <div class="card-body">
            <div class="pb-3" v-for="day of recordStore.BookingDays">
                <div class="pb-2" v-for="s of day.BookingDayServices">
                    <div class="row" v-if="s">
                        <div class="col-md-1">
                            <label v-if="s.OrderNr == getFirstToShow(day.BookingDayServices)">
                                {{tr('Day')}} {{ day.DayNr + 1 }}
                            </label>
                        </div>
                        <div class="col-md-11">
                            <input v-model="s.Quote.Include" type="checkbox" class="form-check-input" @change="change"/>
                            <label>{{ s.Service.Name || s.Name }} {{ getSupplierName(s) }}</label>
                        </div>
                    </div>
                </div>
                <hr class="hr-row" v-if="day.BookingDayServices.length > 0">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

//const moment = require('moment');

export default {
    name: 'booking-quote-template',
    data () {
        return {}
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    methods: {
        getFirstToShow (services) {
            for (let s of services) {
                if (s && s.Quote) return s.OrderNr;
            }
        },
        getSupplierName (s) {
            if (s.Service && s.Service.Supplier) return s.Service.Supplier.Name;
            return '';
        },
        async change (value) {
        },
    }
}
</script>
