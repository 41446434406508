<template>
    <div class="col-11" v-if="ready">
        <div class="section-title">
            <label>{{tr('Summary')}}</label>
        </div>
        <div>
            <div class="form-row">
                <label class="col"></label>
                <label class="col"></label>
                <label class="col text-center">{{tr('Rooms')}}</label>
                <label class="col text-right">{{tr('Price per Person')}}</label>
                <label class="col text-right" v-if="flightsNotIncluded">{{tr('Flights per Person')}}</label>
                <label class="col text-right">{{tr('Total')}}</label>
                <label class="col text-right" v-if="finalTotalFlights">{{tr('With Flights')}}</label>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <hr class="my-1">
                </div>
            </div>
            <div v-for="(quote, baseId) in recordStore.bases">
                <div v-if="recordStore.isVariant(baseId)">
                    <div v-for="(variant, variantId) of totalByPaxVariant[baseId]">
                        <div v-for="(value, pType) of totalByPaxVariant[baseId][variantId]" class="form-row" v-if="recordStore.QuoteVariants[baseId][variantId].Selected[pType]">
                            <label class="col">{{recordStore.QuoteVariants[baseId][variantId].Name}}</label>
                            <label class="col">{{paxBaseByPaxType[baseId][pType]}} {{recordStore.QuoteVariants[baseId][variantId][pType]}} {{pType}}</label>
                            <label class="col text-center">{{roomsTypeNamesByVariant[baseId][variantId][pType]}}</label>
                            <label class="col text-right">{{parseFloat(getTotalInCurrency(value.Price)) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                            <label class="col text-right" v-if="flightsNotIncluded">
                                <span v-if="totalFlightsNotIncludedAll[baseId] && totalFlightsNotIncludedAll[baseId][pType]">{{getTotalInCurrency(totalFlightsNotIncludedAll[baseId][pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 1}) }}</span>
                            </label>
                            <label class="col text-right">{{getTotalInCurrency(totalByVariants[baseId][variantId][pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                            <label class="col text-right" v-if="finalTotalFlights">{{getTotalInCurrency(totalByVariantWithFlights[baseId][variantId][pType]) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-for="pType of paxTypesByBase[baseId]" >
                        <div class="form-row" v-if="totals && Totals[baseId] && Totals[baseId][pType] && Totals[baseId][pType].Selected">
                            <label class="col"><span v-if="Totals[baseId][pType].Comment">{{Totals[baseId][pType].Comment}}</span></label>
                            <label class="col">{{paxBaseByPaxType[baseId][pType]}} {{pType}}</label>
                            <label class="col text-center" v-if="totals[baseId][pType].Rooms">{{pType}} {{tr('Room')}} {{getRoomsNames(totals[baseId][pType].Rooms)}} </label>
                            <label class="col text-center" v-else>{{pType}} </label>
                            <label class="col text-right" v-if="recordStore.QuoteBy!='PERSON'">{{parseFloat(getTotalInCurrency(totals[baseId][pType].PersonPrice)) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                            <label class="col text-right" v-else>{{parseFloat(getTotalInCurrency(totals[baseId][pType].Price)) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                            <label class="col text-right" v-if="flightsNotIncluded">
                                <span v-if="totalFlightsNotIncludedAll[baseId] && totalFlightsNotIncludedAll[baseId][pType]">{{getTotalInCurrency(totalFlightsNotIncludedAll[baseId][pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 1}) }}</span>
                            </label>
                            <label class="col text-right">{{getTotalInCurrency(totalByBasePaxType[baseId][pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                            <label class="col text-right" v-if="finalTotalFlights">{{getTotalInCurrency(totalByBasePaxTypeWithFlights[baseId][pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="row of recordStore.BookingExpenses" >
                <div class="form-row" v-if="row.Type=='PAX' && row.Amount && row.CurrencyId">
                    <label class="col-3 offset-7">{{getExpenseName(row.ExpenseTypeId)}}</label>
                    <label class="col-2 text-right">{{currencyConvert(row.CurrencyId, row.Amount) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <hr class="my-1">
                </div>
            </div>
            <div class="form-row">
                <label class="col"></label>
                <label class="col"></label>
                <label class="col"></label>
                <label class="col text-right">{{tr('TOTAL')}}:</label>
                <label class="col" v-if="recordStore.BookingFlights.length > 0"></label>
                <label class="col text-right">{{finalTotal | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                <label class="col text-right" v-if="finalTotalFlights">{{finalTotalWithFlights | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote-summary-variant',
    data () {
        return {
            totals: {},
            Totals: {},
            finalTotal: 0,
            paxTypesByBase: {},
            totalByBasePaxTypeWithFlights: {},
            totalByBasePaxType: {},
            paxBaseByPaxType: {},
            finalTotalWithFlights: 0,
            finalTotalFlights: null,
            ready: false,
        }
    },
    mounted () {
        this.setTotals();
        EventBus.$on('update-totals', this.setTotals);
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        flightsNotIncluded () {
            if (this.recordStore.BookingFlights.length == 0) return;
            for (let baseId in this.totalFlightsNotIncludedAll) {
                for (let pType in this.totalFlightsNotIncludedAll[baseId]) {
                    return true;
                }
            }
        }
    },
    methods: {
        setTotals () {
            //window._booking = this.recordStore;
            this.totals = Object.assign({}, this.recordStore.totals);
            this.Totals = Object.assign({}, this.recordStore.Totals);
            this.finalTotal = this.getTotalInCurrency(this.recordStore.finalTotal);
            this.paxTypesByBase = Object.assign({}, this.recordStore.paxTypesByBase);
            this.paxBaseByPaxType = Object.assign({}, this.recordStore.paxBaseByPaxType);
            this.totalByBasePaxType = Object.assign({}, this.recordStore.totalByBasePaxType);
            this.totalByBasePaxTypeWithFlights = Object.assign({}, this.recordStore.totalByBasePaxTypeWithFlights);
            this.totalFlightsNotIncludedAll = Object.assign({}, this.recordStore.totalFlightsNotIncludedAll);
            this.finalTotalWithFlights = this.getTotalInCurrency(this.recordStore.finalTotalWithFlights);
            if (this.recordStore.finalTotalFlightsNotIncluded) {
                this.finalTotalFlights = this.getTotalInCurrency(this.recordStore.finalTotalFlightsNotIncluded.Price);
            }
            this.totalByVariantWithFlights = Object.assign({}, this.recordStore.totalByVariantWithFlights);
            this.totalByPaxVariant = Object.assign({}, this.recordStore.totalByPaxVariant);
            this.roomsTypeNamesByVariant = Object.assign({}, this.recordStore.roomsTypeNamesByVariant);
            this.totalByVariants = Object.assign({}, this.recordStore.totalByVariants);
            this.ready = true;
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        showInput () {
          if (!this.recordStore.CurrencyId || !this.recordStore.PriceCurrencyId) {
            return;
          }
          this.showInputQuote = true;
        },
        getRoomsNames (rooms) {
            let r = [];
            for (let roomId in rooms) {
                if (rooms[roomId].Checkin) continue;
                if (r.indexOf(rooms[roomId].Name)==-1) {
                    r.push(rooms[roomId].Name);
                }
            }
            return r.join(' / ');
        },
        getExpenseName (id) {
            let r = _.find(api.tables.expensetype, (p) => p.id == id);
            if (r) {
                return r.Name;
            }
            return '';
        },
        currencyConvert (currencyId, value) {
            return this.recordStore.currencyConvert(currencyId, this.recordStore.PriceCurrencyId, value);
        },
    },
    beforeDestroy() {
        EventBus.$off('update-totals', this.setTotals);
    },
}
</script>

