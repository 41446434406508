<template>
    <itinerary
        v-if="!processing"
        :view="true"
        :id="id"
        :version="version"
        :showMap="true"
    ></itinerary>
</template>

<script>
const itinerary = importVueComp('components/itinerary', 'Itinerary');
import { mapState } from 'vuex';
export default {
    name: 'itinerary-view',
    props: ['id', 'hash', 'version'],
    components: {
        'itinerary': itinerary
    },
    data () {
        return {
            record: null
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
    },
    async mounted () {
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r;
        if (this.version) {
            r = await api.get('/public/api/bookingversion/' + this.hash + '/' + this.id);
        } else {
            r = await api.get('/public/api/booking/' + this.hash + '/' + this.id);
        }
        if (r) {
            r._public = true;
            let record = await new model({record: r, notFieldOptions: true});
            this.$store.commit('initRecord', {record});
            this.record = tools.plainObject(this.recordStore);
        }
        api.setProcessing(false)
    },
    beforeDestroy() {
        this.$store.commit('initRecord', {record: null});
    },
}
</script>
