<template>
    <div>
        <div class="form-row">
            <div class="form-group col">
                <div class="form-check">
                    <input v-model="allDays" name="ByWeekDay" @change="change"
                    class="form-check-input" type="checkbox" id="ByWeekDay">
                    <label class="smaller form-check-label">{{tr('All Days')}}</label>
                </div>
            </div>
            <div class="form-group col" v-if="!allDays" v-for="rw of [0, 1, 2, 3, 4, 5, 6]">
                <div class="form-check">
                    <input v-model="record.WeekDays[rw]" class="form-check-input" type="checkbox" @change="changeWeekDays(rw)">
                    <label class="smaller form-check-label">{{tr(getWeekDayName(rw))}}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'record-week-days',
  props: {
      record: {
          type: Object,
      },
      emits: {
          type: Boolean,
          default: true
      },
      modal: {
          type: String,
          default: ''
      },
  },
	data () {
	    return {
	        allDays: !this.record.ByWeekDay,
	    }
	},
	mounted () {
	    if (!this.record.WeekDays) {
	        this.record.WeekDays = {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false}
	    }
	},
	methods: {
    getWeekDayName (w) {
        return tools.getWeekDayName(w);
    },
    change () {
        if (this.emits) {
            vueTools.change(this, null, 'ByWeekDay', !this.record.ByWeekDay, this.modal);
        } else {
            this.$emit('change');
            this.record.ByWeekDay = !this.record.ByWeekDay;
        }
    },
    changeWeekDays (i) {
        if (this.emits) {
            vueTools.change(this, ['WeekDays'], i, this.record.WeekDays[i], this.modal);
        } else {
            this.$emit('change');
        }
    }
	}
}
</script>
