import 'vanilla-cookieconsent';
import '@/css/cookieconsent.css';

// obtain cookieconsent plugin
var cc = initCookieConsent();
var logo = require('@/extra/custom/img/logo-full.png');

// example logo
var logo = `<img src="${logo}" alt="Logo" loading="lazy" style="margin-left: -4px; margin-bottom: -5px; height: 35px">`;
var cookie = '🍪';
cc.run({
    current_lang : 'en',
    autoclear_cookies : true,                   // default: false
    cookie_name: 'cc_cookie_demo1',             // default: 'cc_cookie'
    cookie_expiration : 365,                    // default: 182
    page_scripts: true,                         // default: false

    // auto_language: null,                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                           // default: true
    // delay: 0,                                // default: 0
    // force_consent: false,
    // hide_from_bots: false,                   // default: false
    // remove_cookie_tables: false              // default: false
    // cookie_domain: location.hostname,        // default: current domain
    // cookie_path: "/",                        // default: root
    // cookie_same_site: "Lax",
    // use_rfc_cookie: false,                   // default: false
    // revision: 0,                             // default: 0

    gui_options: {
        consent_modal: {
            layout: 'box',                      // box,cloud,bar
            position: 'bottom right',           // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
        },
        settings_modal: {
            layout: 'box',                      // box,bar
            // position: 'left',                // right,left (available only if bar layout selected)
            transition: 'slide'                 // zoom,slide
        }
    },

    onFirstAction: () => {
        //console.log('onFirstAction fired');
    },

    onAccept: (cookie) => {
        //console.log('onAccept fired ...');
    },

    onChange: (cookie, changed_preferences) => {
        //console.log('onChange fired ...');
    },

    languages: {
        'en': {
            consent_modal: {
                title: cookie + ' We use cookies! ',
                description: 'Hi, this website uses essential cookies to ensure its proper operation. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: logo,
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Session',
                        description: 'Session cookies expire once you log off or close the browser. They are only stored temporarily and are destroyed after leaving the page. They are also known as transient cookies, non-persistent cookies, or temporary cookies.',
                        toggle: {
                            value: 'session',     // there are no default categories => you specify them
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'session',
                                col2: api.serverEndpoint,
                                col3: '3 days',
                                is_regex: true
                            },
                        ]
                    }
                ]
            }
        },
        'es': {
            consent_modal: {
                title: cookie + ' Usamos cookies! ',
                description: 'Hola, este sitio web utiliza cookies esenciales para garantizar su correcto funcionamiento. <button type="button" data-cc="c-settings" class="cc-link">Dejame elegir</button>',
                primary_btn: {
                    text: tr('Accept all'),
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: tr('Reject all'),
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: logo,
                save_settings_btn: tr('Save settings'),
                accept_all_btn: tr('Accept all'),
                reject_all_btn: tr('Reject all'),
                close_btn_label: tr('Close'),
                cookie_table_headers: [
                    {col1: tr('Name')},
                    {col2: tr('Domain')},
                    {col3: tr('Expiration')},
                ],
                blocks: [
                    {
                        title: 'Sso de cookies 📢',
                        description: 'Utilizo cookies para garantizar las funcionalidades básicas del sitio web y mejorar su experiencia en línea. Puede elegir para cada categoría optar por participar o no participar cuando lo desee. Para obtener más detalles relacionados con las cookies y otros datos confidenciales, lea la <a href="#" class="cc-link">política de privacidad</a>.'
                    }, {
                        title: 'Session',
                        description: 'Las cookies de sesión caducan una vez que cierra la sesión o cierra el navegador. Solo se almacenan temporalmente y se destruyen después de salir de la página. También se conocen como cookies transitorias, cookies no persistentes o cookies temporales.',
                        toggle: {
                            value: 'session',     // there are no default categories => you specify them
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'session',
                                col2: api.serverEndpoint,
                                col3: '3 días',
                                is_regex: true
                            },
                        ]
                    }
                ]
            }
        }

    }

});

if (api.language == 'es') cc.updateLanguage('es', false);
window._cc = cc;
