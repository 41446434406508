<template>
    <div v-if="recordStore">
        <!--section class="breadcrumbs" :style="image">
          <div class="container">
            <h2>{{recordStore.Title}}</h2>
          </div>
        </section-->
        <itinerary-header-cover></itinerary-header-cover>
        <section id="trip" class="banner">
          <!-- div class="">
            <h2>{{recordStore.Title}}</h2>
          </div-->
          <div class="container">
            <div class="col-lg-12">
              <div class="row gx-4  ">
                <div class="col">
                  <div class="info-box">
                    <i class="ri-user-line"></i>
                    <div>
                      <p>{{tr('TRAVEL FOR')}}</p>
                      <h3>{{recordStore.PaxName}}</h3>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="info-box">
                    <i class="ri-calendar-2-line"></i>
                    <div>
                      <p>{{tr('DURATION')}}</p>
                      <h3>{{recordStore.daysLength}} {{tr('Days')}} /
                      {{recordStore.daysLength - 1}} {{tr('Nights')}}</h3>
                    </div>
                  </div>
                </div>
                <div class="col" v-if="recordStore.TripModeId">
                  <div class="info-box">
                    <i class="ri-map-pin-user-line"></i>
                    <div>
                      <p>{{tr('TRIP MODE')}}</p>
                      <h3>{{tr(recordStore.TripMode.Name)}}</h3>
                    </div>
                  </div>
                </div>
                <div class="col" v-if="recordStore.Difficulty">
                  <div class="info-box">
                    <i class="ri-walk-line"></i>
                    <div>
                      <p>{{tr('DIFFICULTY')}}</p>
                      <h3>{{tr(recordStore.Difficulty)}}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <itinerary-header-summary :canEdit="canEdit">
        </itinerary-header-summary>
        <section id="trip2">
          <div class="container not-break-inside">
            <div class="row">
              <div class="col-6-4 mt-4">
                <div class="member">
                  <div class="member-info">
                    <div class="pic">
                      <async-img :url="recordStore.SalesUser.Image.url" class="img-fluid"
                          v-if="recordStore.SalesUser && recordStore.SalesUser.Image && recordStore.SalesUser.Image.url">
                       </async-img>
                    </div>
                    <h4>{{recordStore.SalesUser.FirstName}} {{recordStore.SalesUser.LastName}}</h4>
                    <span>{{getJobTitle}}</span>
                    <div class="social">
                      <a :href="'mailto:' + recordStore.SalesUser.Email"><i class="ri-mail-fill"></i></a>
                      <div class="social-text ml-2">{{recordStore.SalesUser.Email}}</div>
                    </div>
                    <div class="social" v-if="recordStore.SalesUser.Skype">
                      <a href=""><i class="ri-skype-fill"></i></a>
                      <div class="social-text ml-2">{{recordStore.SalesUser.Skype}}</div>
                    </div>
                    <div class="social" v-if="recordStore.SalesUser.Phone">
                      <a href=""><i class="ri-whatsapp-fill"></i></a>
                      <div class="social-text ml-2">{{recordStore.SalesUser.Phone}}</div>
                    </div>
                    <text-rows-style
                        class="text-justify"
                        tag="p"
                        :text="getUserProfile"
                    ></text-rows-style>
                  </div>
                </div>
              </div>
              <div class="col-6-4 mt-4">
                <div class="sidebar map">
                  <h3 class="sidebar-title">{{tr('Map')}}</h3>
                  <div v-if="displayMap" id="mapDiv">
                    <google-map
                        :bookingCities="bookingCities"
                        :bookingCitiesObject="recordStore.bookingCitiesObject"
                        :languageCode="recordStore.Language.Code"
                    v-if="bookingCities.length>0"/>
                  </div>
                </div>
              </div>
              <div class="col-6-4 mt-4 print-hide">
                <div class="sidebar">
                  <h3 class="sidebar-title">{{tr('ITINERARY')}}</h3>
                  <div class="sidebar-item recent-posts">
                    <div class="post-item clearfix" v-for="day of recordStore.BookingDays" :key="day.DayNr">
                      <div v-if="!recordStore.skipDays[day.DayNr] && toShow(day)">
                        <a class="nav-link cursor-pointer d-flex" @click="allDays = true" v-if="showMoreDays(day)">
                          <i class="dot ri-focus-line"></i>
                          <h4>...</h4>
                        </a>
                        <a class="nav-link cursor-pointer d-flex" @click="scrollTo('day-' + day.DayNr)" v-else
                          @mouseover="overDay(day)" @mouseleave="mouseLeave()">
                          <i class="dot ri-focus-line"></i>
                          <h4>{{getDayNumber(day)}}</h4>
                          <h4 class="ml-2"><i> {{day.dayTitle}}</i></h4>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
</template>

<script>
const GoogleMap  = importVueComp('components/tools', 'GoogleMap');
const textAreaEditStyle = importVueComp('components/tools', 'TextAreaEditStyle');
const itineraryHeaderCover = importVueComp('components/itinerary', 'ItineraryHeaderCover');
const itineraryHeaderSummary = importVueComp('components/itinerary', 'ItineraryHeaderSummary');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'itinerary-header',
    props: ['crop', 'canEdit', 'expanded', 'version'],
    components: {
        'google-map': GoogleMap,
        'text-area-edit-style': textAreaEditStyle,
        'itinerary-header-cover': itineraryHeaderCover,
        'itinerary-header-summary': itineraryHeaderSummary,
    },
    data () {
        return {
            displayMap: false,
            edit: false,
            allDays: false,
        }
    },
    async mounted () {
        if (!this.displayMap && this.bookingCities.length>0) {
            this.displayMap = true;
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
        }),
        bookingCities () {
            return this.recordStore? this.recordStore.bookingCities: [];
        },
        getUserProfile () {
            if (!this.recordStore) return '';
            if (!this.recordStore.SalesUser) return '';
            let r = _.find(this.recordStore.SalesUser.UserProfiles, (c) => c.LanguageId == this.recordStore.LanguageId);
            if (r && r.Description) return r.Description;
            if (this.recordStore.SalesUser.Profile) return this.recordStore.SalesUser.Profile;
            return '';
        },
        getJobTitle () {
            if (!this.recordStore) return '';
            if (!this.recordStore.SalesUser) return '';
            let r = _.find(this.recordStore.SalesUser.UserProfiles, (c) => c.LanguageId == this.recordStore.LanguageId);
            if (r && r.JobTitle) return r.JobTitle;
            return this.tr('Travel Advisor');
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.recordStore.Language.Code);
        },
        setEdit () {
            if (!this.canEdit) return;
            if (!this.salesActions && !this.managerActions) return;
            this.edit = true;
        },
        getCitiesNames (day) {
            return day.getCitiesNames(this.recordStore.hotelsByDay[day.DayNr]);
        },
        scrollTo (id) {
            frontTools.scrollTo(id, -70);
        },
        getDayNumber (day) {
            if (day.GroupDays && day.GroupDayTo) {
                return `${this.tr('_From')} ${this.tr('Day')} ${day.DayNr + 1} ${this.tr('_TO')} ${day.GroupDayTo}`;
            }
            return `${this.tr('Day')} ${parseInt(day.DayNr) + 1}`;
        },
        toShow (day) {
            if (this.recordStore.daysLength <= 10) return true;
            if (day.DayNr < 5) return true;
            let m = this.recordStore.daysLength - 5;
            if (day.DayNr >= m) return true;
            if (this.recordStore.daysLength > 10 && day.DayNr == 5) return true;
            return this.allDays;
        },
        showMoreDays (day) {
            if (this.allDays) return false;
            if (this.recordStore.daysLength > 10 && day.DayNr == 5) return true;
        },
        overDay (day) {
            EventBus.$emit('show-city', day.getCities(this.recordStore.hotelsByDay[day.DayNr]));
        },
        mouseLeave () {
            EventBus.$emit('show-city', []);
        }
    },
    watch: {
        bookingCities: {
            handler () {
                this.displayMap = false;
                let self = this;
                setTimeout(function () {
                    self.displayMap = true;
                }, 1);
            },
            deep: true,
        },
    },
}
</script>


<style>
    #mapDiv {
        border: 1px solid #ebebeb;
    }
    .social-text {
        font-size: 14px;
    }
</style>
