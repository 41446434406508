<template>
    <div id="fstyles" @contextmenu="handleClick($event)">
        <div v-if="recordStore && !processing">
            <button type="button" class="btn btn-primary action-button button-fixed" @click="printPDF">
                <font-awesome-icon icon="print"/>
            </button>


            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Itinerary & Confirmation</h1>
                    </div>
                </div>
            </div>

            <booking-final-preview
            ></booking-final-preview>
            <br>
            <booking-final-terms></booking-final-terms>
            <booking-final-footer></booking-final-footer>
        </div>
    </div>
</template>

<script>
let bookingFinalPreview = importVueComp('components/documentation', 'BookingFinalPreview');
let bookingFinalTerms = importVueComp('components/documentation', 'BookingFinalTerms');
let bookingFinalFooter = importVueComp('components/documentation', 'BookingFinalFooter');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-final-view',
    props: ['id', 'hash'],
    components: {
        'booking-final-preview': bookingFinalPreview,
        'booking-final-terms': bookingFinalTerms,
        'booking-final-footer': bookingFinalFooter,
    },
    data () {
        return {}
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        langCode () {
           let r = _.find(api.tables.language, (c) => c.id == this.recordStore.LanguageId)
           if (r) return r.Code;
        },
    },
    async mounted () {
        await this.mount();
        this.afterMount();
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        printPDF () {
            window.print();
        },
        async mount() {
            api.setProcessing(true)
            let model = await api.importMixinModule('booking', 'model');
            let r = await api.get('/public/api/get_booking/' + this.hash + '/' + this.id);
            if (r) {
                r._public = true;
                let record = await new model({record: r, notFieldOptions: true});
                this.$store.commit('initRecord', {record});
            }
            api.setProcessing(false)
        },
        afterMount () {
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        }

    },
    beforeDestroy() {
        this.$store.commit('initRecord', {record: null});
    },

}
</script>

