
<script>
const paymentListReport = importVueComp('components/reports', 'PaymentListReport', 'arg');
export default {
    name: 'arg-payment-list-report',
    mixins: [paymentListReport],
    methods: {
        getData (self) {
            let r = paymentListReport.methods.getData(self);
            r.buttons.push({ callback: self.applyRetencion, label: 'Aplicar Retención', buttonClass: 'btn btn-primary', id: 'applyRetencion',
                    disabled: false})
            return r;
        },
        async applyRetencion (self)  {
            let values = [];
            if (this.selected.length==0) return;
            let res = await api.post('/api/bookingpayment/retencion/' + this.selected[0].id, {});
            if (res) {
                self.run();
            } else {
                alert('No es posible realizar retención')
            }
        },

    }
}
</script>

