<template>
    <div v-if="langCode && recordStore">
        <div v-if="day.hotelListByDay.length==1">
            <div class="entry-meta icon-box not-break-inside">
                <i class="ri-hotel-line"></i>
                <div>
                  <h4>{{tr('Accommodation')}}</h4>
                  <span v-if="day.hotelListByDay[0].HotelCategory"> {{day.hotelListByDay[0].HotelCategory}}: </span>
                  <span class="mb-1">{{day.hotelListByDay[0].Hotel.Name}} ({{day.hotelListByDay[0].rooms}})</span>
                </div>
            </div>
            <div class="entry-meta icon-box pl-0">
                <text-rows-style tag="p"
                  class="text-justify"
                  :text="getHotelText(day.hotelListByDay[0].Hotel.HotelDescriptions)">
                </text-rows-style>
            </div>
            <photo-gallery
                :crop="crop"
                :images="hotelImageList"
            ></photo-gallery>
        </div>
        <div v-else-if="day.hotelListByDay.length>1">
            <div class="entry-meta icon-box not-break-inside">
                <i class="ri-hotel-line"></i>
                <div class="w-100">
                    <h4>{{tr('Accommodation')}}</h4>
                    <div class="border-bottom" :id="'hotels-' + day.DayNr" v-for="(hotel, hIdx) of day.hotelListByDay">
                        <div class="py-1" :id="'hotelsHeading-' + day.DayNr + '-' + hIdx">
                            <div class="accordion-button collapsed hotel-col d-flex align-items-center"
                                @click="setSelectedHotel(day.DayNr, hIdx)" data-toggle="collapse"
                                :data-target="'#hotelsCollapse-' + day.DayNr + '-' + hIdx"
                                aria-expanded="false" :aria-controls="'hotelsCollapse-' + day.DayNr + '-' + hIdx">
                                <div class="d-flex">
                                  <span v-if="hotel.HotelCategory">{{tr('Option')}} {{hotel.HotelCategory}}:
                                    {{hotel.Hotel.Name}} ({{hotel.rooms}})
                                  </span>
                                  <span v-else>
                                    {{hotel.Hotel.Name}} ({{hotel.rooms}})
                                  </span>
                                </div>
                            </div>
                        </div>

                        <div :id="'hotelsCollapse-' + day.DayNr + '-' + hIdx" class="collapse"
                            v-if="hotelText"
                            :aria-labelledby="'hotelsHeading-' + day.DayNr + '-' + hIdx"
                            :data-parent="'#hotels-' + day.DayNr">
                            <div class="hotel-text not-break-inside">
                                <text-rows-style tag="p"
                                  :text="getHotelText(hotel.Hotel.HotelDescriptions)"></text-rows-style>
                            </div>
                            <photo-gallery
                                v-if="hotel.Hotel && hotelImageListByHotel"
                                :images="hotelImageListByHotel[hotel.Hotel.id]" :crop="crop">
                            </photo-gallery>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
const photoGallery = importVueComp('components/tools', 'PhotoGallery');

export default {
    name: 'itinerary-body-hotel-text',
    props: ['day', 'crop'],
    components: {
        'photo-gallery': photoGallery
    },
    data () {
        return {
            cityId: null,
            expanded: false,
            hotelText: true,
            hotelSelected: {},
        }
    },
    asyncComputed: {
        async hotelImageList () {
            let res = [];
            if (!this.day) return res;
            if (!this.day.hotelListByDay) return res;
            if (!this.day.hotelListByDay[0]) return res;
            if (!this.day.hotelListByDay[0].Hotel) return res;
            for (let image of this.day.hotelListByDay[0].Hotel.HotelPhotos) {
                if (image.Image) {
                    let imageSRC = await api.getImage(image.Image.url);
                    res.push({id: image.id, src: imageSRC, thumbnail: imageSRC});
                }
            }
            return res;
        },
        async hotelImageListByHotel () {
            let res = {};
            for (let hotel of this.day.hotelListByDay) {
                if (!hotel) continue;
                if (!hotel.Hotel) continue;
                if (!hotel.Hotel.HotelPhotos) continue;
                if (!res[hotel.Hotel.id]) res[hotel.Hotel.id] = [];
                for (let image of hotel.Hotel.HotelPhotos) {
                    if (image.Image) {
                        let imageSRC = await api.getImage(image.Image.url);
                        res[hotel.Hotel.id].push({id: image.id, src: imageSRC, thumbnail: imageSRC});
                    }
                }
            }
            return res;
        },
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        async getImageList (images) {
            let res = [];
            for (let image of images) {
                if (!image.Image) continue;
                if (!image.Image.url) continue;
                let imageSRC = await api.getImage(image.Image.url);
                res.push({id: image.id, src: imageSRC, thumbnail: imageSRC});
            }
            return res;
        },
        getHotelText (descriptions) {
            let t = _.find(descriptions, (c) => c.LanguageId==this.recordStore.LanguageId);
            if (t) return t.Description;
            return '';
        },
        setSelectedHotel (dayNr, i) {
            this.hotelSelected[dayNr] = i;
            this.hotelSelected = Object.assign({}, this.hotelSelected);
        },
        getRoomCategoryName (hotelDay) {
            let hotelList = [];
            let hotels = {};
            for (let room of hotelDay.BookingDayRooms) {
                if (!room.Hotel) continue;
                if (!room.Hotel.id) continue;
                if (!room.Hotel.RoomCategories) continue;
                if (!room.roomQuoteSelected && this.recordStore.anyQuoteSelected) continue;
                if (!hotels[room.Hotel.id]) {
                    hotelList.push(room.Hotel.id);
                    hotels[room.Hotel.id] = {rooms: [], hotelName: room.Hotel.Name};
                    if (room.HotelCategory && room.HotelCategory.Name) {
                        hotels[room.Hotel.id].HotelCategory = room.HotelCategory.Name;
                    }
                }
                for (let category of room.Hotel.RoomCategories) {
                    if (category.id == room.RoomCategory && hotels[room.Hotel.id].rooms.indexOf(category.Name)==-1) {
                        hotels[room.Hotel.id].rooms.push(category.Name);
                    }
                }
            }
            let res = [];
            for (let id of hotelList) {
                let hotel = hotels[id];
                let r = '';
                if (hotel.HotelCategory) {
                    r += hotel.HotelCategory + ' - ';
                }
                r += hotel.hotelName + ' (' + hotel.rooms.join(', ') + ')';
                res.push(r);

            }
            return res.join(' / ');
        },
        getCityImage (city) {
            if (!city) return;
            if (city.Image && city.Image.url) return city.Image.url;
        },
        getHotelName () {
            if (this.hotelsByDay[this.day.DayNr] && this.hotelsByDay[this.day.DayNr].BookingDayRooms[0] &&
                this.hotelsByDay[this.day.DayNr].BookingDayRooms[0].Hotel) {
                return this.hotelsByDay[this.day.DayNr].BookingDayRooms[0].Hotel.Name;
            }
            return '';
        }
   },
}
</script>


