
const quill = {
    state: {
        quillFocus: false,
        quillStatus: false,
    },
    mutations: {
        setQuillFocus (state, value) {
            state.quillFocus = value;
        },
        setQuillStatus (state, value) {
            state.quillStatus = value;
        },
    },
    getters: {
        quillFocus: state => {
          return state.quillFocus;
        },
        quillStatus: state => {
            return state.quillStatus;
        },
    },
}

export default quill;
