<template>
  <div class="modal fade bd-example-modal-lg" id="app-news-modal" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document"">
      <div class="modal-content">
        <div class="modal-header text-center mx-3">
          <h4 class="modal-title w-100">
              {{tr('Version News')}}
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-2">
          <div class="row">
                <div class="col-3 border-right">
                    <div class="flex-column">
                      <div class="news-version-tab" v-for="(r, i) of news"  :class="{'border-bottom': i < news.length - 1}">
                            <div class="cursor-pointer" @click="setCurrent(i)">
                                <label class="mb-0 ml-3" :class="{'font-weight-bold': current == i, 'font-italic': current == i}">
                                  {{r.title}}
                                </label>
                            </div>
                      </div>
                    </div>
                </div>
                <div class="col-9" v-if="!news[current].html">
                    {{news[current].comment}}
                </div>
                <div class="col-9" v-show="news[current].html" id="html-view">
                </div>
          </div>
        </div>
        <div class="modal-footer  mx-3">
            <button @click="setCurrent(current + 1)" v-if="current < news.length - 1"  class="btn waves-effect waves-light btn-primary ml-1">
              {{tr('Next')}}
            </button>
            <button @click="close" v-if="current >= news.length - 1"  class="btn waves-effect waves-light btn-primary ml-1">
              {{tr('Finish')}}
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'app-news-modal',
    mixins: [escape],
    props: ['news', 'showAppNews'],
    data () {
        return {
            current: 0,
        }
    },
    async mounted () {
        $('#app-news-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        this.setCurrent(0);
    },
    methods: {
        close () {
            this.$emit('close');
            this.$emit('update:showAppNews', false);
            $('#app-news-modal').modal('hide');
        },
        setCurrent (i) {
            this.current = i;
            if (this.news[this.current].html) {
                let el = $('#html-view')[0];
                el.innerHTML = this.news[this.current].comment;
            }
        }
    },
}
</script>

<style scoped>
.news-version-tab {
    min-height: 60px !important;
    display: flex;
    align-items: center;
}
.modal-header {
    border-bottom: 2px solid #448aff !important;
}
.modal-footer {
    border-top: 2px solid #448aff !important;
}
.border-bottom {
    border-bottom: 2px solid #448aff !important;
}

</style>
