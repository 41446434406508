<template>
      <report-window endpoint="/reports/bookingsbysalesorigin"
          v-if="ready"
          :fields="fields"
          :title="cardTitle || title"
          :headers="headers"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :slotsFields="[]"
          :totalsOn="totalsOn"
          :allowChart="true"
          :chartOptions="chartOptions"
          :totalLabel="totalLabel"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'bookings-by-sales-origin-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From', columns: 2},
              {name: 'ToDate', editor: 'date', label: 'To', columns: 2},
              {name: 'SalesUserId', label: 'Seller', editor: 'select', columns: 2,
                optionLabels: 'UserName', relation: 'user', addBlank: true,
                filters: (row) => {
                    if (row.Closed) return false;
                    if (row.Department && row.Department.Sales) return true;
                }
              },
            ],
            title: 'Bookings by Sales Origin',
            headers: {
              Bookings: 'Quotations',
              Rate: `% ${tr('Conversion')}`,
            },
            templates: [
                {name: 'Rate', callback: (row) => {
                    if (!row.calculated) return row.Rate;
                    return (row.Confirmed / row.Bookings * 100).toFixed(0);
                  }
                },

            ],
            totalsOn: [ 'Bookings', 'Confirmed'],
            totalLabel: ['SalesOrigin'],
            chartOptions: {
              y: 'SalesOrigin',
              x: ['Bookings', 'Confirmed', 'Rate']
            }
        }
    },
}
</script>

