<template>
    <div>
        <div class="form-row">
            <div v-if="field.mainImage && record[field.mainImageId]" :class="'col-md-' + getColumns(field)">
                <photo-div
                    matClass="form-material"
                    :showDelete="true"
                    :image.sync="record[field.mainImage]"
                    :imageId.sync="record[field.mainImageId]"
                    @change="changeMainImage"
                    @remove="removeMainImage"
                ></photo-div>
            </div>
            <div :class="'col-md-' + getColumns(field)" v-for="(row, rowNr) of record[field.name]">
                <div class="form-group">
                    <photo-div
                        matClass="form-material"
                        :key="'photo-div-' + rowNr"
                        ref="photoDiv"
                        :showDelete="true"
                        :image.sync="row.Image"
                        :imageId.sync="row.ImageId"
                        @change="change(rowNr)"
                        @remove="removeRow(rowNr)"
                        @newImage="newImage"
                    ></photo-div>
                </div>
            </div>
        </div>
        <i class="add-item cursor-pointer" @click="newRow">
            <font-awesome-icon icon="plus-circle"/>
        </i>
    </div>
</template>

<script>
export default {
  name: 'record-image-rows',
	props: ['field', 'record', 'modal'],
	methods: {
        async newRow () {
            let rowNr = this.record[this.field.name].length;
            await this.addRow();
            if (this.$refs.photoDiv[rowNr]) {
                this.$refs.photoDiv[rowNr].showPhotoPicker();
            }
        },
        async addRow (){
            await vueTools.addRow(this, null, this.field.name, null, this.modal);
        },
        async removeRow (rowNr) {
            await vueTools.removeRow(this, null, this.field.name, rowNr, this.modal);
        },
        changeMainImage () {
            vueTools.change(this, null, this.field.mainImageId, this.record[this.field.mainImageId]);
        },
        removeMainImage () {
            vueTools.change(this, null, this.field.mainImageId, null);
        },
        change (i) {
            vueTools.change(this, [this.field.name, i], 'ImageId', this.record[this.field.name][i].ImageId);
        },
        getColumns (field) {
            if (field.columns) return field.columns;
            if (this.columns) return this.columns;
            return 3;
        },
        async newImage (img) {
            let rowNr = this.record[this.field.name].length;
            await this.addRow();
            let row = this.record[this.field.name][rowNr];
            if (!row) return;
            row.Image = img;
            row.ImageId = img.id;
            this.change(rowNr);
        }

	}
}
</script>
