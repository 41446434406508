<template>
    <div class="mb-2 cursor-pointer">
        <label class="mb-0"><b>{{hotel.Supplier.Name}}</b></label> /
        <i :class="color">{{roomStatusLabel}}</i>
    </div>
</template>

<script>
export default {
    name: 'operations-hotel-link',
    props: ['hotel', 'current'],
    data () {
        let roomView = api.importMixinModule('bookingdayroom', 'views');
        return {
            roomStatusOptions: roomView.serviceStatus,
            order: ['PENDING', 'REQUESTED', 'CONFIRMED', 'CANCELED'],
            colors: {
                'text-danger': ['PENDING', 'CANCELED', 'NOT_SERVICES'],
                'text-success': ['CONFIRMED'],
                'text-primary': ['REQUESTED']
            }
        }
    },
    computed: {
        color () {
            for (let id in this.colors) {
                if (this.colors[id].indexOf(this.roomStatus)>-1) return id;
            }
        },
        roomStatusLabel () {
            if (this.roomStatusOptions[this.roomStatus]) {
                return tr(this.roomStatusOptions[this.roomStatus]);
            }
            if (this.roomStatus=='NOT_SERVICES') {
                return tr('Without Services');
            }
            return tr('Without Services');
        },
        roomStatus () {
            if (this.hotel.Rooms.length == 0) return 'NOT_SERVICES';
            let status = {}
            for (let id in this.roomStatusOptions) {
                status[id] = 0;
            }
            for (let s of this.hotel.Rooms) {
                if (status[s.Status]!=undefined) {
                    status[s.Status] += 1;
                }
            }
            for (let s of this.order){
                if (status[s] > 0) {
                    return s;
                }
            }
        }
    },
}
</script>
