<template>
    <vue-json-editor v-model="value" :expandedOnStart="true"
    @json-change="onJsonChange"></vue-json-editor>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import { mapState } from 'vuex';
export default {
    name: 'json-editor',
    props: ['record', 'field'],
    data () {
        return {
            value: null,
            type: 'object',
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    mounted () {
        let fieldName = this.field.name;
        if (this.field.fieldName) fieldName = this.field.fieldName;
        if (this.record[fieldName]) {
            if (typeof this.record[fieldName] == 'string') {
                this.value = JSON.parse(this.record[fieldName]);
                this.type = 'string';
            } else {
                this.value = this.record[fieldName];
            }
        } else {
            this.value = {"example": "example"};
        }
    },
    components: {
      'vue-json-editor': vueJsonEditor,
    },
    methods: {
        onJsonChange (value) {
            let fieldName = this.field.name;
            if (this.field.fieldName) fieldName = this.field.fieldName;
            if (this.type == 'string') {
                this.recordStore.setValue({fieldName, value: JSON.stringify(value)});
            } else {
                this.recordStore.setValue({fieldName, value});
            }
        },
    }
}
</script>

<style>
    .jsoneditor-tree {
        min-height: 200px !important;
    }
</style>
