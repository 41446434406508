import ImportInvoices from '@/extra/custom/components/tools/ImportInvoices';

function getCustomRoutes () {
    let res = [];
    res.push({
        path: '/importinvoices',
        name: 'import-invoices',
        component: ImportInvoices,
        label: 'Importar Facturas',
        menu: 'adminMenu',
    })
    let remove = []
    return {routes: res, remove: remove};
}

export default getCustomRoutes();