<template>
    <report-window
        v-if="ready"
        endpoint="/reports/due_cost"
        :perPage="500"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :current.sync="current" :slotsFields="[]"
        :notFilterable="options && options.notFilterable"
        :hideBlankColumns="true"
    ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";

export default {
    name: "due-cost-report",
    props: ["options"],
    components: {
        ReportWindow
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    mounted: async function() {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        return {
            current: {},
            ready: false,
            margins: null,
            fields: [
                {
                    name: "Date",
                    editor: "date",
                    required: true,
                    label: "Date",
                    columns: 6
                },
            ],
            title: "Due Cost",
            headers: {
                SupplierId: "Supplier",
            },
            templates: [
                {name: 'LastDate', callback: (row) => {
                    if (!row.LastDate) return '';
                    return moment(row.LastDate).format("DD/MM/YYYY")
                  }
                },
            ]
        };
    },
    methods: {
    }
};
</script>