<template>
    <div class="container-fluid">
        <nav class="row d-flex px-3">
            <div class="col-lg-6 col-xs-10">
                <div class="pb-0 display-4"><h3>{{tr(pageTitle)}}</h3></div>
            </div>
            <div class="col-lg-6 col-xs-12 ">
                <breadcrumb
                    :list="[{title: module}, {title: pageTitle}]"
                ></breadcrumb>
            </div>
        </nav>
        <abm-booking
            v-if="user && ready"
            :refresh="true"
            :endpoint="apiEndpoint"
            :options="options"
            :tableName="tableName"
            :template="template"
            :sales="sales"
        ></abm-booking>
    </div>
</template>

<script>
import abmTable from '@/components/pages/AbmTable';
const abmBooking = importVueComp('components/tools', 'abmBooking')
import { mapState } from 'vuex';

export default {
    name: 'page-booking',
    props: ['template', 'byUser', 'title', 'sales', 'status', 'table'],
    mixins: [abmTable],
    components: {
        'abm-booking': abmBooking,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        pageTitle () {
            if (this.title) return this.title;
            if (this.options.title) return this.options.title;
            return this.tableName;
        }
    },
    methods: {
        async onMount () {
            this.apiEndpoint = `/api/booking/?`;
            let table = 'booking';
            if (this.table) table = this.table;
            if (this.template != 'NONE') {
                this.apiEndpoint = `/api/template/?TemplateType=${this.template}`;
            } else {
                this.apiEndpoint = `/api/${table}/?TemplateType=NONE`;
            }
            if (this.byUser) {
                if (this.byUser=='SALES') {
                  this.apiEndpoint += `&UserId=${this.user.id}`;
                } else {
                  this.apiEndpoint += `&OperUserId=${this.user.id}`;
                }
            } else {
                this.apiEndpoint += `&ByFilters=${this.user.id}`;
            }
            if (this.sales) {
                this.apiEndpoint += `&Confirmed=true&OperMode=true`;
            }
            if (this.status) {
                this.apiEndpoint += `&Status=${this.status}`;
            }
            this.options = await api.getModuleFieldsOptions('booking', true, {isTemplate: this.template != 'NONE'});
            if (this.title) this.options.title = this.title;
            this.module = frontTools.getModuleByPath(this.$router.currentRoute.path.split('/')[2]);
            this.tableName = 'booking';
            this.ready = true;
        },
    }
}
</script>

