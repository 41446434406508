<template>
      <div class="col-12 form-row px-0">
          <div class="col-1 d-flex align-items-center">
              <font-awesome-icon :icon="getIcon"/>
          </div>
          <div :class="nameCols">
              <div>
                  <div class="form-row ">
                      <label
                          v-if="recordStore.BookingDays[dayId].BookingDayServices[rowIdx].serviceRowName"
                          class="my-0 cursor-pointer"
                          :class="recordStore.BookingDays[dayId].BookingDayServices[rowIdx].serviceClassName">
                        {{tr(recordStore.BookingDays[dayId].BookingDayServices[rowIdx].serviceRowName)}}
                      </label>
                      <label v-else class="my-0 cursor-pointer">
                        {{tr('Add Service Name')}}
                      </label>
                  </div>
                  <div class="form-row">
                      <i><label class="my-0">
                          <span v-if="service.supplierName">{{service.supplierName}}</span>
                          <span v-else>{{tr('Add Supplier')}}</span>
                          /
                          <span v-if="service.cityName">{{service.cityName}}</span>
                          <span v-else>{{tr('Add Destination')}}</span>

                      </label></i>
                  </div>
              </div>
          </div>
          <div :class="imageCols" v-if="serviceImages.length>0" class="d-flex justify-content-center">
              <photo-small
                  v-for="r of serviceImages"
                  class="d-flex align-items-center pl-1 mh-30"
                  :image="r.Image"
              ></photo-small>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-center">
              <span v-if="recordStore.BookingDays[dayId].BookingDayServices[rowIdx].Optional">
                  <i>{{tr('Optional')}}</i>
              </span>
          </div>
      </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-day-service-info',
    props: ['rowIdx', 'dayId'],
    data () {
        return {
        }
    },
    mounted () {
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        getIcon () {
            if (!this.service.Service) return 'grip-vertical';
            if (!this.service.Service.ServiceClass) return 'grip-vertical';
            if (!this.service.Service.ServiceClass.Icon) return 'grip-vertical';
            return this.service.Service.ServiceClass.Icon;
        },
        serviceImages () {
            return this.service.BookingDayServiceImages;
        },
        nameCols () {
            let v = 10;
            let i = this.service.BookingDayServiceImages.length;
            let r = v - i;
            return `col-${r}`;
        },
        imageCols () {
            let i = this.serviceImages.length;
            return `col-${i}`;
        },
        service () {
            return this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx];
        },
    },
}
</script>




