<template>
    <div class="modal fade bd-example-modal-lg" id="booking-day-settings" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
                <button type="button" class="close mr-1" @click="close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <booking-day-options
                    :compressView="compressView"
                    :dayId="dayId"
                    :dayNr="dayNr"
                ></booking-day-options>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
const bookingDayOptions = importVueComp('components/booking', 'BookingDayOptions');
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';

export default {
    name: 'booking-day-settings',
    mixins: [escape],
    props: ['dayId', 'dayNr', 'compressView'],
    components: {
        'booking-day-options': bookingDayOptions,
    },
    data () {
        return {
            currentDay: null,
            isOver: false,
            templateSelector: false,
            onlyServices: null,
        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
        }),
        sales () {
            return this.salesActions || this.recordStore.isTemplate;
        },
    },
    mounted () {
        $('#booking-day-settings').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        close () {
            this.$emit('close');
            $('#booking-day-settings').modal('hide');
        }
    },
    watch: {
    },
    beforeDestroy() {
    },
}
</script>



