<template>
    <div class="modal fade bd-example-modal-lg" id="record-window-modal" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-large" role="document" :class="{'modal-large': modalLarge, modalClass: modalClass}">
        <div class="modal-content">
          <div class="modal-body">
              <div class="close py-0">
                  <button type="button" class="close ml-3" @click="$refs.recordWindow.close()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <button type="button" class="close" @click="minimize">
                    <font-awesome-icon icon="minus" />
                  </button>
              </div>
              <record-window
                  ref="recordWindow"
                  :table="table"
                  :id="id"
                  :view="view"
                  :newRecord="newRecord"
                  @close="close"
                  @setTitle="setTitle"
                  modal="1"
              ></record-window>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
import recordWindow from '@/components/tools/RecordWindow';
export default {
    name: 'record-window-modal',
    mixins: [escape],
    components: {
        'record-window': recordWindow,
    },
    data () {
        return {
            title: null,
        }
    },
    props: ['table', 'id', 'showRecordModal', 'modalLarge', 'modalClass', 'newRecord', 'view'],
    async mounted () {
        $('#record-window-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        $('#record-window-modal').draggable({handle: ".record-header"});
    },
    methods: {
        setTitle (t) {
            this.title = t;
        },
        close () {
            this.$emit('close');
            this.$emit('update:showRecordModal', false);
            $('#record-window-modal').modal('hide');
        },
        minimize () {
            $('#record-window-modal').toggleClass("display-none");
            $(".modal-backdrop").toggleClass("display-none");
            $(".minmax-con").toggleClass("display-none");
            $("body").toggleClass("modal-open");
            this.$store.commit('setModalMinTitle', this.title);
        },
    },
}
</script>
