<template>
    <div class="w-100">
        <transition name="fade" mode="out-in">
            <div class="" v-if="loaded" id="service-selector">
                <div class="form-row" v-if="!modal">
                    <div class="form-group col-md-12 section-title mb-3 mt-1" id="serviceSelectorTitle">
                        <label class="ml-4 mb-0">{{tr('Add Service')}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-9 mb-0">
                        <search-box v-model="searchBox" :addId="_uid"></search-box>
                    </div>
                    <div class="form-group col-md-3 mb-0 d-flex justify-content-end">
                        <div class="form-row d-flex align-items-center">
                            <i class="cursor-pointer filters-div mr-3" @click="filters = !filters" :class="{'row-primary-button': filters}">
                                <svg-filters class="service-actions"></svg-filters>
                                <span v-if="filtersCnt" class="badge badge-filters">{{filtersCnt}}</span>
                            </i>
                            <i class="cursor-pointer mr-3" @click="quoteServices"
                             	v-if="modal"
                             	:class="{'row-primary-button': showPrices}"
                                :disabled="list.length>100">
                                <font-awesome-icon icon="dollar-sign"/>
                            </i>
                            <div class="text-right ml-3" v-if="modal">
                                <button type="button" class="btn btn-primary " @click="add"
                                    :disabled="selected.length==0">
                                    {{tr('Done')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <button v-for="x in tags" type="button" class="btn btn-secondary btn-service-selected ml-1"
                            @click="removeTag(x)">{{x}}
                        </button>
                    </div>
                </div>

                <div class="form-row" v-if="modal">
                    <div class="form-group col-md-12">
                        <button v-for="s in selected" type="button" class="btn btn-primary btn-service-selected mx-1">{{s.Name}}
                          <span aria-hidden="true" class="remove-item" @click="removeService(s)">&times;</span>
                        </button>
                    </div>
                </div>

                <div class="form-row form-material" v-if="filters">
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="SupplierId"
                            :fieldOptions="fieldOptions.SupplierId"
                            label="Supplier"
                            :addBlank="true"
                            :currentValue.sync="supplierId"
                        ></vue-select-input>
                    </div>
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="CityId"
                            :fieldOptions="fieldOptions.CityId"
                            label="City"
                            :addBlank="true"
                            :currentValue.sync="cityId"
                        ></vue-select-input>
                    </div>
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="ServiceTypeId"
                            :fieldOptions="fieldOptions.ServiceTypeId"
                            label="Type"
                            :addBlank="true"
                            :currentValue.sync="serviceTypeId"
                        ></vue-select-input>
                    </div>
                    <div class="form-group col-md-6 my-0">
                        <vue-select-input
                            fieldName="ServiceClassId"
                            :fieldOptions="fieldOptions.ServiceClassId"
                            label="Classification"
                            :addBlank="true"
                            :currentValue.sync="serviceClassId"
                        ></vue-select-input>
                    </div>
                </div>
                <div class="service-report-view" >
                    <v-client-table :data="list" :columns="columnsNames" :options="options"
                        v-if="list && loaded"  @row-click="onRowClick">
                          <template slot-scope="props" slot="Name">
                              <div class="form-row services-selector-drag services-table" v-dragula bag="detailbag" v-if="!modal && canAddService">
                                  <div class="col-12 form-row pb-0 service-drag-card text-drag mx-0 px-0">
                                      <div class="col-1 d-flex align-items-center pl-3"
                                        :serviceCategoryId="props.row.ServiceCategoryId"
                                        :serviceId="props.row.sId" v-if="!showPrices">
                                          <font-awesome-icon :icon="getIcon(props.row.Icon)"/>
                                      </div>
                                      <photo-small v-if="!showPrices" class="col-1 d-flex align-items-center justify-content-center"
                                      :image="props.row.Image"></photo-small>
                                      <div class="col-10 form-row service-name pr-0">
                                            <div class="col-12 service-selector-row d-flex align-items-center justify-content-center">
                                                <div :class="getLargeTextClass(props.row)">
                                                <b class="text-drag">{{props.row.Name}}</b> -
                                                <i class="text-drag" v-if="!serviceTypeId">{{listValues[['ServiceTypeId', props.row.ServiceTypeId]]}} - </i>
                                                <b class="text-drag" v-if="props.row.ServiceCategoryId">{{listValues[['ServiceCategoryId', props.row.ServiceCategoryId]]}} - </b>
                                                <b class="text-drag" v-if="!supplierId">{{listValues[['SupplierId', props.row.SupplierId]]}} - </b>
                                                <i class="text-drag" v-if="!cityId">{{listValues[['CityId', props.row.CityId]]}}</i>
                                                </div>
                                            </div>
                                      </div>
                                      <!--div class="col-1 d-flex align-items-center justify-content-end cursor-move">
                                        <font-awesome-icon icon="arrows-alt"/>
                                      </div-->
                                  </div>
                              </div>

                              <div class="row services-table" v-else>
                                  <div class="col-12 form-row pb-2">
                                      <div class="col-1 d-flex align-items-center pl-3" :serviceId="props.row.sId">
                                          <font-awesome-icon :icon="getIcon(props.row.Icon)"/>
                                      </div>
                                      <photo-small class="col-1 d-flex align-items-center" :image="props.row.Image"></photo-small>
                                      <div class="col-10 form-row" :class="{'d-flex align-items-center': !isBreakLine(props.row)}">
                                            <div class="col-12 service-selector-row">
                                                <b>{{props.row.Name}}</b> -
                                                <i v-if="!serviceTypeId">{{listValues[['ServiceTypeId', props.row.ServiceTypeId]]}}</i> -
                                                <b v-if="props.row.ServiceCategoryId">{{listValues[['ServiceCategoryId', props.row.ServiceCategoryId]]}} - </b>
                                                <b v-if="!supplierId">{{listValues[['SupplierId', props.row.SupplierId]]}} - </b>
                                                <i v-if="!cityId">{{listValues[['CityId', props.row.CityId]]}}</i>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </template>
                    </v-client-table>
                </div>
                <div class="text-right" v-if="modal">
                    <button type="button" class="btn btn-primary " @click="add"
                        :disabled="selected.length==0">
                        {{tr('Done')}}
                    </button>
                </div>
            </div>
            <div v-else>
                <div class="linear-activity">
                    <div class="indeterminate"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import svgFilters from '@/components/svg/Filters';
import { mapState } from 'vuex';
import {ClientTable, Event} from 'vue-tables-2';
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
import ResizeSensor from "resize-sensor";


export default {
    name: 'service-selector',
    props: ['dayNr', 'language', 'city', 'modal'],
    components: {
        'svg-filters': svgFilters,
        ClientTable,
    },
    data () {
        let self = this;
        let columnsNames =  ['Name'];
        return {
            searchBox: '',
            showPrices: false,
            filters: false,
            fields: Object.freeze([
                'Name',
                { name:'ServiceTypeId', relation: 'servicetype', optionLabels: 'Name'},
                { name:'ServiceClassId', relation: 'serviceclass', optionLabels: 'Name'},
                { name:'SupplierId', relation: 'supplier', optionLabels: 'Name',
                     filters: (r) => {
                        return r.SupplierType != 'HOTEL';
                    }
                },
                { name:'CityId', relation: 'city', optionLabels: 'Name'},
                { name:'ServiceCategoryId', relation: 'servicecategory', optionLabels: 'Name'},
                'Languages'
            ]),
            listValues: {},
            abmFields: [],
            fieldOptions: {},
            selected: [],
            tags: [],
            supplierId: null,
            cityId: null,
            serviceTypeId: null,
            serviceClassId: null,
            prices: null,
            loaded: false,
            originColumnsNames: columnsNames,
            columnsNames: columnsNames,
            options: {
                filterable: false,
                perPage: 7,
                perPageValues: [10, 20, 50, 100],
                headings: {
                    'Name': tr('Service'),
                    'SupplierId': tr('Supplier'),
                    'CityId': tr('City'),
                    'ServiceTypeId': tr('Type'),
                    'ServiceClassId': tr('Classification'),
                    'Languages': tr('Languages'),
                    'ServiceCategoryId': tr('Category'),
                    'Comment': tr('Comment')
                    },
                destroyEventBus: true,
                texts: vueTools.vueTableText(),
                templates: {},
                skin: 'table-striped table-sm w-100',
            },
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
        }),
        canAddService () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        },
        serviceList () {
            return travelTools.serviceList(api.tables.service, api.tables.supplier, api.tables.servicecategory,
                api.tables.serviceclass);
        },
        search () {
            let res = this.searchBox;
            for (let tag of this.tags){
                res += ' ' + tag
            }
            return res;
        },
        filtersCnt () {
            let res = 0;
            if (this.supplierId) res += 1;
            if (this.cityId) res += 1;
            if (this.serviceTypeId) res += 1;
            if (this.serviceClassId) res += 1;
            return res;
        },
        dataList() {
            let rows = this.serviceList;
            
            if (this.supplierId) {
              rows = rows.filter(r => r.SupplierId == this.supplierId);
            }
            if (this.cityId) {
              rows = rows.filter(r => r.CityId == this.cityId);
            }
            if (this.serviceTypeId) {
              rows = rows.filter(r => r.ServiceTypeId == this.serviceTypeId);
            }
            if (this.serviceClassId) {
              rows = rows.filter(r => r.ServiceClassId == this.serviceClassId);
            }

            if (this.prices) {
              return rows.map(row => {
                const priceRow = this.prices.find(r => r.Service && r.Service.id == row.sId);
                if (priceRow && priceRow.Data) {
                  return Object.assign({}, row, priceRow.Data);
                }
                return row;
              });
            }

            return rows;
        },
        list  () {
            return tools.abmList(this);
        },
        getTemplates () {
            let self = this;
            let res = {}
            for (let field of this.fields) {
                res[field.name] = function(t, row) {
                    let r = self.listValues[[field.name, row[field.name]]]
                    return r;
                }
            }
            return res;
        }
    },
    async mounted () {
        if (!api.tables.service) {
            await api.setTable({table: "service"})
        }
        const debouncedResize = _.debounce(() => {
          $('.service-selector').width($('.add-services').width());
        }, 100);
        new ResizeSensor($('.add-services'), debouncedResize);
        this.selected = [];
        this.fieldOptions = await tools.calculateFieldOptions(this.fields);
        this.getValues();
        if (this.city) this.cityId = this.city;
        this.options.templates = this.getTemplates;
        this.loaded = true;
    },
    methods: {
        getValues () {
            this.listValues = {};
            this.abmFields = tools.getAbmFields(this.fields);
            if (!this.abmFields) return;
            if (!this.dataList) return;
            for (let field of this.abmFields) {
                for (let row of this.dataList) {
                    let r = tools.getDisplayValue(row, field, this.serverSide, this.fieldOptions, false);
                    if (!this.listValues[[field.name, row[field.name]]]) {
                        this.listValues[[field.name, row[field.name]]] = r;
                    }
                }
            }
        },
        getDisplayValue (record, field) {
            return tools.getDisplayValue(record, field, this.fieldOptions, false)
        },
        addService (record){
            if (this.selected.indexOf(record)==-1) this.selected.push(record)
        },
        removeService (record){
            let i = this.selected.indexOf(record);
            if (i>-1) this.selected.splice(i, 1)
        },
        removeTag (record){
            let i = this.tags.indexOf(record);
            if (i>-1) this.tags.splice(i, 1)
        },
        add () {
            this.$emit('add', this.selected)
            this.selected = [];
        },
        evenListener (e) {
            let focusOn = document.activeElement;
            if (!focusOn) return;
            if (focusOn.id != this._uid) return;
            if (e.keyCode == 13) {
                let x = document.activeElement;
                if (x && x.value){
                    this.tags.push(x.value);
                    this.searchBox = '';
                }
            }
            if (e.keyCode == 27) {
                this.$emit('close');
            }
        },
        async onRowClick (data) {
            let res = await api.getObjectFromStore('service', data.row.sId);
            if (res) {
                if (data.row.ServiceCategoryId)  {
                    res.ServiceCategoryId = data.row.ServiceCategoryId;
                }
                if (data.row.ServiceCategory) {
                    res.ServiceCategory = _.cloneDeep(data.row.ServiceCategory);
                }
                if (this.selected.indexOf(res)==-1) this.selected.push(res);
            }
        },
        async quoteServices () {
            if (this.showPrices) {
                this.columnsNames = Object.assign([], this.originColumnsNames);
                this.showPrices = false;
                return;
            }
            this.showPrices = true;
            let ids = [];
            for (let r of this.list) {
                ids.push(r.sId)
            }
            let data = {
                Ids: ids.join(',')
            }
            let res = await api.get('/reports/servicecost', data);
            if (res) {
                this.columnsNames = Object.assign([], this.originColumnsNames);
                let fromIndex = res.header.indexOf('CurrencyId')
                let toIndex = res.header.indexOf('Comment')
                this.columnsNames = this.columnsNames.concat(res.header.slice(fromIndex +1))
                this.prices = res.rows;
            }
        },
        setCityId () {
            let s = _.find(api.tables.supplier, (r) => r.id == this.supplierId);
            if (s && s.CityId) {
                this.cityId = s.CityId;
            }
        },
        getIcon (icon) {
            if (!icon) return 'grip-vertical';
            return icon;
        },
        isBreakLine (row) {
            let t = `${row.Name} - ${this.listValues[['ServiceTypeId', row.ServiceTypeId]]} - `;
            if (row.ServiceCategoryId) t += `${this.listValues[['ServiceCategoryId', row.ServiceCategoryId]]} - `;
            t += `${this.listValues[['SupplierId', row.SupplierId]]} - ${this.listValues[['CityId', row.CityId]]}`;
            let divWith = $('.service-selector').width();
            let limit = divWith / 10;
            //console.log(t, divWith, limit, t.length)
            if (t.length > limit && !this.modal) return true;
            if (t.length > 115) return true;
            return false;
        },
        getLargeTextClass (row) {
            let t = row.Name;
            if (row.ServiceTypeId) t += ` - ${this.listValues[['ServiceTypeId', row.ServiceTypeId]]}`;
            if (row.ServiceCategoryId) t += ` - ${this.listValues[['ServiceCategoryId', row.ServiceCategoryId]]}`;
            if (row.SupplierId) t += ` - ${this.listValues[['SupplierId', row.SupplierId]]}`;
            if (row.CityId) t += ` - ${this.listValues[['CityId', row.CityId]]}`;
            let divWith = $('.service-name').width();
            let limit = divWith / 4;
            let f = t.length / limit;
            if (f > 1.2 && !this.modal) return 'text-80';
            if (t.length > limit && !this.modal) return 'text-85';
            if (t.length > 150) return 'text-85';
            return 'text-100';
        }

    },
    beforeDestroy () {
        document.removeEventListener('keydown',this.evenListener);
    },
    created () {
        this.selected = [];
        this.$nextTick(() => {
            document.addEventListener("keydown", this.evenListener);
        });
    }
}
</script>

<style scoped>
  .text-85 {
    font-size: 1.4vh !important;
    letter-spacing: -0.03rem;
  }
  .text-80 {
    font-size: 1.3vh !important;
    letter-spacing: -0.05rem;
  }
  .text-100 {
    font-size: 1.5vh !important;
  }
  .service-selector-row {
    min-height: 100%;
  }
  .services-table {
      min-height: 35px;
  }
</style>