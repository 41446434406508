<template>
  <div class="mb-4 mt-2">
    <label><i>{{tr('New field language in Leads and language filter in Pax Lead Summary Report')}}</i></label>
  </div>
</template>

<script>
export default {
    name: 'pax-lead-changes',
}
</script>
