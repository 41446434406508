<template>
    <div class="form-row px-1 mx-1">
        <div v-if="!hotelsByDay[dayNr]" class="col-md-4 card clearfix my-1" @click="editHotel()">
            <div class="card-header hotel-card-header py-1" :class="getBGColor">
                <label class="cursor-pointer">{{tr('Edit Hotel')}}</label>
            </div>
        </div>
        <div v-if="!hotelsByDay[dayNr]" class="col-md-4 card clearfix my-1 cursor-pointer" @click="setPaxHotel(1)">
            <div class="card-header hotel-card-header py-1" :class="getBGColor">
                <b v-if="recordStore.BookingDays[dayId].PaxHotel==1"><label>{{tr('Hotel booked by Passenger')}}</label></b>
                <label  class="cursor-pointer" v-else>{{tr('Hotel booked by Passenger')}}</label>
            </div>
        </div>
        <div v-if="!hotelsByDay[dayNr]" class="col-md-4 card clearfix my-1 cursor-pointer" @click="setPaxHotel(2)">
            <div class="card-header hotel-card-header py-1" :class="getBGColor">
                <b v-if="recordStore.BookingDays[dayId].PaxHotel==2"><label>{{tr('Without Hotel')}}</label></b>
                <label  class="cursor-pointer" v-else>{{tr('Without Hotel')}}</label>
            </div>
        </div>
        <div v-if="hotelsByDay[dayNr]" class="col-md-12 card clearfix my-0">
            <div class="card-header hotel-card-header py-1 mt-1 form-row" :class="getBGColor">
                <div class="col-10 form-row cursor-pointer" @click="editHotel()">
                    <div class="col-1 d-flex align-items-center">
                        <font-awesome-icon icon="h-square"/>
                    </div>
                    <hotel-day
                        class="col-11"
                        :hotelInfo="hotelsByDay[dayNr]"
                        :dayNr="dayNr"
                    ></hotel-day>
                </div>
            </div>
        </div>
        <hotel-selector-modal
            v-if="hotelSelector"
            :dayId="currentDayId"
            :dayNr="currentDayNr"
            :exchange="changeHotel"
            @close="closeHotelSelector"
            @delete="deleteHotel"
        ></hotel-selector-modal>
    </div>
</template>

<script>
const hotelDay = importVueComp('components/hotel', 'HotelDay');
const hotelSelectorModal = importVueComp('components/hotel', 'HotelSelectorModal');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'booking-day-hotel',
    props: ['dayId', 'dayNr', 'servicesLength'],
    components: {
      'hotel-day': hotelDay,
      'hotel-selector-modal': hotelSelectorModal
    },
    data () {
        return {
            hotelSelector: false,
            changeHotel: false,
            currentDayId: null,
            currentDayNr: null,
            //hotelsByDay: {},
        }
    },
    async mounted () {
        if (!api.hotelsList || api.hotelsList.length==0) {
            api.addHotelsList();
        }
        //this.setHotelsByDate();
    },
    computed: {
        ...mapState({
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
            operActions: state => state.main.operActions,
            recordStore: state => state.main.record,
            user: state => state.main.user,
        }),
        canEdit () {
            if (this.recordStore.isTemplate) return true;
            if (!tools.canAccess(api.currentUser, 'api','GET', 'edit_booking_hotels', true)) return false;
            return this.salesActions || this.managerActions || this.operActions;
        },
        getBGColor () {
            return 'bg-hotel';
        },
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        }
    },
    methods: {
        setHotelsByDate () {
            this.hotelsByDay = this.recordStore.hotelsByDay;
        },
        setPaxHotel (value) {
            this.recordStore.BookingDays[this.dayId].setPaxHotel(value);
        },
        hasPayments () {
            let hotelInfo = this.hotelsByDay[this.dayNr];
            if (!hotelInfo) return true;
            if (!hotelInfo.BookingDayRooms) return true;
            for (let room of hotelInfo.BookingDayRooms) {
                let supplierId;
                if (room.Hotel && room.Hotel.SupplierId) supplierId = room.Hotel.SupplierId;
                if (!supplierId) continue;
                let payments = _.filter(api.payments, (p) => p.SupplierId == supplierId);
                if (payments.length > 0) {
                    let res = confirm(tr('Attention! This provider has payments in the file. Before make changes, edit or remove payments. Do you want to continue?'));
                    if (!res) return false;
                }
            }
            return true
        },
        async editHotel () {
            if (!this.canEdit) return;
            let hotelInfo = this.hotelsByDay[this.dayNr];
            if (!this.hasPayments()) return;
            if (!hotelInfo) {
                await this.recordStore.BookingDays[this.dayId].editHotel();
            }
            this.currentDayNr = this.dayNr;
            if (hotelInfo && !hotelInfo.firstDay) this.currentDayNr = this.currentDayNr - hotelInfo.dayNr;

            this.currentDayId = this.dayId;
            if (hotelInfo && !hotelInfo.firstDay) this.currentDayId = this.currentDayId - hotelInfo.dayNr;

            this.hotelSelector = true;
            EventBus.$emit('update-totals');
        },
        async deleteHotel () {
            await this.recordStore.BookingDays[this.dayId].deleteHotel()
        },
        closeHotelSelector () {
            this.changeHotel = false;
            this.hotelSelector = false;
            this.currentDay = null;
        },

    }
}

</script>
