<template>
    <div>
        <report-window
            v-if="ready"
            endpoint="/admin/reports/purchaseservices"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :templates="templates"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :current.sync="current"
            :slotsFields="slotsFields"
            :callbacks="callbacks"
            :headerColumns="headerColumns"
            :columnsClasses="columnsClasses"
            :buttons="buttons"
            :notFilterable="options && options.notFilterable"
            :afterRun="afterRun"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
var EventBus = require('@/tools/event-bus').default;
import { mapState } from "vuex";
export default {
    name: "purchase-service-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        columnsClasses () {
            return {
                Cost: 'text-right'
            }
        },
    },
    mounted () {
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                }
            }
        }
        let currency = _.find(this.fields, (r) => r.name == 'CurrencyId');
        if (currency) {
            currency.options.push({
                value: api.baseCurrency.BookingCurrency, label: api.baseCurrency.BookingCurrency
            });
            currency.options.push({
                value: api.baseCurrency.BaseCurrency, label: api.baseCurrency.BaseCurrency
            });
        }
        for (let f of this.slotsFields) {
            f.getDisabled = (row) => {
                //if (f.name=='Selected' && row.Invoice) return true;
                if (f.name=='Selected' && row.InvoiceType=='CREDIT') return true;
            }
        }

        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            selected: [],
            notifications: [],
            headerColumns: ['BookingId', 'PaxName', 'EndDate', 'Supplier', 'Detail', 'TaxCode', 'Cost', 'PaymentTypeId'
                ,'Invoice', 'Selected'],
            current: {},
            ready: false,
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From', columns: 6},
              {name: 'ToDate', editor: 'date', label: 'to', columns: 6},
              {name: 'SupplierId', relation: 'supplier', optionLabels: 'Name'
                  , label: 'Supplier', addBlank: true, editor: 'vue-select'},
              {name: 'SupplierPaymentTypeId', relation: 'paymenttype', optionLabels: 'Name'
                  , editor: 'select', label: 'Supplier Payment Type', addBlank: true, list: true},
              {name: 'GroupBy', label: 'Group by', editor: 'select', addBlank: true, columns: 6,
                options: [
                    {value: 'BOOKING', label: 'File'},
                    {value: 'BOOKING_TAX', label: 'File - Tax'}
                    ]
                },
              {name: 'Invoiced', label: 'Invoiced', editor: 'select', addBlank: true, columns: 6,
                options: [
                    {value: '0', label: 'NO'},
                    {value: '1', label: 'YES'}
                    ]
                },
              {name: 'BookingId', label: 'File Nr', columns: 6},
              {name: 'CurrencyId', label: 'Currency', editor: 'select', required: true, columns: 6,
                options: []
                },

            ],
            title: "Invoice Purchases",
            headers: {
                BookingId: 'File Nr',
                PaxName: 'Passenger',
                TaxCode: 'Tax',
                Cost: 'Amount',
                EndDate: 'Date Out',
                Supplier: 'Supplier',
                Detail: 'Concept',
                PaymentTypeId: 'Payment Type',
                Selected: 'Select',
                Invoice: 'Invoice'
            },
            templates: [
                {name: 'Cost', callback: (row) => {
                    if (!row.Cost) return;
                    return parseFloat(row.Cost).toFixed(2);
                  }
                },
                {name: 'Invoice', callback: (row) => {
                    if (!row.Invoice) return;
                    let types = {'INVOICE': 'Fact.', 'CREDIT': 'NC'}
                    return types[row.InvoiceType] + ' ' + row.Invoice;

                  }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                }
            ],
            slotsFields: [
                {name: 'Selected', editor: 'checkbox', classes: 'bottom-base'},
            ],
            callbacks: {
                'Selected': function(fieldName, row, report) {
                    self.selected = _.filter(report.dataList, (r) => r.Selected);
                    self.getDisableInvoice();
                }
            },
            buttons: [
                {callback: this.selectAll, label: 'Select All', buttonClass: 'btn btn-primary', id: 'selectAll'},
                {callback: this.createInvoice, label: 'Purchase Invoice', buttonClass: 'btn btn-primary', id: 'createInvoice',
                    disabled: false},
                {callback: this.createCredit, label: 'Credit Note', buttonClass: 'btn btn-primary', id: 'createCredit',
                    disabled: false},
            ]

        }
    },
    methods: {
        afterRun () {
            this.selected = [];
            this.getDisableInvoice();
        },
        selectAll (self) {
            for (let row of self.list) {
                if (row.Invoice) continue;
                if (!row.RowId) continue;
                let r = _.find(self.dataList, (p) => p.RowId == row.RowId);
                if (r) {
                    r.Selected = true;
                }
            }
            this.selected = _.filter(self.dataList, (r) => r.Selected);
            this.getDisableInvoice();
            self.dataList = Object.assign([], self.dataList);
        },
        async createCredit (self) {
            await this.createInvoice(self, true);
        },
        async createInvoice (self, credit) {
            let invoice = {};
            invoice.CurrencyId = this.current.CurrencyId;
            invoice.Type = 'INVOICE';
            let f = 1;
            if (credit) {
                invoice.Type = 'CREDIT';
                f = -1;
            }
            let sum = 0;
            invoice.PurchaseInvoiceDetails = [];
            for (let row of self.dataList) {
                if (row.Invoice && !credit) continue;
                if (!row.RowId) continue;
                if (!row.Selected) continue;
                let r = {
                    Detail: row.Detail,
                    Amount: row.Cost * f,
                    TaxCodeId: row.TaxCodeId
                }
                if (row.Cost) {
                    sum += parseFloat(row.Cost * f);
                }
                if (row.Type=='SERVICE') {
                    r.BookingDayServiceId = row.id;
                } else if (row.Type=='ROOM') {
                    r.BookingRoomId = row.id;
                } else if (row.Type=='FLIGHT') {
                    r.BookingFlightId = row.id;
                }
                invoice.SupplierId = row.SupplierId;
                r.BookingId = row.BookingId;
                r.PaxName = row.PaxName;
                invoice.PurchaseInvoiceDetails.push(r);
            }
            EventBus.$emit('open-modal-record', {modalRecord: invoice, modalTable: 'purchaseinvoice', modalId: 'new'});

        },
        addNotification   (msg) {
            app.addNotification(this, msg, true)
        },
        removeNotifications (){
            this.notifications = [];
        },
        getDisableInvoice () {
            let suppliers = _.countBy(this.selected, function(r) {
                return r.SupplierId
            })
            let invoices = _.filter(this.selected, function(r) {
                return !r.Invoice
            })
            let nc = _.filter(this.selected, function(r) {
                return r.Invoice
            })
            let button = _.find(this.buttons, (b) => b.id == 'createInvoice');
            if (button) {
                if (_.keys(suppliers).length==1 && nc.length==0) {
                    button.disabled = false;
                } else {
                    button.disabled = true;
                }
            }
            let button2 = _.find(this.buttons, (b) => b.id == 'createCredit');
            if (button2) {
                if (_.keys(suppliers).length==1 && invoices.length==0) {
                    button2.disabled = false;
                } else {
                    button2.disabled = true;
                }
            }

            this.buttons = Object.assign([], this.buttons);
        }

    }
};
</script>
