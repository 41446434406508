<template>
    <div class="modal fade bd-example-modal-lg" id="booking-backups" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="templateBookingBackups" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>

            <table class="table table-striped  table-sm table-bordered">
                <thead>
                    <tr class="table-secondary">
                        <th><span>{{tr('Date')}}</span></th>
                        <th><span>{{tr('File')}}</span></th>
                        <th><span>{{tr('Pax')}}</span></th>
                        <th><span>{{tr('Title')}}</span></th>
                        <th><span>{{tr('Days')}}</span></th>
                        <th><span>{{tr('Size')}}</span></th>
                        <th><span></span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(c, i) in bkpList" class="service-card" >
                        <td>{{c.time | toLocalDateTime}}</td>
                        <td>{{c.record.id}}</td>
                        <td>{{c.record.PaxName}}</td>
                        <td>{{c.record.Title}}</td>
                        <td>{{c.record.BookingDays.length}}</td>
                        <td>{{c.size}}</td>
                        <td>
                            <button type="button" class="btn btn-primary btn-sm" @click="restoreVersion(i)">
                                {{tr('Restore')}}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type="button" class="btn btn-primary btn-sm" @click="restoreFile">
                {{tr('Restore from File')}}
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
const LZString = require('lz-string');

export default {
    name: 'booking-backups',
    props: ['show'],
    mixins: [escape],
    data () {
        return {
            bkpList: [],
            ready: false,
        }
    },
    mounted () {
        $('#booking-backups').modal({backdrop: 'static', keyboard: false}, 'show');
        frontTools.getFromIndexDB('bookingBkp', 'bkp', (bkpList) => {
            if (bkpList) {
                //this.bkpList = JSON.parse(LZString.decompress(bkpList));
                bkpList = JSON.parse(bkpList);
                for (let row of bkpList) {
                    row.size = (new Blob([JSON.stringify(row)]).size / 1000).toFixed() + ' kb' ;
                }
                this.bkpList = bkpList;
            };
        })
        this.ready = true;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            user: state => state.main.user,
        }),
    },
    methods: {
        close () {
            this.$emit('update:show', false);
            $('#booking-backups').modal('hide');
        },
        async restoreVersion (i) {
            api.setProcessing(true);
            let record = this.bkpList[i].record;
            record.syncVersion = this.recordStore.syncVersion;
            let model = await api.importMixinModule('booking', 'model');
            let booking = await new model({record, isTemplate: false});
            await tools.setRelations(booking);
            this.$store.commit('initRecord', {record: booking});
            if (record.id && this.recordStore && this.recordStore.operationStatus) {
                await this.$store.dispatch('setBookingForm', {bookingId: this.recordStore.id})
            }
            EventBus.$emit('set-record');
            api.setProcessing(false);
            this.close();
        },
        restoreFile () {
            let input = document.createElement('input');
            input.type = 'file';
            input.onchange = _ => {
                let files =   Array.from(input.files);
                 var file = files[0];
                 var reader = new FileReader();
                 reader.readAsText(file,'UTF-8');
                 reader.onload = async readerEvent => {
                    var content = readerEvent.target.result;
                    var record = JSON.parse(content);
                    let model = await api.importMixinModule('booking', 'model');
                    let booking = await new model({record, isTemplate: false});
                    await tools.setRelations(booking);
                    this.$store.commit('initRecord', {record: booking});
                    if (record.id && this.recordStore && this.recordStore.operationStatus) {
                        await this.$store.dispatch('setBookingForm', {bookingId: this.recordStore.id})
                    }
                    EventBus.$emit('set-record');
                    api.setProcessing(false);
                    this.close();
                 }
            };
            input.click();
        }
    },
}
</script>
