<template>
      <footer id="footer" class="footer">
        <div class="container">
          <div class="copyright">
            &copy; Copyright <strong><span>NUBBO</span></strong>. All Rights Reserved
          </div>
        </div>
      </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'itinerary-footer',
}
</script>
