<template>
    <div class="form-row">
        <table class="table table-sm table-bordered" v-if="ready">
            <thead>
                <tr class="table-secondary">
                    <th class="table-head-th">{{tr('Room')}}</th>
                    <th v-for="n in nights" class="table-head-th">
                        {{getNightsHeaders(n)}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(room, i) in rooms" v-if="!room.Closed" class="form-material">
                    <td class="hotel-table-row">
                        <span v-if="room.Comment">{{room.Name}}, {{room.Comment}}</span>
                        <span v-else>{{room.Name}}</span>
                    </td>
                    <td v-for="(n, k) in nights"  class="hotel-table-row-input" >
                        <number-input
                            v-if="period.Data[[n, room.id]]"
                            class="m-0"
                            :v.sync="period.Data[[n, room.id]].value"
                            :currency="currency"
                            :id="i + '-' + k" :column="k" :row="i"
                        ></number-input>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</template>

<script>
const roomsMixin = importVueComp('components/tools', 'TableMixin');
export default {
    name: 'nights-room-category',
    props: ['rooms', 'period', 'nights'],
    mixins: [roomsMixin],
    data () {
        return {
            ready: false,
            currency: null,
        }
    },
    mounted () {
        this.currency = _.find(api.tables.currency, (r) => r.id == this.period.CurrencyId);
        let found;
        for (let n in this.nights) {
            for (let room of this.rooms) {
                if (room.Closed) continue;
                if (!this.period.Data[[n, room.id]]) {
                    this.period.Data[[n, room.id]] = {value: null, roomClass: room, night: n};
                    found = true;
                }
            }
        }
        if (found) {
            this.period.isModified = true;
            this.$emit('setModified');
        }
        this.ready = true;
    },
    watch: {
        period: {
            handler () {
                this.period.isModified = true;
                this.$emit('setModified');
            },
            deep: true,
        },
    },
    methods: {
        getNightsHeaders (n) {
            if (n==1) return "1 " + tr('Night');
            if (parseInt(n)) return n + " " + tr('Nights');
            return n;
        }
    }
}
</script>
