<script>
import Vue from 'vue';
import { mapState } from 'vuex';

let bookingCoverMainExtras = Vue.component('booking-cover-main-extras', {
    props: [],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    template: `
        <div class="form-row col-md-8">
                <div class="px-3 col-md-3" v-if="recordStore.bookingConfirmed">
                    <input-field
                        :defValue.sync="recordStore.CreatedUTC"
                        :field="recordStore.fieldsObject.CreatedUTC"
                        :readonly="true"
                        format="YYYY-MM-DDT"
                    ></input-field>
                </div>
                <input-field
                    :defValue.sync="recordStore.Dossier"
                    :field="recordStore.fieldsObject.Dossier"
                    class="col-md-3 px-3"
                ></input-field>
                <input-field
                    :defValue.sync="recordStore.EndDate"
                    :field="recordStore.fieldsObject.EndDate"
                    :readonly="true"
                    format="DD/MM/YYYY"
                    class="col-md-3 px-3"
                ></input-field>
                <input-field
                    v-if="recordStore.bookingConfirmed"
                    :defValue.sync="recordStore.WithCommission"
                    :field="recordStore.fieldsObject.WithCommission"
                    class="col-md-3 px-3"
                ></input-field>
            </div>
        </div>
    </div>    
    `,
})


const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain', 'custom');
export default {
    name: 'custom-booking-cover-main',
    mixins: [bookingCoverMain],
    mounted () {
        this.extraComp.push({
            component: bookingCoverMainExtras,
            ref: 'bookingCoverMainExtras',
            change: () => {},
        })
    },
    methods: {
        setStatus () {
            let tabs = {};
            if (this.recordStore.Status == 'CONFIRMED') {
                tabs['receipts'] = true;
            } else {
                tabs['receipts'] = false;
            }
            this.$parent.$parent.$parent.$refs.navItems.updateItems(tabs);
        }
    }

}
</script>

<style scoped>
.l-date{
    top: -42px;
    position: relative;
}
</style>

