<template>
      <report-window endpoint="/reports/receiptlist"
        v-if="ready"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :slotsFields="[]"
        :current.sync="current"
        :totalsOn="totalsOn"
        :columnsClasses="columnsClasses"
        :columnClick="columnClick"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'receipt-list-report',
    props: ['options'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            adminActions: state => state.main.adminActions,
            user: state => state.main.user,
        }),
        totalsOn () {
            let res = [];
            if (this.current.CurrencyId) {
                res.push('Amount');
            }
            if (this.current.ReceivedCurrencyId) {
                res.push('ReceivedAmount');
            }
            if (res.length == 0) return null;
            return res;
        },
        columnsClasses () {
            return {
                Amount: 'text-right',
                ReceivedAmount: 'text-right',
                CurrencyRate: 'text-right',
                BookingId: 'cursor-pointer-report',
            }
        },

    },
    async mounted () {
        frontTools.setReportValues(this);
        if (this.user.Department && this.user.Department.Sales && !this.adminActions) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        let bookingReceiptView = api.importMixinModule('bookingreceipt', 'views');
        return {
            ready: false,
            current: {},
            fields: [
                {name: 'FromDate', editor: 'date', label: 'From', columns: 2},
                {name: 'ToDate', editor: 'date', label: 'To', columns: 2},
                {name: 'FilterDate', label: 'Filter Date by', editor: 'select', required: true, defValue: '1',
                      options: [
                          {value: '0', label: 'Received Date'},
                          {value: '1', label: 'Payment Date'},
                          {value: '2', label: 'Date Out'}
                      ]
                  },
                {name: 'SalesUserId', label: 'Seller', editor: 'select',
                    optionLabels: 'UserName', relation: 'user',
                    filters: function(row) {
                        if (row.Closed) return false;
                        if (row.Department && row.Department.Sales) return true;
                    }
                },
                {name: 'PaymentStatus', label: 'Status', editor: 'select', addBlank: true,
                    options: bookingReceiptView.receiptStatusOptions
                },
                {name: 'AgencyId', label: 'Agency', editor: 'select', optionLabels: 'Name', relation: 'agency',
                    addBlank: true},
                {name: 'CurrencyId', label: 'Currency', editor: 'select', optionLabels: 'id', relation: 'currency',
                    addBlank: true},
                {name: 'ReceivedCurrencyId', label: 'Received Currency', editor: 'select', optionLabels: 'id',
                    relation: 'currency', addBlank: true},
            ],
            title: 'Receipts',
            headers: {
                PaymentDate: 'Payment Date',
                PaxName: 'Passenger',
                BookingId: 'File Nr',
                AgencyId: 'Agency',
                CurrencyId: 'Currency',
                PaymentTypeId: 'Payment Type',
                PaymentStatus: 'Status',
                ReceivedCurrencyId: 'Currency',
                ReceivedAmount: 'Receipt Amount',
                ReceivedPaymentDate: 'Received Date'
            },
            templates: [
                {name: 'PaymentDate',
                    callback: function(row) {
                        if (!row.PaymentDate) return '';
                        return moment(row.PaymentDate).format("DD/MM/YYYY");
                    }
                },
                {name: 'ReceivedPaymentDate',
                    callback: function(row) {
                        if (!row.ReceivedPaymentDate) return '';
                        return moment(row.ReceivedPaymentDate).format("DD/MM/YYYY");
                    }
                },
                {name: 'PaymentStatus',
                    callback: function(row) {
                        return tr(bookingReceiptView.receiptStatus[row.PaymentStatus]);
                    }
                },
                {name: 'Amount', callback: (row) => {
                    if (row.calculated) {
                        if (this.current.CurrencyId) {
                            return tools.toNumber(row.Amount, null, this.current.CurrencyId);
                        }
                        return row.Amount
                    };
                    return tools.toNumber(row.Amount, null, row.CurrencyId);
                  }
                },
                {name: 'ReceivedAmount', callback: (row) => {
                    if (row.calculated) {
                        if (this.current.ReceivedCurrencyId) {
                            return tools.toNumber(row.ReceivedAmount, null, this.current.ReceivedCurrencyId);
                        }
                        return row.ReceivedAmount
                    };
                    return tools.toNumber(row.ReceivedAmount, null, row.ReceivedCurrencyId);
                  }
                },

            ],
            columnClick: {
                BookingId: this.showBooking,
            }
        }
    },
    methods: {
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-sale', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        }

    }
}
</script>

