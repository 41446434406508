<template>
        <report-window
            v-if="ready"
            endpoint="/reports/user_access"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :headerColumns="headerColumns"
            :templates="templates"
            :beforeRender="beforeRender"
        ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
let langDict = require('@/languages/system_languages.json');
let dict = langDict['es'];
export default {
    name: "user-access-report",
    props: ["options", "tableName", "id"],
    components: {
        ReportWindow,
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        return {
            current: {},
            ready: false,
            fields: [
                {
                    name: "Type",
                    label: "Type",
                    required: true,
                    columns: 2,
                    editor: 'select',
                    options: [{value: 'api', label: 'api'}, {value: 'view', label: 'view'}]
                },
                {
                    name: "Method",
                    label: "Method",
                    required: true,
                    editor: 'select',
                    options: [
                        {value: 'GET', label: 'GET'}, 
                        {value: 'POST', label: 'POST'}, 
                        {value: 'PUT', label: 'PUT'}, 
                        {value: 'DELETE', label: 'DELETE'}
                    ]
                },
                {
                    name: "Endpoint",
                    label: "Endpoint",
                    required: true,
                },
                {
                    name: "DefValue",
                    label: "DefValue",
                    editor: 'select',
                    options: [
                        {value: true, label: 'True'}, 
                        {value: false, label: 'False'}
                    ]
                },
                {
                    name: "Access",
                    label: "Access",
                    addBlank: true,
                    editor: 'select',
                    options: [
                        {value: '1', label: 'Yes'}, 
                        {value: '0', label: 'No'}, 
                    ]
                },
            ],
            title: "User Access",
            headerColumns: ['UserName', 'AccessGroup', 'Access'],
            headers: {
            },
            templates: [
                {
                    name: "Access",
                    callback: (row) => {
                        if  (tools.canAccess(row.User, this.current.Type, this.current.Method, this.current.Endpoint, this.current.DefValue)) return tr('YES');
                        return tr('NO');

                    }
                },
            ],
        }
    },
    methods: {
        beforeRender (report) {
            if (this.current.Access == '1') {
                report.dataList = report.dataList.filter((r) => tools.canAccess(r.User, this.current.Type, this.current.Method, this.current.Endpoint, this.current.DefValue));
            } else if (this.current.Access == '0') {
                report.dataList = report.dataList.filter((r) => !tools.canAccess(r.User, this.current.Type, this.current.Method, this.current.Endpoint, this.current.DefValue));
            }
        },
    }
};
</script>
