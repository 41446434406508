<template>
    <form class="form-inline azul header-row" action="">
       <div class="form-group col-md-2">
          <div class="label">{{tr('How booked your trip?')}}*</div>
          <select class="custom-select" name="" id="" disabled>
             <option v-for="opt of fieldOptions['SalesUserId']" :value="opt.value"
                :selected="opt.value == bookingForm.SalesUserId">{{opt.label}}</option>
          </select>
       </div>
       <div-input fieldName="PaxName" :v.sync="bookingForm.PaxName" :cols="2"
            label="Reservation under which name" :langCode="langCode"
            :invalid="invalid"
       ></div-input>
       <div-input fieldName="Country" :v.sync="bookingForm.Country" :cols="2"
            label="Country" :langCode="langCode"
            :invalid="invalid"
       ></div-input>
       <div-input fieldName="Address" :v.sync="bookingForm.Address" :cols="2"
            label="Address" :langCode="langCode"
            :invalid="invalid"
       ></div-input>
       <div-input fieldName="Phone" :v.sync="bookingForm.Phone" :cols="2"
            label="Phone Number" :langCode="langCode"
            :invalid="invalid"
       ></div-input>
       <div-input fieldName="Mobile" :v.sync="bookingForm.Mobile" :cols="2"
            label="Mobile Phone Number" :langCode="langCode"
            :invalid="invalid"
       ></div-input>
    </form>
</template>

<script>
const divInput = importVueComp('components/reservationform', 'ReservationFormInput');
export default {
    name: 'reservation-form-main',
    props: ['langCode', 'fieldOptions', 'bookingForm', 'invalid'],
    components: {
        'div-input': divInput
    },
    data () {
        return {
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        isRequired (fieldName) {
            return this.$parent.isRequired(fieldName);
        },

    },
}
</script>

