<template>
    <report-window
    v-if="ready"
    endpoint="/admin/reports/expense_list"
    :perPage="500"
    :fields="fields"
    :title="title"
    :headers="headers"
    :templates="templates"
    :hideFilters="options && options.hideFilters"
    :runMounted="options && options.runMounted"
    :cardView="options && options.cardView"
    :current.sync="current"
    :totalsOn="totalsOn"
    :headerColumns="null"
    :columnsClasses="columnsClasses"
    :notFilterable="options && options.notFilterable">
    </report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "expense_list-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        columnsClasses () {
            return {
                Amount: 'text-right'
            }
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
                { name: 'FromDate', editor: 'date', label: 'From', required: true},
                { name: 'ToDate', editor: 'date', label: 'To', required: true},
                { name: 'ExpenseTypeId', label: 'Expense Type', editor: 'select',
                  optionLabels: 'Name', relation: 'expensetype',
                  filters: (row) => {
                      if (row.BookingExpense==true) return true;
                  },
                },
                { name: 'FilterDate', label: 'Filter by', editor: 'select', defValue: '0', required: true,
                    options: [
                        {value: '0', label: 'Date Inn'},
                        {value: '1', label: 'Sales Date'},
                        {value: '2', label: 'Payment Date'},
                        {value: '3', label: 'Date Out'}
                    ]
                },
                { name: 'ExpenseStatus', label: 'Status', editor: 'select', addBlank: true,
                    options: [
                        {value: 'PENDING', label: 'Pending'},
                        {value: 'PAID', label: 'Paid'},
                    ]
                },
                { name: 'CurrencyId', label: 'Currency', editor: 'select', defValue: api.baseCurrency.SummaryCurrency,
                    optionLabels: 'id', relation: 'currency', required: true },

            ],
            title: "Expenses List",
            headers: {
                BookingId: 'File',
            },
            templates: [
                {
                    name: "Amount",
                    callback: (row) =>  {
                        if (!row.Amount) return "";
                        return tools.toNumber(row.Amount, 2, row.CurrencyId);
                    }
                },
                {
                    name: "StartDate",
                    callback: function(row) {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "ConfirmedDate",
                    callback: function(row) {
                        if (!row.ConfirmedDate) return "";
                        return moment(row.ConfirmedDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "PaymentDate",
                    callback: function(row) {
                        if (!row.PaymentDate) return "";
                        return moment(row.PaymentDate).format("DD/MM/YYYY");
                    }
                }
            ],
            totalsOn: ['Amount'],
        }
    },
};
</script>
