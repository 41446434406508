import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

class VueRouterEx extends VueRouter {
  constructor(options) {
    super(options);
    const { addRoutes } = this.matcher;
    this.routes = Object.assign([], this.options.routes);

    this.matcher.addRoutes = (newRoutes) => {
      this.routes.push(...newRoutes);
      addRoutes(newRoutes);
    };
  }
}


export default VueRouterEx;