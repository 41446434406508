import mountBooking from '@/test/booking/mountBooking';
var assert = require('assert');
console.log('test')

class Test {

    static getServiceName (booking, dayNr, sId) {
        return booking.BookingDays[dayNr].BookingDayServices[sId].Service.Name
            || booking.BookingDays[dayNr].BookingDayServices[sId].Name;
    }

    async moveDays () {
        let booking = await mountBooking(679);
        console.log(booking)

        let newCityOrder = {};
        let cityList = booking.cityList;
        for (let i in cityList) {
            newCityOrder[i] = {name: cityList[i].City.Name, from: parseInt(i), to: i}
        }

        newCityOrder[5].to = 4;
        newCityOrder[4].to = 5;
        let s1 = Test.getServiceName(booking, 12, 0);
        let s2 = Test.getServiceName(booking, 13, 0);
        let s3 = Test.getServiceName(booking, 13, 1);
        console.log(booking.cityOrderNames);
        await booking.moveDays(5, 4, newCityOrder);
        setTimeout(() => {
            console.log(booking.cityOrderNames);
        }, 100);
        let r1 = Test.getServiceName(booking, 8, 0);
        let r2 = Test.getServiceName(booking, 9, 0);
        let r3 = Test.getServiceName(booking, 9, 1);
        window._booking = booking;
        //assert.equal(s1, r1)
        //assert.equal(s2, r2)
        //assert.equal(s3, r3)

    }


    async addBase () {
        let booking = await mountBooking(202);
        await booking.addBase({adt: 1, chd: 0});
        window._booking = booking;

    }

}

//window.test = new Test();
//window.test.moveDays();
