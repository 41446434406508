<template>
    <div class="form-row" v-if="flight">
        <div class="col-md-12">
            <br>
            <div class="form-row col-md-12">
                <div class="col-md-6 text-uppercase">
                    <h6><b>{{flight.Supplier.Name}}</b></h6>
                </div>
                <div class="col-md-2 pull-right">
                    <label>{{tr('Cost')}}</label>
                </div>
                <div class="col-md-2 pull-right">
                    <label>{{tr('Status')}}</label>
                </div>
                <div class="col-md-1">
                    <label class="pull-right">{{tr('Reservation')}}</label>
                </div>
            </div>
            <hr class="hr-row service-hr">
            <div class="form-material">
                <div class="form-row col-md-12" v-for="f of flight.Flights">
                    <div class="col-md-6">
                        <label>{{getFlightDescription(f)}}</label>
                    </div>
                    <div class="col-md-2 pull-right">
                        <!--label> {{f.FlightCost | formatNumber({c: recordStore.Currency})}} {{recordStore.CurrencyId}}</label-->
                    </div>
                    <div class="col-md-2">
                        <select-input fieldName="Status"
                            :field="f.fieldsObject.Status" label=""
                            class="my-0"
                            :disabled="!canEdit"
                            :currentValue.sync="f.Status" :id="f.id"
                        ></select-input>
                    </div>

                    <div class="col-md-2 form-group my-0 form-default ">
                        <input v-model="f.ReservationNumber" class="form-control" type="text"
                            :readonly="!canEdit"
                            :class="{'fill': f.ReservationNumber}"
                             maxlength="50">
                                <span class="form-bar"></span>
                        </input>
                    </div>
                </div>
                <div class="form-row col-md-12 font-weight-bold">
                    <div class="col-md-6">
                        <label>{{tr('Total')}}</label>
                    </div>
                    <div class="col-md-2 pull-right">
                        <label v-if="totalFlightBySuppliers"> {{totalFlightBySuppliers.Cost | formatNumber({c: recordStore.Currency})}} {{recordStore.CurrencyId}}</label>
                    </div>
                </div>

                <hr class="hr-row service-hr">
            </div>
            <div class="form-row col-md-12 mt-4">
                <div class="section-title ml-4">
                    <label class="my-0">{{tr('Request Flights')}}</label>
                </div>
                <div class="col-1 offset-1">
                    <button type="button" class="btn btn-primary action-button my-0" @click="sendFlightEmail()"
                        rel="tooltip" data-placement="top" title="Email" :disabled="!canEdit">
                        <font-awesome-icon icon="envelope"/>
                    </button>
                </div>
            </div>
            <div class="col-md-12">
                <booking-payments
                    :supplierId="flight.Supplier.id"
                    :supplierData="flight"
                ></booking-payments>
            </div>
        </div>
        <booking-operations-notes :supplier.sync="supplier">
        </booking-operations-notes>
        <attach
          title="Documents"
          :dataList.sync="recordStore.attachList"
          :attached.sync="recordStore.attached"
          :id="recordStore.id"
          :folder="'supplier/' + flight.Supplier.id + '/'"
          :processing="recordStore.processingAttach"
          :canAttach="true"
          :canDelete="true"
          v-if="recordStore.attached"
        ></attach>
        <slot name="custom"></slot>
    </div>
</template>

<script>
const bookingPayments = importVueComp('components/booking', 'BookingPayments');
const bookingOperationsNotes = importVueComp('components/booking', 'BookingOperationsNotes');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'operations-flight',
    components: {
        'booking-payments': bookingPayments,
        'booking-operations-notes': bookingOperationsNotes,
      },
    props: ['flight', 'supplierId'],
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            bookingForm: state => state.booking.bookingForm,
        }),
        totalFlightBySuppliers () {
            return this.recordStore? this.recordStore.totalFlightBySuppliers[this.supplierId]: {};
        },
        supplier () {
            if (this.recordStore) {
                return this.recordStore.flights[this.supplierId];
            }
        },
        canEdit () {
            return true;
        },
    },
    methods: {
        getFlightDescription (flight) {
            let res = '';
            if (!flight) return res;
            if (flight.FlightType) res += flight.FlightType + '. ';
            if (flight.IssueDate) res += tr('Issue Date') + ': ' + moment(flight.IssueDate).format('DD/MM/YYYY') + ' ';
            return res;
        },
        sendFlightEmail (value) {
            if (!this.bookingForm) {
                alert(tr('There is not reservation form'));
                return;
            }
            let t = 1;
            let emails = [];
            let email;
            if (this.flight.Supplier && this.flight.Supplier.Email) {
                if (emails.indexOf(this.flight.Supplier.Email)==-1) {
                    emails.push(this.flight.Supplier.Email);
                }
            }
            if (emails.length>1 && !value) {
                this.emailsOptions = _.map(emails, (r) => {
                    return {label: r, value: r};
                })
                return;
            } else if (emails.length>1 && value) {
                email = value;
            } else if (emails.length==1) {
                email = emails[0];
            }
            if (value) {
                t = 1000;
            }
            setTimeout(async () => {
                let mailRecord = await this.recordStore.createSupplierFlightMail(this.bookingForm, this.user, email, this.flight);
                this.$root.$children[0].openModalRecord({modalRecord: mailRecord, modalTable: 'mail', modalId: 'new'});
            }, t);
        },
    },
}
</script>
