<script>
import recordWindow from '@/components/tools/RecordWindow';
import Vue from 'vue';

var c = Vue.component('mixin-window', {
    extends: recordWindow,
    props: {
        table: {
            type: String,
            default: 'service'
        },
        id: {
            type: String
        },
    }
});

export default {
    name: 'supplier-service-window',
    props: ['table', 'id', 'supId', 'parentTable', 'parentRoute', 'hotelId'],
    mixins: [c],
    methods: {
        replacePath () {
            if (this.modal) return;
            if (!this.recordStore.id) return;
            if (this.hotelId) {
                this.$router.push({ name: 'hotel-service', params: { table: 'service', supId: this.supId, hotelId: this.hotelId, id: this.recordStore.id.toString(), hotel: this.hotelId }});
            } else {
                this.$router.push({ name: 'sup-service', params: { table: 'service', supId: this.supId, id: this.recordStore.id.toString() }});
            }
        },
        closePath () {
            if (this.hotelId) {
                this.$router.push({ name: 'record', params: { table: 'hotel', id: this.hotelId, tab: 'detail-tab-SupplierServiceList' }});
            } else {
                this.$router.push({ name: 'record', params: { table: 'supplierservice', id: this.supId, tab: 'detail-tab-SupplierServiceList' }});
            }
        },
        async afterMount () {
            if (this.supId) {
                this.record.SupplierId = this.supId;
                vueTools.change(this, null, 'SupplierId', this.supId);
            }
        },
    }
}
</script>
