<template>
    <div class="form-material">
        <div class="form-row col-md-12">
            <div class="form-group form-default col-md-6 section-title">
                <label class="mx-4">Input</label>
                <textarea
                    :disabled="!canEdit"
                    v-model="flight.Input"
                    class="text-control"
                    type="text"
                    rows="5"
                    @change="setValue(i)"
                ></textarea>
                <span class="form-bar"></span>
            </div>
            <div class="form-group form-default col-md-6 section-title">
                <label class="mx-4">Output</label>
                <textarea
                    :disabled="!canEdit"
                    v-model="flight.Output"
                    class="text-control"
                    type="text"
                    rows="5"
                ></textarea>
                <span class="form-bar"></span>
            </div>
        </div>
        <div class="form-row col-md-11">
            <div class="form-row col-md-12">
                <div class="col-md-2">
                    <select-input
                        fieldName="FlightType"
                        :field="flight.fieldsObject.FlightType"
                        label="Flight Type"
                        :addClass="flight.invalid.FlightType? 'border-danger': ''"
                        :currentValue.sync="flight.FlightType"
                        :required="true"
                        :id="'BookingFlights-FlightType-' + i"
                        :disabled="!canEditFlights(i)"
                    ></select-input>
                </div>
                <div class="col-md-1">
                    <select-input
                        fieldName="CurrencyId"
                        :field="flight.fieldsObject.CurrencyId"
                        :addClass="flight.invalid.CurrencyId? 'border-danger': ''"
                        label="Currency"
                        :currentValue.sync="flight.CurrencyId"
                        :required="true"
                        :id="'BookingFlights-CurrencyId-' + i"
                        :disabled="!canEditFlights(i)"
                    ></select-input>
                </div>
                <div class="col-md-1">
                    <number-input
                        :changeStyle="true"
                        :v.sync="flight.FlightCost"
                        :addClass="flight.invalid.FlightCost? 'border-danger': ''"
                        label="Cost"
                        :currency="recordStore.Currency"
                        :disabled="!canEditFlights(i)"
                        :id="'BookingFlights-FlightCost-' + i"
                        @change="changeCost(i)"
                    ></number-input>
                </div>
                <div class="col-md-1">
                    <number-input
                        :changeStyle="true"
                        :v.sync="flight.Markup"
                        :addClass="flight.MarkupModified? 'border-warning': ''"
                        label="Markup"
                        :currency="recordStore.Currency"
                        :disabled="!canEditFlights(i)"
                        :id="'BookingFlights-Markup-' + i"
                    ></number-input>
                </div>
                <div class="col-md-1">
                    <number-input
                        :changeStyle="true"
                        :v.sync="flight.FlightPrice"
                        :addClass="flight.invalid.FlightPrice? 'border-danger': ''"
                        label="Price"
                        :currency="recordStore.Currency"
                        :disabled="!canEditFlights(i)"
                        :id="'BookingFlights-FlightPrice-' + i"
                    ></number-input>
                </div>
                <div class="col-md-2 ">
                    <select-input
                        fieldName="QuoteType"
                        :field="flight.fieldsObject.QuoteType"
                        label="Quote"
                        :currentValue.sync="flight.QuoteType"
                        :required="true"
                        :id="'BookingFlights-QuoteType-' + i"
                        :disabled="!canEdit"
                    ></select-input>
                </div>
                <div class="col-md-2">
                    <select-input
                        fieldName="SupplierId"
                        :field="flight.fieldsObject.SupplierId"
                        label="Supplier"
                        :currentValue.sync="flight.SupplierId"
                        :required="true"
                        :id="'BookingFlights-SupplierId-' + i"
                        :disabled="!canEdit"
                    ></select-input>
                </div>
                <div class="col-md-1">
                    <select-input
                        fieldName="PaxType"
                        :field="flight.fieldsObject.PaxType"
                        label="Pax Type"
                        :addClass="flight.invalid.PaxType? 'border-danger': ''"
                        :currentValue.sync="flight.PaxType"
                        :required="true"
                        :disabled="!canEdit"
                        :id="'BookingFlights-PaxType-' + i"
                    ></select-input>
                </div>
                <div class="col-md-2">
                    <select-input
                        fieldName="PricePer"
                        :field="flight.fieldsObject.PricePer"
                        label="Price Per"
                        :addClass="flight.invalid.PricePer? 'border-danger': ''"
                        :currentValue.sync="flight.PricePer"
                        :required="true"
                        :id="'BookingFlights-PricePer-' + i"
                        :disabled="!canEditFlights(i)"
                    ></select-input>
                </div>
                <div class="col-md-2">
                  <date-picker
                    label="Issue Date"
                    :id="'BookingFlights-IssueDate-' + i"
                    :def.sync="flight.IssueDate"
                    :addClass="flight.invalid.IssueDate? 'border-danger': ''"
                  ></date-picker>
                </div>
                <div class="col-md-7">
                    <div class="form-group form-default">
                        <input v-model="flight.Comment" class="form-control" type="text"
                        :maxlength="flight.fieldsObject.Comment.length"
                        :disabled="!canEdit" :class="{'fill': flight.Comment}">
                            <span class="form-bar">
                            </span>
                            <label class="float-label ml-1">{{tr('Comment')}}</label>
                        </input>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1 d-flex align-items-center" v-if="canDeleteFlights(i)">
            <i class="add-item cursor-pointer" @click="deleteFlight(i)">
                <font-awesome-icon icon="trash-alt"/>
            </i>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: 'booking-flight',
    props: ['flight', 'i'],
    computed: {
        ...mapState({
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            managerActions: state => state.main.managerActions,
            operActions: state => state.main.operActions,
        }),
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions || this.operActions;
            return true;
        },
    },
    methods: {
        async deleteFlight (i) {
            await vueTools.removeRow(this, null, 'BookingFlights', i);
        },
        canEditFlights (i) {
            let flight = this.recordStore.BookingFlights[i];
            if (flight.id && !this.canEdit) return;
            return true;
        },
        canDeleteFlights (i) {
            return this.canEditFlights(i);
        },
        async setValue (i) {
        },
        changeCost (i) {
        }
    }
};
</script>


