<template>
    <div v-if="s.Optional">
        <ul>
            <li>
                <b><u>
                    <a @click="showModal" class="cursor-pointer">{{getServiceTitle(s)}}</a>
                </u></b>
            </li>
        </ul>
        <div class="modal fade bd-example-modal-lg" :id="'optionals-modal' + _uid" tabindex="-1" role="dialog"
            data-backdrop="static" aria-labelledby="optionalsModal" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
              <h5 class="modal-title">{{getServiceTitle(s)}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body modal-optionals">
                <div class="form-row">
                    <div :class="{'col-md-7': imageUrl, 'col-md-12': !imageUrl}">
                      <text-rows-style classStyle="text-justify" tag="p" :text="s.Description"></text-rows-style>
                      <ul>
                      </ul>
                    </div>
                    <div class="col-md-5 image optional-image" v-if="imageUrl">
                       <img class="img-fluid" :src="imageUrl">
                    </div>
                </div>
                <div class="col-12 table">
                    <div class="tabla-head">
                        {{tr('Price per Person')}}
                    </div>
                    <table class="table">
                      <tbody>
                            <tr v-for="price of prices">
                                <td>
                                    {{price.text}}
                                </td>
                                <td>
                                    <span>{{price.value | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}</span>
                                </td>
                            </tr>
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: 'itinerary-body-optionals',
    props: ['crop', 'day', 's', 'i', 'canEdit'],
    components: {
    },
    data () {
        return {
            edit: false,
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getServiceTitle (s) {
            if (s.title) return s.title;
            return s.Name;
        },
        showModal () {
            $('#optionals-modal' + this._uid).modal('show');
        },
        close () {
            $('#optionals-modal' + this._uid).modal('hide');
        },
        currencyConvert (price) {
            return travelTools.currencyConvert(this.recordStore.CurrencyId, this.recordStore.PriceCurrencyId,
                price, this.recordStore.CurrencyRates);
        },
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
        },
        imageUrl () {
            if (this.s.Service && this.s.Service.Image && this.s.Service.Image.url) return this.s.Service.Image.url;
            if (this.s.BookingDayServiceImages && this.s.BookingDayServiceImages[0] &&
                this.s.BookingDayServiceImages[0].Image && this.s.BookingDayServiceImages[0].Image.url)
                return this.s.BookingDayServiceImages[0].Image.url;
        },
        paxBases () {
            let res = {};
            for (let baseId in this.recordStore.BaseQuotes.bases) {
                res[baseId] = parseInt(this.recordStore.BaseQuotes[baseId].ADT);
                if (this.recordStore.BaseQuotes[baseId].CHD) {
                    res[baseId] += parseInt(this.recordStore.BaseQuotes[baseId].CHD);
                }
            }
            return res;
        },
        prices () {
            let res = [];
            for (let baseId in this.s.Quote.QuoteList.bases) {
                for (let paxType of this.s.Quote.QuoteList[baseId].paxTypes ) {
                    if (this.s.Quote.QuoteList[baseId][paxType] && this.s.Quote.QuoteList[baseId][paxType].Price) {
                        let text = `${tr('based on')} ${this.paxBases[baseId]} ${paxType}`;
                        let value = this.currencyConvert(this.s.Quote.QuoteList[baseId][paxType].Price).toFixed(0);
                        let f = _.find(res, (r) => {
                            return r.text == text && r.value == value;
                        })
                        if (!f) res.push({text, value})
                    }
                }
            }
            return res;
        }
    },
}
</script>


