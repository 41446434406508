<template>
  <div class="modal fade printable" id="booking-oper-summary" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-large" role="document">
      <div class="modal-content">
        <div class="modal-body booking-form-print" id="print-content" v-if="ready">
            <div class="text-uppercase">
                <h6><b>DETALLE DE OPERACIONES PARA {{recordStore.PaxName}}</b></h6>
            </div>
            <hr class="hr-row mt-0 mb-3">
            <div class="px-2">
                <div class="form-row my-0" v-for="pax of bookingForm.BookingFormPaxs">
                    <span>{{pax.FirstName}} {{pax.LastName}}</span>
                </div>
            </div>
            <hr class="hr-row mt-0 mb-3">
            <div class="px-2">
                <div class="form-row my-1">
                    <b>VUELOS DEL FORMULARIO DE RESERVA</b>
                </div>
                <div class="form-row my-0" v-for="flight of bookingForm.BookingFormFlights">
                    <span v-if="flight.FlightDate">{{flight.FlightDate}} - </span>
                    <span v-if="flight.FlightNumber">{{flight.FlightNumber}} - </span>
                    <span v-if="flight.DepartureCity">{{flight.DepartureCity}} - </span>
                    <span v-if="flight.ArrivalCity">{{flight.ArrivalCity}}</span>
                </div>
                <div class="form-row my-1">
                    <b>VUELOS INTERNOS</b>
                </div>
                <div class="form-row my-0" v-for="flight of flights">
                    {{flight}}
                </div>
            </div>
            <hr class="hr-row mt-0 mb-3">
            <div class="px-2" v-for="(supplierData, sId) of getSuppliersAndHotels">
                <div class="form-row my-1">
                    <b>{{supplierData.Supplier.Name}}</b>
                </div>
                <div v-if="supplierData.Services">
                    <div class="form-row my-0" v-for="service of supplierData.Services">
                        <span class="mr-2">{{getDate(service.ServiceDate)}}</span>
                        <span class="mr-2">{{service.serviceName}}</span>
                        <span v-if="service.PickupInfo">{{service.PickupInfo}}</span>
                    </div>
                </div>
                <div v-if="supplierData.Days">
                    <div class="form-row my-0" v-for="(day, dayNr) of supplierData.Days">
                        <div class="form-row mb-1" v-for="room of day.Rooms">
                            <span class="col-12">{{room.RoomsText}}</span>
                            <span class="col-12">
                                <span class="mr-5">IN: {{day.DateIn}}</span><span>OUT: {{day.DateOut}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="notesBySupplier[supplierData.Supplier.id] && notesBySupplier[supplierData.Supplier.id].length > 0" class="mt-2 ml-1">
                    <i>**** NOTAS ****</i>
                    <div v-for="n of notesBySupplier[supplierData.Supplier.id]" class="form-row">
                        - {{n}}
                    </div>
                </div>
                <hr class="hr-row mt-0 mb-3">
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="print">Imprimir</button>
            <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';
export default {
    name: 'booking-oper-summary',
    props: ['show'],
    mixins: [escape],
    data () {
        return {
            ready: false,
            notes: [],
        }
    },
    async mounted () {
        $('#booking-oper-summary').modal({backdrop: 'static', keyboard: false}, 'show');
        let notes = await api.get('/api/bookingnote/', {IncludeClosed: false
            , filters: JSON.stringify({BookingId: this.recordStore.id})})
        if (notes) {
            this.notes = _.filter(notes, (r) => !r.Closed);
        }
        this.ready = true;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            bookingForm: state => state.booking.bookingForm,
        }),
        flights () {
            let res = []
            if (this.recordStore) {
                for (let f of this.recordStore.BookingFlights) {
                    if (f.Output) {
                        res = res.concat(f.Output.split('\n'));
                    }
                }
            }
            return res;
        },
        getHotels  () {
            return this.recordStore.hotels;
        },
        getSuppliers () {
            let res = {}
            if (this.recordStore && this.recordStore.BookingDays) {
                for (let day of this.recordStore.BookingDays) {
                    for (let service of day.BookingDayServices) {
                        if (service.Optional) continue;
                        if (service.Status == 'CANCELED') continue;
                        if (service.Supplier && service.Supplier.id) {
                            if (!service.Name && (!service.Service || !service.Service.Name)) continue;
                            let key = service.Supplier.id;
                            if (!res[key]) {
                                res[key] = {Supplier: service.Supplier, Services: [], key: key};
                            }
                            res[key].Services.push(service);
                            res[key].ServiceDate = this.getDateIn(res[key].Services, 'YYYY-MM-DD')

                        }
                    }
                }
            }
            return res;
        },
        getHotelsRooms () {
            let res = {};
            for (let hotelId in this.getHotels) {
                for (let room of this.getHotels[hotelId].Rooms) {
                    if (room.Status == 'CANCELED') continue;
                    if (!res[room.Hotel.id]) {
                        res[room.Hotel.id] = {Supplier: room.Hotel, Days: {}};
                        res[room.Hotel.id].ServiceDate = room.TransDate;
                    }
                    if (!res[room.Hotel.id].Days[room.DayNr]) {
                        res[room.Hotel.id].Days[room.DayNr] = {Rooms: [], TransDate: room.TransDate, days: room.nights};
                        res[room.Hotel.id].Days[room.DayNr].DateIn = moment(room.TransDate).format('DD MMM');
                        res[room.Hotel.id].Days[room.DayNr].DateOut = moment(room.TransDate).add(room.nights, 'days').format('DD MMM');
                    }
                    res[room.Hotel.id].Days[room.DayNr].Rooms.push(room);
                }
            }
            for (let hotelId in res) {
                let hotel = res[hotelId]
                for (let dayNr in hotel.Days) {
                    let day = hotel.Days[dayNr]
                    let t = day.days + ' ' + tr('NIGHTS ACCOMMODATION IN') + ' ';
                    for (let room of day.Rooms) {
                        let textRoom = [];
                        textRoom.push(room.Rooms);
                        if (!room.Room) continue;
                        if (!room.Room.Checkin) textRoom.push(tr('ROOM'));
                        textRoom.push(room.Room.Name);
                        let roomType = _.find(room.Hotel.RoomCategories, (c) => c.id == room.RoomCategory);
                        if (roomType && !room.Room.Checkin) {
                            let t = roomType.Name;
                            if (roomType.Comment) {
                                t+= ` ${roomType.Comment}`;
                            }
                            textRoom.push(t);
                        }
                        if (hotel.Supplier.Checkin) textRoom.push(`, check-in ${hotel.Supplier.Checkin}`);
                        if (hotel.Supplier.Checkout) textRoom.push(`, check-out ${hotel.Supplier.Checkout}`);
                        room.RoomsText = textRoom.join(' ');
                    }
                }
            }
            return res;
        },
        getSuppliersAndHotels () {
            let res = {}
            if (!this.recordStore) return res;
            let i = 1;
            for (let s in this.getSuppliers) {
                res[s] = this.getSuppliers[s];
                res[s].id = i;
                i += 1;
            }
            for (let h in this.getHotelsRooms) {
                let key = 'day' + h;
                res[key] = this.getHotelsRooms[h];
                res[key].id = i;
                i += 1;
            }
            let rows = [];
            for (let key in res) {
                rows.push(res[key]);
            }
            rows.sort(function(a, b) {
                if (a.ServiceDate > b.ServiceDate) return 1;
                if (a.ServiceDate < b.ServiceDate) return -1;
                return 0;
            })
            return rows;
        },
        notesBySupplier () {
            let res = {};
            for (let s of this.getSuppliersAndHotels) {
                if (!res[s.Supplier.id]) res[s.Supplier.id] = [];
                let notes = _.filter(this.notes, (r) => r.SupplierId == s.Supplier.id);
                if (!notes) continue;
                for (let n of notes) {
                    if (!n.Note) continue;
                    n = n.Note.split('\n');
                    res[s.Supplier.id] = [...res[s.Supplier.id], ..._.filter(n, (r) => r)];
                }
            }
            return res;
        }
    },
    methods: {
        print () {
            window.print();
        },
        close () {
            this.$emit('update:show', false);
            $('#booking-oper-summary').modal('hide');
        },
        getDate (d) {
            return moment(d).format('DD MMM');
        },
        getDateIn (services, format) {
            let dateIn;
            for (let s of services) {
                if (!dateIn || (dateIn && s.ServiceDate<dateIn)) dateIn = s.ServiceDate;
            }
            return moment(dateIn).format(format);
        },

    },
}
</script>
