import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "directionsRenderer",

  ctr() {
    return window.google.maps.DirectionsRenderer;
  },

  events: [],

  mappedProps: {},

  props: {
    origin: { type: String },
    destination: { type: String },
    wayPoints: {type: Array }
  },

  afterCreate(directionsRenderer) {
    let directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: this.origin,
        waypoints: this.wayPoints,
        destination: this.destination,
        travelMode: 'DRIVING',
      },
    ).then((response) => {
      directionsRenderer.setDirections(response);
    }).catch((e) => window.alert("Directions request failed due to " + status));;
  },
});