<template>
    <div class="cursor-pointer">
        <label><b>{{flight.Supplier.Name}}</b></label> /
        <i :class="{'text-danger': flightStatus=='PENDING' || flightStatus=='CANCELED' || flightStatus=='NOT_SERVICES', 'text-success': flightStatus=='CONFIRMED', 'text-primary': 'REQUESTED'}">{{flightStatusLabel}}</i>
    </div>
</template>

<script>
export default {
    name: 'operations-flights-link',
    props: ['flight', 'current'],
   computed: {
        flightStatusLabel () {
            if (this.flightStatus=='PENDING') {
                return tr('Pending');
            } else if (this.flightStatus=='REQUESTED') {
                return tr('Requested');
            } else if (this.flightStatus=='CONFIRMED') {
                return tr('Confirmed');
            } else if (this.flightStatus=='PAID') {
                return tr('Paid');
            } else if (this.flightStatus=='CANCELED') {
                return tr('Canceled');
            } else if (this.flightStatus=='NOT_SERVICES') {
                return tr('Without Flights');
            }
        },
        flightStatus () {
            if (this.flight.Flights.length == 0) return 'NOT_SERVICES';
            let status = {
                PENDING: 0,
                REQUESTED: 0,
                CONFIRMED: 0,
                CANCELED: 0,
                PAID: 0
            }
            for (let s of this.flight.Flights) {
                if (status[s.Status]!=undefined) {
                    status[s.Status] += 1;
                }
            }
            if (status.PENDING>0) {
                return 'PENDING';
            } else if (status.REQUESTED>0) {
                return 'REQUESTED';
            } else if (status.CONFIRMED>0) {
                return 'CONFIRMED';
            } else if (status.PAID>0) {
                return 'PAID';
            } else if (status.CANCELED>0) {
                return 'CANCELED';
            }
            return 'PENDING';
        }
    },

}
</script>
