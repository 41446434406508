<template>
      <languages-list
        :title="field.title"
        :textStyle="field.textStyle"
        :descriptions.sync="record[field.name]"
        :fieldName="field.name"
        :modal="modal"
        :maxLength="field.length"
        :field="field"
        @change="change"
        ></languages-list>
</template>

<script>
const languagesList = importVueComp('components/tools', 'LanguagesList');

export default {
    name: 'languages-comp',
    props: ['record', 'field', 'tableSpec', 'modal'],
    components: {
      'languages-list': languagesList
    },
    methods: {
        change (options, fieldName, value) {
            this.$emit('change', options, fieldName, value);
        }
    },
}
</script>
