<template>
    <div class="card-footer bg-transparent service-footer">
        <div class="form-row">
            <div class="col-12 form-group form-default">
                <input
                    :disabled="!canEdit && !operActions"
                    v-model="service.PickupInfo"
                    class="form-control"
                    :class="{'fill': service.PickupInfo}"
                    type="text" maxlength="255">
                        <label class="float-label">PICK UP:</label>
                    </input>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'day-service-pickup',
    props: ['service', 'canEdit'],
    computed:{
        ...mapState({
            operActions: state => state.main.operActions,
        }),
    },
}
</script>



