<template>
    <div>
        <div class="form-material form-row">
            <input-field
                :field="usersField"
                :defValue.sync="users"
                class="col-md-4 px-3"
                @change="onChange"
            ></input-field>
            <input-field
                :field="closedField"
                :defValue.sync="showClosed"
                class="col-md-4 px-3"
            ></input-field>
            <button class="btn btn-primary" @click="fetchList">Refresh</button>

        </div>

        <ul v-if="treeData.length">
            <li v-for="node in treeData" :key="node.id">
                <span v-if="selectedGroupIds.includes(node.id)" style="background-color: yellow;"> {{ node.Description }}</span>
                <span v-else>{{ node.Description }}</span>
                <span > ({{countUsers(node.id)}})</span>
                <font-awesome-icon icon="users" size="xs" class="ml-2 mb-1" 
                    @click="showUsers = showUsers == node.id ? false : node.id" :class="{'blue': showUsers == node.id}"/>
                <span v-if="showUsers == node.id">
                    <span v-for="user in groupUsers(node.id)" :key="user.id" :class="{'closed': user.Closed}"> {{ user.UserName }}</span>
                </span>
                <access-group-tree-node :node="node" :seletedGroups="selectedGroupIds"/>
            </li>
        </ul>
    </div>
</template>

<script>
import AccessGroupTreeNode from './AccessGroupTreeNode.vue';

export default {
    name: 'access-group-tree',
    components: {
        AccessGroupTreeNode
    },
    data() {
        return {
            list: [],
            treeData: [],
            showClosed: false,
            usersField: {
                name: 'users',
                label: 'Users',
                editor: 'vue-select',
                relation: 'user',
                optionLabels: 'UserName',
                addBlank: true,
                list: true,
            },
            closedField: {
                name: 'closed',
                label: 'Include Closed',
                editor: 'checkbox',
            },
            users: null,
            selectedGroupIds: [],
            showUsers: false
        }
    },
    mounted() {
        this.fetchList();
    },
    methods: {
        async fetchList() {
            let res = await api.get('/api/accessgroup/');
            if (res) {
                this.list = _.filter(res, (g) => {
                    if (this.showClosed) {
                        return true;
                    } else {
                        return !g.Closed;
                    }
                });
                this.buildTree();
            }
        },
        onChange() {
            this.highlightSelectedGroups();
        },
        highlightSelectedGroups() {
            // Reset all groups' background color
            this.selectedGroupIds = [];
            if (!this.users) return;
            const selectedUsers = this.users.split(',');


            this.selectedGroupIds = _.filter(this.list, (g) => {
                return _.find(selectedUsers, (userId) => {
                    let user = _.find(api.tables.user, (u => u.id == parseInt(userId)));
                    if (user) {
                        if (user.AccessGroupId == g.id) {
                            return true;
                        }
                    }
                });
            }).map(g => g.id);
        },
        buildTree() {
            // Create a map of all access groups, converting IDs to strings
            const accessGroupMap = new Map(this.list.map(group => [String(group.id), { ...group, children: [] }]));
            
            // Build the tree structure
            this.treeData = this.list.filter(group => {
                const parentIds = group.permissions.access_group || [];
                parentIds.forEach(parentId => {
                    const parent = accessGroupMap.get(String(parentId));
                    if (parent) {
                        parent.children.push(accessGroupMap.get(String(group.id)));
                    }
                });
                // Return true for root nodes (those without parents or with non-existent parents)
                return parentIds.length === 0 || !parentIds.every(id => accessGroupMap.has(String(id)));
            }).map(group => accessGroupMap.get(String(group.id)));
        },
        groupUsers (groupId) {
            return _.filter(api.tables.user, (u) => u.AccessGroupId == groupId);
        },
        countUsers (groupId) {
            return _.filter(api.tables.user, (u) => u.AccessGroupId == groupId).length;
        }
    }
}
</script>

<style scoped>
/* Add this style to ensure the background color is visible */
li {
    padding: 5px;
    margin: 2px 0;
}
.closed {
    color: red;
}
.blue {
    color: blue !important;
}
</style>
