<template>
    <div v-if="user">
        <report-window v-if="ready"
            endpoint="/reports/receiptpending"
            :perPage="(options && options.perPage)? options.perPage: 500"
            :fields="fields"
            :title="title"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :hideSearch="options && options.hideSearch"
            :current.sync="current"
            :headers="headers"
            :templates="templates"
            :columnClick="columnClick"
            :fieldClasses="fieldClasses"
            :columnsClasses="columnsClasses"
            :reportViewClass="options? options.reportViewClass: null"
            :notFilterable="options && options.notFilterable"
            :totalsOn="totalsOn"
         ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "receipt-pending-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            adminActions: state => state.main.adminActions,
            user: state => state.main.user,
        }),
        columnsClasses () {
            return {
                Amount: 'text-right minw',
            }
        },
        totalsOn () {
            let res = [];
            if (this.current.CurrencyId) {
                return ['Amount'];
            }
            return null;
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        if (this.user.Department && this.user.Department.Sales && !this.adminActions) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
              {name: 'SalesUserId', label: 'Seller', editor: 'select', columns: 2,
                optionLabels: 'UserName', relation: 'user', addBlank: true, list: true,
                filters: (row) => {
                    if (row.Closed) return false;
                    if (row.Department && row.Department.Sales) return true;
                }
              },
              {name: 'FromDate', editor: 'date', label: 'From', columns: 2},
              {name: 'ToDate', editor: 'date', label: 'To', columns: 2},
              {name: 'CurrencyId', relation: 'currency', optionLabels: 'id', editor: 'select', label: 'Currency'
                , columns: 2},
              { name: 'AgencyId', label: 'Agency', editor: 'select', optionLabels: 'Name', relation: 'agency'},

            ],
            title: "Receipts Pending",
            headers: {
                BookingId: 'File Nr',
                PaxName: 'Passenger',
                CurrencyId: 'Currency',
                SalesUserName: 'Seller',
            },
            templates: [
                {name: 'PaymentDate', callback: (row) => {
                    if (!row.PaymentDate) return '';
                    return moment(row.PaymentDate).format("DD/MM/YYYY")
                  }
                },
                {name: 'Amount', callback: (row) => {
                    return tools.toNumber(row.Amount, 0);
                  }
                },
            ],
            columnClick: {
                BookingId: this.showBooking,
            },
            fieldClasses: {
                PaymentDate: (row) => {
                    return self.getFieldStyle(row);
                },
                BookingId: (row) => {
                    return 'cursor-pointer-report';
                },
            },

        };
    },
    methods: {
        getFieldStyle (row) {
            if (row.PaymentDate) {
                if (moment(row.PaymentDate)< moment(new Date())) return 'exclamation-alert';
                let d = moment(row.PaymentDate).add(-7, 'days');
                if (d < moment(new Date())) return 'exclamation-yellow';
            }
            return '';
        },
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-sale', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        }

    }
};
</script>
