<template>
  <div class="modal fade" id="input-value" tabindex="-1" role="dialog" aria-labelledby="input-value"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content form-material">
        <div class="modal-header">
          <h5 class="modal-title">{{tr(text)}}</h5>
        </div>
        <div class="modal-body form-group form-default">
          <textarea
            type="text"
            v-model="value"
            class="text-control"
            :class="addClass"
            :disabled="disabled"
            :rows="rows? rows: 3"
            v-if="type=='memo'"
          >
            <span class="form-bar"></span>
          </textarea>
          <input type="text" v-model="value" class="form-control" :class="addClass" :maxlength="maxLength" v-else>
            <span class="form-bar"></span>
          </input>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">{{tr('Done')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'input-value',
    mixins: [escape],
    props: ['def', 'show', 'options', 'text', 'addClass', 'type', 'maxLength', 'disabled', 'rows'],
    data () {
        return {
            value: null,
        }
    },
    mounted () {
        if (this.def) {
            this.value = this.def;
        }
        $('#input-value').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        save (record) {
            this.$emit('save', this.value, this.options);
            this.close();
        },
        close () {
            this.$emit('close');
            this.$emit('update:show', false);
            $('#input-value').modal('hide');
        },
    },
}
</script>
