<template>
      <report-window
        v-if="ready"
        endpoint="/admin/reports/cash_list"
        :fields="fields"
        :title="cardTitle || title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :columnsClasses="columnsClasses"
        :notFilterable="options && options.notFilterable"
        :headerColumns="options? options.headerColumns: null"
        :columnClick="columnClick"
        :current.sync="current"
        :slotsFields="slotsFields"
        :columnsFormat="columnsFormat"
        :fieldClasses="fieldClasses"
        ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
export default {
    name: 'cash-list-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            columnsClasses: {
                CurrencyAmount: 'text-right',
                Amount: 'text-right',
                Balance: 'text-right',
            },
            ready: false,
            fields: [
                { name: "FromDate", editor: "date", label: "From", required: true },
                { name: "ToDate", editor: "date", label: "To", required: true },
                { name: 'PaymentTypeId', label: 'Payment Type', editor: 'select', list: true,
                  optionLabels: 'Name', relation: 'paymenttype', required: true },
                { name: 'CurrencyId', label: 'Currency', editor: 'select',
                  optionLabels: 'id', relation: 'currency', required: true },
                { name: 'Tables', label: 'Options', editor: 'select', defValue: 'ALL',
                  options: [
                      {value: 'ALL', label: 'All'},
                      {value: 'PAYMENT', label: 'Payments'},
                      {value: 'RECEIPT', label: 'Receipts'},
                      {value: 'EXPENSE', label: 'Expenses'},
                      {value: 'CASH_OUT', label: 'Cash Out'},
                      {value: 'ACC_MOVE', label: 'Account Movement'},
                  ]
                },
            ],
            title: 'Cash List',
            headers: {
              TransDate: 'Date',
              'CurrencyAmount': 'Currency Transaction',
            },
            templates: [
                {
                    name: "Concept",
                    callback: (row) =>  {
                        return tr(row.Concept)
                    }
                },
                {
                    name: "TransDate",
                    callback: (row) =>  {
                        if (!row.TransDate) return "";
                        return moment(row.TransDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "Amount",
                    callback: (row) =>  {
                        if (!row.Amount) return "";
                        return tools.toNumber(row.Amount, null, row.CurrencyId);
                    }
                },
                {
                    name: "CurrencyAmount",
                    callback: (row) =>  {
                        if (!row.Amount) return "";
                        let value = tools.toNumber(row.CurrencyAmount, null, row.CurrencyId);
                        return `${value} ${row.CurrencyId}`;
                    }
                },
                {
                    name: "Balance",
                    callback: (row) =>  {
                        return tools.toNumber(row.Balance, null, row.CurrencyId);
                    }
                },
            ],
            slotsFields: [
            ],
            columnsFormat: {
            },
            fieldClasses: {
            },
            columnClick: {
            },

      }
    },
    methods: {
    }
}
</script>
