<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="134.000000pt" height="86.000000pt" viewBox="0 0 134.000000 86.000000"  preserveAspectRatio="xMidYMid meet" class="service-actions svg-inline--fa fa-ad fa-w-20">
  <g transform="translate(-3.000000,89.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
    <path d="M102 883 c-18 -9 -43 -30 -55 -47 -22 -31 -22 -36 -22 -376 0 -341 0 -345 22 -376 47 -65 32 -64 653 -64 621 0 606 -1 653 64 22 31 22 35 22 376 0 341 0 345 -22 376 -47 65 -32 64 -655 64 -498 0 -567 -2 -596 -17z m373 -204 c50 -14 100 -64 114 -114 17 -60 13 -145 -8 -190 -34 -74 -85 -105 -172 -105 -119 0 -189 78 -189 210 0 153 110 239 255 199z m645 -69 c0 -36 3 -40 25 -40 21 0 25 -5 25 -30 0 -25 -4 -30 -25 -30 -25 0 -25 -1 -25 -84 0 -80 1 -85 23 -88 31 -4 37 -53 7 -63 -23 -7 -79 2 -97 17 -9 7 -13 43 -13 114 0 97 -1 104 -20 104 -16 0 -20 7 -20 30 0 23 4 30 20 30 17 0 20 7 20 40 l0 40 40 0 40 0 0 -40z m-373 -45 c5 -14 8 -14 29 0 58 38 148 1 172 -70 16 -47 10 -120 -13 -164 -28 -56 -97 -76 -155 -46 l-30 16 0 -66 0 -65 -40 0 -40 0 0 198 c0 109 3 202 7 205 13 13 64 7 70 -8z"/>
    <path d="M361 594 c-48 -34 -63 -113 -35 -184 18 -46 38 -60 84 -60 44 0 69 16 86 55 38 93 -9 205 -86 205 -14 0 -36 -7 -49 -16z"/>
    <path d="M777 490 c-32 -27 -32 -104 2 -131 30 -25 37 -24 66 6 61 60 -2 178 -68 125z"/>
  </g>
</svg>

</template>

<script>
export default {
    name: 'svg-optional',
}
</script>
