import abm from '@/components/tools/abm';
import { mapState } from 'vuex';
export default {
  name: 'contact',
  template: `
    <abm v-if="user" endpoint="/api/contact/" :options="options" tablename="contact"></abm>
  `,
  components: {
    abm,
  },
  computed: {
    ...mapState(['me']),
  },
  data () {
    return {
      options: {
        fields: [
            {name: 'ContactName', label: 'Contact Name'},
            {name: 'Title', label: 'Title'},
            {name: 'FirstName', label: 'Nombre'},
            {name: 'LastName', label: 'Apellido'},
            {name: 'AgencyId', label: 'Agencia', editor: 'vue-select',
                relation: 'agency', optionLabels: 'Name', addBlank: true },
            {name: 'SupplierId', label: 'Proveedor', editor: 'vue-select',
                relation: 'supplier', optionLabels: 'Name', addBlank: true },
            {name: 'Phone', label: 'Teléfono'},
            {name: 'Mobile', label: 'Celular'},
            {name: 'Email', label: 'Email'},
            {name: 'JobDescription', label: 'Puesto de Trabajo', hideFromList: true},
            {name: 'Address', label: 'Address', hideFromList: true},
            {name: 'CountryName', label: 'País', hideFromList: true},
            {name: 'Gender', label: 'Género', editor: 'select', hideFromList: true, addBlank: true,
                options: [
                    {value: 'M', label: 'M'},
                    {value: 'F', label: 'F'}
                ]
            },
            {name: 'Closed', editor: 'checkbox', label: 'Archive', hideFromList: true}
        ],
        title: 'Contactos',
        title2: 'Contacto'
      }
    }
  },
};

