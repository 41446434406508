<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="172.000000pt" height="88.000000pt" viewBox="0 0 172.000000 88.000000"  preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M390 703 c-14 -2 -31 -12 -37 -21 -15 -19 -17 -102 -3 -102 6 0 10 6 10 13 0 7 15 24 33 38 31 23 37 24 208 24 130 0 180 -3 192 -13 12 -11 24 -10 65 3 39 13 82 16 205 13 144 -3 158 -5 186 -26 17 -13 36 -20 41 -17 15 9 12 48 -4 71 -14 18 -31 19 -443 20 -235 1 -439 0 -453 -3z"/>
      <path d="M445 577 c-39 -29 -7 -37 151 -37 133 0 156 2 161 16 3 9 0 20 -8 25 -24 15 -282 11 -304 -4z"/>
      <path d="M885 577 c-39 -29 -7 -37 155 -37 160 0 185 5 160 35 -17 21 -287 22 -315 2z"/>
      <path d="M246 458 c-12 -17 -16 -50 -16 -144 0 -67 3 -129 6 -138 10 -26 31 -18 43 14 l10 30 538 -2 537 -3 6 -25 c3 -14 12 -25 20 -25 12 0 16 26 18 144 2 97 -1 148 -9 157 -10 12 -107 14 -575 14 l-563 0 -15 -22z"/>
    </g>
  </svg>
</template>

<script>
export default {
    name: 'mat-bed',
}
</script>
