<template>
    <footer>
        <div class="container mb-5 mb-5">
            <div class="row">
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-final-terms',
    props: ['id', 'hash'],
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
           let r = _.find(api.tables.language, (c) => c.id == this.recordStore.LanguageId)
           if (r) return r.Code;
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
    }
}
</script>

