<template>
    <div class="col-md-12 card clearfix service-drag-card border my-1" :day="dayId">
        <div class="card-header py-1 form-row cursor-pointer" @click="editService"
            v-if="compressView" :class="getBGColor"
            :serviceId="recordStore.BookingDays[dayId].BookingDayServices[rowIdx].id || recordStore.BookingDays[dayId].BookingDayServices[rowIdx].tempId">
            <booking-day-service-info
              :dayId="dayId"
              :rowIdx="rowIdx"
            ></booking-day-service-info>
        </div>
        <div class="py-1 cursor-pointer form-material" @click="editService" v-else
            :serviceId="recordStore.BookingDays[dayId].BookingDayServices[rowIdx].id || recordStore.BookingDays[dayId].BookingDayServices[rowIdx].tempId">
            <day-service
                :compressView="compressView"
                :service.sync="recordStore.BookingDays[dayId].BookingDayServices[rowIdx]"
                :rowIdx="rowIdx"
                :dayId="dayId"
                :dayNr="dayNr"
                @removeService="removeService"
                @replaceService="replaceService"
            ></day-service>
        </div>
        <day-service-modal
            v-if="showService && compressView"
            :service.sync="recordStore.BookingDays[dayId].BookingDayServices[rowIdx]"
            :rowIdx="rowIdx"
            :dayId="dayId"
            :dayNr="dayNr"
            :compressView="compressView"
            @removeService="removeService"
            @replaceService="replaceService"
            @close="showService = false"
        ></day-service-modal>
    </div>
</template>

<script>
const dayServiceModal = importVueComp('components/booking', 'DayServiceModal');
const bookingDayServiceInfo = importVueComp('components/booking', 'BookingDayServiceInfo');
const dayService = importVueComp('components/booking', 'DayService');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';

export default {
    name: 'booking-day-service',
    props: ['row', 'rowIdx', 'dayId', 'dayNr', 'compressView'],
    components: {
        'day-service-modal': dayServiceModal,
        'day-service': dayService,
        'booking-day-service-info': bookingDayServiceInfo,
    },
    data () {
        return {
            showService: false,
        }
    },
    mounted () {
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            operActions: state => state.main.operActions,
            managerActions: state => state.main.managerActions,
            recordStore: state => state.main.record,
        }),
        sales () {
            return this.salesActions || this.recordStore.isTemplate;
        },
        getBGColor () {
            if (this.rowIdx % 2 == 1) return 'bg-service-grey';
            return 'bg-service-white';
        },
        service () {
            return this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx];
        }
    },
    methods: {
        removeService () {
            if (!this.hasPayments()) return;
            let day = this.recordStore.getDay(this.dayNr);
            day.removeService(this.rowIdx);
            //day.setTitle();
        },
        editService () {
            if (this.compressView) this.showService = true;
        },
        replaceService () {
            if (!this.hasPayments()) return;
            EventBus.$emit('replaceService', this.dayNr, this.rowIdx, this.service.id);
        },
        hasPayments () {
            let day = this.recordStore.getDay(this.dayNr);
            if (!day) return true;
            let service = day.BookingDayServices[this.rowIdx];
            if (!service) return true;
            let supplierId;
            if (service && service.Service && service.Service.SupplierId) supplierId = service.Service.SupplierId;
            if (!supplierId && service && service.SupplierId) supplierId = service.SupplierId;
            if (!supplierId) return true;
            let payments = _.filter(api.payments, (p) => p.SupplierId == supplierId);
            if (payments.length > 0) {
                let res = confirm(tr('Attention! This provider has payments in the file. Before make changes, edit or remove payments. Do you want to continue?'));
                return res;
            }
            return true

        }
    },
}
</script>



