<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="225.000000pt" height="76.000000pt" viewBox="0 0 225.000000 76.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(-10.000000,76.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M298 609 c-16 -9 -27 -69 -12 -69 3 0 22 11 42 25 35 24 44 25 172 25 128 0 137 -1 172 -25 20 -14 39 -25 42 -25 4 0 6 13 6 30 0 19 -7 33 -19 40 -26 13 -380 13 -403 -1z"/>
      <path d="M364 509 c-13 -22 23 -29 146 -27 103 3 125 6 125 18 0 12 -23 15 -132 18 -90 2 -134 -1 -139 -9z"/>
      <path d="M215 433 c-38 -9 -45 -32 -45 -154 0 -65 4 -120 8 -123 5 -3 19 8 31 24 l22 30 262 0 c221 0 266 -2 285 -16 12 -8 22 -22 22 -30 0 -34 18 -10 24 32 13 97 7 190 -13 215 l-19 24 -279 2 c-153 0 -287 -1 -298 -4z"/>
    </g>
    <g transform="translate(63.000000,76.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M298 609 c-16 -9 -27 -69 -12 -69 3 0 22 11 42 25 35 24 44 25 172 25 128 0 137 -1 172 -25 20 -14 39 -25 42 -25 4 0 6 13 6 30 0 19 -7 33 -19 40 -26 13 -380 13 -403 -1z"/>
      <path d="M1016 604 c-17 -17 -19 -25 -10 -48 5 -13 12 -12 48 9 40 23 52 25 173 25 123 0 132 -1 168 -26 23 -15 41 -22 47 -16 15 15 -2 60 -25 66 -12 3 -104 6 -204 6 -152 0 -184 -3 -197 -16z"/>
      <path d="M364 509 c-13 -22 23 -29 146 -27 103 3 125 6 125 18 0 12 -23 15 -132 18 -90 2 -134 -1 -139 -9z"/>
      <path d="M1092 503 c3 -16 17 -18 138 -18 113 0 135 2 135 15 0 12 -23 15 -138 18 -128 2 -138 1 -135 -15z"/>
      <path d="M215 433 c-38 -9 -45 -32 -45 -154 0 -65 4 -120 8 -123 5 -3 19 8 31 24 l22 30 262 0 c221 0 266 -2 285 -16 12 -8 22 -22 22 -30 0 -34 18 -10 24 32 13 97 7 190 -13 215 l-19 24 -279 2 c-153 0 -287 -1 -298 -4z"/>
      <path d="M930 427 c-25 -12 -25 -15 -28 -145 -2 -86 1 -132 8 -132 5 0 10 5 10 10 0 6 9 19 20 30 19 19 33 20 283 20 144 0 267 -4 273 -8 6 -4 17 -18 23 -31 23 -43 32 -7 29 120 -3 120 -4 124 -28 136 -36 18 -554 18 -590 0z"/>
    </g>
  </svg>
</template>

<script>
export default {
    name: 'tpl-bed',
}
</script>
