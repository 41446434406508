<template>
    <report-window endpoint="/custom/reports/cash-out"
    :current.sync="current"
    :fields="fields"
    :title="title"
    :headers="headers"
    :headerColumns="headerColumns"
    :hideFilters="options && options.hideFilters"
    :runMounted="options && options.runMounted"
    :cardView="options && options.cardView"
    :totalsOn="['TotalExpense']"
    :templates="templates"
    :columnsClasses="columnsClasses"
    ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";

export default {
    name: "cash-out-report",
    props: ["options"],
    components: {
        ReportWindow
    },
    computed: {
        columnsClasses () {
            return {
                TotalExpense: 'text-right',
            }
        }
    },
    data() {
        return {
            title: "Listado de Salida de Fondos",
            headerColumns: ["ExpenseDescription", "PaymentType", "ExpenseType", "Currency", "TotalExpense", "InvoiceStatus"],
            headers: {
                StartDate: 'Fecha desde',
                EndDate: 'Fecha hasta',
                ExpenseDescription: "Tipo de Gasto",
                PaymentType: "Forma de Pago",
                ExpenseType: "Tipo de Gasto Fijo o Variable",
                Currency: "Moneda",
                TotalExpense: "Gastos totales",
                InvoiceStatus: "Estado de factura"
            },
            current:{},
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "Fecha Desde",
                    required: true,
                },
                {
                    name: "EndDate",
                    editor: "date",
                    label: "Fecha Hasta",
                    required: true,
                },
                {
                    name: 'ExpenseTypeId',
                    relation: 'expensetype',
                    optionLabels: 'Name',
                    setRelationTo: 'ExpenseType',
                    editor: 'select',
                    label: 'Concept',
                    addBlank: true
                },
                {
                    name: 'PaymentTypeId',
                    relation: 'paymenttype',
                    optionLabels: 'Name',
                    editor: 'select',
                    label: 'Payment Type',
                    addBlank: true
                },
                {
                    name: 'FilterPaymentType',
                    label: 'Expense Type',
                    editor: 'select',
                    addBlank: true,
                    options: [
                        {value : 'FIXED', label: 'Fixed', tr: true},
                        {value : 'VARIABLE', label: 'Variable', tr: true}
                    ]
                },
                { 
                    name: 'CurrencyId',
                    label: 'Currency',
                    relation: 'currency',
                    optionLabels: 'id',
                    editor: 'select',
                    addBlank: true,
                },
                { 
                    name: 'InvoiceStatus',
                    label: 'Invoice Status',
                    options: [
                        {value : 'PENDING', label: 'Pending', tr: true},
                        {value : 'RECEIVED', label: 'Received', tr: true},
                        {value : 'CONFIRMED' , label: 'Confirmed', tr: true},
                        {value : 'NOT_APPLY', label: 'Not Apply', tr: true}
                    ],
                    editor: 'select',
                    addBlank: true,
                }
            ],
            templates:[
                {
                    name: 'ExpenseType', 
                    callback: function(row) {               
                        return tr(row.ExpenseType)
                    }
                },
                {
                    name: 'TotalExpense', 
                    callback: function(row) {
                        return tools.toNumber(row.TotalExpense, 0)
                    }
                },
                {
                    name: 'InvoiceStatus', 
                    callback: function(row) {
                        return tr(row.InvoiceStatus)
                    }
                }
            ]    
        }
    },
    async mounted(){
        frontTools.setReportValues(this);
    }
}
</script>