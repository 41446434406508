<template>
    <div class="app-index">
        <div class="container d-flex justify-content-center">
            <div class="form-material mt-5">
                <div>
                    <img src="@/img/LOGO_NUBBO_FINAL-02.png" width="250">
                </div>
                <div class="form-group form-default" v-if="!msg">
                    <input type="text" class="form-control" :class="{'fill': username}" id="username" name="username" v-model="username">
                        <span class="form-bar">
                        </span>
                        <label class="float-label">{{tr('User')}}</label>
                    </input>
                </div>
                <div class="form-group form-default" v-if="!forgot">
                    <input type="password" class="form-control" :class="{'fill': password}" id="password" name="password" v-model="password">
                        <span class="form-bar">
                        </span>
                        <label class="float-label">{{tr('Password')}}</label>
                    </input>
                </div>
                <div class="d-flex justify-content-center" v-if="!forgot">
                    <button id="submit" @click="login" class="btn btn-primary w-100" :disabled="processing">
                        <span v-if="!processing">{{tr('Sign in')}}</span>
                        <img class="processing-gif" src="@/img/loading.gif" v-else>
                    </button>
                </div>
                <div class="d-flex justify-content-center" v-if="forgot && !msg">
                    <button id="submit" @click="reset" class="btn btn-primary w-100" :disabled="processing || !username">
                        <span v-if="!processing">{{tr('Reset Password')}}</span>
                        <img class="processing-gif" src="@/img/loading.gif" v-else>
                    </button>
                </div>

                <div class="form-group d-flex justify-content-center h6" v-if="!forgot">
                    <label class="cursor-pointer" @click="forgot = true">
                        {{ tr('Forgot password?') }}
                    </label>
                </div>
                <div class="form-group d-flex justify-content-center h6" v-else>
                    <label class="cursor-pointer" @click="forgot = false">
                        {{ tr('Sign in') }}
                    </label>
                </div>
                <div class="form-group d-flex justify-content-center">
                    <label v-if="error" class="alert alert-danger" role="alert">
                        {{ tr(error) }}
                    </label>
                </div>
                <div class="form-group d-flex justify-content-center" v-if="forgot">
                    <label v-if="msg" class="alert alert-success" role="alert">
                        {{ tr(msg) }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'login',
    data () {
        return {
            processing: false,
            username: null,
            password: null,
            error: null,
            forgot: false,
            msg: null,
        }
    },
    mounted () {
        this.$store.commit("setUser", null);
    },
    methods: {
        handleError (response) {
            this.error = response.err;
        },
        async login () {
            this.error = null;
            var data = new FormData();
            data.append('username', this.username);
            data.append('password', this.password);
            let res = await api.post('/login', data, {}, this.handleError)
            if (res) {
                if (res.AgencyId) {
                    await api.get("/logout");
                    await api.logout();
                    return;
                }
                this.$store.commit('setUser', res);
                api.getCurrentUser()
                if (res.ChangePassword) {
                    this.$router.push('/change-password')
                } else {
                    this.$router.push('/')
                }
            }
        },
        async reset () {
            this.error = null;
            let res = await api.post('/reset_password', JSON.stringify({UserName: this.username}), null, this.handleError)
            if (res) {
                this.msg = 'An email was sent to your inbox';
            }
        }
    },
    watch: {
        forgot () {
            this.error = null;
            this.msg = null;
        }
    }
}
</script>

