<template>
  <div class="modal fade" id="paymentsHelp" tabindex="-1" role="dialog" aria-labelledby="paymentsHelp"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'payments-help',
    props: ['supplierId', 'show', 'supplierData'],
    mounted () {
        $('#paymentsHelp').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        close () {
            this.$emit('update:show', false);
            $('#paymentsHelp').modal('hide');
        },
    },
}
</script>
