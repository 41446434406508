<template>
    <div class="modal fade bd-example-modal-lg" id="booking-share" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="booking-share" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
          <h5 class="modal-title">{{tr('Share File')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body form-material">
              <div class="form-row d-flex align-items-baseline" v-for="(row, rowNr) of recordStore.BookingUserShares">
                  <div class="my-0 form-group form-default col-md-6">
                      <select-input fieldName="UserId"
                      :field="recordStore.fieldsObject.UserId" label="User"
                      :currentValue.sync="row.UserId" @change="changeUserId($event, rowNr)">
                      </select-input>
                  </div>
                  <div class="col-md-1">
                    <i class="add-item cursor-pointer" @click="remove(rowNr)">
                       <font-awesome-icon icon="trash-alt""/>
                    </i>
                  </div>
              </div>
              <i class="add-item cursor-pointer" @click="addRow()">
                  <font-awesome-icon icon="plus-circle"/>
              </i>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';
export default {
    name: 'booking-share',
    mixins: [escape],
    props: ['bookingShare'],
    async mounted () {
        $('#booking-share').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    methods: {
        async addRow () {
            await vueTools.addRow(this, null, 'BookingUserShares');
        },
        close () {
            this.$emit('update:bookingShare', false);
            $('#booking-share').modal('hide');
        },
        async remove (rowNr) {
            await vueTools.removeRow(this, null, 'BookingUserShares', rowNr);
        },
        async changeUserId (value, rowNr) {
            await this.recordStore.BookingUserShares[rowNr].setValue({fieldName: 'UserId', value});
        },
    },
}
</script>
