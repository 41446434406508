<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'escape',
  methods: {
    close () {},
    evenListener (e) {
      if (e.keyCode == 27) {
        if ($('.modal').length==2) {
            return;
        }
        this.close();
      }
    }
  },
  mounted () {
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.evenListener);
  },
  created () {
    setTimeout(()=> document.addEventListener("keydown", this.evenListener), 500);
  }
}
</script>
