<template>
    <section class="breadcrumbs" :style="image">
      <div class="container">
        <h2>{{recordStore.Title}}</h2>
      </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'itinerary-header-cover',
    components: {
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    asyncComputed: {
        async image () {
            let img = '';
            if (this.recordStore && this.recordStore.Image && this.recordStore.Image.url) {
                img = this.recordStore.Image.url;
            }
            if (!img) return '';
            let values = {};
            let list = img.split('/');
            for (let i=5; i<list.length; i++) {
                let name = list[i]
                let encoded = encodeURIComponent( name ).replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
                values[name] = encoded;
            }
            for (let id in values) {
                img = img.replace(id, values[id])
            }
            if (!img) return '';
            img = await api.getImage(img);
            return {
              backgroundImage: "url(" + img + ")",
                'align-items': 'center',
                'background-attachment': 'fixed',
                'background-position': 'center',
                //'transition': 'transform 10s linear',
                //'transform': 'translate3d(0, 0, 0) scale(1.2)',
              };
        }
    },
}
</script>


<style scoped>
</style>
