<template>
    <div v-if="readMore || textLength.start < limit">
        <text-rows-style
            class="italic text-justify mb-0"
            :text="text"
            v-if="s && ifText && !edit"
            @edit="setEdit"
        ></text-rows-style>
        <text-area-edit-style
            v-else-if="s && edit"
            :text.sync="s.Description"
         ></text-area-edit-style>
        <p v-if="certified" class="mb-1">
            <font-awesome-icon icon="recycle" color="green"/>
            <span class="certified ml-2">{{tr('Certified Supplier')}}</span>
        </p>
        <p v-if="!recordStore.typeOfServices[day.DayNr] && s.Service.ServiceType && s.Description && s.Description.length>0 && ifText && !s.Service.NotPrice"  class="mb-0">
            <i>
                {{tr('Type of service')}}: {{tr(s.Service.ServiceType.Name)}}
            </i>
        </p>
        <br v-if="!recordStore.typeOfServices[day.DayNr] && s.Service.ServiceType && s.Description && s.Description.length>0">
        <p v-if="recordStore.ShowServiceLanguage && s.Service.languageOffered">
            <strong>
                <i>{{tr('Languages')}}: {{getLanguageOffered(s.Service)}}</i>
            </strong>
        </p>
    </div>
</template>

<script>
const itineraryBodyService = importVueComp('components/itinerary', 'ItineraryBodyService', 'custom');
export default {
    name: 'custom-itinerary-body-service',
    mixins: [itineraryBodyService],
    computed: {
        certified () {
            if (this.s.Service && this.s.Service.Supplier && this.s.Service.Supplier.Certified) return true;
            if (this.s.Supplier && this.s.Supplier.Certified) return true;
        }
    }
}
</script>


<style scoped>
.certified {
  font-style: italic;
}
</style>
