<template>
    <div v-if="user && ready">
        <report-window
            v-if="ready"
            endpoint="/reports/dependent_objects"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :slotsFields="slotsFields"
            :updateReport="updateReport"
            @afterEdit="afterEdit"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
let langDict = require('@/languages/system_languages.json');
let dict = langDict['es'];
export default {
    name: "dependencies-report",
    props: ["options", "tableName", "id"],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        let tables = await api.get('/api/get_model_tables/');
        if (tables) {
            this.tables = tables;
            let tableField = _.find(this.fields, (r) => r.name == 'Table');
            for (let t of tables) {
                let label = tr(t.table);
                if (!label) continue;
                tableField.options.push({value: t.table, label: label})
            }
        }
        if (this.tableName) {
            this.current.Table = this.tableName;
            await this.afterEdit('Table');
        }
        if (this.id) {
            this.current.Id = this.id;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
        if (this.id && this.tableName) {
            setTimeout(() => {
                this.updateReport = !this.updateReport;
            }, 1)
        }
    },
    data() {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ready: false,
            tables: null,
            fields: [
                {
                    name: "Table",
                    label: "Table",
                    required: true,
                    columns: 2,
                    editor: 'select',
                    editor: 'vue-select',
                    options: []
                },
                {
                    name: "Id",
                    label: "Id",
                    required: true,
                    columns: 2
                },
            ],
            title: "Dependencies",
            headers: {
            },
            templates: [
            ],
            columnClick: {},
            slotsFields: [
                {
                    name: "Dependency",
                    editor: "label",
                    class: "justify-content-center",
                    tags: [
                        {tag: 'strong', key: 'table', tr: true},
                        {tag: 'strong', value: ':', class: "mr-1"},
                        {tag: 'span', key: 'id'},
                    ],
                },
            ],

        };
    },
    methods: {
        async afterEdit (fieldName) {
            if (fieldName == 'Table') {
                let table = _.find(this.tables, (r) => r.table == this.current.Table);
                let idField = _.find(this.fields, (r) => r.name == 'Id');
                if (table && (table.id == 'Name' || table.id == 'UserName')) {
                    let ids = await api.get('/api/get_model_table_ids/'+ this.current.Table);
                    idField.editor = 'vue-select';
                    idField.options = [];
                    if (ids) {
                        for (let row of ids) {
                            idField.options.push({sortBy: row.id, value: row.id, label: row.id + " - " +  row[table.id]})
                        }
                    }
                    idField.sortInteger = true;
                } else {
                    idField.editor = 'text';
                }
                this.fields = Object.assign([], this.fields);
                this.ready = false;
                let self = this;
                setTimeout(() => {
                    self.ready = true;
                }, 1)
            }
        }
    },
};
</script>
