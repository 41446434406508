<script>
const expenseListReport = importVueComp('components/reports', 'ExpenseListReport', 'custom');
export default {
    name: 'custom-expense-list-report',
    mixins: [expenseListReport],
    data () {
        let r = expenseListReport.data();
        let expenseStatusField = r.fields.find(field => field.name === 'ExpenseStatus');
        let optionsArray = expenseStatusField.options;
        optionsArray.push({value: 'CONFIRMED', label: 'Confirmed'});
        return {
            fields: r.fields
        }
    }
}
</script>

