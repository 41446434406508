<template>
    <div class="form-group" v-if="ready">
        <ul class="nav flex-column md-tabs" :id="_uid + '-langTabs'" role="tablist">
            <li v-for="(language, idx) in languages" class="nav-item">
                <span
                    class="nav-link"
                    :class="isMain(idx)"
                    :id="getTabLabelId(idx)"
                    data-toggle="tab"
                    :href="'#' + getTabId(idx)"
                    role="tab"
                    :aria-controls="getTabId(idx)"
                    aria-selected="true"
                    @click="setActive(idx)">
                    {{language.Name}}
                </span>
            </li>
        </ul>
        <div class="tab-content" :id="_uid + '-langTabsContent'">
            <div
                v-for="(language, idx) in languages"
                class="tab-pane fade form-material"
                :class="isMainContent(idx)"
                :id="getTabId(idx)"
                role="tabpanel"
                :aria-labelledby="getTabLabelId(idx)">
                <div v-if="idx==active && descriptions[getLangIndex(language.id)]">
                    <input-field
                        :key="field.name"
                        v-for="field of extraFields"
                        :field="field"
                        :maxLength="field.maxlength"
                        :fieldOptions="field.options? field.options: []"
                        :defValue.sync="descriptions[getLangIndex(language.id)][field.name]"
                        @change="change(idx, field.name)"
                    ></input-field>
                    <text-area-style
                        :key="_uid + '-' + idx"
                        v-if="textStyle"
                        :text.sync="descriptions[getLangIndex(language.id)].Description"
                        :tabId="getTabId(idx)"
                        @change="change(idx, 'Description')"
                    ></text-area-style>
                    <textarea
                        v-else
                        v-model="descriptions[getLangIndex(language.id)].Description"
                        rows="5"
                        class="text-control"
                        @change="change(idx, field.name)"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'languages-list',
    props: ['descriptions', 'textStyle', 'field', 'fieldName', 'modal'],
    data () {
        return {
            ready: false,
            active: 0,
            languages: [],
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        extraFields () {
            let res = [];
            if (this.field && this.field.rowFields) {
                for (let field of this.field.rowFields) {
                   if (field.name == 'Description') continue;
                   if (field.name == 'LanguageId') continue;
                   res.push(field);
                }
            };
            return res;
        },
        recordStore () {
            if (this.modal) return this.$store.state.main.modalRecord;
            return this.$store.state.main.record;
        },
    },
    async mounted () {
        await this.init();
        this.languages = await api.getTable('language');
        this.ready = true;
    },
    methods: {
        getLangIndex (languageId) {
            let res = _.findIndex(this.descriptions, (r) => r.LanguageId == languageId);
            return res;
        },
        async init () {
            let res = [];
            let found = false;
            let languages = await api.getTable('language');
            for (let language of languages) {
                let d = _.find(this.descriptions, (r) => r.LanguageId == language.id);
                if (d) {
                    res.push(d)
                } else {
                    let r = {
                        LanguageId: language.id,
                        Description: null
                    }
                    for (let field of this.extraFields) {
                        r[field.name] = null;
                    }
                    res.push(r);
                    await vueTools.addRow(this, null, this.fieldName, r, this.modal);
                    found = true;
                }
            }
            if (found) {
                this.recordStore.setModified(false);
            }
            this.$emit('update:descriptions', res);
        },
        isMain: function(idx){
            if (idx==0) return 'active'
            return ''
        },
        isMainContent: function(idx){
            if (idx==0) return 'show active'
            return ''
        },
        setActive (idx) {
            this.active = idx;
            //EventBus.$emit('update-text-style', this.getTabId(idx));
        },
        getTabId (idx) {
            return '-lang-' + this._uid  + idx;
        },
        getTabLabelId (idx) {
            return '-language-' + this._uid  + idx;
        },
        change (i, rowFieldName) {
            this.$emit('change', [this.fieldName, i] , rowFieldName, this.descriptions[i][rowFieldName]);
        }
    },
}
</script>
