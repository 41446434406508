<template>
        <report-window
            v-if="ready"
            endpoint="/api/get_docker_logs/"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :headerColumns="headerColumns"
            :templates="templates"
            :columnsClasses="columnsClasses"
        ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
let langDict = require('@/languages/system_languages.json');
let dict = langDict['es'];
export default {
    name: "user-access-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        return {
            current: {},
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    required: true,
                    label: "UTC Date From",
                },
                {
                    name: "FromTime",
                    editor: "time",
                    required: true,
                    label: "UTC Time",
                },
                {
                    name: "ToDate",
                    editor: "date",
                    required: true,
                    label: "UTC Date To",
                },
                {
                    name: "ToTime",
                    editor: "time",
                    required: true,
                    label: "UTC Time",
                },
                {
                    name: "grep",
                    editor: "text",
                },
            ],
            title: "System Logs",
            columnsClasses: {
              row: "text-left"
            },
            headers: {
            },
            templates: [
            ],
        };
    },
    methods: {
    }
};
</script>
