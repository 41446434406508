<template>
    <abm v-if="user && ready" endpoint="/api/contact/" :options="options" tableName="contact"></abm>
</template>


<script>
const abm = importVueComp('components/tools', 'abm');
import { mapState } from 'vuex';
export default {
    name: 'agency-contact-list',
    props: [ "record" ],
    components: {
        'abm': abm,
    },
    async mounted () {
        let options = await api.getModuleFieldsOptions('contact');
        this.options = _.cloneDeep(options);
        let agencyId = this.record.id;
        let compName = 'agencyContact';
        this.options.filters = {'AgencyId': this.record.id};
        let agencyIdField = _.find(this.options.fields, (r) => r.name == 'AgencyId');
        if (agencyIdField) {
            agencyIdField.defValue = agencyId;
        }
        this.options.showRecord = (sId) => {
            this.$router.push({ name: compName, params: { id: sId, agencyId: agencyId, table: 'contact',
                parentTable: this.$parent.table, parentRoute: this.$router.currentRoute.path}})
        }
        this.options.addNew = () => {
            this.$router.push({ name: compName, params: { id: 'new', agencyId: agencyId, table: 'contact',
                parentTable: this.$parent.table, parentRoute: this.$router.currentRoute.path}})
        }
        this.options.serverSide = false;
        this.ready = true;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    data () {
        return {
            ready: false,
            options: null,
        }
    },
};
</script>
