<template>
  <div class="modal fade modal-delete" id="confirmDelete" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteLabel"
      data-backdrop="static" aria-hidden="true" @click="close">
    <div class="modal-dialog" role="document" >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmDeleteLabel">{{tr('Confirm')}}</h5>
          <button type="button" class="close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{tr(text)}}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
          <button type="button" class="btn btn-danger" @click="remove">{{tr('Delete')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'confirm-delete',
    mixins: [escape],
    props: ['toDelete', 'label'],
    data () {
        return {
            text: 'Delete Record?',
        }
    },
    mounted () {
        $('#confirmDelete').modal({backdrop: 'static', keyboard: false}, 'show');
        if (this.label) this.text = this.label;
    },
    methods: {
        remove (record) {
            this.$emit('confirmRemove');
            this.$emit('update:toDelete', false);
            $('#confirmDelete').modal('hide');
        },
        close () {
            this.$emit('update:toDelete', false);
            $('#confirmDelete').modal('hide');
        },
    },
}
</script>
