<script>
const bookingButtonActions = importVueComp('components/booking', 'BookingButtonActions', 'custom');
import memoInfo from '@/components/svg/MemoInfo2';
const bookingOperSummary = importVueComp('components/booking', 'BookingOperSummary');
var EventBus = require('@/tools/event-bus').default;
import Vue from 'vue';
Vue.component('booking-oper-summary', bookingOperSummary);

export default {
    name: 'custom-booking-button-actions',
    mixins: [bookingButtonActions],
    props: ['qNotes'],
    data () {
        return {
            operSummary: false,
            customButtons: [{
                click: this.showOperSummary,
                show: () => {
                    return this.recordStore.TemplateType=='NONE' && this.recordStore.bookingConfirmed;
                },
                title: 'Operaciones Resumen',
                component: 'memo-info',
                //childComponent: bookingOperSummary,
                //childName: 'bookingOperSummary',
                //childShow: false,
            }]
        }
    },
    components: {
        'memo-info': memoInfo,
    },
    methods: {
        async showOperSummary (b) {
            this.$root.$children[0].openModal({ref: 'bookingOperSummary', name: 'booking-oper-summary'});
        }
    }
}
</script>
