<template>
  <div class="modal fade" id="linkPaymentInvoice" tabindex="-1" role="dialog" aria-labelledby="linkPaymentInvoiceBody"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-large" role="document">
      <div class="modal-content">
        <div class="modal-body form-material p-5" v-if="ready" id="linkPaymentInvoiceBody">
            <div class="form-row">  
                <div class="form row col-12">
                    <input-field
                        class="col-3"
                        :field="supplierField"
                        :defValue.sync="supplierId"
                        @change="getInvoices"
                    ></input-field>
                    <input-field
                        class="col-2"
                        :field="currencyField"
                        :defValue.sync="currencyId"
                        @change="getInvoices"
                    ></input-field>
                    <input-field
                        class="col-2"
                        :field="fromDateField"
                        :defValue.sync="fromDate"
                        @change="getInvoices"
                    ></input-field>
                    <input-field
                        class="col-2"
                        :field="toDateField"
                        :defValue.sync="toDate"
                        @change="getInvoices"
                    ></input-field>
                </div>
                <div class="col-md-7">
                    <table class="table table-striped  table-sm table-bordered form-material">
                        <thead>
                            <tr>
                                <th>Factura</th>
                                <th>Monto</th>
                                <th>No Aplicado</th>
                                <th>A Aplicar</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invoice in invoices">
                                <td class="mw-100">{{invoice.InvoiceNumber}}</td>
                                <td class="text-right mw-80">{{toNumber(invoice.Total)}}</td>
                                <td class="text-right mw-80">{{toNumber(invoice.Balance)}}</td>
                                <td class="mw-80">
                                    <!--input type="number" v-model="invoice.applied" class="form-control text-right"-->
                                    <row-input-field
                                        :rowNr="invoice.id"
                                        :rowField="{name: 'applied', editor: 'number', 'classes': 'text-right my-0 small', decimal: 2}"
                                        :arrayField="{name: 'invoices'}"
                                        :defValue.sync="invoice.applied"
                                        @change="invoiceSelected(invoice)"
                                    ></row-input-field>
                                </td>
                                <td><input type="checkbox" v-model="invoice.selected"
                                    :disabled="invoice.disabled"
                                    @change="invoiceSelected(invoice)"></td>
                            </tr>
                        </tbody>
                        <tfoot class="font-weight-bold">
                            <tr>
                                <td colspan="3">Total</td>
                                <td class="text-right" :class="{'text-danger': error}">{{toNumber(totalInvoices)}}</td>
                            </tr>
                            </tfoot>
                    </table>
                    <div class="text-danger" v-if="error">{{error}}</div>
                    <div class="text-success" v-if="applied">Facturas aplicadas</div>
                </div>
                <div class="col-md-5">
                    <table class="table table-striped  table-sm table-bordered">
                        <thead>
                            <tr>
                                <th>Pago</th>
                                <th>Monto</th>
                                <th>Saldo</th>
                                <th v-if="payments.length>1">A Aplicar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="payment in paymentsList">
                                <td>{{payment.id}}</td>
                                <td class="text-right mw-80">{{toNumber(payment.PaymentAmount)}}</td>
                                <td class="text-right mw-80">{{toNumber(payment.Balance)}}</td>
                                <td v-if="payments.length>1" class="mw-80">
                                    <row-input-field
                                        :rowNr="payment.id"
                                        :rowField="{name: 'applied', editor: 'number', 'classes': 'text-right my-0 small', decimal: 2}"
                                        :arrayField="{name: 'payments'}"
                                        :defValue.sync="payment.applied"
                                        @change="paymentSelected(payment)"
                                    ></row-input-field>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="font-weight-bold">
                            <tr>
                                <td colspan="2">Total</td>
                                <td class="text-right">{{toNumber(totalPaymentBalance)}}</td>
                                <td class="text-right">{{toNumber(totalPaymentsApplied)}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        </div>
        <div class="modal-footer" v-if="ready">
            <button type="button" class="btn btn-secondary" @click="apply" :disabled="error || applied">
                Aplicar</button>
            <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'link-payment-invoice',
    mixins: [escape],
    props: ['show', 'report', 'payments', 'current'],
    data () {
        return {
            ready: false,
            paymentsList: [],
            supplierId: null,
            currencyId: null,
            applied: false,
            fromDate: null,
            toDate: null,
            services: [],
            printing: false,
            supplierField: {
                name: 'SupplierId',
                editor: 'vue-select',
                relation: 'supplier',
                label: 'Supplier',
                optionLabels: 'Name'
            },
            currencyField: {
                name: 'CurrencyId',
                editor: 'select',
                relation: 'currency',
                label: 'Currency',
                optionLabels: 'id'
            },
            fromDateField: {
                name: 'InvFromDate',
                editor: 'date',
                label: 'From Date'
            },
            toDateField: {
                name: 'InvToDate',
                editor: 'date',
                label: 'To Date'
            },
            invoices: [],
        }
    },
    computed: {
        totalPayments () {
            return _.sumBy(this.paymentsList, (r) => r.PaymentAmount);
        },
        totalPaymentBalance () {
            return _.sumBy(this.paymentsList, (r) => r.Balance);
        },
        totalPaymentsApplied () {
            if (this.paymentsList && this.paymentsList.length>1) {
                return _.sumBy(this.paymentsList, (r) => r.applied);
            }
        },
        totalInvoices () {
            return _.sumBy(this.invoices, (r) => {
                if (r.selected) return r.applied;
                return 0;
            });
        },
        error () {
            if (this.totalPaymentsApplied) {
                if (this.totalInvoices < this.totalPaymentsApplied) return 'El monto aplicado en pagos es mayor al saldo de facturas';
                return false;
            }
            if (this.totalInvoices > this.totalPaymentBalance) return 'El monto aplicado en facturas es mayor al saldo de pagos';
            return false;
        }
    },
    async mounted () {
        $('#linkPaymentInvoice').modal({backdrop: 'static', keyboard: false}, 'show');
        this.paymentsList = Object.assign([], this.payments);
        if (this.payments && this.payments.length>0) {
            this.supplierId = this.payments[0].SupplierId;
            this.currencyId = this.payments[0].PaymentCurrencyId;
            this.fromDate = this.current.FromDate;
            this.toDate = this.current.ToDate;
            this.getInvoices();
        }
        if (this.paymentsList.length>1) {
            this.paymentsList.forEach((r)=> {
                r.applied = r.Balance;
            });
        }
        this.ready = true;
    },
    methods: {
        close () {
            this.$emit('update:show', false);
            $('#linkPaymentInvoice').modal('hide');
        },
        toNumber (value) {
            return tools.toNumber(value, 2);
        },
        async getInvoices () {
            if (!this.supplierId || !this.currencyId) return;
            api.setProcessing(true);
            let res = await api.get('/api/purchaseinvoice/get_pending/', {
                filters: JSON.stringify(
                    {   
                        SupplierId: this.supplierId, 
                        CurrencyId: this.currencyId,
                        FromDate: this.fromDate,
                        ToDate: this.toDate
                    }
                )
            });
            if (res) {
                api.setProcessing(false);
                this.invoices = res.map((r)=> Object.assign({
                    selected: false, 
                    disabled: false,
                    applied: r.Balance
                }, r));
            }
        },
        invoiceSelected (invoice) {
            if (this.paymentsList.length>1) {
                this.invoices.forEach((r)=> {
                    if (invoice.selected && r.id!=invoice.id) r.disabled = true;
                    if (!invoice.selected) r.disabled = false;
                });
                this.invoices = Object.assign([], this.invoices);
            }
        },
        async apply () {
            let res = await api.post('/api/purchaseinvoice/link_payment/', JSON.stringify({
                payments: this.paymentsList,
                invoices: this.invoices
            }));
            if (res) {
                this.applied = true;
            }
        },
        getBalance (payment) {
            return payment.PaymentAmount - payment.InvoiceTotal;
        },
        paymentSelected (payment) {
            this.paymentsList = Object.assign([], this.paymentsList);
        }
    }
}
</script>


<style scoped>
.mw-80 {
    max-width: 80px !important;
}
.mw-100 {
    max-width: 100px !important;
}
</style>
