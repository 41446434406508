
import store from '@/store/store';
var EventBus = require('@/tools/event-bus').default;

class VueTools {

    async getObjectFromStore (table, id) {
        let res;
        if (api.tables[table]) {
            let res = _.find(api.tables[table], (r) => r.id == id);
            if (res) {
                let syncVersion = await api.get('/api/get_sync_version/' + table + '/' + id );
                if (syncVersion && syncVersion != res.syncVersion) {
                    let res2 = await api.get('/api/' + table + '/' + id);
                    if (res2)  {
                        res2.original_id = id;
                        this.updateToStore(table, res2);
                        return res2;
                    }
                } else {
                    return res;
                }
            }
        }
        res = await api.get('/api/' + table + '/' + id);
        if (res) return res;
    }


    vueTableText () {
        return {
           count: tr("Showing {from} to {to} of {count} records|{count} records|One record"),
           first: tr("First"),
           last: tr("Last"),
           filter: tr("Filtro"),
           filterPlaceholder: tr("Search"),
           limit: tr("Registros"),
           page: tr("Page"),
           noResults: tr("Not results"),
           filterBy: "Filter by {column}",
           loading: tr('Cargando...'),
           defaultOption: tr('Select {column}'),
           columns: tr('Columns')
        }
    }

    setProcessing (value) {
        store.commit('setProcessing', value);
    }

    get processing () {
        return store.state.main.processing;
    }

    getCurrentUser () {
        return store.state.main.user;
    }

    get mainStore () {
        return store.state.main;
    }

    setModified (value) {
        if (store.state.modified != value) {
            store.commit('setModified', value);
        }
    }

    async action (self, params, actionName, args, modalRecord) {
        await self.$store.dispatch('callAction', {params, action: actionName, args, modalRecord})
        EventBus.$emit('set-record');
    }

    async change (self, params, fieldName, value, modalRecord) {
        await self.$store.dispatch('callAction', {params, action: 'setValue', args: {fieldName, value}, modalRecord})
        //EventBus.$emit('set-record');
    }

    async addRow (self, params, fieldName, values, modalRecord) {
        await self.$store.dispatch('callAction', {params, action: 'addRow', args: {fieldName, values}, modalRecord})
        EventBus.$emit('set-record');
    }

    async removeRow (self, params, fieldName, rowNr, modalRecord) {
        await self.$store.dispatch('callAction', {params, action: 'removeRow', args: {fieldName, rowNr}, modalRecord})
        EventBus.$emit('set-record');
    }

    async closeRow (self, params, fieldName, rowNr, modalRecord) {
        await self.$store.dispatch('callAction', {params, action: 'closeRow', args: {fieldName, rowNr}, modalRecord})
        EventBus.$emit('set-record');
    }


    importModule (name, path, module) {
        let extras = Object.assign([], api.EXTRAS).reverse();
        let p = `@/${path}/${name}.js`;
        let m;
        if (!module && extras.length>0) {
            m  = extras[0];
            p = `@/extra/${m}/${path}/${name}`;
        } else if (extras.length>0) {
            let i = extras.indexOf(module);
            m = extras[i+1];
            if (m) p = `@/extra/${m}/${path}/${name}`;
        }
        let res;
        try {
            if (m) {
                res = require(`@/extra/${m}/${path}/${name}`);
            } else {
                res = require(`@/${path}/${name}`);
            }
        } catch (err) {
            if (m) res = this.importModule(name, path, m);
        }
        return res;
    }

    importModule3 (name, path, module) {
        console.log(name, path, module)
        let extras = Object.assign([], api.EXTRAS).reverse();
        let p = `@/${path}/${name}.js`;
        let m;
        if (!module && extras.length>0) {
            m  = extras[0];
            p = `@/extra/${m}/${path}/${name}`;
        } else if (extras.length>0) {
            let i = extras.indexOf(module);
            m = extras[i+1];
            if (m) p = `@/extra/${m}/${path}/${name}`;
        }
        let res;
        try {
            if (m) {
                console.log(1, `@/extra/${m}/${path}/${name}`)
                res = require(`@/extra/${m}/${path}/${name}`);
                console.log(11, res, `@/extra/${m}/${path}/${name}`)
            } else {
                console.log(2, `@/${path}/${name}`)
                res = require(`@/${path}/${name}`);
            }
        } catch (err) {
            console.log(3, err)
            if (m) res = this.importModule3(name, path, m);
        }
        return res;
    }

    async redirect ($router, endpoint, tableName, report, module, componentName) {
        if ($router.currentRoute.name == endpoint && !tableName && !componentName) return;
        if ($router.currentRoute.name == endpoint && tableName && $router.currentRoute.params.table == tableName) return;
        if ($router.currentRoute.name == endpoint && report && $router.currentRoute.params.report == report) return;
        store.commit('setAbmFilters', null);
        store.commit('setTablePage', null);
        store.commit('setTableOrderBy', {ascending: true, column: false});
        if (tableName) {
            $router.push({ name: endpoint, params: { table: tableName, module: module }});
            return;
        }
        if (componentName) {
            $router.push({ name: endpoint, params: { report: report, componentName: componentName, module: module }})
            return;
        }
        $router.push({ name: endpoint, module: module })
    }

    resolve ($router, endpoint, tableName, report, module, componentName) {
        let res;
        if (tableName) {
            return $router.resolve({ name: endpoint, params: { table: tableName, module: module }});
        }
        if (componentName) {
            return $router.resolve({ name: endpoint, params: { report: report, componentName: componentName, module: module }})
            return;
        }
        return $router.resolve({ name: endpoint, module: module });
    }

    showBooking (router, name, params) {
       router.push({ name, params })
    }

}

function importVueComp (path, name, module) {

    let r = vueTools.importModule(name, path, module);
    if (r)  {
        let res = r.default;
        res.__name = name;
        return res;
    };
}

function importVueComp3 (path, name, module) {
    console.log(path, name, module)
    let r = vueTools.importModule3(name, path, module);
    if (r) return r.default;
}

function importRawTemplate (comp) {
    if (comp.template) {
        return comp.template;
    }
    if (!comp._compiled) {
        let cc = new comp();
        return cc.$options.template;
    }
}

function insertLast (html, div) {
    let p = html.lastIndexOf('</div>');
    return html.substring(0, p) + div + '</div>';
}

window.vueTools = new VueTools();
window.importVueComp = importVueComp;
window.importVueComp3 = importVueComp3;
window.importRawTemplate = importRawTemplate;
window.insertLast = insertLast;