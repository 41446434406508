    <template>
    <div class="w-100">
        <label class="label">{{tr(label)}}</label>
        <div class="form-row mx-0" :id="getId" >
            <select v-model="day" @change="change" class="form-control col-3" :disabled="disabled" :id="'d-' + getId">
                <option v-for="d of days" :value="d"
                :selected="d == day" :disabled="disabled">
                    {{d}}
                </option>
            </select>
            <select v-model="month" @change="change" class="form-control month-select col-5" :disabled="disabled" :id="'m-' + getId">
                <option v-for="m of months" :value="m"
                :selected="m == month" :disabled="disabled">
                    {{getMonth(m)}}
                </option>
            </select>
            <select v-model="year" @change="change" class="form-control col-4" :disabled="disabled" :id="'y-' + getId">
                <option v-for="y of getYears" :value="y"
                :selected="y == year" :disabled="disabled">
                    {{y}}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

const monthsLong = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export default {
    name: 'date-selector',
    props: ['def', 'id', 'disabled', 'readonly', 'disablePast', 'label', 'langCode', 'invalid', 'disableFuture'],
    data () {
      return {
          value: null,
          day: null,
          month: null,
          year: null
      };
    },
    computed: {
        months () {
            return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        },
        days () {
            return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
                '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
                '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
        },
        getId () {
            if (!this.id) return this._uid;
            return this.id;
        },
        getYears () {
            let y = moment(new Date()).format('YYYY');
            let to = parseInt(y);
            if (!this.disableFuture) to += 100;
            let res = [];
            let fr = 1900;
            if (this.disablePast) fr = y;
            for (let i=to; i>=fr; i=i-1) {
                res.push(i);
            }
            if (!this.disableFuture) res.reverse();
            return res;
        }
    },
    mounted () {
        if (this.readonly) return;
        if (this.def)  {
            this.setValue();
        }
    },
    watch: {
        def () {
            if (this.def) {
                this.setValue();
            }
        },
        invalid () {
            if (!this.day) {
                let div = $('#d-' + this.getId);
                if (div[0]) div[0].classList.add('is-invalid');
            }
            if (!this.month) {
                let div = $('#m-' + this.getId);
                if (div[0]) div[0].classList.add('is-invalid');
            }
            if (!this.year) {
                let div = $('#y-' + this.getId);
                if (div[0]) div[0].classList.add('is-invalid');
            }
        }
    },
    methods: {
        setDate () {
            this.$emit('update:def', moment(this.value, "DD-MM-YYYY").format("YYYY-MM-DD"));
            this.$emit('change');
        },
        getMonth (m) {
            return tr(monthsLong[m-1]);
        },
        change () {
            if (this.year && this.month && this.day) {
                this.value = this.year + '-' + this.month + '-' + this.day;
                this.$emit('update:def', this.value);
                this.$emit('change');
            }
            if (this.day) {
                let div = $('#d-' + this.getId);
                if (div[0]) div[0].classList.remove('is-invalid');
            }
            if (this.month) {
                let div = $('#m-' + this.getId);
                if (div[0]) div[0].classList.remove('is-invalid');
            }
            if (this.year) {
                let div = $('#y-' + this.getId);
                if (div[0]) div[0].classList.remove('is-invalid');
            }
        },
        setValue () {
            this.value = moment(this.def, "YYYY-MM-DD").format("DD/MM/YYYY");
            this.year = parseInt(moment(this.def, "YYYY-MM-DD").format("YYYY"));
            this.month = moment(this.def, "YYYY-MM-DD").format("MM");
            this.day = moment(this.def, "YYYY-MM-DD").format("DD");
        },
    },
}
</script>
