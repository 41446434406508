<template>
    <div id="voucher" class="card no-border-top pb-4">
        <div class="container" v-if="recordStore" id="voucher">
            <div v-for="(supplierData, sId) of getSuppliersAndHotels">
                <voucher
                    :supplierData="supplierData"
                    :sId="sId"
                    :edit="edit"
                ></voucher>
                <p v-if="getPageBreak(sId+1)" style="page-break-after: always;">&nbsp;</p>
                <p v-if="getPageBreak(sId+1)" style="page-break-before: always;">&nbsp;</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import '@/css/voucher.scss';
import '@/extra/custom/css/voucher.scss';
const voucher = importVueComp('components/voucher', 'Voucher');
export default {
    name: 'vouchers-preview',
    props: ['edit'],
    components: {
        'voucher': voucher,
    },
    data () {
        return {
            image: 'img',
            editing: null,
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        getHotels  () {
            let res = {};
            for (let day of this.recordStore.BookingDays) {
                for (let hotel of day.BookingDayHotels) {
                    for (let room of hotel.BookingDayRooms) {
                        if (!room.HotelId) continue;
                        if (!room.Hotel) continue;
                        if (!room.Hotel.id) continue;
                        if (!room.roomQuoteSelected && this.anyQuoteSelected) continue;
                        if (room.Status == 'CANCELED') continue;
                        let supplier = room.Hotel.Supplier;
                        if (room.SupplierId && room.Supplier && room.SupplierId!=room.Hotel.Supplier.id) {
                            supplier = room.Supplier;
                        }
                        if (!supplier) continue;
                        if (!res[supplier.id]) {
                            res[supplier.id] = {Rooms: [], Supplier: supplier};
                        }
                        let roomData = _.cloneDeep(room);
                        roomData.DayNr = day.DayNr;
                        roomData.TransDate = day.TransDate;
                        roomData.nights = hotel.Days;
                        res[supplier.id].Rooms.push(roomData);
                    }
                }
            }
            return res;
        },
        anyQuoteSelected () {
            return this.recordStore.anyQuoteSelected;
        },
        getSuppliers () {
            let res = {}
            if (this.recordStore && this.recordStore.BookingDays) {
                for (let day of this.recordStore.BookingDays) {
                    for (let service of day.BookingDayServices) {
                        if (service.VoucherDisabled) continue;
                        if (service.Service && service.Service.VoucherDisabled) continue;
                        if (service.Optional) continue;
                        if (service.Status == 'CANCELED') continue;
                        if (service.Supplier && service.Supplier.id) {
                            if (!service.Name && (!service.Service || !service.Service.Name)) continue;
                            let key = this.getVoucherKey(service);
                            //let comments = tr('PARK ENTRANCES NOT INCLUDED');
                            let comments = '';
                            if (key in this.recordStore.VoucherComments) {
                                comments = this.recordStore.VoucherComments[key];
                            }
                            if (!res[key]) {
                                res[key] = {Supplier: service.Supplier, Services: [], Comments: comments, key};
                            }
                            res[key].Services.push(service);
                            res[key].ServiceDate = this.getDateIn(res[key].Services, 'YYYY-MM-DD')
                            res[key].Pickup = service.PickupInfo;
                            res[key].DateIn = this.getDateIn(res[key].Services, 'DD/MM')
                            res[key].DateOut = this.getDateOut(res[key].Services)
                            if (day.EntranceIncluded && !this.recordStore.VoucherComments[key]) {
                                //res[key].Comments = tr('PARK ENTRANCES INCLUDED');
                            }
                        }
                    }
                }
            }
            return res;
        },

        getHotelsRooms () {
            let res = {};
            for (let hotelId in this.getHotels) {
                for (let room of this.getHotels[hotelId].Rooms) {
                    if (room.Status == 'CANCELED') continue;
                    let key = [room.DayNr, room.Hotel.id].join(',');
                    if (!res[key]) {
                        res[key] = {Supplier: room.Hotel, Rooms: [], TransDate: room.TransDate, days: room.nights, key};
                        res[key].ServiceDate = room.TransDate;
                        res[key].DateIn = moment(room.TransDate).format('DD/MM');
                        res[key].DateOut = moment(room.TransDate).add(room.nights, 'days').format('DD/MM');
                        let comments = '';
                        if (key in this.recordStore.VoucherComments) {
                            comments = this.recordStore.VoucherComments[key];
                        }
                        res[key].Comments = comments;
                    }
                    res[key].Rooms.push(room);
                }
            }
            for (let key in res) {
                let hotel = res[key];
                let t = hotel.days + ' ' + tr('NIGHTS ACCOMMODATION IN') + ' ';
                let rooms = [];
                for (let room of hotel.Rooms) {
                    let textRoom = [];
                    textRoom.push(room.Rooms);
                    if (!room.Room) continue;
                    if (!room.Room.Checkin) textRoom.push(tr('ROOM'));
                    if (!room.VoucherText) {
                        textRoom.push(room.Room.Name);
                        let roomType = _.find(room.Hotel.RoomCategories, (c) => c.id == room.RoomCategory);
                        if (roomType && !room.Room.Checkin) {
                            let t = roomType.Name;
                            if (roomType.Comment) {
                                t+= ` ${roomType.Comment}`;
                            }
                            textRoom.push(t);
                        }
                        if (hotel.Supplier.Checkin) textRoom.push(`, check-in ${hotel.Supplier.Checkin}`);
                        if (hotel.Supplier.Checkout) textRoom.push(`, check-out ${hotel.Supplier.Checkout}`);
                        rooms.push(textRoom.join(' '));
                    } else {
                        rooms.push(room.VoucherText);
                    }
                }
                t += rooms.join('; ');
                res[key].RoomsText = rooms;
                res[key].VoucherText = t;

            }
            return res;
        },

        getSuppliersAndHotels () {
            let res = {}
            if (!this.recordStore) return res;
            let i = 1;
            for (let s in this.getSuppliers) {
                res[s] = this.getSuppliers[s];
                res[s].id = i;
                i += 1;
            }
            for (let h in this.getHotelsRooms) {
                let key = 'day' + h;
                res[key] = this.getHotelsRooms[h];
                res[key].id = i;
                i += 1;
            }
            let rows = [];
            for (let key in res) {
                rows.push(res[key]);
            }
            rows.sort(function(a, b) {
                if (a.ServiceDate > b.ServiceDate) return 1;
                if (a.ServiceDate < b.ServiceDate) return -1;
                return 0;
            })
            return rows;
        },
    },
    methods: {
        getVoucherKey (s) {
            return s.Supplier.id;
        },
        getDateIn (services, format) {
            let dateIn;
            for (let s of services) {
                if (!dateIn || (dateIn && s.ServiceDate<dateIn)) dateIn = s.ServiceDate;
            }
            return moment(dateIn).format(format);
        },
        getDateOut (services) {
            let dateOut;
            for (let s of services) {
                if (!dateOut || (dateOut && s.ServiceDate>dateOut)) dateOut = s.ServiceDate;
            }
            return moment(dateOut).format('DD/MM');;

        },
        getPageBreak (i) {
            if (i==Object.keys(this.getSuppliersAndHotels).length) return false;
            if (i % 3 == 0) return true;
            return false;
        },
        evenListener (e) {
            if (e.keyCode == 13) {
                this.editing = null;
            }
            if (e.keyCode == 9) {
                this.editing = null;
            }
            if (e.keyCode == 27) {
                this.editing = null;
            }
        },
    },
    beforeDestroy () {
        document.removeEventListener('keydown', this.evenListener);
    },
    created () {
        setTimeout(()=> document.addEventListener("keydown", this.evenListener), 1000);
    },
}
</script>
