<template>
    <div class="form-row" v-if="hotel">
        <div class="col-md-12">
            <br>
            <div class="form-row d-flex justify-content-center mb-2">
                <label class="text-uppercase"><b>{{hotel.Supplier.Name}}</b></label>
            </div>
            <div class="form-row col-md-12 border-bottom">
                <div class="col-md-6 section-title">
                    <label>{{tr('Rooms')}}</label>
                </div>
                <div class="col-md-2 text-right">
                    <label>{{tr('Cost')}}</label>
                </div>
                <div class="col-md-2">
                    <label class="text-right">{{tr('Status')}}</label>
                </div>
                <div class="col-md-1">
                    <label class="text-right">{{tr('Reservation')}}</label>
                </div>
            </div>
            <div v-for="(room, idx) of hotel.Rooms" v-if="room.quoteIncluded">
                <div class="form-row col-md-12 form-material border-bottom" :class="getBGClass(idx)"
                    v-if="totalsByHotelByRoom[room.id]">
                    <div class="col-md-6">
                        <label>{{formatDate(room.TransDate)}}</label>
                        <label><span v-if="!room.Room.Checkin" class="ml-2">{{room.Rooms}}</span>
                        {{room.Room.Name}} {{getRoomCategoryName(room.Hotel, room.RoomCategory)}}
                        <span v-if="!room.Room.Checkin">- {{tr('Nights')}}: {{room.nights}} </span>
                        - {{room.Hotel.Name}}
                        </label>
                    </div>
                    <div class="col-md-2 text-right">
                        <label v-if="totalsByHotelByRoom[room.id]">
                            {{totalsByHotelByRoom[room.id].Cost | formatNumber({c: recordStore.Currency}) }} {{recordStore.CurrencyId}}
                        </label>
                    </div>
                    <div class="col-md-2">
                        <select-input fieldName="Status"
                            :field="room.fieldsObject.Status" label=""
                            class="my-0"
                            :disabled="!canEdit"
                            :currentValue.sync="room.Status" @change="updateStatusRoom" :id="room.id"
                        ></select-input>
                    </div>
                    <div class="col-md-2 form-group my-0 form-default ">
                        <input v-model="room.ReservationNumber" class="form-control" type="text"
                            :readonly="!canEdit"
                            :class="{'fill': room.ReservationNumber}"
                             @change="updateReservationNumber(room)" maxlength="50">
                                <span class="form-bar"></span>
                        </input>
                    </div>
                </div>
            </div>
            <div class="form-row col-md-12 font-weight-bold" v-if="hotel.Rooms.length>0">
                <div class="col-md-6">
                    <label>{{tr('Total')}}</label>
                </div>
                <div class="col-md-2 text-right">
                    <label>{{getTotalByHotel | formatNumber({c: recordStore.Currency})}} {{recordStore.CurrencyId}}</label>
                </div>
                <div class="col-md-2 form-material">
                    <select-input fieldName="Status"
                    :field="hotel.Rooms[0].fieldsObject.Status" label=""
                    :currentValue.sync="serviceStatus" @change="setStatus($event, hotel.Rooms)"></select-input>
                </div>
            </div>
        </div>
        <div class="form-row col-md-12 mt-4">
            <div class="section-title ml-4">
                <label class="my-0">{{tr('Request Rooms')}}</label>
            </div>
            <div class="col-1 offset-1">
                <button type="button" class="btn btn-primary action-button my-0" @click="sendHotelEmail()"
                    rel="tooltip" data-placement="top" title="Email" :disabled="!canEdit">
                    <font-awesome-icon icon="envelope"/>
                </button>
            </div>
            <div class="col-1 offset-1">
                <input v-model="includeFlights"  class="form-check-input" type="checkbox" name="includeFlights">
                <label class="smaller form-check-label">{{tr('Flights')}}</label>
            </div>
        </div>
        <div class="col-md-12">
            <booking-payments
                :supplierId="hotel.Supplier.id"
                :supplierData="hotel"
            ></booking-payments>
        </div>
        <select-value
            v-if="emailsOptions"
            @save="sendHotelEmail"
            :show.sync="emailsOptions"
            text="Select Email"
            :fieldOptions="emailsOptions"
        ></select-value>
        <booking-operations-notes :supplier.sync="hotel">
        </booking-operations-notes>
        <attach
          title="Documents"
          :dataList.sync="recordStore.attachList"
          :attached.sync="recordStore.attached"
          :id="recordStore.id"
          :folder="'supplier/' + hotel.Supplier.id + '/'"
          :processing="recordStore.processingAttach"
          :canAttach="true"
          :canDelete="true"
          v-if="recordStore.attached"
        ></attach>


        <slot name="custom"></slot>
    </div>
</template>

<script>
const bookingPayments = importVueComp('components/booking', 'BookingPayments');
const bookingOperationsNotes = importVueComp('components/booking', 'BookingOperationsNotes');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'operation-hotel',
    components: {
        'booking-payments': bookingPayments,
        'booking-operations-notes': bookingOperationsNotes,
    },
    props: ['hotel'],
    data () {
        return {
            loaded: false,
            includeFlights: null,
            serviceStatus: null,
            emailsOptions: null,
        }
    },
    watch: {
        hotel () {
            this.serviceStatus = null;
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            bookingForm: state => state.booking.bookingForm,
        }),
        canEdit () {
            return true;
        },
        totalsByHotelByRoom () {
            let res = {};
            for (let room of this.hotel.Rooms) {
                for (let roomId in this.recordStore.totalsByHotelByRoom[room.Hotel.id]) {
                    res[roomId] = this.recordStore.totalsByHotelByRoom[room.Hotel.id][roomId];
                }
            }
            return res;
        },
        roomsByDateIn () {
            let res = {};
            for (let room of this.hotel.Rooms) {
                if (!res[room.TransDate]) res[room.TransDate] = [];
                res[room.TransDate].push(room);
            }
            return res;
        },
        getTotalByHotel () {
            let res = 0;
            for (let roomId in this.totalsByHotelByRoom) {
                if (this.totalsByHotelByRoom[roomId].Status == 'CANCELED') continue;
                if (!this.totalsByHotelByRoom[roomId]) continue;
                res += this.totalsByHotelByRoom[roomId].Cost;
            }
            return res;
        },
    },
    methods: {
        sendHotelEmail (value) {
            if (!this.bookingForm) {
                alert(tr('There is not reservation form'));
                return;
            }
            let t = 1;
            let emails = [];
            let email;
            for (let room of this.hotel.Rooms) {
                if (!room.Hotel) continue;
                if (emails.indexOf(room.Hotel.Email)==-1) {
                    emails.push(room.Hotel.Email);
                }
            }
            if (this.hotel.Supplier && this.hotel.Supplier.Email) {
                if (emails.indexOf(this.hotel.Supplier.Email)==-1) {
                    emails.push(this.hotel.Supplier.Email);
                }
            }
            if (emails.length>1 && !value) {
                this.emailsOptions = _.map(emails, (r) => {
                    return {label: r, value: r};
                })
                return;
            } else if (emails.length>1 && value) {
                email = value;
            } else if (emails.length==1) {
                email = emails[0];
            }
            if (value) {
                t = 1000;
            }
            setTimeout(async () => {
                let mailRecord = await this.recordStore.createSupplierHotelMail(this.bookingForm, this.user, email, this.hotel,
                    this.includeFlights, this.roomsByDateIn);
                this.$root.$children[0].openModalRecord({modalRecord: mailRecord, modalTable: 'mail', modalId: 'new'});
            }, t);
        },
        async setStatus (value, rooms) {
            await this.$nextTick();
            this.recordStore.setHotelStatus(value, this.hotel);
        },
        formatDate (d) {
            return moment(d, "YYYY-MM-DD").format("DD/MM/YYYY");
        },

        getRoomCategoryName (hotel, roomId) {
            for (let category of hotel.RoomCategories) {
                if (category.id == roomId) return category.Name;
            }
            return '';
        },
        async updateStatusRoom (value, id) {
            await this.$nextTick();
            for (let day of this.recordStore.BookingDays) {
                for (let h of day.BookingDayHotels) {
                    for (let room of h.BookingDayRooms) {
                        if (room.id==id) {
                            room.Status = value;
                        }
                    }
                }
            }
        },
        async updateReservationNumber (r) {
            await this.$nextTick();
            for (let day of this.recordStore.BookingDays) {
                for (let h of day.BookingDayHotels) {
                    for (let room of h.BookingDayRooms) {
                        if (room.id==r.id) {
                            room.ReservationNumber = r.ReservationNumber;
                        }
                    }
                }
            }
        },
        getBGClass (idx) {
            if (idx % 2 == 0) return 'bg-line';
            return '';
        }
    },
}
</script>
