import main from '@/store/main';
import quill from '@/store/quill';
import booking from '@/store/booking';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        main: main,
        quill: quill,
        booking: booking,
    }
})

export default store;
