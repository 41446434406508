<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-dark fixed-top py-0">
            <div class="toggle-sidebar">
                <font-awesome-icon icon="bars" @click="toggle" class="mr-3"/>
                <span></span>
            </div>
            <img src="@/img/LOGO_NUBBO.png" width="150" class="cursor-pointer" @click="setPath({path: '/'})">
            <div class="collapse navbar-collapse ml-3" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown no-arrow">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarRecent" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline">
                                {{tr('Recent Entries')}}
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="navbarRecent" style="width: max-content">
                            <a class="dropdown-item cursor-pointer"
                                v-for="r of history"
                                @click="setPath(r)">{{routeName(r)}}</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown no-arrow">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarMarks" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{tr('Bookmarks')}}
                        </a>
                        <div class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="navbarMarks" style="width: max-content">
                            <a class="dropdown-item cursor-pointer"
                                v-for="r of marks"
                                @click="setPath(r)">{{routeName(r)}}</a>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto text-white" v-if="testing">
                        {{tr('TESTING ENVIROMENT')}}
                </ul>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown no-arrow" >
                        <search-box class="mt-2" v-model="search" @focusOn="focusOn"></search-box>
                        <div class="dropdown-menu dropdown-menu-right show" aria-labelledby="navbarSearch" v-if="searchList.length || paxList.length">
                            <a v-for="(n, idx) in searchList" class="dropdown-item" @click="setRoute(n)">
                              <b>{{tr(n.label)}}</b>
                            </a>
                            <a v-for="(n, idx) in paxList" class="dropdown-item" @click="setRoute(n)">
                              <b>{{tr(n.label)}}</b>
                            </a>
                        </div>
                    </li>
                    <li class="nav-item" @click="speedAlert" v-if="speed && speed < 30">
                        <a class="nav-link cursor-pointer" data-toggle="tooltip" data-placement="top" :title="'Download speed is ' + (speed).toFixed(0) + ' mbps'">
                            <svg-speed class="service-actions" :class="{'pulsating': speed < 5, 'bg-red': speed < 10, 'bg-yellow': speed < 30}">
                            </svg-speed>
                        </a>
                    </li>
                    <li class="nav-item" @click="memoryAlert" v-if="memoryLevel">
                        <a class="nav-link cursor-pointer" data-toggle="tooltip" data-placement="top" :title="'Memory usage: ' + (jsLevel * 100).toFixed(0) + '%'">
                            <font-awesome-icon icon="exclamation-triangle"
                                :class="{'pulsating': memoryLevel == 3, 'bg-red': memoryLevel == 2, 'bg-yellow': memoryLevel == 1}"/>
                        </a>
                    </li>
                    <li class="nav-item" @click="addToMarks" v-if="!isMarked">
                        <a class="nav-link cursor-pointer">
                            <font-awesome-icon :icon="['far', 'star']"/>
                        </a>
                    </li>
                    <li class="nav-item cursor-pointer" @click="removeToMarks" v-else>
                        <a class="nav-link cursor-pointer">
                            <font-awesome-icon :icon="['fas', 'star']"/>
                        </a>
                    </li>
                    <li class="nav-item dropdown cursor-pointer">
                        <a class="nav-link" href="#" id="navbarNotifications" role="button" @click="updateNotificationStatus"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <font-awesome-icon icon="bell"/>
                            <span v-if="newNotifications>0" class="badge badge-notify blink">{{newNotifications}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right py-0" aria-labelledby="navbarNotifications">
                            <div class="dropdown-item d-flex justify-content-between pl-3 pr-2 align-items-baseline">
                                <b>{{tr('Notifications')}}</b>
                                <font-awesome-icon icon="trash-alt" size="sm" class="ml-2" 
                                    v-if="notifications.length > 0" @click="removeAll($event)"/>
                            </div>
                            <hr class="my-0">
                            <a v-for="(n, idx) in notifications" class="dropdown-item d-flex justify-content-between pl-3 pr-2 align-items-baseline">
                                <b v-if="n.status != 'READ'"  @click="readNotification(idx, n)">{{tr(n.comment)}}</b>
                                <span v-else @click="readNotification(idx, n)">{{tr(n.comment)}}</span>
                                <font-awesome-icon icon="trash-alt" size="sm" class="ml-3" @click="removeNotification($event, idx, n)"/>
                            </a>
                            <div class="dropdown-item pl-3" v-if="notifications.length==0">{{tr('No new Notifications')}}</div>
                        </div>
                    </li>
                    <li class="nav-item dropdown no-arrow">
                        <a v-if="user" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline"> {{ user.UserName }} </span>
                            <img class="img-profile rounded-circle" :src="user.Image.url" v-if="user.ImageId">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a class="dropdown-item cursor-pointer" @click="showUserProfile">
                                <font-awesome-icon icon="user" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Profile')}}
                            </a>
                            <a class="dropdown-item cursor-pointer" @click="showSelectLanguage">
                                <font-awesome-icon icon="language" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Language')}}
                            </a>
                            <a class="dropdown-item cursor-pointer" @click="showSelectActivitiesView">
                                <font-awesome-icon icon="hiking" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Activities View')}}
                            </a>
                            <menu-item
                                class="dropdown-item"
                                icon="key"
                                iconClass="fa-sm fa-fw mr-2 text-gray-400"
                                v-if="user"
                                endpoint="change-password"
                                label="Change Password"
                            ></menu-item>
                            <a class="dropdown-item cursor-pointer" @click="sendLogs">
                                <font-awesome-icon icon="headset" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Support')}}
                            </a>
                            <!--a class="dropdown-item cursor-pointer" @click="gmailAuthorize">
                                <font-awesome-icon icon="hiking" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Gmail Authorize')}}
                            </a>
                            <a class="dropdown-item cursor-pointer" @click="clearGmail">
                                <font-awesome-icon icon="hiking" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Gmail Clear')}}
                            </a>
                            <a class="dropdown-item cursor-pointer" @click="testMails">
                                <font-awesome-icon icon="envelope" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Test Mails')}}
                            </a-->
                            <!--a class="dropdown-item cursor-pointer" @click="openChangeLog">
                                <svg-change-log class="service-actions"></svg-change-log>
                                {{tr('Change Log')}}
                            </a-->
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item cursor-pointer" @click="logout">
                                <font-awesome-icon icon="sign-out-alt" class="fa-sm fa-fw mr-2 text-gray-400"/>
                                {{tr('Sign out')}}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <app-news-modal
            v-if="showAppNews"
            :showAppNews.sync="showAppNews"
            :news="news"
        ></app-news-modal>
        <notification-modal
            v-if="notificationText"
            :title="notificationTitle"
            :text.sync="notificationText"
        ></notification-modal>
        <select-value
            v-if="selectLanguage"
            @save="setLanguage"
            :show.sync="selectLanguage"
            text="Select Language"
            :fieldOptions="languages"
            :defValue="defLanguage"
        ></select-value>
        <select-value
            v-if="selectActivitiesView"
            @save="setActivitiesView"
            :show.sync="selectActivitiesView"
            text="Select Activities View"
            :fieldOptions="activitiesOptions"
            :defValue="defActivitiesView"
        ></select-value>
        <support
            v-if="showSendLogs"
            :show.sync="showSendLogs"
            @memoryUsage="memoryAlert"
        ></support>
        <!--change-log ref="changeLog"></change-log-->
    </div>
</template>

<script>
import '@/css/app.scss';
const menuItem  = importVueComp('components/tools', 'VueMenuItem');
const support  = importVueComp('components/tools', 'Support');
import { mapState } from "vuex";
import appNewsModal from '@/components/tools/AppNewsModal';
import notificationModal from '@/components/tools/NotificationModal';
var EventBus = require('@/tools/event-bus').default;
import svgSpeed from '@/components/svg/Speed';
const modules = vueTools.importModule('modules', 'tools').default;
//import svgChangeLog from '@/components/svg/SvgChangelog';
//const changeLog  = importVueComp('components/changelog', 'ChangeLog');
import commitFront from '@/commit.txt';
const testing = process.env.TESTING;
import recordHistory from '@/components/svg/RecordHistory';

export default {
    name: 'nav-bar',
    components: {
        'menu-item': menuItem,
        'app-news-modal': appNewsModal,
        'support': support,
        'notification-modal': notificationModal,
        //'change-log': changeLog,
        //'svg-change-log': svgChangeLog,
        'record-history': recordHistory,
        'svg-speed': svgSpeed,
    },
    data () {
        return {
            history: [],
            commitFront: commitFront,
            testing: testing,
            marks: [],
            selectLanguage: false,
            selectActivitiesView: false,
            languages: [{label: 'es', value: 'es'}, {label: 'en', value: 'en'}],
            activitiesOptions: [
                {label: 'Expanded', value: 'EXPANDED'},
                {label: 'Compress', value: 'COMPRESS'},
            ],
            defLanguage: null,
            defActivitiesView: null,
            isMarked: false,
            notifications: [],
            search: '',
            searchList: [],
            paxList: [],
            showAppNews: false,
            news: [],
            apiNews: null,
            newsTitle: null,
            newNotifications: 0,
            showSendLogs: false,
            notificationText: null,
            notificationTitle: null,
            updated: true,
            intervalCommit: null,
            performanceInterval: null,
            notificationsInterval: null,
            speedInterval: null,
            jsLimit: null,
            jsTotal: null,
            jsUsed: null,
            speed: null,
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record
        }),
        jsLevel () {
            if (this.jsUsed && this.jsLimit) {
                return this.jsUsed / this.jsLimit;
            }
        },
        memoryLevel () {
            if (this.jsLevel) {
                if (this.jsLevel > 0.9) {
                    return 3;
                } else if (this.jsLevel > 0.8 ) {
                    return 2;
                } else if (this.jsLevel > 0.7 ) {
                    return 1;
                }
            }
        }
    },
    async mounted () {
        EventBus.$on('check-marked', this.checkMarked);
        EventBus.$on('update-history', this.getHistoryRoutes);
        EventBus.$on('update-profile', this.updateUserProfile);
        this.getHistoryRoutes();
        this.getMarksRoutes();
        /*for (let langCode in sl) {
            this.languages.push({value: langCode, label: langCode});
        }*/
        this.defLanguage = localStorage.getItem('user-language');
        this.defActivitiesView = localStorage.getItem('activities-view');
        this.checkMarked();
        this.getNotifications();
        this.checkNotifications();
        //this.checkSpeed()
        this.appNews();
        this.sendCommit();
        this.intervalCommit = setInterval(() => {
            if (this.updated) {
                this.sendCommit()
            }
        },1000*600);
        this.intervalNotifications = setInterval(() => {
            this.getNotifications();
        },1000*300);
        if (process.env.CHECK_MEMORY) {
            if (performance.memory) {
            this.checkMemory();
            this.performanceInterval = setInterval(() => {
                this.checkMemory();
            }, 60 * 1000); // cada 60 segundos
            } else {
                console.log('La API performance.memory no está disponible en este navegador.');
            }
        }
    },
    methods: {
        checkMemory () {
            const memoryInfo = performance.memory;
            this.jsLimit = memoryInfo.jsHeapSizeLimit / 1048576
            this.jsTotal = memoryInfo.totalJSHeapSize / 1048576
            this.jsUsed = memoryInfo.usedJSHeapSize / 1048576
        },
        memoryAlert () {
            //this.$root.$children[0].showAlert = `Memory use ${this.jsLevel}%. Save changes and reload Nubbo`;
            if (this.memoryLevel == 3) {
                api.showAlert = {text: `Memory use ${(this.jsLevel * 100).toFixed(0)}%. Save changes and reload Nubbo`, level: 1};
            } else {
                api.showAlert = {text: `Memory use ${(this.jsLevel * 100).toFixed(0)}%`, level: 1};
            }
        },
        speedAlert () {
            alert(`Download speed is ${this.speed.toFixed(0)} mbps`);
        },
        sendLogs () {
            this.showSendLogs = true;
        },
        openChangeLog () {
            //this.$refs.changeLog.open()
        },
        async sendCommit () {
            try {
                let commitText = commitFront.split('\n')[0].replace(/\t/g, "")
                let commitList = _.filter(commitText.split(' '),(r) => {
                    return r && r.length > 0;
                })
                let Code = commitList[1]
                if (Code.length < 7) return;
                let commitList2 = commitText.split(Code)
                let CommitDate = moment.utc(commitList2[1].replace(/ /g, '')).format('YYYY-MM-DDTHH:mm:ss')
                var err = false;
                let res = await api.post('/api/save_commit/',JSON.stringify({Code, CommitDate}), null, () => {
                    err = true;
                });
                if (!res && !err) {
                    this.$root.$children[0].appWarning = tr("There is a new update, please save changes and reload the site");
                    this.updated = false;
                }
            } catch(err) {
                console.log(err)
            }
        },
        appNews () {
            let storedNews = localStorage.getItem('app-news');
            if (storedNews) {
                let r = JSON.parse(storedNews);
                for (let key in r) {
                    if (!r[key] && this.apiNews[key]) {
                        for (let n of this.apiNews[key]) {
                            this.news.push(n);
                        }
                        r[key] = true;
                    }
                }
                localStorage.setItem('app-news', JSON.stringify(r));
                if (this.news.length>0) {
                    this.showAppNews = true;
                }
            }
        },
        logout () {
            this.$router.push("/logout");
        },
        async getNotifications () {
            localStorage.setItem('notifications', JSON.stringify([]))
            let res = await api.get('/api/notification/by_user/');
            if (res) {
                for (let n of res) {
                    if (n.sttus == 'REMOVED') continue;
                    tools.addNotification({
                        id: n.id,
                        msg: n.Description,
                        comment: n.Title,
                        action: n.Actions,
                        status: n.Status,
                    }, false)
                }
            }
        },
        async showUserProfile () {
            let syncVersion = await api.get('/api/get_sync_version/user/' + this.user.id);
            if (syncVersion && syncVersion!=this.user.syncVersion) {
                let user = await api.getUser();
                if (user) {
                    this.$store.commit('setUser', user);
                }
            }
            this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'user', view: 'userprofile', modalId: this.user.id, closeAction: 'update-profile'});
        },
        showSelectLanguage () {
            this.selectLanguage = true;
        },
        showSelectActivitiesView () {
            this.selectActivitiesView = true;
        },
        setLanguage (value) {
            localStorage.setItem('user-language', value);
            this.selectLanguage = false;
        },
        setActivitiesView (value) {
            localStorage.setItem('activities-view', value);
            this.selectActivitiesView = false;
        },
        getHistoryRoutes () {
            let routerHistory = localStorage.getItem('router-history');
            if (routerHistory) {
                this.history = [];
                routerHistory = JSON.parse(routerHistory);
                for (let i=routerHistory.length-1; i>0; i= i - 1) {
                    let p = routerHistory[i].path.split('/');
                    if (!parseInt(p[p.length-1])) continue;
                    this.history.push(routerHistory[i]);
                }
            }
        },
        setPath (r) {
            localStorage.setItem('reportFilters', '');
            if (r.endpoint) {
                if (this.$router.currentRoute.name == r.endpoint) return;
                this.$router.push({name: r.endpoint});
            } else if (r.path) {
                if (this.$router.currentRoute.path == r.path) return;
                this.$router.push({path: r.path});
            }
        },
        routeName (r) {
            let l = r.path.split('/');
            if (l.length==3 && l[1]=='abm') {
                return tr(l[2]);
            }
            let name = l[l.length-1];
            if (r.name) name = r.name;
            let prev = l[l.length-2];
            if (prev == 'report') {
                let route;
                for (let i in modules) {
                    let m = modules[i];
                    for (let e of m.endpoints) {
                        if (e.report == name) {
                            route = e;
                        }
                    }
                };
                if (route && route.label) return tr(l[l.length-2]) + ': ' + tr(route.label);
            }
            return tr(l[l.length-2]) + ': ' + tr(name);
        },
        addToMarks () {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                routerMarks = JSON.parse(routerMarks);
            } else {
                routerMarks = [];
            }
            let name = '';
            if (this.recordStore) name = this.recordStore.getName;
            routerMarks.push({path: this.$router.currentRoute.path, name: name})
            localStorage.setItem('router-marks', JSON.stringify(routerMarks));
            this.isMarked = true;
            this.getMarksRoutes();
        },
        checkMarked (path) {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                routerMarks = JSON.parse(routerMarks);
                let p = path;
                if (!p) p = this.$router.currentRoute.path;
                let f = _.find(routerMarks, (r) => r.path == p);
                 if (f) {
                    this.isMarked = true;
                } else {
                    this.isMarked = false;
                }
            }
        },
        removeToMarks () {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                routerMarks = JSON.parse(routerMarks);
                let f = _.findIndex(routerMarks, (r) => r.path == this.$router.currentRoute.path);
                if (f>-1) {
                    routerMarks.splice(f, 1);
                    localStorage.setItem('router-marks', JSON.stringify(routerMarks));
                    this.isMarked = false;
                    this.getMarksRoutes();
                }
            }
        },
        getMarksRoutes () {
            let routerMarks = localStorage.getItem('router-marks');
            if (routerMarks) {
                this.marks = JSON.parse(routerMarks);
            }
        },
        toggle () {
            $('#sidebar').toggleClass('active');
        },
        checkNotifications () {
            this.notificationsInterval = setInterval(() => {
                let notifications = localStorage.getItem('notifications');
                if (notifications) {
                    this.notifications = JSON.parse(notifications);
                    let c = 0;
                    for (let n of this.notifications) {
                        if (n.status == 'NEW') c += 1
                    }
                    this.newNotifications = c;
                }
            }, 1000);
        },
        checkSpeed () {
            var speeds = [];
            this.speedInterval = setInterval(() => {
                api.testDownloadSpeed('testfile100k', (value) => {
                    speeds.push(value);
                    if (speeds.length > 5) {
                        speeds.splice(0, 1);
                        let sum = speeds.reduce((a, v) => {return a + v;}, 0);
                        let avg = sum / speeds.length;
                        this.speed = avg;
                    }
                }, (error) => {
                    console.log(error)
                })
                

            }, 1000 * 5)
        },
        readNotification (i, n) {
            //this.notifications.splice(i, 1)
            this.notifications[i].status = 'READ';
            localStorage.setItem('notifications', JSON.stringify(this.notifications));
            if (n.id) {
                api.post('/api/notification/set_read/' + n.id);
                if (n.action && n.action.show) {
                    let endpoint = `/abm/${n.action.table}/${n.action.id}`;
                    this.$router.push({ path: endpoint })
                }
                if (n.action && n.action.text) {
                    this.notificationText = n.action.text;
                    this.notificationTitle = n.comment;
                }
            }
        },
        removeNotification (event, i, n) {
            event.stopPropagation();
            this.notifications[i].status = 'REMOVED';
            this.notifications.splice(i, 1)
            localStorage.setItem('notifications', JSON.stringify(this.notifications));
            if (n.id) {
                api.post('/api/notification/remove/' + n.id);
            }
        },
        removeAll (event) {
            event.stopPropagation();
            for (let i in this.notifications) {
                let n = this.notifications[i];
                if (n.id) {
                    api.post('/api/notification/remove/' + n.id);
                }
            }
            this.notifications = [];    
            localStorage.setItem('notifications', JSON.stringify(this.notifications));
        },
        filterSearch (data) {
            let values = this.search.split(' ')
            for (let value of values){
                let re = new RegExp(tools.normalize(value), 'i')
                let m = tools.normalize(data).match(re)
                if (!m) return false;
            };
            return true;
        },
        setRoute (r) {
            //if (this.$router.currentRoute.name == r.endpoint) return;
            if (r.path) {
                if (this.$router.currentRoute.path == r.path) return;
                this.$router.push({path: r.path});
            } else {
                vueTools.redirect(this.$router, r.endpoint, r.tableName, r.report, r.module, r.componentName)
            }
            //this.search = '';
            this.searchList = [];
            this.paxList = [];
        },
        async searchPax () {
            this.paxList = [];
            let res = await api.get('/api/booking/get_file_by_pax/' + this.search);
            if (res) {
              this.paxList = [];
              for (let row of res) {
                  let path = `/abm/booking/${row.id}`;
                  if (row.Status != 'QUOTE') path = `/abm/sales/${row.id}`;
                  this.paxList.push({
                      tableName: 'booking',
                      label: `File ${row.id}: ${tr(row.Status)} ${row.PaxName}`,
                      path: path,
                      endpoint: 'abm'
                  })
              }
            }
        },
        updateNotificationStatus () {
            let notifications = localStorage.getItem('notifications');
            if (notifications) {
                notifications = JSON.parse(notifications);
                for (let n of notifications) {
                    if (!n.id) continue;
                    if (n.status == 'READ') continue;
                    if (n.status == 'REMOVED') continue;
                    n.status = 'VIEWED';
                    api.post('/api/notification/set_view/' + n.id);
                }
                localStorage.setItem('notifications', JSON.stringify(notifications));
            }
        },
        updateUserProfile () {
            this.$store.commit('setUser', api.currentUser);
        },
        setSearch () {
            if (this.search.length < 3) {
                this.searchList = [];
                this.paxList = [];
                return;
            }
            let res = [];
            for (let name in modules) {
                let module = modules[name];
                let access = tools.canAccessModule(this.user, module);
                if (!access) continue;
                for (let endpoint of module.endpoints) {
                    let access = frontTools.routeAccess(endpoint);
                    let userAccess = tools.canAccess(this.user, "view", "GET", `/${access}`);
                    if (!userAccess) continue;
                    let label = endpoint;
                    if (endpoint.label) label = endpoint.label;
                    if (this.filterSearch(tr(label))) {
                        if (!endpoint.label) {
                            endpoint = {
                                tableName: endpoint,
                                label: endpoint,
                                endpoint: 'abm',
                            }
                        } else {
                            endpoint = {
                                endpoint: endpoint.endpoint,
                                label: endpoint.label,
                                table: endpoint.table,
                                report: endpoint.report,
                                componentName: endpoint.componentName,
                            }
                        }
                        let f = _.find(res, (r) => {
                            return label == r.label;
                        })
                        if (!f) {
                            res.push(endpoint);
                        }
                    }
                }
            }
            this.searchList = res;
            this.searchPax();
        },
        focusOn () {
            this.setSearch()
        },
        async gmailAuthorize () {
            let res = await api.get('/api/gmail_user/authorize');
            console.log(res);
        },
        async clearGmail () {
            let res = await api.get('/api/gmail_user/clear');
            console.log(res);
        },
        async testMails () {
            let res = await api.get('/api/gmail_user/test');
            console.log(res);
        },
    },
    watch: {
        search: {
          handler () {
              this.setSearch()
          }
        }
    },
    beforeDestroy() {
        EventBus.$off('check-marked');
        EventBus.$off('update-history', this.getHistoryRoutes);
        EventBus.$off('update-profile');
        clearInterval(this.intervalNotifications);
        clearInterval(this.intervalCommit);
        clearInterval(this.performanceInterval);
        clearInterval(this.notificationsInterval);
        clearInterval(this.speedInterval);
    },
};
</script>

<style scoped>

.pulsating {
    animation: pulse 1.3s infinite;
}
.bg-red {
    color: red;
}
.bg-yellow {
    color: yellow;
}

/* Definición de la animación */
@keyframes pulse {
    0% {
        color: none;
        transform: scale(1); /* Tamaño normal */
    }
    50% {
        color: red;
        transform: scale(1.1); /* Tamaño ligeramente aumentado */
    }
    100% {
        color: none;
        transform: scale(1); /* Vuelve al tamaño normal */
    }
}
</style>
