<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="382.000000pt" height="308.000000pt" viewBox="0 0 382.000000 308.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,308.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path d="M662 2724 c-14 -10 -22 -25 -22 -45 0 -26 68 -98 558 -585 l557 -554 -557 -554 c-493 -490 -558 -558 -558 -585 0 -37 38 -66 74 -57 13 3 262 247 574 560 l550 555 551 -555 c400 -403 558 -556 578 -560 39 -7 66 15 66 57 0 30 -42 75 -557 586 l-556 553 556 553 c515 511 557 556 557 586 0 42 -27 64 -66 57 -20 -4 -178 -157 -578 -560 l-550 -555 -557 560 c-377 379 -564 560 -577 559 -11 0 -31 -7 -43 -16z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'x-mark',
}
</script>
