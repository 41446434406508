<script>
import lingallery from 'lingallery';
export default {
    name: 'lin-gallery',
    mixins: [lingallery],
    methods: {
        updateCurrentImageSizes() {
          let img;
          if (this.$refs.mainImage && Object.prototype.hasOwnProperty.call(this.$refs.mainImage, 'src')) {
              img = this.$refs.mainImage;
          } else if (this.$refs.mainImage && this.$refs.mainImage.$el) {
              img = this.$refs.mainImage.$el.getElementsByTagName('img')[0]
          }

          if (img) {
            this.currentImageWidth = img.naturalWidth
            this.currentImageHeight = img.naturalHeight
          }
        },

    },
}
</script>
