<template>
    <div class="modal fade bd-example-modal-lg" id="booking-alerts" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="templateBookingVersions" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
            <table class="table table-striped  table-sm table-bordered">
                <tbody>
                    <tr v-for="c of errorList" class="service-card"
                        v-if="!c.r.dayNr || (recordStore.BookingDays[c.r.dayNr] && recordStore.BookingDays[c.r.dayNr].BookingDayServices[c.r.sId] && !recordStore.BookingDays[c.r.dayNr].BookingDayServices[c.r.sId].ServiceCommentDeleted)">
                        <td>
                            <input v-if="c.r.dayNr" v-model="recordStore.BookingDays[c.r.dayNr].BookingDayServices[c.r.sId].ServiceCommentReaded"
                                name="ShowDates" type="checkbox">
                        </td>
                        <td v-if="!c.r.color">
                            <p v-if="!c.r.service">{{tr(c.r.text)}}</p>
                            <p v-else>{{c.r.service}}: {{tr(c.r.text)}}</p>
                        </td>
                        <td v-else :style="'color: ' + c.r.color"><b>{{tr(c.r.text)}}</b></td>
                        <td>
                            <i class="add-item cursor-pointer" @click="remove(c.r)">
                                <font-awesome-icon icon="trash-alt"/>
                            </i>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';
export default {
    name: 'booking-alerts',
    mixins: [escape],
    mounted () {
        $('#booking-alerts').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        bookingErrors () {
            return this.recordStore.bookingErrors;
        },
        errorList () {
            return _.map(this.recordStore.bookingErrors, (r, i) => {
                return {r, i};
            }).sort((a, b) => {
                if (a.r.color == 'red' && b.r.color != 'red') return -1;
                if (a.r.color != 'red' && b.r.color == 'red') return 1;
                return 0;
            });
        }
    },
    methods: {
        remove (c) {
            if (this.recordStore.BookingDays[c.dayNr]) {
                this.recordStore.BookingDays[c.dayNr].BookingDayServices[c.sId].deleteComment();
            }
        },
        close () {
            this.$emit('close');
            $('#booking-alerts').modal('hide');
        }
    },
}
</script>
