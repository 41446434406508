<template>
    <section class="data">
      <div class="container">
        <div class="row justify-content-evenly">
          <div class="col-lg-12 mt-4 mt-lg-0">
            <div class="data-description">
              <h2 class="text-center">{{recordStore.Title}}</h2>
              <text-rows-style
                  class="italic text-justify"
                  tag="p"
                  :text="recordStore.Summary"
                  v-if="!edit"
                  @edit="setEdit"
              ></text-rows-style>
              <text-area-edit-style
                  tag="p"
                  v-else
                  :disabled="!canEdit"
                  :text.sync="recordStore.Summary"
                ></text-area-edit-style>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
const textAreaEditStyle = importVueComp('components/tools', 'TextAreaEditStyle');
import { mapState } from 'vuex';
export default {
    name: 'itinerary-header-summary',
    props: ['canEdit'],
    components: {
        'text-area-edit-style': textAreaEditStyle,
    },
    data () {
        return {
            edit: false,
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
        }),
    },
    methods: {
        tr (t) {
            return tr(t, this.recordStore.Language.Code);
        },
        setEdit () {
            if (!this.canEdit) return;
            if (!this.salesActions && !this.managerActions) return;
            this.edit = true;
        },
    },
}
</script>
