<template>
    <div>
        <component v-if="component" :is="component" v-bind="{...params}"></component>
    </div>
</template>

<script>
const modules = vueTools.importModule('modules', 'tools').default;
export default {
    props: ['report', 'componentName'],
    name: 'report',
    data () {
        return {
            component: null,
            params: {}
        }
    },
    async mounted () {
        this.params = this.$route.query;
        let componentName = this.componentName;
        for (let moduleId in modules) {
            let module = modules[moduleId];
            if (!module.endpoints) continue;
            for (let endpoint of module.endpoints) {
                if (endpoint.report == this.report) {
                    componentName = endpoint.componentName;
                }
            }
        }
        let component = importVueComp('components/reports', componentName);
        if (component) this.component = component;
    },
}
</script>

