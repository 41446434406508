<template>
    <abm v-if="user && ready" endpoint="/api/service/" :options="options" tableName="service"></abm>
</template>


<script>
const abm = importVueComp('components/tools', 'abm')
import { mapState } from 'vuex';
export default {
    name: 'supplier-service-list',
    props: [ "record" ],
    components: {
        abm,
    },
    async mounted () {
        let options = await api.getModuleFieldsOptions('service');
        this.options = _.cloneDeep(options);
        let supplierId = this.record.id;
        let compName = 'sup-service';
        let hotelId;
        if (this.record.SupplierId) {
            supplierId = this.record.SupplierId;
            compName = 'hotel-service';
            hotelId = this.record.id;
        }
        this.options.filters = {'SupplierId': supplierId};
        let supplierIdField = _.find(this.options.fields, (r) => r.name == 'SupplierId');
        if (supplierIdField) {
            supplierIdField.defValue = supplierId;
        }
        this.options.showRecord = (sId) => {
            this.$router.push({ name: compName, params: { id: sId, supId: supplierId, table: 'service',
                hotelId: hotelId, parentTable: this.$parent.table, parentRoute: this.$router.currentRoute.path}})
        }
        this.options.addNew = () => {
            this.$router.push({ name: compName, params: { id: 'new', supId: supplierId, table: 'service',
                hotelId: hotelId, parentTable: this.$parent.table, parentRoute: this.$router.currentRoute.path}})
        }
        this.options.serverSide = false;
        this.ready = true;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    data () {
        return {
            ready: false,
            options: null,
        }
    },
};
</script>
