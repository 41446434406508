<template>
  <div class="mb-4 mt-2">
      <img src="https://nubbo-change-log.s3.amazonaws.com/HideReportFilters.png" class="w-50 border">
  </div>
</template>

<script>
export default {
    name: 'edit-service-title',
}
</script>
