<template>
    <report-window v-if="ready"
        endpoint="/reports/servicebyprovider"
        :perPage="500"
        :fields="fields"
        :title="title"
        :headers="headers"
        :current.sync="current"
        :templates="templates"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
    ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
export default {
    name: "service-by-provider-report",
    props: ["options"],
    components: {
        ReportWindow
    },
    mounted: function() {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        return {
            ready: false,
            current: {},
            fields: [
                {
                    name: "SupplierId",
                    relation: "supplier",
                    optionLabels: "Name",
                    editor: "select",
                    label: "Supplier",
                    addBlank: true
                }
            ],
            title: "Service by Supplier List",
            headers: {
                SupplierName: "Supplier"
            },
            templates: []
        };
    }
};
</script>
