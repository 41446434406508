<script>
const operPaymentListReport = importVueComp('components/reports', 'OperPaymentListReport', 'custom');
export default {
    name: 'custom-oper-payment-list-report',
    mixins: [operPaymentListReport],
    data () {
        let r = operPaymentListReport.data();
        let paymentStatusField = r.fields.find(field => field.name === 'PaymentStatus')
        let paymentArray = paymentStatusField.options
        paymentArray.push({value: 'NO_PAY', label: 'No Pagado'});
        return {
            fields: r.fields
        }
    },
}
</script>