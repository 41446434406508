<script>
import Vue from 'vue';
import { mapState } from 'vuex';

let bookingCoverMainExtrasCrm = Vue.component('booking-cover-main-extras-crm', {
    props: [],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    template: `
          <div class="px-3 col-md-4">
              <vue-select-input
                  fieldName="PaxLeadId"
                  :field="recordStore.fieldsObject.PaxLeadId"
                  label="Lead"
                  :record="recordStore"
                  :addBlank="true"
                  :sortInteger="true"
                  :currentValue.sync="recordStore.PaxLeadId"
              ></vue-select-input>
          </div>
    `,
})


const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain', 'crm');
export default {
    name: 'crm-booking-cover-main',
    mixins: [bookingCoverMain],
    mounted () {
        this.extraComp.push({
            component: bookingCoverMainExtrasCrm,
            ref: 'bookingCoverMainExtrasCrm',
            change: () => {},
        })
    },
}
</script>

<style scoped>
.l-date{
    top: -42px;
    position: relative;
}
</style>

