<template>
    <div v-if="options" class="form-group form-default d-flex" :id="_uid"
        @mouseleave="ellipsis = true">
        <div class="w-100" @click="ellipsis = false" >
            <v-select
                class="form-control vue-select-input"
                :class="{'fill': selected || focus, 'ellipsis': ellipsis, 'clicked': !ellipsis}"
                :disabled="disabled"
                @input="setInput(selected)"
                :options="options"
                v-model="selected"
                label="label"
                autocomplete="on"
                @search:focus="focus=true"
                @search:blur="focus=false"
            ></v-select>
            <span class="form-bar">
            </span>
            <label class="float-label" :class="{'label-focus': focus}">{{tr(label)}}</label>
        </div>
        <font-awesome-icon icon="external-link-alt" v-if="linkTo" @click="openLink" class="cursor-pointer z-999"/>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'vue-select-input',
    props: ['fieldName', 'fieldOptions', 'label', 'currentValue', 'required', 'disabled', 'id',
        'addBlank', 'sort', 'notSort', 'sortInteger', 'field', 'record'],
    components: {
        'v-select': vSelect
    },
    data () {
        return {
            field_value: null,
            selected: null,
            ready: false,
            focus: false,
            refresh: false,
            ellipsis: true,
        }
    },
    computed: {
        linkTo () {
            return this.field && this.field.linkTo;
        }
    },
    asyncComputed: {
        async options () {
            let self = this;
            let options;
            this.refresh;
            if (this.fieldOptions && this.fieldOptions.length) {
                options = _.cloneDeep(this.fieldOptions);
            } else if (this.field) {
                let fieldOptions = await tools.calculateFieldOptions([this.field], null, this.record, null, true,
                  this.field.name, this.currentValue);
                options = fieldOptions[this.field.name];
            }
            if (this.addBlank) {
                options.push({value: null, label: ''})
            }
            if (this.currentValue && this.field && this.field.relation && !_.find(options, (r) => {
                return this.currentValue == r.value;
            })) {
                let r = await api.get(`/api/${this.field.relation}/${this.currentValue}`, {noCollections: true, noRelations: true});
                if (r) {
                    if (typeof(this.field.optionLabels) === 'function') {
                        let label =  this.field.optionLabels(r);
                        options.push({label: label, value: this.currentValue});
                    } else {
                        options.push({label: r[this.field.optionLabels], value: this.currentValue});
                    }
                }
            }
            if (options && !this.notSort) {
                options.sort(( a, b ) => {
                    let aLabel = a.label;
                    if (aLabel && typeof aLabel === 'object') {
                        aLabel = aLabel.label;
                    }
                    if (aLabel) aLabel = tr(aLabel)
                    if (a.sortBy) aLabel = a.sortBy;

                    let bLabel = b.label;
                    if (bLabel && typeof bLabel === 'object') {
                        bLabel = bLabel.label;
                    }
                    if (bLabel) bLabel = tr(bLabel)
                    if (b.sortBy) bLabel = b.sortBy;

                    //let aLabel = a.sortBy || tr(a.label);
                    //let bLabel = b.sortBy || tr(b.label);
                    if (self.sortInteger) {
                        aLabel = parseInt(aLabel);
                        bLabel = parseInt(bLabel);
                    }
                    if ( aLabel < bLabel ){
                        return -1;
                    }
                    if ( aLabel > bLabel ){
                        return 1;
                    }
                    return 0;
                });
            }
            return options.map(o => {
                return {label: o.label, value: o.value, disabled: o.disabled};
            });
        }
    },
    mounted  () {
        if (this.currentValue) {
            this.field_value = this.currentValue;
            let res = _.find(this.options, (r) => r.value == this.currentValue);
            if (res) {
                this.selected = res;
            } else {
                this.selected = null;
            }
            //this.setInput(this.currentValue);
        } else {
            this.selected = null;
        }
        setTimeout(() => {
            this.ready = true;
        }, 10);
    },
    watch: {
        field_value () {
            if (this.ready) {
                this.$emit('update:currentValue', this.field_value);
                this.$emit('change', this.field_value, this.id, this.fieldName);
            }
        },
        currentValue () {
            this.selected = _.find(this.options, (r) => r.value == this.currentValue);
        },
        options () {
            this.selected = _.find(this.options, (r) => r.value == this.currentValue);
        },
        selected () {
            if (this.selected && !this.selected.value) {
                this.selected = null;
            }
        }
    },
    methods: {
        setInput (value) {
            if (this.selected) {
                this.field_value = this.selected.value;
            } else {
                this.field_value = null;
            }
        },
        openLink () {
            if (!this.currentValue) return;
            if (!this.field) return;
            if (!this.field.relation) return;
            EventBus.$emit('open-modal-record', {modalRecord: null, modalTable: this.field.relation, modalId: this.currentValue});
            //this.$router.push({ name: 'record', params: {id: this.currentValue.toString(), table: this.field.relation} })

        }
    }
}
</script>
