<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="640" height="480" viewBox="0 0 640 480" class="service-actions svg-inline--fa fa-ad fa-w-16">

<g transform="matrix(1.03 0 0 1.03 316.56 242.88)"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  transform=" translate(-288, -256)" d="M 64 64 C 28.7 64 0 92.7 0 128 v 64 c 0 8.8 7.4 15.7 15.7 18.6 C 34.5 217.1 48 235 48 256 s -13.5 38.9 -32.3 45.4 C 7.4 304.3 0 311.2 0 320 v 64 c 0 35.3 28.7 64 64 64 H 512 c 35.3 0 64 -28.7 64 -64 V 320 c 0 -8.8 -7.4 -15.7 -15.7 -18.6 C 541.5 294.9 528 277 528 256 s 13.5 -38.9 32.3 -45.4 c 8.3 -2.9 15.7 -9.8 15.7 -18.6 V 128 c 0 -35.3 -28.7 -64 -64 -64 H 64 z m 64 112 l 0 160 c 0 8.8 7.2 16 16 16 H 432 c 8.8 0 16 -7.2 16 -16 V 176 c 0 -8.8 -7.2 -16 -16 -16 H 144 c -8.8 0 -16 7.2 -16 16 z M 96 160 c 0 -17.7 14.3 -32 32 -32 H 448 c 17.7 0 32 14.3 32 32 V 352 c 0 17.7 -14.3 32 -32 32 H 128 c -17.7 0 -32 -14.3 -32 -32 V 160 z" stroke-linecap="round" />
</g>
<g transform="matrix(0.95 0 0 0.95 313.71 241.45)"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  transform=" translate(-320, -256)" d="M 5.1 9.2 C 13.3 -1.2 28.4 -3.1 38.8 5.1 l 592 464 c 10.4 8.2 12.3 23.3 4.1 33.7 s -23.3 12.3 -33.7 4.1 L 9.2 42.9 C -1.2 34.7 -3.1 19.6 5.1 9.2 z" stroke-linecap="round" />
</g>
<g transform="matrix(0.83 0 0 0.83 274.47 264.4)"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"  transform=" translate(-320, -256)" d="M 5.1 9.2 C 13.3 -1.2 28.4 -3.1 38.8 5.1 l 592 464 c 10.4 8.2 12.3 23.3 4.1 33.7 s -23.3 12.3 -33.7 4.1 L 9.2 42.9 C -1.2 34.7 -3.1 19.6 5.1 9.2 z" stroke-linecap="round" />
</g>
<g transform="matrix(0.94 0 0 0.94 338.33 234.17)"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  transform=" translate(-320, -256)" d="M 5.1 9.2 C 13.3 -1.2 28.4 -3.1 38.8 5.1 l 592 464 c 10.4 8.2 12.3 23.3 4.1 33.7 s -23.3 12.3 -33.7 4.1 L 9.2 42.9 C -1.2 34.7 -3.1 19.6 5.1 9.2 z" stroke-linecap="round" />
</g>
    </svg>
</template>

<script>
export default {
    name: 'voucher-slash',
}
</script>
