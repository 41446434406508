<template>
    <div>
          <report-window endpoint="/admin/reports/paymentlist"
          v-if="ready"
          :fields="fields" :title="title" :headers="headers"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :columnsClasses="columnsClasses"
          :notFilterable="options && options.notFilterable"
          :slotsFields="this.current.Format=='0'? slotsFields: []"
          :afterRun="afterRun"
          :callbacks="callbacks"
          :buttons="buttons"
          :headerColumns="headerColumns"
          :hideFilteredFields="true"
          :current.sync="current"
          :totalsOn="totalsOn"
          :fieldClasses="fieldClasses"
          ref="reportWindow"
          @afterEdit="afterEdit"
        ></report-window>
        <select-value
            v-if="selectInvoice"
            @save="setInvoiceToPayments"
            @close="closeSelectInvoice"
            text="Select Purchase Invoice"
            :fieldOptions="selectInvoice">
        </select-value>
        <pay-modal
            v-if="paidInput"
            :show.sync="paidInput"
            @save="pay"
        ></pay-modal>
        <paid-voucher
            :report="report"
            v-if="showPaidVoucher"
            :show.sync="showPaidVoucher"
        ></paid-voucher>
        <link-payment-invoice
            v-if="showLinkPaymentInvoice"
            :show.sync="showLinkPaymentInvoice"
            :payments="selected"
            :current="current"
        ></link-payment-invoice>
    </div>
</template>

<script>
const paymentListReport = importVueComp('components/reports', 'PaymentListReport', 'custom');
const paidVoucherModal = importVueComp('components/reports', 'PaidVoucher');
const linkPaymentInvoiceModal = importVueComp('components/reports', 'LinkPaymentInvoice');
export default {
    name: 'custom-payment-list-report',
    mixins: [paymentListReport],
    props: ['options'],
    components: {
        'payment-list-report' :paymentListReport,
        'paid-voucher': paidVoucherModal,
        'link-payment-invoice': linkPaymentInvoiceModal
    },
    methods: {
        getData (self) {
            let r = paymentListReport.methods.getData(this);
            r.showPaidVoucher = false;
            r.showLinkPaymentInvoice = false;
            r.buttons.push({callback: this.paidVoucher, label: 'Comprobante', buttonClass: 'btn btn-primary',
                id: 'paidVoucher', disabled: false})
            r.buttons.push({callback: this.linkPaymentInvoice, label: 'Vincular Facturas', buttonClass: 'btn btn-primary',
                id: 'linkPaymentInvoice', disabled: false})

            let index = r.headerColumns.indexOf('CurrencyRate');
            r.headerColumns.splice(index + 1, 0, 'MEPCurrencyRate');
            r.headers.MEPCurrencyRate = 'Cotización MEP';
            r.slotsFields.push({name: 'MEPCurrencyRate', editor: 'number', decimal: 2, classes: 'mb-0'})
            return r;
        },
        paidVoucher (self) {
            this.report = self;
            this.showPaidVoucher = true;
        },
        linkPaymentInvoice (self) {
            this.report = self;
            this.showLinkPaymentInvoice = true;
        },
        async pay (currencyRate, currencyId, fromCurrencyId, toCurrencyId, paymentTypeId, mepRate) {
            let data = {
                FromCurrencyId: fromCurrencyId,
                ToCurrencyId: toCurrencyId,
                CurrencyId: currencyId,
                CurrencyRate: currencyRate,
                PaymentTypeId: paymentTypeId,
                MEPCurrencyRate: mepRate,
                Payments: _.map(this.selected, (r) => {
                    return r.id
                }),
            }
            let res = await api.post('/api/bookingpayment/pay_many', JSON.stringify(data));
            if (res) {
                this.report.message = tr('Payments Applied');
                let fields = ['PaymentCurrencyId', 'PaymentStatus', 'PaymentAmount', 'PaymentDate', 'CurrencyRate',
                    'PaymentTypeId', 'MEPCurrencyRate']
                for (let payment of res) {
                    for (let fieldName of fields) {
                        let listRow = _.find(this.report.list, (r) => r.id==payment.id);
                        if (listRow) {
                            listRow[fieldName] = payment[fieldName];
                        }
                    }
                }
            }
        },

    },
}
</script>

