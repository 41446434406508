<template>
    <div class="form-row rooms-config" v-if="ready">
        <div class="section-title col-md-2 pt-0">
            <label>{{tr('Rooms')}}</label>
        </div>
        <div class="col-md-2">
            <i class="add-item cursor-pointer" @click="addRoom" v-if="canEdit">
               <font-awesome-icon icon="plus-circle""/>
            </i>
        </div>
        <div class="col-md-8">
            <div class="form-row" v-for="(room, idx) in recordStore.RoomConfiguration">
                <select-input
                    fieldName="RoomTypeId"
                    :disabled="!canEdit"
                    :fieldOptions="getRoomsOptions"
                    class="col-md-4 mt-0"
                    :currentValue.sync="recordStore.RoomConfiguration[idx].RoomTypeId"
                    :id="idx"
                ></select-input>
                <div class="col-md-4">
                    <input-field
                        :readonly="!canEdit"
                        addClass="mt-0"
                        :field="{name: 'Rooms', editor: 'number'}"
                        :defValue.sync="recordStore.RoomConfiguration[idx].Rooms"
                    >
                    </input-field>
                </div>
                <div class="col-md-2 mt-0">
                    <i class="add-item cursor-pointer" @click="removeRow(idx)" v-if="canEdit">
                       <font-awesome-icon icon="trash-alt""/>
                    </i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'rooms-profile',
    data () {
        return {
            ready: false,
        }
    },
    async mounted () {
        if (!api.tables.roomtype) {
            api.setTable({table: 'roomtype'});
        }
        this.ready = true;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
        }),
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        },
        getRoomsOptions () {
            return _.map(api.tables.roomtype, function(r) {
                return {value: r.id, label: r.Name}
            });
        },
    },
    methods: {
        async addRoom () {
            this.recordStore.addRow({fieldName: 'RoomConfiguration'});
            //await vueTools.addRow(this, null, 'RoomConfiguration');
        },
        async removeRow (rowNr) {
            await this.recordStore.removeRow({fieldName: 'RoomConfiguration', rowNr});
            //await removeRow(this, null, 'RoomConfiguration', rowNr);
        },
    }
}
</script>

