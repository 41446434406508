<template>
    <div>
        <div class="form-row ml-3" v-if="!processing">
            <div class="col-md-12">
                <div v-for="(period, i) in periods" class="card-header ml-4 col-md-12 px-0 py-2" v-if="!periods[i].remove && (!periods[i].Closed || includeClosed)">
                    <div class="section-title py-0">
                        <label class="float-left cursor-pointer" @click="showHotelCost(i)"
                            v-if="periods[i].StartDate && periods[i].EndDate" :class="{'text-danger': periodsError[i]}">
                            {{periods[i].StartDate | toLocalDate }} {{tr('to')}} {{ periods[i].EndDate | toLocalDate }}   {{periods[i].Comment}}
                        </label>
                        <i class="add-item cursor-pointer ml-3" @click="remove(i)">
                            <font-awesome-icon icon="trash-alt"/>
                        </i>
                        <i class="add-item cursor-pointer ml-3" @click="copy(i)">
                            <font-awesome-icon icon="copy"/>
                        </i>
                    </div>
                </div>
            </div>
            <div class="col-md-10 mt-2">
                <i class="add-item cursor-pointer" @click="addPeriod">
                    <font-awesome-icon icon="plus-circle"/>
                </i>
            </div>
            <div class="col-md-2 mt-2">
                <div class="form-group">
                    <div class="form-check">
                        <input v-model="includeClosed" name="includeClosed"
                        class="form-check-input" type="checkbox" id="includeClosed">
                        <label class="smaller form-check-label">{{tr('Show Archived')}}</label>
                    </div>
                </div>

            </div>
        </div>
        <hotel-cost
            v-if="currentPeriod"
            :record="record"
            :period.sync="currentPeriod"
            @close="currentPeriod = null"
            @setModified="setModified"
        ></hotel-cost>
    </div>
</template>

<script>
const hotelCost = importVueComp('components/hotel', 'HotelCost');
import { mapState } from 'vuex';

export default {
    name: 'hotel-cost-list',
    props: ['record'],
    components: {
      'hotel-cost': hotelCost,
    },
    data  () {
        return {
            currentPeriod: null,
            currentPeriodId: null,
            includeClosed: false,
            periods: [],
            ready: false,
            fields: [
                {name: 'StartDate', editor: 'date', required: true},
                {name: 'EndDate', editor: 'date', required: true},
                { name: 'DiscountType', label: 'Discount',  editor: 'select', hideFromList: true,
                    options: [
                        {value: 'NONE', label: 'Without Discount', tr: true},
                        {value: 'CONSECUTIVE', label: 'Consecutive Nights', tr: true},
                        {value: 'TOTAL', label: 'Total Nights', tr: true},
                    ]
                },
                { name: 'CurrencyId', relation: 'currency', optionLabels: 'id'
                    , editor: 'select', label: 'Currency', hideFromList: true},
                { name: 'RoomCostBy', label: 'Cost per', hideFromList: true, editor: 'select',
                    options: [
                        {value: 'ROOM_TYPE_CATEGORY', label: 'Category and Room Type', tr: true},
                        {value: 'NIGHT_ROOM_TYPE', label: 'Program - Night and Room Type', tr: true},
                        {value: 'NIGHT_ROOM_CATEGORY', label: 'Program - Night and Room Category', tr: true},
                        {value: 'ROOM_TYPE_CATEGORY_NIGHT', label: 'Program - Night, Category and Room Type', tr: true},
                    ]
                },
                { name: 'PeriodCostBy', label: '', hideFromList: true, editor: 'select',
                    options: [
                        {value: 'NIGHT', label: 'Night', tr: true},
                        {value: 'PROGRAM', label: 'Program', tr: true},
                    ]
                },
                { name: 'UnitCostBy', label: '', hideFromList: true, editor: 'select',
                    options: [
                        {value: 'ROOM', label: 'Room', tr: true},
                        {value: 'PERSON', label: 'Person', tr: true},
                    ]
                },

            ]

        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        rooms () {
        	return _.filter(this.record.RoomCategories, (r) => !r.Closed);
        },
        roomTypes () {
            return _.map(this.record.HotelRoomTypes, function(r) {
                return r.RoomType;
            })
        },
        nights () {
            if (!this.record.Nights) return [1, 2, 3, 4, 'extra']
            let res = [];
            for (let i=1; i<=parseInt(this.record.Nights); i++) {
                res.push(i);
            }
            res.push('extra');
            return res;
        },
        hasProgramRooms () {
            let rooms = _.filter(this.rooms, (r) => r.Program);
            if (rooms.length>0) return true;
        },
        programRooms () {
            if (this.hasProgramRooms) {
                return _.filter(this.rooms, (r) => r.Program);
            }
            return this.rooms;
        },
        notProgramRooms () {
            if (this.hasProgramRooms) {
                return _.filter(this.rooms, (r) => !r.Program);
            }
            return this.rooms;
        },
        periodsError () {
            let res = {};
            if (!this.record.periodError) return res;
            for (let i in this.periods) {
                let p = this.periods[i];
                for (let period of this.record.periodError) {
                    if (!period) continue
                    if (period.StartDate==p.StartDate && period.EndDate==p.EndDate) {
                        res[i] = true;
                    }
                }
            }
            return res;
        },
    },
    async mounted () {
        api.setProcessing(true)
        if (this.record.id) {
            await this.getHotelCost();
        } else {
            this.periods = []
        }
        api.setProcessing(false)
        this.ready = true;
    },
    watch: {
        periods: {
            handler () {
                        this.setPeriods();
                    },
                    deep: true,
        },
    },
    methods: {
        async setModified () {
            let i = this.currentPeriodId;
            this.periods[i] = this.currentPeriod;
            this.periods[i].isModified = true;
            let hotelCost = []
            let k = 0;
            for (let period of this.periods){
                period.rowNr = k;
                if (period.isModified || period.remove) {
                    hotelCost.push(period)
                }
                k ++;
            }
            let args = { fieldName: 'HotelCost', value: hotelCost};
            await this.$store.dispatch('callAction', {options: null, action: 'setValue', args: args})
        },
        async getHotelCost (){
            let res = await api.get('/api/hotelcost/', {filters: JSON.stringify({HotelId: this.record.id }), orderBy: 'StartDate'})
            if (res){
                this.periods = res;
            }
        },
        setPeriodBy (roomCostBy) {
            var data = {}
            if (roomCostBy=='ROOM_TYPE_CATEGORY'){
                for (let row of this.notProgramRooms) {
                    if (!row) continue;
                    if (row.Closed) continue;
                    for (let roomType of this.roomTypes) {
                        if (!roomType) continue;
                        data[[roomType.id, row.id]] = {value: null, roomType: roomType, roomClass: row};
                    }
                }
            }
            if (roomCostBy=='NIGHT_ROOM_TYPE'){
                for (let roomType of this.roomTypes) {
                    for (let n of this.nights) {
                        data[[n, roomType.id]] = {value: null, roomType: roomType, night: n};
                    }
                }
            }
            if (roomCostBy=='NIGHT_ROOM_CATEGORY'){
                for (let row of this.programRooms) {
                    if (row.Closed) continue;
                    for (let n of this.nights) {
                        data[[n, row.id]] = {value: null, roomClass: row, night: n};
                    }
                }
            }
            if (roomCostBy=='ROOM_TYPE_CATEGORY_NIGHT'){
                for (let roomType of this.roomTypes) {
                    for (let row of this.programRooms) {
                        if (row.Closed) continue;
                        for (let n of this.nights) {
                            data[[n, row.id, roomType.id]] = {value: null, roomClass: row, roomType: roomType, night: n};
                        }
                    }
                }
            }

            return data;
        },
        addPeriod (){
            var data = this.setPeriodBy(this.record.RoomCostBy);
            let unitCostBy;
            let periodCostBy;
            if (this.record.RoomCostBy=='ROOM_TYPE_CATEGORY'){
                unitCostBy = 'ROOM';
                periodCostBy = 'NIGHT';
            }
            if (this.record.RoomCostBy=='NIGHT_ROOM_TYPE'){
                unitCostBy = 'PERSON';
                periodCostBy = 'PROGRAM';
            }
            if (this.record.RoomCostBy=='NIGHT_ROOM_CATEGORY'){
                unitCostBy = 'PERSON';
                periodCostBy = 'PROGRAM';
            }
            if (this.record.RoomCostBy=='ROOM_TYPE_CATEGORY_NIGHT'){
                unitCostBy = 'PERSON';
                periodCostBy = 'PROGRAM';
            }

            this.periods.push({
                id: null,
                isModified: true,
                HotelId: this.record.id,
                StartDate: moment(new Date()).format('YYYY-MM-DD'),
                EndDate: moment(new Date()).format('YYYY-MM-DD'),
                Closed: null,
                RoomCostBy: this.record.RoomCostBy,
                CurrencyId: this.record.CurrencyId,
                Comment: null,
                UnitCostBy: unitCostBy,
                PeriodCostBy: periodCostBy,
                Data: data
            })
            this.periods = Object.assign([], this.periods);
        },
        copy (i){
            let newPeriod = _.cloneDeep(this.periods[i]);
            newPeriod.id = null;
            newPeriod.isModified = null;
            newPeriod.StartDate = moment(new Date()).format('YYYY-MM-DD');
            newPeriod.EndDate = moment(new Date()).format('YYYY-MM-DD');
            newPeriod.Comment = null;
            newPeriod.Closed = null;
            this.periods.push(newPeriod);
            this.periods = Object.assign([], this.periods);
        },
        remove (i) {
            this.periods[i].remove = true;
            this.periods = Object.assign([], this.periods);
        },
        setPeriods (){
            this.recordStore.setPeriods(this.periods);
        },
        showHotelCost (i) {
            this.currentPeriod = this.periods[i];
            this.currentPeriodId = i;
        }
    },
}
</script>
