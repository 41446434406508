<template>
  <div class="small" v-if="dataCollection">
    <pie-chart :chart-data="dataCollection"></pie-chart>
  </div>
</template>

<script>
  import pieChart from '@/tools/PieChart.js'
  export default {
    name: 'pie-report',
    components: {
      'pie-chart': pieChart,
    },
    data () {
      return {
        dataCollection: null
      }
    },
    async mounted () {
      await this.fillData()
    },
    methods: {
      async fillData () {
        let res = await api.get('/reports/bookings_summary')
        if (res) {
            this.dataCollection = {
              labels: [tr('Sales'), tr('Quotations')],
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: ['#f87979', '#3283e7'],
                  data: [res.sales, res.quotes]
                }
              ]
            }
        }
      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }
    }
  }
</script>

<style>
  .small {
    max-width: 300px;
    margin:  10px auto;
  }
</style>