<template>
    <div id="pstyles">
      <itinerary-header-bar
          :view="view"
          @print="print"
      ></itinerary-header-bar>
      <main id="main">
        <itinerary-header
            :canEdit="canEdit"
            :crop="crop"
            :expanded="expanded"
            :version="version"
        ></itinerary-header>
        <itinerary-body
            :crop="crop"
            :expanded="expanded"
            :canEdit="canEdit"
        ></itinerary-body>
        <p v-if="expanded" class="page-break" style="page-break-after: always;">&nbsp;</p>
        <p v-if="expanded" class="page-break" style="page-break-before: always;">&nbsp;</p>
        <itinerary-terms
            :expanded="expanded"
        ></itinerary-terms>
        <itinerary-conditions
            v-if="recordStore && recordStore.id"
            :crop="crop"
            :expanded="expanded"
            :id="id"
            :version="version"
        ></itinerary-conditions>
      </main>
      <a class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </div>
</template>

<script>
import '@/css/style.scss';

import { mapState } from 'vuex';
const itineraryHeader = importVueComp('components/itinerary', 'ItineraryHeader');
const itineraryHeaderBar = importVueComp('components/itinerary', 'ItineraryHeaderBar');
const itineraryBody = importVueComp('components/itinerary', 'ItineraryBody');
const itineraryTerms = importVueComp('components/itinerary', 'ItineraryTerms');
const itineraryConditions = importVueComp('components/itinerary', 'ItineraryConditions');

var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'itinerary',
    props: ['canEdit', 'view', 'id', 'version'],
    components: {
        'itinerary-header': itineraryHeader,
        'itinerary-header-bar': itineraryHeaderBar,
        'itinerary-body': itineraryBody,
        'itinerary-terms': itineraryTerms,
        'itinerary-conditions': itineraryConditions,
    },
    data () {
        return {
            expanded: false,
            crop: false,
        }
    },
    mounted () {
        let dataTheme = 'root';
        let p = this.$router.currentRoute.meta.public;
        /*if (p && this.recordStore && this.recordStore.Agency && this.recordStore.Agency.IconId) {
            document.title = this.recordStore.Agency.Name;
            const link = document.querySelector("[rel='icon']")
            link.setAttribute('href', this.recordStore.Agency.Icon.url)
        }*/
        if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.Code) {
            dataTheme = this.recordStore.Agency.Code;
        }
        document.documentElement.setAttribute("data-theme", dataTheme);
    },
    computed:{
        ...mapState({
            fullScreen: state => state.main.fullScreen,
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        imageAgency () {
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.Image) {
                return this.recordStore.Agency.Image.url;
            }
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        async print () {
            this.crop = true;
            this.expanded = true;
            await this.$nextTick();
            setTimeout(function() {
                window.print();
            }, 1000);
        },
        scrollTo (id) {
            frontTools.scrollTo(id, -70);
        }
   },
}
</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>
