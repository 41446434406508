<template>
    <div v-if="show" class="form-row mb-2">
        <div class="col-12 form-row days-drag" v-dragula :bag="'detailbag'+_uid" id="detailed-cities">
            <div class="col-md-12 card-row" v-for="(city, idx) of cityList" :city-nr="idx">
                <div class="card city-card" :daynr="idx" >
                    <div class="card-header clearfix card-header-day py-1 pl-5 ml-0 section-title">
                        <label class="float-left mb-0"> <b>{{city.City.Name}}</b></label>
                    </div>
                    <div class="card-body day-group-body ml-3">
                        <div class="form-row" v-for="dayNr in city.DayNrs">
                            <label class="float-left mb-0"> <b>Día {{dayNr + 1}}:</b> {{getServicesNamesByDayCity(dayNr, city.City.id)}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <button type="button" class="btn btn-outline-success btn-group" @click="$emit('groupBy')">
                {{tr('Group by Day')}}
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Vue from 'vue'
var EventBus = require('@/tools/event-bus').default;
var VueDragula = require('vue-dragula');
Vue.use(VueDragula);

export default {
    name: 'booking-detail-city',
    props: [],
    components: {
    },
    data () {
        return {
            isOver: false,
            show: true,
            displayCities: null,
            cityList: {},
        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
        }),
    },
    mounted () {
        let self = this;
        this.cityList = Object.assign({}, this.recordStore.cityList);
        Vue.vueDragula.eventBus.$on('drop', async function ([bag, el, target, source]) {
            //vueTools.setProcessing(true)
            api.setProcessing(true)
            let moveFrom = $(el).attr('city-nr');
            let div = $('#detailed-cities')[0].childNodes;
            let moveTo;
            let newCityOrder = {}
            let cityList = self.recordStore.cityList;
            for (let i in cityList) {
                newCityOrder[i] = {name: cityList[i].City.Name, from: parseInt(i), to: i}
            }
            for (let i = 0; i < div.length; i++) {
                let node = div[i];
                let cityNr = node.getAttribute('city-nr');
                if (newCityOrder[cityNr]) newCityOrder[cityNr].to = i;
                if (cityNr==moveFrom) {
                    moveTo = i;
                }
            }
            await self.recordStore.switchCities(moveFrom, moveTo);
            EventBus.$emit('set-record');
            self.cityList = Object.assign({}, self.recordStore.cityList);
            let bagId = 'detailbag' + self._uid;
            let vueEvent = Vue.vueDragula.find(bagId);
            if (vueEvent && vueEvent.drake) vueEvent.drake.cancel(true);
        })
        Vue.vueDragula.eventBus.$on('cancel',function() {
            self.isOver = false;
            return true;
        })
    },
    methods: {
        getServicesNamesByDayCity (dayNr, cityId) {
            return this.recordStore.getServicesNamesByDayCity(dayNr, cityId);
        },
    },
    beforeDestroy() {
        Vue.vueDragula.eventBus._events = {}
    },
}
</script>



