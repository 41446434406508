<template>
    <div class="form-row">
        <table class="table table-sm table-bordered" v-if="ready">
            <thead>
                <tr class="table-secondary text-center">
                    <th class="table-head-th">{{tr('Room')}}</th>
                    <th v-for="roomType in roomTypes" class="table-head-th" v-if="roomType">
                        {{roomType.Name}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) of rooms" v-if="!row.Closed" class="form-material">
                    <td class="text-center">
                        <span v-if="row.Comment">{{row.Name}}, {{row.Comment}}</span>
                        <span v-else>{{row.Name}}</span>
                    </td>
                    <td v-for="(roomType, k) of roomTypes" class="hotel-table-row-input">
                        <number-input
                            v-if="roomType && period.Data[[roomType.id, row.id]]"
                            class="m-0"
                            :v.sync="period.Data[[roomType.id, row.id]].value"
                            :currency="currency"
                            :id="i + '-' + k" :column="k" :row="i"
                        ></number-input>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
const roomsMixin = importVueComp('components/tools', 'TableMixin');
export default {
    name: 'room-type-category',
    props: ['rooms', 'period', 'roomTypes'],
    mixins: [roomsMixin],
    data () {
        return {
            ready: false,
            p: null,
            currency: null,
        }
    },
    mounted () {
        this.p = this.period;
        this.currency = _.find(api.tables.currency, (r) => r.id == this.period.CurrencyId);
        this.setRoomData();
        this.ready = true;
    },
    watch: {
        period: {
            handler () {
                        this.period.isModified = true;
                        this.p = this.period;
                        this.setRoomData()
                        this.$emit('setModified');
                    },
                    deep: true,
        },
        rooms: {
            handler () {
                        this.setRoomData();
                    },
                    deep: true,
        },
        roomTypes: {
            handler () {
                        this.setRoomData();
                    },
                    deep: true,
        },
    },
    methods: {
        setRoomData () {
            let found = false;
            if (this.roomTypes) {
                for (let row of this.rooms) {
                    if (row.Closed) continue;
                    if (!row.id) continue;
                    for (let roomType of this.roomTypes) {
                        if (!roomType) continue;
                        if (!row) continue;
                        if (!this.p.Data[[roomType.id, row.id]]) {
                            this.p.Data[[roomType.id, row.id]] = {value: null, roomType: roomType, roomClass: row};
                            found = true;
                        }
                    }
                }
            }
            if (found) {
                this.p.isModified = true;
                this.p = Object.assign({}, this.p);
                //this.$emit('update:period', this.p);
            }
        },
    },
}
</script>

