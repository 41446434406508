<script>
import recordWindow from '@/components/tools/RecordWindow';
export default {
    name: 'mail-window',
    mixins: [recordWindow],
    methods: {
        async onMount () {
            let record;
            let model = await api.importMixinModule('model', 'mail');
            if (this.id != 'new') {
                record = await model.get({id: this.id});
            } else {
                record = await new model({});
                this.isNew = true;
            }
        },
        async runSave () {
            this.errors = [];
            /*let errors = this.bookingChecks();
            if (errors.length>0) {
                let msg = errors.join('; ');
                if (this.errors.indexOf(msg)==-1) {
                    this.errors.push(msg);
                }
                return;
            }*/
            if (this.recordStore.hasErrors) {
                this.alertsNextSave = true;
                this.showBookingAlerts();
            } else {
                await this.save();
            }
        },

    },
    beforeDestroy() {
        EventBus.$off('show-version-input');
        EventBus.$off('show-version-list');
        EventBus.$off('show-notes');
        EventBus.$off('share-booking');
        EventBus.$off('form-actions');
        EventBus.$off('show-alerts');
        EventBus.$off('action-save');
        EventBus.$off('action-close');
    },
}
</script>
