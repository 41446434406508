<template>
  <div class="modal fade bd-example-modal-lg" id="reports-filters-config-modal" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document"">
      <div class="modal-content">
        <div class="modal-header py-2">
          <h5 class="modal-title">{{tr('Configure report filters')}}</h5>
        </div>
        <div class="modal-body py-1">
          <div class="row px-2">
              <div class="col-4" v-for="field of fields">
                  <input type="checkbox" class="" v-model="values[field.name]" @change="setValues(field.name)">
                  <label class="smaller ml-1 form-check-label">{{tr(field.label)}}</label>
              </div>
          </div>
        </div>
        <div class="modal-footer  mx-3 py-2">
            <button @click="close" type="button" class="btn btn-secondary">
              {{tr('Close')}}
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'report-filters-config-modal',
    mixins: [escape],
    props: ['reportName', 'fields', 'show'],
    data () {
        return {
            values: {}
        }
    },
    async mounted () {
        //$('#reports-filters-config-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        this.open();
    },
    methods: {
        open () {
            this.getItems();
            $('#reports-filters-config-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        },
        close () {
            //this.$emit('close');
            $('#reports-filters-config-modal').modal('hide');
            this.$parent.filtersConfig = this.values;
            this.$emit('update:show', false);
        },
        getItems () {
            this.values = this.$parent.getFiltersConfig();
            /*let r = localStorage.getItem('hideReportFilters');
            if (r) r = JSON.parse(r);
            if (!r) r = {};
            if (!r[this.reportName]) r[this.reportName] = {};
            for (let field of this.fields) {
                this.values[field.name] = true;
                if (r[this.reportName][field.name]) this.values[field.name] = false;
            }
            this.values = Object.assign({}, this.values);
            return this.values;*/
        },
        setItems () {
            let r = localStorage.getItem('hideReportFilters');
            if (r) r = JSON.parse(r);
            if (!r) r = {};
            if (!r[this.reportName]) r[this.reportName] = {};
            for (let field of this.fields) {
                r[this.reportName][field.name] = !this.values[field.name];
            }
            localStorage.setItem('hideReportFilters', JSON.stringify(r));
        },
        async setValues (fieldName) {
            await this.$nextTick();
            //console.log(fieldName, this.values[fieldName])
            this.setItems();
        }
    },
}
</script>

<style scoped>

</style>
