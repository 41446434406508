<template>
    <receipt-pending-report></receipt-pending-report>
</template>

<script>
const receiptPendingReport = importVueComp('components/reports', 'ReceiptPendingReport');
export default {
  name: 'receipt-pending',
  components: {
    'receipt-pending-report': receiptPendingReport
  },
}
</script>

