<template>
      <report-window endpoint="/admin/reports/paymentlist?oper=1"
      v-if="ready"
      :fields="fields"
      :title="title"
      :headers="headers"
      :templates="templates"
      :perPage="(options && options.perPage)? options.perPage: 500"
      :hideFilters="options && options.hideFilters"
      :headerColumns="headerColumns"
      :runMounted="options && options.runMounted"
      :cardView="options && options.cardView"
      :notFilterable="options && options.notFilterable"
      :slotsFields="[]"
      :current.sync="current"
      :totalsOn="totalsOn"
      :columnsClasses="columnsClasses"
      :columnClick="columnClick"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'oper-payment-list-report',
    props: ['options'],
    components: {
        ReportWindow,
    },
    computed: {
        totalsOn () {
            let res = [];
            if (this.current.CurrencyId) {
                res.push('Amount');
            }
            if (this.current.PaymentCurrencyId) {
                res.push('PaymentAmount');
            }
            if (res.length == 0) return null;
            return res;
        },
        columnsClasses () {
            return {
                Amount: 'text-right',
                PaymentAmount: 'text-right',
                BookingId: 'cursor-pointer-report',
            }
        }
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        let self = this;
        let bookingPaymentView = api.importMixinModule('bookingpayment', 'views');
        let paymentStatusOptions = _.map(bookingPaymentView.paymentStatusOptions(), (r) => {
            r.disabled = false;
            return r;
        });
        return {
            ready: false,
            headerColumns: ['id', 'DueDate', 'SupplierName', 'DateIn', 'Amount', 'CurrencyId', 'BookingId'
                , 'PaxName','PaymentDate', 'PaymentStatus', 'PaymentTypeId', 'PaymentAmount', 'PaymentCurrencyId', 'Comment'],
            current: {},
            onAccountOptions: [],
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From'},
              {name: 'ToDate', editor: 'date', label: 'To'},
              {name: 'SupplierId', relation: 'supplier', optionLabels: 'Name'
                  , label: 'Supplier', addBlank: true, editor: 'vue-select', list: true},
              {name: 'PaymentStatus', label: 'Status', editor: 'select', addBlank: true,
                  options: paymentStatusOptions
              },
              {name: 'FilterDate', label: 'Filter Date by', editor: 'select', required: true, defValue: '0',
                  options: [
                      {value: '0', label: 'Due Date'},
                      {value: '1', label: 'Date Inn'},
                      {value: '2', label: 'Payment Date'},
                      {value: '3', label: 'Date Out'}
                  ]
              },
              {name: 'SummaryRow', label: 'Totals', editor: 'select', defValue: '0',
                  options: [
                    {value: '0', label: 'NO'},
                    {value: '1', label: 'SI'},
                  ]
              },
              {name: 'CurrencyId', label: 'Currency', editor: 'select', optionLabels: 'id', relation: 'currency',
                    addBlank: true},
              {name: 'PaymentCurrencyId', label: 'Payment Currency', editor: 'select', optionLabels: 'id', relation: 'currency',
                    addBlank: true},
            ],
            title: 'Payments List',
            headers: {
                id: 'Payment Number',
                Amount: 'Amount to Pay',
                PaymentAmount: 'Paid Amount',
                PaxName: 'Passenger',
                PaymentDate: 'Payment Date',
                BookingId: 'File Nr',
                Comment: 'Notes',
                SupplierName: 'Supplier',
                CurrencyId: 'Currency',
                PaymentCurrencyId: 'Payment Currency',
                OnAccountPaymentId: 'Credit',
                DateIn: 'Date Inn',
                PaymentTypeId: 'Payment Type'
            },
            templates: [
                {name: 'DateIn',
                    callback: (row) => {
                        if (!row.DateIn) return '';
                        return moment(row.DateIn).format("DD/MM/YYYY")
                    }
                },
                {name: 'PaymentStatus',
                    callback: (row) => {
                        if (!row.PaymentStatus) return '';
                        return tr(row.PaymentStatus)
                    }
                },
                {name: 'PaymentTypeId',
                    callback: (row) => {
                        if (!row.PaymentTypeId) return '';
                        if (!row.PaymentType) return '';
                        return row.PaymentType.Name
                    }
                },
                {name: 'DueDate',
                    callback: (row) => {
                        if (!row.DueDate) return '';
                        return moment(row.DueDate).format("DD/MM/YYYY")
                    }
                },
                {name: 'PaymentDate',
                    callback: (row) => {
                        if (!row.PaymentDate) return '';
                        return moment(row.PaymentDate).format("DD/MM/YYYY")
                    },
                },
                {name: 'PaymentStatus',
                    callback: (row) => {
                        return bookingPaymentView.paymentStatus[row.PaymentStatus];
                    }
                },
                {name: 'Amount', callback: (row) => {
                    if (row.calculated) {
                        if (this.current.CurrencyId) {
                            return tools.toNumber(row.Amount, null, this.current.CurrencyId);
                        }
                        return row.Amount
                    };
                    return tools.toNumber(row.Amount, null, row.CurrencyId);
                  }
                },
                {name: 'PaymentAmount', callback: (row) => {
                    if (row.calculated) {
                        if (this.current.PaymentCurrencyId) {
                            return tools.toNumber(row.PaymentAmount, null, this.current.PaymentCurrencyId);
                        }
                        return row.PaymentAmount
                    };
                    return tools.toNumber(row.PaymentAmount, null, row.PaymentCurrencyId);
                  }
                }
            ],
            columnClick: {
                BookingId: this.showBooking,
            }
        }
    },
    methods: {
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-sale', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        }
    }
}
</script>

