<template>
    <div>
      <tag :is="tag">{{v}}</tag>
    </div>
</template>

<script>
export default {
  name: 'html-tag',
	props: ['tag', 'value', 'ttr'],
    render(h){
        return h(this.tag, this.$slots.default)
    },
    computed: {
        v () {
            if (this.ttr) return tr(this.value);
            return this.value;
        }
    }

}
</script>
