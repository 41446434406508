<template>
  <div class="modal fade" id="inputQuote" tabindex="-1" role="dialog" aria-labelledby="inputQuoteLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Passengers')}}</h5>
        </div>
        <div class="modal-body form-row form-material">
          <div class="col-sm-6 form-group form-default">
             <number-input
                label="Adults"
                :v.sync="adt"
                d="0"
            ></number-input>
          </div>
          <div class="col-sm-6 form-group form-default">
             <number-input
                label="Children"
                :v.sync="chd"
                d="0"
            ></number-input>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">{{tr('Done')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'input-quote',
    props: ['defADT', 'defCHD'],
    mixins: [escape],
    data () {
        return {
            adt: 1,
            chd: 0
        }
    },
    mounted () {
        if (this.defADT) this.adt = this.defADT;
        if (this.defCHD) this.chd = this.defCHD;
        $('#inputQuote').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        save () {
            $('#inputQuote').modal('hide');
            this.$emit('save', this.adt, this.chd);
        },
        close (){
            this.$emit('close');
            $('#inputQuote').modal('hide');
        },
    },
}
</script>
