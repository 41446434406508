<template>
    <report-window
    v-if="ready"
    endpoint="/admin/reports/invoices"
    :perPage="500"
    :fields="fields"
    :title="title"
    :headers="headers"
    :templates="templates"
    :hideFilters="options && options.hideFilters"
    :runMounted="options && options.runMounted"
    :cardView="options && options.cardView"
    :current.sync="current"
    :slotsFields="slotsFields"
    :callbacks="callbacks"
    :headerColumns="null"
    :columnsClasses="columnsClasses"
    :buttons="buttons"
    :notFilterable="options && options.notFilterable">
    </report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "invoices-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        columnsClasses () {
            return {
                Cost: 'text-right'
            }
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From', columns: 6},
              {name: 'ToDate', editor: 'date', label: 'To', columns: 6},
              {name: 'InvoiceNumber', label: 'Reference', columns: 12},
            ],
            title: "Sales Invoices List",
            headers: {
                InvoiceNumber: 'Reference',
                Amount: 'Amount',
                TaxCode: 'Tax',
                InvoiceDate: 'Invoice Date',
                Detail: 'Concept',
            },
            templates: [
                {name: 'Cost', callback: function(row) {
                    return parseFloat(row.Cost).toFixed(2);
                  }
                },
                {
                    name: "InvoiceDate",
                    callback: function(row) {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                }
            ],
            slotsFields: [],
            callbacks: {},
            buttons: [],
        }
    },
};
</script>
