import Contact from '@/extra/contacts/components/pages/Contact';

function getCustomRoutes () {
    let res = [];
    var supplierContactWindow = importVueComp('components/pages', 'SupplierContactWindow');
    var agencyContactWindow = importVueComp('components/pages', 'AgencyContactWindow');
    res.push({ path: '/abm/supplier/:supId/:table/:id?', name: 'supContact', component: supplierContactWindow, props: true});
    res.push({ path: '/abm/supplierservice/:supId/:table/:id?', name: 'supServiceContact', component: supplierContactWindow, props: true});
    res.push({ path: '/abm/hotel/:hotelId/:supId/:table/:id?', name: 'hotelContact', component: supplierContactWindow, props: true});
    res.push({ path: '/abm/agency/:agencyId/:table/:id?', name: 'agencyContact', component: agencyContactWindow, props: true});

    return {routes: res, remove: []};
}

export default getCustomRoutes();