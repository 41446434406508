<template>
  <div v-if="ready">
    <section>
      <div class="container">
        <div class="row px-3">
            <h4>{{tr('Nuestro trabajo responde a las premisas de los ODS')}}</h4>
        </div>
        <div class="row px-3">
            <div class="col-12 d-flex justify-content-center">
              <img src="@/extra/custom/components/itinerary/assets/img/ods-obj.jpeg" class="w-100">
            </div>
            <div v-for="img of odsImages" class="col-lg-2 col-6 px-0 p-1">
                <img :src="img.url" width="100%">
            </div>
        </div>
        <div class="row px-4 mt-3">
            <text-rows-style class="ods" :text="odsText"></text-rows-style>
        </div>
        <div class="row px-2 mt-4">
            <strong>
            <div class="col-12">{{tr('Your trip contributes to')}}</div>
            <ul>
            <li v-for="ods of recordStore.odsList">
                {{ods.Name}}
            </li>
            </ul>
            </strong>
        </div>
      </div>
    </section>
    <section id="clients" class="clients">
      <div class="container">
          <div class="row d-flex justify-content-center mb-2">
              <h3>{{tr('PARTNER')}}</h3>
          </div>
          <div class="row d-flex justify-content-center mb-2">
              <h4><i>{{tr('People who always support and endorse our good work')}}</i></h4>
          </div>
          <div class="clients-slider pt-3 row">
            <div class="align-items-center">
                <photo-slider
                    :images="images"
                    :crop="crop"
                    :seconds="3"
                    imgClass="img-fluid big-img img-clients"
                    :margin="20"
                    :f="1"
                    :quantity="5"
                    :innerWidth="200"
                    :repeat="true"
                ></photo-slider>
            </div>
          </div>
      </div>
    </section>
    <section id="faq" class="faq">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
              <a :href="termsLink" target="_blank">
                  {{tr('Terms and Conditions')}}
              </a>
          </div>
          <div class="col-lg-12">
              <a :href="visa" target="_blank">
                  {{tr('Travel to Argentina: Requirements | Visa')}}
              </a>
          </div>
          <div class="col-lg-12">
              <a :href="sustainabilityPolicy" target="_blank">
                  {{tr('Sustainability Policy')}}
              </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const photoSlider = importVueComp('components/tools', 'PhotoSlider');
const itineraryConditions = importVueComp('components/itinerary', 'ItineraryConditions', 'custom');
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-conditions',
    mixins: [itineraryConditions],
    components: {
        'photo-slider': photoSlider,
    },
    data () {
        return {
            images: [],
            ready: false,
            odsImages: [],
            odsText: '',
        }
    },
    computed: {
        termsLink () {
            if (this.recordStore.Language.Code == 'es') return 'https://mater.travel/terminos-y-condiciones/?lang=es';
            if (this.recordStore.Language.Code == 'fr') return 'https://mater.travel/termes-et-conditions/?lang=fr';
            if (this.recordStore.Language.Code == 'en') return 'https://mater.travel/terms-and-conditions/';
        },
        visa () {
            if (this.recordStore.Language.Code == 'es') return 'https://mater.travel/viajar-a-argentina-requisitos-visa/?lang=es';
            if (this.recordStore.Language.Code == 'fr') return 'https://mater.travel/voyager-en-argentine-formalites-dentree-visa/?lang=fr';
            if (this.recordStore.Language.Code == 'en') return 'https://mater.travel/travel-to-argentina-requirements-visa/';
        },
        sustainabilityPolicy () {
            if (this.recordStore.Language.Code == 'es') return 'https://mater.travel/nuestras-politicas-de-sustentabilidad/?lang=es';
            if (this.recordStore.Language.Code == 'fr') return 'https://mater.travel/politique-de-durabilite/?lang=fr';
            if (this.recordStore.Language.Code == 'en') return 'https://mater.travel/sustainability-policy/';
        }
    },
    async mounted () {
        let res = [];
        res.push(await this.importImage('custom/components/itinerary/assets/img/img1.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img2.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img3.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img4.png'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img5.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img6.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img7.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img8.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img9.png'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img10.png'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img11.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img12.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img13.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img14.jpeg'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img15.png'));
        res.push(await this.importImage('custom/components/itinerary/assets/img/img16.png'));
        this.images = res;
        let odsImages = [];
        //odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-obj.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-1.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-2.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-3.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-4.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-5.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-6.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-7.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-8.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-9.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-10.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-11.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-12.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-13.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-14.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-15.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-16.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-17.jpeg'));
        odsImages.push(await this.importImage('custom/components/itinerary/assets/img/ods-18.jpeg'));
        this.odsImages = odsImages;
        this.odsText = this.getODS();
        this.ready = true;
    },
    methods: {
        async importImage (src) {
            let res = {id: src};
            await Promise.resolve(import(`@/extra/${src}`)).then((value) => {
                res.url = value.default;
            });
            return res;
        },
        getODS () {
            if (!this.recordStore) return '';
            let r = _.find(api.tables.odstext, (c) => c.LanguageId == this.recordStore.LanguageId)
            if (r) return r.Text;
            return '';
        },

    }

}
</script>

<style scoped>


.clients-slider .photo-slider {

    .img-clients {
      max-width: 100%;
      width: auto;
      height: 120px !important;
      object-fit: initial;
      object-position: bottom;
      padding: 10px;
    }

    #slides article {
      float: left;
      padding-right: 10px;
      max-height: 120px;
    }

}

@media print {
    .clients-slider {
        display: none;
    }
}

@media (max-width: 768px) {
    .clients-slider .photo-slider {
        #overflow {
            width: 300% !important;
        }
    }
}

</style>
