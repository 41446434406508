<template>
    <div class="modal fade bd-example-modal-lg" id="day-service-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content form-material">
          <div class="modal-header py-2 section-title">
                <label class="mt-1 mb-0 ml-4">
                  {{tr('Edit Service')}}
                </label>
                <button type="button" class="cursor-pointer close ml-3" @click="close">
                    <span aria-hidden="true">&times;</span>
                </button>
          </div>
          <div class="modal-body">
              <day-service
                  :compressView="compressView"
                  :service.sync="service"
                  :rowIdx="rowIdx"
                  :dayId="dayId"
                  :dayNr="dayNr"
                  @replaceService="replaceService"
                  @removeService="removeService"
                  @close="close"
              ></day-service>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const dayService = importVueComp('components/booking', 'DayService');
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';

export default {
    name: 'day-service-modal',
    mixins: [escape],
    props: ['service', 'rowIdx', 'dayId', 'dayNr', 'compressView'],
    components: {
      'day-service': dayService,
    },
    data () {
        return {
        }
    },
    mounted () {
        $('#day-service-modal').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    computed:{
    },
    methods: {
        close () {
            $('#day-service-modal').modal('hide');
            this.$emit('close');
        },
        removeService () {
            this.close();
            this.$emit('removeService');
        },
        replaceService () {
            this.close();
            this.$emit('replaceService');
        }


    },
}
</script>



