
<script>
const bookingWindow = importVueComp('components/booking', 'BookingWindow');
export default {
    name: 'my-template-window',
    mixins: [bookingWindow],
    methods: {
        closePath () {
            this.$router.push({ name: 'abm-my-templates'});
        },
    }
}
</script>
