
async function mountBooking (id) {

        let options = await api.getModuleFieldsOptions('booking');
        let endpoint = '/api/booking/';
        let model = api.importMixinModule('booking', 'model');
        let record = await model.get({id, tableName: 'booking', options, endpoint, payments: true, receipts: true});
        return record;
}

export default mountBooking;